import {Values} from '@wix/devzai-utils-common';
import {DoppeBusinessEntity} from './doppe-business-entity';
import {DoppeAutoProductType} from './doppe-auto-product-type';

export const DoppeProductsExternalDataSourceType = {
    LatestWixProducts: 'LatestWixProducts',
    LatestHoppProducts: 'LatestHoppProducts',
} as const;

export type DoppeProductsExternalDataSourceType = Values<typeof DoppeProductsExternalDataSourceType>;

export type DoppeProductsExternalDataSource =
    | {
        type: typeof DoppeProductsExternalDataSourceType.LatestWixProducts;
    }
    | {
        type: typeof DoppeProductsExternalDataSourceType.LatestHoppProducts;
    };

export function doppeProductsExternalDataSourceResolveBusinessEntityReferences(
    externalDataSource: DoppeProductsExternalDataSource
) {
    switch (externalDataSource.type) {
        case DoppeProductsExternalDataSourceType.LatestWixProducts: {
            return {businessEntity: DoppeBusinessEntity.WixAutoProductsList, id: DoppeAutoProductType.Latest}
        }
        case DoppeProductsExternalDataSourceType.LatestHoppProducts: {
            return {businessEntity: DoppeBusinessEntity.HoppAutoProductsList, id: DoppeAutoProductType.Latest}
        }
    }
}