import {doppeActionTypeDefineViewerMetadata} from '../../client-server-common/doppe-action-types/doppe-action-type';
import React from 'react';
import {doppeActionTypeTestimonialsList} from './doppe-action-type-testimonials-list';

export const doppeActionTypeTestimonialsListViewer = doppeActionTypeDefineViewerMetadata(doppeActionTypeTestimonialsList, {

    loadViewerComponents: async () => {
        return {
            DoppeActionTypeTestimonialsListWidget: (await import('./doppe-action-type-testimonials-list-widget/doppe-action-type-testimonials-list-widget')).DoppeActionTypeTestimonialsListWidget
        }
    },

    renderWidget: (renderProps, viewerComponents) => {

        const {
            DoppeActionTypeTestimonialsListWidget
        } = viewerComponents;

        return <DoppeActionTypeTestimonialsListWidget {...renderProps} />;
    }
})