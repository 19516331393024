import {Nullable} from "@wix/devzai-utils-common";

export function wixDeployPreviewGetHeaders (deployPreview: Nullable<string>) {
    if (deployPreview) {
        const splittedDeployPreview = deployPreview.split(':');
        if (splittedDeployPreview.length === 2) {
            return {
                [`x-wix-route-${splittedDeployPreview[0]}`]: splittedDeployPreview[1]
            }
        }
    }

    return {};
}