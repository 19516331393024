import {doppeActionTypeDefineViewerMetadata} from "../../client-server-common/doppe-action-types/doppe-action-type";
import React from "react";
import {doppeActionTypeStoreProducts} from "./doppe-action-type-store-products";

export const doppeActionTypeStoreProductsViewer = doppeActionTypeDefineViewerMetadata(doppeActionTypeStoreProducts, {

    loadViewerComponents: async () => {
        return {
            DoppeActionTypeStoreProductsWidget: (await import(
                './doppe-action-type-store-products-widget/doppe-action-type-store-products-widget'
                )).DoppeActionTypeStoreProductsWidget
        }
    },

    renderWidget: (renderProps, viewerComponents) => {

        const {
            DoppeActionTypeStoreProductsWidget
        } = viewerComponents;

        return (
            <DoppeActionTypeStoreProductsWidget
                {...renderProps}
            />
        );
    }
})