import {DoppeViewerAppClientServiceBase} from './doppe-viewer-app-client-service-base';
import {assertDefined, Dictionary, ObservableValue} from '@wix/devzai-utils-common';
import {doppeActionIsLockActive, DoppeDtoAction} from '../../client-server-common/types/doppe-dto-action';
import {SupportedCookieNames} from '../../client-server-common';

export class DoppeViewerAppLockService extends DoppeViewerAppClientServiceBase {

    protected readonly cachedLockedActionsObservable = new Map<DoppeDtoAction.Id, ObservableValue<boolean>>();

    public getActionUnlockStateFromCookie(actionId: DoppeDtoAction.Id) {
        const unlockedCookieValue = this.clientServices.doppeViewerAppCookieService.consentSafeCookieStorageAccessor?.getItem<Dictionary<any>>(SupportedCookieNames.UnlockedActions);
        return unlockedCookieValue ? unlockedCookieValue[actionId] : undefined
    }

    public updateActionUnlockStateInCookie(actionId: DoppeDtoAction.Id, unlockedValue: any) {
        this.clientServices.doppeViewerAppCookieService.consentSafeCookieStorageAccessor?.updateItem(SupportedCookieNames.UnlockedActions, (value) => {
            if (value === undefined) {
                return {
                    [actionId]: unlockedValue
                };
            }
            else {
                return {
                    ...value,
                    [actionId]: unlockedValue
                };
            }
        })
    }

    public getActionLockObservable(action: DoppeDtoAction<any>) {
        const isActionSettingsLocked = doppeActionIsLockActive(action);

        if (!isActionSettingsLocked) {
            if (this.cachedLockedActionsObservable.get(action.id)?.getValue() === false) {
                this.clientServices.doppeViewerAppCookieService.consentSafeCookieStorageAccessor?.updateItem(SupportedCookieNames.UnlockedActions, (value) => {
                    if (value === undefined) {
                        return undefined
                    }
                    else {
                        return {
                            ...value,
                            [action.id]: undefined
                        };
                    }
                })
            }

            this.cachedLockedActionsObservable.delete(action.id);

            return undefined
        }
        else {
            if (this.cachedLockedActionsObservable.has(action.id)) {
                return assertDefined(this.cachedLockedActionsObservable.get(action.id));
            }
            else {
                // const unlockedCookieValue = this.clientServices.doppeViewerAppCookieService.consentSafeCookieStorageAccessor?.getItem<Dictionary<boolean>>(SupportedCookieNames.UnlockedActions);
                // const isUnlockedFromCookie = unlockedCookieValue ? unlockedCookieValue[action.id] : false;
                const booleanObservableValue = new ObservableValue<boolean>(isActionSettingsLocked);
                this.cachedLockedActionsObservable.set(action.id, booleanObservableValue);
                return booleanObservableValue;
            }
        }
    }
}
