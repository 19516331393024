import {
    doppeActionTypeCreateSettingsResolvingFunction,
    doppeActionTypeDefineMetadata
} from "../../client-server-common/doppe-action-types/doppe-action-type";

export interface DoppeActionTypeSkipSettings {
    message: string;
    countdownSeconds: number
}

export const doppeActionTypeSkip = doppeActionTypeDefineMetadata<DoppeActionTypeSkipSettings>({
    id: 'io.bymo.action/skip',
    resolveActionSettings: doppeActionTypeCreateSettingsResolvingFunction({
        message: '',
        countdownSeconds: 3
    }),
    settingsMetadata: false,
    isWidgetOpener: false
})