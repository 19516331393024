import {doppeActionTypeDefineMetadata} from '../../client-server-common/doppe-action-types/doppe-action-type';
import {
    DoppeHideableValue,
    doppeHideableValueCreateHidden,
    doppeHideableValueGetValue,
    doppeHideableValueIsVisibleAndNotEqualValue,
    doppeHideableValueModifyValue
} from '../../client-server-common/types/doppe-hideable-value';
import {WixMediaResource, wixMediaResourceReplaceExternalImage} from '@wix/devzai-common-wix';
import {DoppeActionTypeEventViewerLang} from './doppe-action-type-event-viewer.lang';
import {
    arrayRemoveNullValues,
    dateResolveFromDateTimeValue,
    dateSerializedToString,
    immerProduce,
    SerializedDateTimeValueAsString,
    stringIsNotNullableOrWhiteSpace
} from '@wix/devzai-utils-common';
import {
    BymoPageProps,
    DoppeActionPriceData,
    DoppeExternalEventProvider,
    doppeExternalEventProviderResolveBusinessEntityReference
} from '../../client-server-common';
import {DoppeDtoEvent} from '../../client-server-common/types/doppe-dto-event';
import {RichTextContent} from '@wix/devzai-utils-react';
import {DoppeAppPageContentLocales} from '../../client/doppe-app-locales/doppe-app-page-content-locales';
import {
    doppeDtoActionSettingsRemoveInnerCtaContentForLockedAction
} from '../../client-server-common/types/doppe-dto-action';
import {
    DoppeActionCtaButtonSettings,
    doppeActionCtaButtonTypeGetDefaultCtaButtonSettingsMigration,
    getDefaultDoppeActionCtaButtonSettings
} from '../../client-server-common/types/doppe-action-cta-button-settings';
import {
    doppeActionCtaButtonsResetSettingsForLock,
    doppeActionCtaButtonTypeGetDefaultSettings,
    DoppeActionCTAType,
    doppeActionMultipleCTAButtonCreate
} from '../../client-server-common/types/doppe-action-cta-button';
import {
    doppeActionCtaButtonsResolveMainURL
} from '../doppe-actions-common/doppe-cta-button-utils/doppe-cta-button-utils';
import {
    doppeActionContentSectionSettingsDefaultValuesMigration,
    DoppeActionContentSectionsSettings
} from '../../client-server-common/types/doppe-action-content-sections-settings';
import {
    DoppeActionCtaButtonsSettings,
    doppeActionCtaButtonsSettingsDefaultValuesMigration
} from '../../client-server-common/types/doppe-action-cta-buttons-settings';

export namespace DoppeActionTypeEvent {

    export interface EventSettings  {
        eventLink: string;
        eventTitle: DoppeHideableValue<string>;
        description: DoppeHideableValue<RichTextContent>;
        eventImages: WixMediaResource[] | null;
        eventDate: DoppeHideableValue<SerializedDateTimeValueAsString>;
        eventTime: DoppeHideableValue<string>;
        eventLocation: DoppeHideableValue<string>;
        priceData: DoppeHideableValue<DoppeActionPriceData>;
        couponCode: DoppeHideableValue<string>;
        ribbon: DoppeHideableValue<string>;
        eventProvider: DoppeExternalEventProvider | null;
        eventProviderId: string | null,
    }

    export interface ActionSettings extends DoppeActionTypeEvent.EventSettings,
        DoppeActionContentSectionsSettings,
        DoppeActionCtaButtonsSettings {
    }

    export type DeprecatedFields = Partial<DoppeActionCtaButtonSettings> & {
        eventButtonText?: DoppeHideableValue<string>;
    }

    export type PartialProps = Partial<ActionSettings> & DeprecatedFields;
}

export const doppeActionTypeEvent = doppeActionTypeDefineMetadata<DoppeActionTypeEvent.ActionSettings, DoppeActionTypeEvent.PartialProps>({
    id: 'io.bymo.action/event',
    resolveActionSettings: action => {

        const {
            eventLink = '',
            eventButtonText = DoppeActionTypeEventViewerLang.defaultTexts.eventButtonText,
            ctaButton,
            ctaButtons,
            sections,
            ...restSettings
        } = action.settings;

        return {
            eventLink: eventLink,
            eventTitle: DoppeActionTypeEventViewerLang.defaultTexts.eventTitle,
            description: DoppeActionTypeEventViewerLang.defaultTexts.description,
            eventButtonText: eventButtonText,
            eventImages: null,
            eventDate: dateSerializedToString(new Date()),
            eventLocation: '',
            priceData: {
                actualPrice: 'Starting from 50$'
            },
            eventTime: doppeHideableValueCreateHidden('00:00'),
            couponCode: doppeHideableValueCreateHidden('CouponCode'),
            ribbon: doppeHideableValueCreateHidden('SALE'),
            eventProvider: null,
            eventProviderId: null,
            ...getDefaultDoppeActionCtaButtonSettings({
                buttonText: eventButtonText,
                showButton: true,
            }),
            ...doppeActionCtaButtonTypeGetDefaultCtaButtonSettingsMigration({
                hasSourceURL: true,
                ctaButton: ctaButton,
            }),
            ...doppeActionContentSectionSettingsDefaultValuesMigration(action.settings),
            ...doppeActionCtaButtonsSettingsDefaultValuesMigration(action.settings, true),
            ...restSettings
        }
    },
    isWidgetOpener: true,
    isLinkOpener: true,
    canRenderInPage: true,
    skipReportingOnGoalAchievedOnActionButtonClick: true,
    prepareForViewer: (actionSettings, options) => {
        doppeDtoActionSettingsRemoveInnerCtaContentForLockedAction(actionSettings, actionSettings => {
            actionSettings.eventLink = '';
            doppeActionCtaButtonsResetSettingsForLock(actionSettings);
        }, options);
    },
    resolveUsedMedia: actionSettings => {
        return actionSettings.eventImages ?? [];
    },
    resolveMainMedia: actionSettings => {
        return actionSettings.eventImages?.[0] ?? null;
    },
    resolveMainLink: (actionSettings, htmlSiteInfo) => {
        return doppeActionCtaButtonsResolveMainURL(actionSettings, {
            htmlSiteInfo: htmlSiteInfo,
            sourceURL: actionSettings.eventLink
        }) ?? null;
    },
    resolveCouponData: actionSettings => {
        return {
            showCouponCode: doppeHideableValueIsVisibleAndNotEqualValue(actionSettings.couponCode, ''),
            couponCode: doppeHideableValueGetValue(actionSettings.couponCode),
            couponDescription: doppeHideableValueIsVisibleAndNotEqualValue(actionSettings.description, '') ? actionSettings.description : '',
            couponTitle: doppeHideableValueIsVisibleAndNotEqualValue(actionSettings.eventTitle, '') ? actionSettings.eventTitle : '',
        }
    },
    replaceExternalImages: (actionSettings, externalImagesUrlToImageResourceMap) => {
        return immerProduce(actionSettings, actionSettings => {
            if (actionSettings.eventImages) {
                actionSettings.eventImages = actionSettings.eventImages.map(
                    image => wixMediaResourceReplaceExternalImage(image, externalImagesUrlToImageResourceMap))
            }
        })
    },
    resolveUsedBusinessEntities: actionSettings => {
        return arrayRemoveNullValues([
            actionSettings.eventProvider !== null && actionSettings.eventProviderId !== null ?
                doppeExternalEventProviderResolveBusinessEntityReference(
                    actionSettings.eventProvider,
                    actionSettings.eventProviderId
                ) :
                null
        ])
    },
});

export function doppeActionTypeEventResolveSettingsFromBoundEntity(
    actionSettings: DoppeActionTypeEvent.EventSettings,
    doppeDtoService: DoppeDtoEvent,
    _bymoPageProps: BymoPageProps,
): DoppeActionTypeEvent.EventSettings {

    // const location = arrayFirst(doppeDtoService.locations);

    return {
        ...actionSettings,
        eventLink: stringIsNotNullableOrWhiteSpace(doppeDtoService.eventPageUrl) ?
            doppeDtoService.eventPageUrl : 'about:blank',
    }
}

export function convertDoppeWixEventToEventSettings(event: DoppeDtoEvent, settings?: DoppeActionTypeEvent.ActionSettings) {
    return {
        eventTime: doppeHideableValueCreateHidden('20:00'),
        couponCode: doppeHideableValueCreateHidden(''),
        ribbon: doppeHideableValueCreateHidden(''),
        sections: [],
        ctaButtons: [doppeActionMultipleCTAButtonCreate({
            text: 'Book now',
            isRequired: false,
            settings: doppeActionCtaButtonTypeGetDefaultSettings(DoppeActionCTAType.Link, true)
        })],
        ...settings,
        eventImages: event.media,
        eventLink: event.eventPageUrl ?? '',
        description: event.description ? event.description :  doppeHideableValueCreateHidden(''),
        eventTitle: event?.name ? event.name : '',
        eventLocation: event?.location ? event.location : doppeHideableValueCreateHidden(''),
        eventDate: event.eventDates?.eventStartDate ? dateSerializedToString(dateResolveFromDateTimeValue(event.eventDates?.eventStartDate)) : doppeHideableValueCreateHidden(dateSerializedToString(new Date(Date.now()))),
        priceData: calculateActualPriceForEvent(event),
        eventProviderId: event.id,
        eventProvider: DoppeExternalEventProvider.Wix,
    }
}

export function populateEventActionSettingsDefaultTexts(options: {
    event: DoppeActionTypeEvent.ActionSettings,
    pageContentLocales: DoppeAppPageContentLocales,
}) : DoppeActionTypeEvent.ActionSettings {

    const {
        event,
        pageContentLocales,
    } = options;

    return  {
        ...event,
        eventTitle: doppeHideableValueGetValue(event.eventTitle) !== '' ? event.eventTitle : pageContentLocales["event.actionSettings.title"],
        description: doppeHideableValueModifyValue(event.description, (value) => {
            return value !== '' ? value : pageContentLocales["event.actionSettings.description"];
        }),
        couponCode: doppeHideableValueModifyValue(event.couponCode, (value) => {
            return value !== '' ? value : pageContentLocales["event.actionSettings.couponCode"];
        }),
        ribbon: doppeHideableValueModifyValue(event.ribbon, (value) => {
            return value !== '' ? value : pageContentLocales["event.actionSettings.ribbon"];
        }),
        eventLocation: doppeHideableValueModifyValue(event.eventLocation, (value) => {
            return value !== '' ? value : pageContentLocales["event.actionSettings.location"];
        }),
        sections: [],
        ctaButtons: [doppeActionMultipleCTAButtonCreate({
            text: pageContentLocales["event.actionSettings.buttonText"],
            isRequired: false,
            settings: doppeActionCtaButtonTypeGetDefaultSettings(DoppeActionCTAType.Link, true)
        })],
    }
}



export function calculateActualPriceForEvent(event: DoppeDtoEvent) {
    const formattedPrice = getFormattedEventPrice(event);

    return event.lowestPrice || event.highestPrice ? {
        actualPrice: formattedPrice
    } : doppeHideableValueCreateHidden({
        actualPrice: formattedPrice
    });
}

export function getFormattedEventPrice(event: DoppeDtoEvent) {
    if (event.lowestPrice !== event.highestPrice && event.lowestPrice && event.highestPrice) {
        return  `${event.lowestPrice} - ${event.highestPrice}`;

    }
    else if (event.lowestPrice) {
        return event.lowestPrice;
    }
    else if (event.highestPrice) {
        return event.highestPrice;
    }
    else {
        return '---';
    }
}