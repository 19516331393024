import {doppeActionTypeDefineMetadata} from '../../client-server-common/doppe-action-types/doppe-action-type';
import {
    DoppeHideableValue,
    doppeHideableValueCreateHidden
} from '../../client-server-common/types/doppe-hideable-value';
import {DoppeActionTypeOutfitViewerLang} from './doppe-action-type-outfit-viewer.lang';
import {DoppeActionTypeProduct} from '../doppe-action-type-product/doppe-action-type-product';
import {
    arrayFlatten,
    arrayRemoveNullValues,
    asArray,
    immerProduce,
    iterableMapToArray,
    OmitStrict,
    PartiallyOptional
} from '@wix/devzai-utils-common';
import {WixMediaResource, wixMediaResourceReplaceExternalImage} from '@wix/devzai-common-wix';
import {
    convertCommonListSettingToDefaultDoppeActionCtaButtonSettingsFromListAction,
    DoppeActionLayout,
    doppeExternalProductProviderResolveBusinessEntityReference,
    doppeListActionGetDefaultListSettings,
    doppeListActionGetDefaultSearchListSettings,
    doppeListActionGetItemClickBehaviourDefaultListSettings,
    doppeListActionItemCtaButtonsResetSettingsForLock,
    doppeListActionListItemGetDefaultListItemSettingsMigration,
    doppeListActionListItemWithCtaButtonsMigration,
    doppeListActionListItemWithSectionsMigration,
    DoppeListActionSettings,
    doppeListActionSettingsFilterHiddenListItemsFromActionView,
    doppeListActionTemplateCtaButtonsSettingsDefaultValuesMigration,
    doppeListActionTemplateSectionsSettingsDefaultValuesMigration
} from '../../client-server-common';
import {
    doppeDtoActionSettingsRemoveInnerCtaContentForLockedAction
} from '../../client-server-common/types/doppe-dto-action';
import {doppeActionCtaButtonsResetSettingsForLock} from '../../client-server-common/types/doppe-action-cta-button';
import {
    DoppeActionCtaButtonSettings,
    doppeActionCtaButtonTypeGetDefaultCtaButtonSettingsMigration,
    getDefaultDoppeActionCtaButtonSettings
} from '../../client-server-common/types/doppe-action-cta-button-settings';
import {
    doppeActionContentSectionSettingsDefaultValuesMigration,
    DoppeActionContentSectionsSettings
} from '../../client-server-common/types/doppe-action-content-sections-settings';
import {
    DoppeActionCtaButtonsSettings,
    doppeActionCtaButtonsSettingsDefaultValuesMigration
} from '../../client-server-common/types/doppe-action-cta-buttons-settings';

export namespace DoppeActionTypeOutfit {


    export interface ProductSettings extends
        DoppeListActionSettings.ListItemWithCtaButtons,
        DoppeListActionSettings.ListItemWithSections,
        DoppeListActionSettings.ListItemBaseSettings,
        DoppeActionTypeProduct.ProductSettings {
        productTitle: string;
    }

    export interface ActionSettings extends
        DoppeListActionSettings<ProductSettings>,
        DoppeListActionSettings.WithSearchSettings,
        DoppeListActionSettings.WithItemClickBehaviour,
        DoppeActionContentSectionsSettings,
        DoppeActionCtaButtonsSettings
    {
        outfitImage: WixMediaResource | null;
        outfitTitle: DoppeHideableValue<string>;
        itemShowPrice: boolean;
        previewShowTitle: boolean;
        previewShowDescription: boolean;
        previewShowPrice: boolean;
        previewShowCoupon: boolean;
    }


    export interface DeprecatedFields extends Partial<DoppeListActionSettings.WithCtaButton> {
        products?: ProductSettings[];
        previewProductButtonText?: string;
    }

    export interface DeprecatedItemFields extends Partial<DoppeActionCtaButtonSettings> {
        productButtonText?: string;
    }

    export type PartialProps = Partial<OmitStrict<ActionSettings, keyof DoppeListActionSettings<ProductSettings>>> & DeprecatedFields & DoppeListActionSettings<ProductSettings & DeprecatedItemFields>;
}

export const doppeActionTypeOutfit = doppeActionTypeDefineMetadata<DoppeActionTypeOutfit.ActionSettings, DoppeActionTypeOutfit.PartialProps>({
    id: 'io.bymo.action/outfit',
    resolveActionSettings: action => {

        const {
            listDataItems = [],
            ctaButton,
            listItemTemplateCtaButtonsSettings,
            listItemTemplateSectionsSettings,
            ctaButtons,
            sections,
            listSearchSettings,
            ...restSettings
        } = action.settings;

        return {
            outfitImage: null,
            outfitTitle: DoppeActionTypeOutfitViewerLang.defaultTexts.outfitTitle,

            itemShowPrice: true,
            itemShowDate: false,
            previewShowCoupon: true,
            previewShowPrice: true,
            previewShowDescription: true,
            previewShowTitle: true,
            ...doppeListActionGetDefaultSearchListSettings(action.settings),
            ...doppeListActionGetDefaultListSettings({
                layout: DoppeActionLayout.Grid,
                listDataItems: listDataItems.map(product => {

                    const {
                        productButtonText,
                        ctaButton,
                        customSections,
                        customCtaButtons,
                        sectionsOverridesMap,
                        ctaButtonsOverridesMap,
                        ...restProduct
                    } = product;

                    return {
                        productProvider: null,
                        productProviderId: null,
                        ribbon: doppeHideableValueCreateHidden('SALE'),
                        ...getDefaultDoppeActionCtaButtonSettings({
                            buttonText: productButtonText,
                            showButton: true
                        }),
                        ...doppeActionCtaButtonTypeGetDefaultCtaButtonSettingsMigration({
                            hasSourceURL: true,
                            ctaButton: ctaButton,
                        }),
                        ...doppeListActionListItemWithCtaButtonsMigration(product, true),
                        ...doppeListActionListItemWithSectionsMigration(product),
                        ...doppeListActionListItemGetDefaultListItemSettingsMigration(product.id),
                        ...restProduct as PartiallyOptional<DoppeActionTypeOutfit.ProductSettings & DoppeActionTypeOutfit.DeprecatedItemFields, 'productProvider' | 'productProviderId' | 'ribbon' | 'ctaButton' | 'customCtaButtons' |'customSections' | 'ctaButtonsOverridesMap' | 'sectionsOverridesMap'>
                    }
                }) ?? [],
            }),
            ...doppeListActionGetItemClickBehaviourDefaultListSettings(),
            ...convertCommonListSettingToDefaultDoppeActionCtaButtonSettingsFromListAction(action.settings),
            ...doppeActionCtaButtonTypeGetDefaultCtaButtonSettingsMigration({
                ctaButton: ctaButton,
                hasSourceURL: false
            }),
            ...doppeActionContentSectionSettingsDefaultValuesMigration(action.settings),
            ...doppeActionCtaButtonsSettingsDefaultValuesMigration(action.settings, false),
            ...doppeListActionTemplateCtaButtonsSettingsDefaultValuesMigration(action.settings, true),
            ...doppeListActionTemplateSectionsSettingsDefaultValuesMigration(action.settings),
            ...restSettings
        }
    },
    prepareForViewer: (actionSettings, options) => {
        doppeDtoActionSettingsRemoveInnerCtaContentForLockedAction(actionSettings, actionSettings => {
            doppeListActionItemCtaButtonsResetSettingsForLock(actionSettings);
            doppeActionCtaButtonsResetSettingsForLock(actionSettings);
        }, options);
    },
    isWidgetOpener: true,
    canRenderInPage: true,
    skipReportingOnGoalAchievedOnActionButtonClick: true,
    isListItemLinkOpener: true,
    resolveUsedMedia: actionSettings => {
        const images = arrayFlatten(actionSettings.listDataItems.map(product => {
            return product.productImage ? asArray(product.productImage) : []
        }));

        return arrayRemoveNullValues([actionSettings.outfitImage, ...images]);
    },
    resolveMainMedia: actionSettings => {
        const images = arrayFlatten(actionSettings.listDataItems.map(product => {
            return product.productImage ? asArray(product.productImage) : []
        }));

        return actionSettings.outfitImage ?? images[0] ?? null;
    },
    resolveUsedBusinessEntities: actionSettings => {
        return iterableMapToArray(actionSettings.listDataItems, (product, skip) => {
            return product.productProvider !== null && product.productProviderId !== null ?
                doppeExternalProductProviderResolveBusinessEntityReference(
                    product.productProvider,
                    product.productProviderId
                ) : skip
        });
    },
    resolveListItemsCount: actionSettings => {
        return doppeListActionSettingsFilterHiddenListItemsFromActionView(actionSettings.listDataItems).length;
    },
    replaceExternalImages: (actionSettings, externalImagesUrlToImageResourceMap) => {
        return immerProduce(actionSettings, actionSettings => {
            if (actionSettings.outfitImage) {
                actionSettings.outfitImage =
                    wixMediaResourceReplaceExternalImage(actionSettings.outfitImage, externalImagesUrlToImageResourceMap)
            }

            for (const item of actionSettings.listDataItems) {
                if (item.productImage) {
                    const images = asArray(item.productImage);
                    item.productImage = images.map(image => {
                        return wixMediaResourceReplaceExternalImage(image, externalImagesUrlToImageResourceMap)
                    });
                }
            }
        })
    },
    resolveListItems: action => {
        const actionSettings = action.settings;
        return actionSettings.listDataItems.map(item => {
            return {
                title: item.productTitle,
                action: action,
                couponCode: item.couponCode,
                description: item.description,
                image: item.productImage ? asArray(item.productImage)[0] : null,
                itemId: item.id,
                ribbon: item.ribbon,
                url: item.productPageLink,
                enabled: item.enabled,
                deleted: item.deleted,
                displayInSearch: item.displayInSearch,
            }
        });
    },
    resolveListSearchSettings: actionSettings => {
        return actionSettings.listSearchSettings;
    },

});