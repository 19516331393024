import {doppeActionTypeDefineViewerMetadata} from "../../client-server-common/doppe-action-types/doppe-action-type";
import React from "react";
import {doppeActionTypeStoreProduct} from "./doppe-action-type-store-product";

export const doppeActionTypeStoreProductViewer = doppeActionTypeDefineViewerMetadata(doppeActionTypeStoreProduct, {

    loadViewerComponents: async () => {
        return {
            DoppeActionTypeStoreProductWidget: (await import(
                './doppe-action-type-store-product-widget/doppe-action-type-store-product-widget'
            )).DoppeActionTypeStoreProductWidget
        }
    },

    renderWidget: (renderProps, viewerComponents) => {

        const {
            DoppeActionTypeStoreProductWidget
        } = viewerComponents;

        return (
            <DoppeActionTypeStoreProductWidget
                {...renderProps}
            />
        );
    }
})