import {doppeActionTypeDefineMetadata} from '../../client-server-common/doppe-action-types/doppe-action-type';
import {
    BymoPagePropsContentSettingSemanticMeaning,
    BymoPagePropsContentSettingsMetadata,
    BymoPagePropsContentSettingType,
    bymoPagePropsCreateContentSettingMetadata,
    DoppeDtoBlogPost,
    DoppeExternalBlogPostProvider,
    doppeExternalBlogPostProviderResolveBusinessEntityReference,
    DoppeHideableValue,
    doppeHideableValueCreateHidden
} from '../../client-server-common';
import {WixMediaResource, wixMediaResourceReplaceExternalImage} from '@wix/devzai-common-wix';
import {
    arrayRemoveNullValues,
    dateConvertToIsoString,
    dateGetCurrentDateTime,
    immerProduce,
    SerializedDateTimeValueAsString
} from '@wix/devzai-utils-common';
import {RichTextContent} from '@wix/devzai-utils-react';
import {DoppeAppPageContentLocales} from '../../client/doppe-app-locales/doppe-app-page-content-locales';
import {
    doppeDtoActionSettingsRemoveInnerCtaContentForLockedAction
} from '../../client-server-common/types/doppe-dto-action';
import {
    DoppeActionCtaButtonSettings,
    doppeActionCtaButtonTypeGetDefaultCtaButtonSettingsMigration,
    getDefaultDoppeActionCtaButtonSettings
} from '../../client-server-common/types/doppe-action-cta-button-settings';
import {
    doppeActionCtaButtonsResetSettingsForLock,
    doppeActionCtaButtonTypeGetDefaultSettings,
    DoppeActionCTAType,
    doppeActionMultipleCTAButtonCreate
} from '../../client-server-common/types/doppe-action-cta-button';
import {DoppeActionMediaViewSettings} from '../../client-server-common/types/doppe-action-media-view-settings';
import {
    doppeBlogPostActionGetMediaViewDefaultSettings
} from '../../doppe-action-types-client-server-common/doppe-blog-post-action-types-utils/doppe-blog-post-action-types-utils';
import {
    doppeActionCtaButtonsResolveMainURL
} from '../doppe-actions-common/doppe-cta-button-utils/doppe-cta-button-utils';
import {
    doppeActionContentSectionSettingsDefaultValuesMigration,
    DoppeActionContentSectionsSettings
} from '../../client-server-common/types/doppe-action-content-sections-settings';
import {
    DoppeActionCtaButtonsSettings,
    doppeActionCtaButtonsSettingsDefaultValuesMigration
} from '../../client-server-common/types/doppe-action-cta-buttons-settings';

export namespace DoppeActionTypeBlogPost {

    export type ArticleSettings = DoppeActionMediaViewSettings &
        {
            blogPostLink: string;
            blogPostTitle: DoppeHideableValue<string>;
            description: DoppeHideableValue<RichTextContent>;
            blogPostImages: WixMediaResource[] | null;
            blogPostDate: DoppeHideableValue<SerializedDateTimeValueAsString>;
            showBlogWebsite: boolean;
            blogPostProvider: DoppeExternalBlogPostProvider | null;
            blogPostProviderId: DoppeDtoBlogPost.Id | null;
        }

    export interface ActionSettings extends DoppeActionTypeBlogPost.ArticleSettings,
        DoppeActionContentSectionsSettings,
        DoppeActionCtaButtonsSettings {

    }

    export type DeprecatedFields = Partial<DoppeActionCtaButtonSettings> & {
        blogPostButtonText?: DoppeHideableValue<string>;
    }

    export type PartialProps = Partial<ActionSettings> & DeprecatedFields;
}

export const doppeActionTypeBlogPostActionContentSettingsMetadata = {
    blogPostTitle: bymoPagePropsCreateContentSettingMetadata(BymoPagePropsContentSettingType.Text, BymoPagePropsContentSettingSemanticMeaning.EntityTitle),
    blogPostDate: bymoPagePropsCreateContentSettingMetadata(BymoPagePropsContentSettingType.Text, BymoPagePropsContentSettingSemanticMeaning.EntityPublishDate),
    description: bymoPagePropsCreateContentSettingMetadata(BymoPagePropsContentSettingType.RichText, BymoPagePropsContentSettingSemanticMeaning.EntityDescription),
    blogPostLink: bymoPagePropsCreateContentSettingMetadata(BymoPagePropsContentSettingType.Url, BymoPagePropsContentSettingSemanticMeaning.EntityPageUrl),
    blogPostImages: bymoPagePropsCreateContentSettingMetadata(BymoPagePropsContentSettingType.MultipleMedia, BymoPagePropsContentSettingSemanticMeaning.EntityMedia),
} satisfies BymoPagePropsContentSettingsMetadata<DoppeActionTypeBlogPost.ActionSettings>


export const doppeActionTypeBlogPost = doppeActionTypeDefineMetadata<DoppeActionTypeBlogPost.ActionSettings, DoppeActionTypeBlogPost.PartialProps>({
    id: 'io.bymo.action/blogPost',
    resolveActionSettings: action => {

        const {
            blogPostLink = '',
            blogPostButtonText = '',
            ctaButton,
            ctaButtons,
            sections,
            mainMediaViewSettings,
            mainMediaViewLayout,
            ...restSettings
        } = action.settings;

        return {
            blogPostLink: blogPostLink,
            blogPostTitle: '',
            description: '',
            blogPostImages: null,
            blogPostDate: dateConvertToIsoString(dateGetCurrentDateTime()),
            showBlogWebsite: true,
            blogPostProvider: null,
            blogPostProviderId: null,
            ...getDefaultDoppeActionCtaButtonSettings({
                buttonText: blogPostButtonText,
                showButton: true,
            }),
            ...doppeActionCtaButtonTypeGetDefaultCtaButtonSettingsMigration({
                hasSourceURL: true,
                ctaButton: ctaButton,
            }),
            ...doppeBlogPostActionGetMediaViewDefaultSettings({
                mainMediaViewSettings: mainMediaViewSettings,
                mainMediaViewLayout: mainMediaViewLayout,
            }),
            ...doppeActionContentSectionSettingsDefaultValuesMigration(action.settings),
            ...doppeActionCtaButtonsSettingsDefaultValuesMigration({
                ctaButtons: ctaButtons,
            }, true),
            ...restSettings,
        }
    },
    isWidgetOpener: true,
    isLinkOpener: true,
    canRenderInPage: true,
    skipReportingOnGoalAchievedOnActionButtonClick: true,
    settingsMetadata: {
        actionSettingsMetadata: {
            contentSettingsMetadata: doppeActionTypeBlogPostActionContentSettingsMetadata,
            supportsContentSections: true
        },
        listItemsSettingsMetadata: false
    },
    prepareForViewer: (actionSettings, options) => {
        doppeDtoActionSettingsRemoveInnerCtaContentForLockedAction(actionSettings, actionSettings => {
            actionSettings.blogPostLink = '';
            doppeActionCtaButtonsResetSettingsForLock(actionSettings);
        }, options);
    },
    resolveUsedMedia: actionSettings => {
        return actionSettings.blogPostImages ?? [];
    },
    resolveMainMedia: actionSettings => {
        return actionSettings.blogPostImages?.[0] ?? null;
    },
    resolveMainLink: (actionSettings, htmlSiteInfo) => {
        return doppeActionCtaButtonsResolveMainURL(actionSettings, {
            htmlSiteInfo: htmlSiteInfo,
            sourceURL: actionSettings.blogPostLink
        }) ?? null;
    },
    replaceExternalImages: (actionSettings, externalImagesUrlToImageResourceMap) => {
        return immerProduce(actionSettings, actionSettings => {
            if (actionSettings.blogPostImages) {
                actionSettings.blogPostImages = actionSettings.blogPostImages.map(
                    image => wixMediaResourceReplaceExternalImage(image, externalImagesUrlToImageResourceMap))
            }
        })
    },
    resolveUsedBusinessEntities: actionSettings => {
        return arrayRemoveNullValues([
            actionSettings.blogPostProvider !== null && actionSettings.blogPostProviderId !== null ?
                doppeExternalBlogPostProviderResolveBusinessEntityReference(
                    actionSettings.blogPostProvider,
                    actionSettings.blogPostProviderId
                ) : null
        ])
    }
});


export function populateBlogPostActionSettingsDefaultTexts(options: {
    blogPost: DoppeActionTypeBlogPost.ArticleSettings,
    pageContentLocales: DoppeAppPageContentLocales,
}): DoppeActionTypeBlogPost.ActionSettings {

    const {
        blogPost,
        pageContentLocales,
    } = options;

    const defaultButtonText = pageContentLocales['blogPost.actionSettings.buttonText']

    return {
        ...blogPost,
        sections: [],
        ...doppeActionCtaButtonsSettingsDefaultValuesMigration({
            ctaButtons: [doppeActionMultipleCTAButtonCreate({
                text: defaultButtonText,
                isRequired: false,
                settings: doppeActionCtaButtonTypeGetDefaultSettings(DoppeActionCTAType.Link, true)
            })],
        }, false),
        blogPostTitle: blogPost.blogPostTitle ?? pageContentLocales['blogPost.actionSettings.title'],
        description: blogPost.description ?? doppeHideableValueCreateHidden(pageContentLocales['blogPost.actionSettings.description'])
    }
}

