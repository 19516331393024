import {RateType, WixBookingService, WixBookingType, WixImageInfo} from '@wix/devzai-common-wix';
import {BymoPageProps} from '../bymo-pages/bymo-page-props';
import {bymoPageFormatDateTime} from '../../components/bymo-page-locales-utils/bymo-page-locales-utils';
import {CurrencyCode, currencyFormatPrice, currencyGetAllCodes, evaluateFunction} from '@wix/devzai-utils-common';


export interface DoppeDtoBookingService {
    id: string;
    name: string;
    description: string;
    media: WixImageInfo[] | null;
    locations: WixBookingService.Location[];
    payment?: WixBookingService.Payment;
    scheduale?: WixBookingService.Schedule;
    type: WixBookingService.ServiceType;
    bookingPageUrl?: string;
    calendarPageUrl?: string;
    servicePageUrl?: string;
    createdDate: Date | null;
    capacity?: number;
}

export namespace DoppeDtoBookingService {
    export type Id = DoppeDtoBookingService['id'];
}

export function doppeDtoBookingServiceConvertToFormattedPrice<EmptyValue>(payment: WixBookingService.Payment | undefined, emptyValue: EmptyValue): string | EmptyValue {

    if (!payment) {
        return emptyValue;
    }

    try {
        switch (payment.rateType) {
            case RateType.FIXED: {
                const currency = payment.fixed?.price?.currency as CurrencyCode;
                const price = payment.fixed?.price?.value ? parseFloat(payment.fixed?.price?.value) : NaN;
                const hasCurrency = (currency !== undefined && currencyGetAllCodes().includes(currency))

                return !isNaN(price) && hasCurrency ? currencyFormatPrice({
                    code: currency,
                    price: price
                }) : (payment.fixed?.price.formattedValue ?? emptyValue)
            }
            case RateType.VARIED: {
                if (!payment.varied?.minPrice?.value || !payment.varied?.maxPrice?.value) {
                    const defaultPrice = payment.varied?.defaultPrice;
                    const currency = defaultPrice?.currency as CurrencyCode;
                    const price = defaultPrice?.value ? parseFloat(defaultPrice?.value) : NaN;
                    const hasCurrency = (currency !== undefined && currencyGetAllCodes().includes(currency))

                    return !isNaN(price) && hasCurrency ? currencyFormatPrice({
                        code: currency,
                        price: price
                    }) : (defaultPrice?.formattedValue ?? emptyValue);
                } else if (payment.varied?.minPrice.value !== payment.varied?.maxPrice?.value) {
                    const formattedMinPrice = evaluateFunction(() => {
                        const minPrice = payment.varied?.minPrice;
                        const currency = minPrice?.currency as CurrencyCode;
                        const price = minPrice?.value ? parseFloat(minPrice?.value) : NaN;
                        const hasCurrency = (currency !== undefined && currencyGetAllCodes().includes(currency))

                        return !isNaN(price) && hasCurrency ? currencyFormatPrice({
                            code: currency,
                            price: price
                        }) : (minPrice?.formattedValue ?? emptyValue);
                    })

                    const formattedMaxPrice = evaluateFunction(() => {
                        const maxPrice = payment.varied?.maxPrice;
                        const currency = maxPrice?.currency as CurrencyCode;
                        const price = maxPrice?.value ? parseFloat(maxPrice?.value) : NaN;
                        const hasCurrency = (currency !== undefined && currencyGetAllCodes().includes(currency))

                        return !isNaN(price) && hasCurrency ? currencyFormatPrice({
                            code: currency,
                            price: price
                        }) : (maxPrice?.formattedValue ?? emptyValue);
                    })

                    return `${formattedMinPrice} - ${formattedMaxPrice}`;
                }


                const defaultPrice = payment.varied?.defaultPrice;
                const currency = defaultPrice?.currency as CurrencyCode;
                const price = defaultPrice?.value ? parseFloat(defaultPrice?.value) : NaN;
                const hasCurrency = (currency !== undefined && currencyGetAllCodes().includes(currency))

                return !isNaN(price) && hasCurrency ? currencyFormatPrice({
                    code: currency,
                    price: price
                }) : (defaultPrice?.formattedValue ?? emptyValue);
            }
            case RateType.CUSTOM:
                return payment.custom ?? '';
            case RateType.NO_FEE:
                return 'Free';
            case RateType.Unspecified:
                return '---';
        }
    }
    catch (e) {
        return emptyValue;
    }
}

export function doppeDtoBookingServiceLocationConvertToActionFormattedLocation(location: WixBookingService.Location): string {
    return location.formattedAddress;
}

export function doppeDtoBookingServiceCapacityConvertToActionFormattedCapacity(capacity: number): string {
    return capacity > 1 ? `${capacity} Participants` : `${capacity} Participant`;
}

export function doppeDtoBookingServiceScheduleConvertToActionFormattedDuration(options: {
    schedule: WixBookingService.Schedule,
    serviceType: WixBookingService.ServiceType
}): string[] | null {
    if (options.serviceType === WixBookingType.APPOINTMENT) {
        if (options.schedule.availabilityConstraints?.sessionDurations?.length) {
            return options.schedule.availabilityConstraints.sessionDurations.map(duration => `${duration} minutes`);
        }
    }
    return null;
}

export function doppeDtoBookingServiceScheduleConvertToActionFormattedSchedule(options: {
    schedule: WixBookingService.Schedule,
    serviceType: WixBookingService.ServiceType,
    bymoPageProps?: BymoPageProps;
}): string | null {
    if (options.serviceType === WixBookingType.APPOINTMENT) {
        if (options.schedule.firstSessionStart) {
            return `Starts ${bymoPageFormatDateTime(
                options.schedule.firstSessionStart,
                {
                    month: 'short',
                    day: '2-digit',
                },
                options.bymoPageProps,
            )}`;
        }
    }
    return null;
}