import { FloatingWindowsContainerScopeContext } from '@wix/devzai-utils-react';
import React, {useRef} from 'react';
import {style, classes} from './doppe-viewer-modal-panel.st.css';
import {Values} from "@wix/devzai-utils-common";
import {bymoPageStyleGetStyleScopeClassName, BymoPageStyleScope, BymoPageStyleScopeContext} from "../bymo-page-style/bymo-page-style";

export const DoppeViewerModalPanelLayout = {
    InnerCentered: 'innerCentered',
    Inner: 'inner',
    InnerFull: 'innerFull',
    FullScreen: 'fullScreen',
    Drawer: 'drawer',
    Toast: 'toast'
} as const;

export type DoppeViewerModalPanelLayout = Values<typeof DoppeViewerModalPanelLayout>;

export const DoppeViewerModalPanel = React.memo(function DoppeViewerModalPanel (props: DoppeViewerModalPanel.Props) {
    const {
        children,
        onSubmit,

        mobileLayout = DoppeViewerModalPanelLayout.InnerCentered,
        desktopLayout = DoppeViewerModalPanelLayout.InnerCentered,

        className,
        ...htmlAttributes
    } = props;

    const rootRef = useRef<HTMLDivElement>(null)

    return (
        <FloatingWindowsContainerScopeContext.Provider
            value={() => {
                return {
                    zIndex: 1,
                    targetContainer: rootRef.current
                }
            }}
        >
            <BymoPageStyleScopeContext.Provider
                value={BymoPageStyleScope.Popup}
            >
                <div
                    {...htmlAttributes}
                    className={style(classes.root, {
                        mobileLayout: mobileLayout,
                        desktopLayout: desktopLayout,
                    }, className, bymoPageStyleGetStyleScopeClassName(BymoPageStyleScope.Popup))}
                    ref={rootRef}
                    onSubmit={event => {
                        event.preventDefault();

                        onSubmit?.(event);
                    }}
                >
                    {children}
                </div>
            </BymoPageStyleScopeContext.Provider>
        </FloatingWindowsContainerScopeContext.Provider>
    )
});

export namespace DoppeViewerModalPanel {

    export interface Props extends React.HTMLAttributes<any> {
        mobileLayout?: DoppeViewerModalPanelLayout;
        desktopLayout?: DoppeViewerModalPanelLayout;
    }
}
