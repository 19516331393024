import {assertDefined} from '@wix/devzai-utils-common';
import {BymoTemplateData, DoppeActionButtonTemplateDefinition} from '../../doppe-sdk';
import {
    DoppeStandardActionButtonTemplateData
} from '../../components/doppe-action-button-templates/doppe-standard-action-button-template/doppe-standard-action-button-template';
import {BymoTemplatesIds, DoppeActionButtonTemplateIds} from '../doppe-templates/doppe-templates';
import {BymoPageFeatures} from '../bymo-pages/bymo-page-props';
import {
    DoppeBymoTemplatesMetadataStore
} from '../doppe-bymo-templates-metadata-store/doppe-bymo-templates-metadata-store';

export class BymoTemplatesStore {

    private bymoTemplatesMetadataStore = new DoppeBymoTemplatesMetadataStore();

    public getBymoTemplatesMetadataStore () {
        return this.bymoTemplatesMetadataStore;
    }

    private bymoPagesTemplates = {
        [BymoTemplatesIds.BasicForActionPreview]: async () => (await import('../../components/bymo-templates/bymo-template-basic/bymo-template-basic')).BymoTemplateBasicTemplateData,
        [BymoTemplatesIds.Basic]: async () => (await import('../../components/bymo-templates/bymo-template-speed-dial/bymo-template-speed-dial-new')).BymoTemplateSpeedDialTemplateData,
        [BymoTemplatesIds.ActionPreview]: async () => (await import('../../components/bymo-templates/bymo-template-action-preview/bymo-template-action-preview')).BymoTemplateActionPreviewTemplateData,
        [BymoTemplatesIds.PagePreview]: async () => (await import('../../components/bymo-templates/bymo-template-page-preview/bymo-template-page-preview')).BymoTemplatePagePreviewTemplateData,
        [BymoTemplatesIds.ActionInPagePreview]: async () => (await import('../../components/bymo-templates/bymo-template-action-in-page-preview/bymo-template-action-in-page-preview')).BymoTemplateActionInPagePreviewTemplateData,
        [BymoTemplatesIds.ActionInLandingPagePreview]: async () => (await import('../../components/bymo-templates/bymo-template-action-in-landing-page-preview/bymo-template-action-in-landing-page-preview')).BymoTemplateActionInLandingPagePreviewTemplateData,
        [BymoTemplatesIds.PreRoll]: async () => (await import('../../components/bymo-templates/bymo-template-pre-roll/bymo-template-pre-roll')).BymoTemplatePreRollTemplateData,
        [BymoTemplatesIds.SpeedDial]: async () => (await import('../../components/bymo-templates/bymo-template-speed-dial/bymo-template-speed-dial-new')).BymoTemplateSpeedDialTemplateData,
    } satisfies Record<BymoTemplatesIds, () => Promise<BymoTemplateData>>;

    private actionButtonTemplates = {
        [DoppeActionButtonTemplateIds.Standard]: () => DoppeStandardActionButtonTemplateData,
    };

    public async getBymoTemplateData(templateId: string) {
        return assertDefined(
            (await this.bymoPagesTemplates[templateId as BymoTemplatesIds]()) as BymoTemplateData<any>,
            `Could not find a bymo page template with id ${templateId}`)
    }

    public getBymoTemplateMetadata(templateId: string) {
        return this.bymoTemplatesMetadataStore.getBymoPageTemplateMetadata(templateId);
    }

    public getActionButtonTemplateData(templateId: string) {
        return assertDefined(
            this.actionButtonTemplates[templateId as DoppeActionButtonTemplateIds]() as DoppeActionButtonTemplateDefinition<any>,
            `Could not find an action button template with id ${templateId}`)
    }


    public getRegisteredBymoPagesTemplatesIds() {
        return Object.keys(this.bymoPagesTemplates);
    }

    // TODO: Should move to DoppeBymoTemplatesMetadataStore
    public isSupportedFeature(templateId: string, feature: BymoPageFeatures) {
        return this.getBymoTemplateMetadata(templateId).features.includes(feature);
    }
}