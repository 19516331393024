import {doppeActionTypeDefineMetadata} from '../../client-server-common/doppe-action-types/doppe-action-type';
import {
    arrayFlatten,
    arrayRemoveNullValues,
    immerProduce,
    iterableMapToArray,
    OmitStrict,
    PartiallyOptional
} from '@wix/devzai-utils-common';
import {
    DoppeActionTypeBlogPost,
    doppeActionTypeBlogPostActionContentSettingsMetadata,
    populateBlogPostActionSettingsDefaultTexts
} from '../doppe-action-type-blog-post/doppe-action-type-blog-post';
import {wixMediaResourceReplaceExternalImage} from '@wix/devzai-common-wix';
import {
    convertCommonListSettingToDefaultDoppeActionCtaButtonSettingsFromListAction,
    DoppeBlogPostsExternalDataSource,
    doppeBlogPostsExternalDataSourceResolveBusinessEntityReferences,
    doppeExternalBlogPostProviderResolveBusinessEntityReference,
    DoppeHideableValue,
    doppeHideableValueIsHidden,
    doppeHideableValueIsVisibleAndNotEqualValue,
    doppeListActionContentSettingsMetadataCreateForListActionWithMainDetails,
    doppeListActionGetDefaultListSettings,
    doppeListActionGetDefaultSearchListSettings,
    doppeListActionGetItemClickBehaviourDefaultListSettings,
    doppeListActionGetMainDetailsDefaultSettings,
    doppeListActionGetWithExternalDataSourceDefaultListSettings,
    doppeListActionItemCtaButtonsResetSettingsForLock,
    doppeListActionListItemCalculateItemButtons,
    doppeListActionListItemGetDefaultListItemSettingsMigration,
    doppeListActionListItemWithCtaButtonsMigration,
    doppeListActionListItemWithSectionsMigration,
    DoppeListActionSettings,
    doppeListActionSettingsFilterHiddenListItemsFromActionView,
    doppeListActionSettingsGetListItemCtaButtonsDefaultSettings,
    doppeListActionSettingsGetListItemSectionsDefaultSettings,
    doppeListActionTemplateCtaButtonsSettingsDefaultValuesMigration,
    doppeListActionTemplateSectionsSettingsDefaultValuesMigration,
    doppeListActionWithMainDetailsGetMainImage,
    doppeListActionWithMainDetailsReplaceMainImage
} from '../../client-server-common';
import {doppeHideableValueCreateHidden} from '../../client-server-common/types/doppe-hideable-value';
import {
    doppeDtoActionSettingsRemoveInnerCtaContentForLockedAction
} from '../../client-server-common/types/doppe-dto-action';
import {DoppeAppPageContentLocales} from '../../client/doppe-app-locales/doppe-app-page-content-locales';
import {doppeActionCtaButtonsResetSettingsForLock} from '../../client-server-common/types/doppe-action-cta-button';
import {
    DoppeActionCtaButtonSettings,
    doppeActionCtaButtonTypeGetDefaultCtaButtonSettingsMigration,
    getDefaultDoppeActionCtaButtonSettings
} from '../../client-server-common/types/doppe-action-cta-button-settings';
import {
    doppeActionCtaButtonsResolveMainURL
} from '../doppe-actions-common/doppe-cta-button-utils/doppe-cta-button-utils';

import {
    doppeBlogPostActionGetMediaViewDefaultSettings
} from '../../doppe-action-types-client-server-common/doppe-blog-post-action-types-utils/doppe-blog-post-action-types-utils';
import {
    doppeActionContentSectionSettingsDefaultValuesMigration,
    DoppeActionContentSectionsSettings
} from '../../client-server-common/types/doppe-action-content-sections-settings';
import {
    DoppeActionCtaButtonsSettings,
    doppeActionCtaButtonsSettingsDefaultValuesMigration
} from '../../client-server-common/types/doppe-action-cta-buttons-settings';


export namespace DoppeActionTypeArticlesList {

    export interface ArticleSettings extends
        DoppeListActionSettings.ListItemWithCtaButtons,
        DoppeListActionSettings.ListItemWithSections,
        DoppeListActionSettings.ListItemBaseSettings,
        DoppeActionTypeBlogPost.ArticleSettings
    {
        blogPostTitle: string;
    }

    export interface ActionSettings extends
        DoppeListActionSettings<ArticleSettings>,
        DoppeListActionSettings.WithSearchSettings,
        DoppeListActionSettings.WithMainDetails,
        DoppeListActionSettings.WithItemClickBehaviour,
        DoppeListActionSettings.WithExternalDataSourceSupport<DoppeBlogPostsExternalDataSource>,
        DoppeActionContentSectionsSettings,
        DoppeActionCtaButtonsSettings
    {
        previewShowTitle: boolean;
        previewShowDescription: boolean;
        previewShowBlogPostDate: boolean;
        previewShowBlogWebsite: boolean;
    }

    export interface DeprecatedFields extends Partial<DoppeListActionSettings.WithCtaButton> {
        articles?: ArticleSettings[];
        previewBlogPostButtonText?: string;
    }

    export interface DeprecatedItemFields extends Partial<DoppeActionCtaButtonSettings> {
        blogPostButtonText?: DoppeHideableValue<string>;
    }

    export type PartialProps = Partial<OmitStrict<ActionSettings, keyof DoppeListActionSettings<ArticleSettings>>> & DeprecatedFields & DoppeListActionSettings<ArticleSettings & DeprecatedItemFields>;
}


export const doppeActionTypeArticlesList = doppeActionTypeDefineMetadata<DoppeActionTypeArticlesList.ActionSettings, DoppeActionTypeArticlesList.PartialProps>({
    id: 'io.bymo.action/articles-list',
    resolveActionSettings: action => {

        const {
            listDataItems = [],
            ctaButton,
            listSearchSettings,
            listItemTemplateCtaButtonsSettings,
            listItemTemplateSectionsSettings,
            ctaButtons,
            sections,
            ...restSettings
        } = action.settings;

        return {
            previewShowBlogPostDate: true,
            previewShowBlogWebsite: true,
            previewShowDescription: true,
            previewShowTitle: true,
            previewBlogPostButtonText: '',
            ...doppeListActionGetWithExternalDataSourceDefaultListSettings(),
            ...doppeListActionGetDefaultSearchListSettings(action.settings),
            ...doppeListActionGetDefaultListSettings({
                listDataItems: listDataItems.map(article => {

                    const {
                        blogPostButtonText,
                        ctaButton,
                        customSections,
                        customCtaButtons,
                        sectionsOverridesMap,
                        ctaButtonsOverridesMap,
                        ...restArticle
                    } = article;

                    return {
                        blogPostProvider: null,
                        blogPostProviderId: null,
                        ...getDefaultDoppeActionCtaButtonSettings({
                            buttonText: blogPostButtonText,
                            showButton: true
                        }),
                        ...doppeActionCtaButtonTypeGetDefaultCtaButtonSettingsMigration({
                            hasSourceURL: true,
                            ctaButton: ctaButton,
                        }),
                        ...doppeListActionListItemGetDefaultListItemSettingsMigration(article.id),
                        ...doppeListActionListItemWithCtaButtonsMigration(article, true),
                        ...doppeListActionListItemWithSectionsMigration(article),
                        ...restArticle as PartiallyOptional<DoppeActionTypeArticlesList.ArticleSettings & DoppeActionTypeArticlesList.DeprecatedItemFields, 'blogPostProvider' | 'blogPostProviderId' | 'ctaButton' | 'customCtaButtons' |'customSections' | 'ctaButtonsOverridesMap' | 'sectionsOverridesMap'>,
                        ...doppeBlogPostActionGetMediaViewDefaultSettings({
                            mainMediaViewSettings: article.mainMediaViewSettings,
                            mainMediaViewLayout: article.mainMediaViewLayout,
                        }),
                    };
                }) ?? [],
            }),
            ...doppeListActionGetItemClickBehaviourDefaultListSettings(),
            ...doppeListActionGetMainDetailsDefaultSettings({
                defaultTitle: ''
            }),
            ...convertCommonListSettingToDefaultDoppeActionCtaButtonSettingsFromListAction(action.settings),
            ...doppeActionCtaButtonTypeGetDefaultCtaButtonSettingsMigration({
                ctaButton: ctaButton,
                hasSourceURL: false
            }),
            ...doppeActionContentSectionSettingsDefaultValuesMigration(action.settings),
            ...doppeActionCtaButtonsSettingsDefaultValuesMigration(action.settings, false),
            ...doppeListActionTemplateCtaButtonsSettingsDefaultValuesMigration(action.settings, true),
            ...doppeListActionTemplateSectionsSettingsDefaultValuesMigration(action.settings),
            ...restSettings
        }
    },
    settingsMetadata: {
        actionSettingsMetadata: {
            contentSettingsMetadata: {
                ...doppeListActionContentSettingsMetadataCreateForListActionWithMainDetails()
            },
            supportsContentSections: true
        },
        listItemsSettingsMetadata: {
            contentSettingsMetadata: doppeActionTypeBlogPostActionContentSettingsMetadata,
            supportsContentSections: true
        }
    },
    isWidgetOpener: true,
    canRenderInPage: true,
    skipReportingOnGoalAchievedOnActionButtonClick: true,
    isListItemLinkOpener: true,
    supportsListLayout: true,
    prepareForViewer: (actionSettings, options) => {
        doppeDtoActionSettingsRemoveInnerCtaContentForLockedAction(actionSettings, actionSettings => {
            doppeListActionItemCtaButtonsResetSettingsForLock(actionSettings);
            doppeActionCtaButtonsResetSettingsForLock(actionSettings);
        }, options);
    },
    resolveUsedMedia: actionSettings => {
        return arrayRemoveNullValues([...arrayFlatten(actionSettings.listDataItems.map(article => {
            return article.blogPostImages ?? []
        })), doppeHideableValueIsVisibleAndNotEqualValue(actionSettings.mainImage, null) ? actionSettings.mainImage : null]);
    },
    resolveMainMedia: actionSettings => {
        return doppeListActionWithMainDetailsGetMainImage(actionSettings, actionSettings.listDataItems[0]?.blogPostImages?.[0] ?? null);
    },
    resolveMainLink: (actionSettings, htmlSiteInfo) => {
        return doppeActionCtaButtonsResolveMainURL(actionSettings, {
            htmlSiteInfo: htmlSiteInfo,
        }) ?? null;
    },
    isConnectedToExternalDataSource: actionSettings => {
        return !!actionSettings.externalDataSource
    },
    resolveUsedBusinessEntities: actionSettings => {
        const externalDataSource = actionSettings.externalDataSource;
        if (externalDataSource) {
            return [
                doppeBlogPostsExternalDataSourceResolveBusinessEntityReferences(externalDataSource)
            ];
        } else {
            return iterableMapToArray(actionSettings.listDataItems, (article, skip) => {
                return article.blogPostProvider !== null && article.blogPostProviderId !== null ?
                    doppeExternalBlogPostProviderResolveBusinessEntityReference(
                        article.blogPostProvider,
                        article.blogPostProviderId
                    ) : skip
            });
        }
    },
    resolveListItemsCount: actionSettings => {
        return actionSettings.externalDataSource ? actionSettings.externalDataSourceItemsCount : doppeListActionSettingsFilterHiddenListItemsFromActionView(actionSettings.listDataItems).length;
    },
    resolveListItems: (action, htmlSiteInfo) => {
        const actionSettings = action.settings;
        return actionSettings.listDataItems.map(item => {
            return {
                url: item.blogPostLink ?? doppeActionCtaButtonsResolveMainURL(
                    doppeListActionListItemCalculateItemButtons(actionSettings.listItemTemplateCtaButtonsSettings, item), {
                    htmlSiteInfo: htmlSiteInfo,
                    sourceURL: item.blogPostLink,
                }) ?? null,
                title: item.blogPostTitle,
                action: action,
                couponCode: doppeHideableValueCreateHidden(''),
                description: item.description,
                image: item.blogPostImages?.[0] ?? null,
                itemId: item.id,
                ribbon: doppeHideableValueCreateHidden(''),
                enabled: item.enabled,
                deleted: item.deleted,
                displayInSearch: item.displayInSearch,
            }
        });
    },
    resolveListSearchSettings: actionSettings => {
        return actionSettings.listSearchSettings;
    },
    replaceExternalImages: (actionSettings, externalImagesUrlToImageResourceMap) => {
        return immerProduce(actionSettings, actionSettings => {
            for (const article of actionSettings.listDataItems) {
                if (article.blogPostImages) {
                    article.blogPostImages = article.blogPostImages.map(
                        image => wixMediaResourceReplaceExternalImage(image, externalImagesUrlToImageResourceMap))
                }
            }

            doppeListActionWithMainDetailsReplaceMainImage(actionSettings, externalImagesUrlToImageResourceMap);
        })
    },
});

export function populateBlogPostsActionPostSettingsDefaultTexts(options: {
    blogPost: DoppeActionTypeArticlesList.ArticleSettings,
    pageContentLocales: DoppeAppPageContentLocales,
}) : DoppeActionTypeArticlesList.ArticleSettings {

    const {
        pageContentLocales,
        blogPost,
    } = options;

    const normalizedBlogPost = populateBlogPostActionSettingsDefaultTexts({
        pageContentLocales: pageContentLocales,
        blogPost: blogPost
    })

    return {
        ...blogPost,
        ...normalizedBlogPost,
        blogPostTitle: doppeHideableValueIsHidden(normalizedBlogPost.blogPostTitle) ? '' : normalizedBlogPost.blogPostTitle,
        ...doppeListActionSettingsGetListItemCtaButtonsDefaultSettings(),
        ...doppeListActionSettingsGetListItemSectionsDefaultSettings(),
    }
}

