import React, {PropsWithChildren} from 'react';
import {
    iterableAny,
    iterableMapToArray,
    objectGetKeys,
    objectShallowEqual,
    stringIsNotNullableOrWhiteSpace
} from "@wix/devzai-utils-common";

export type ReactComponentProps<T> = T extends React.ComponentType<infer P> ? PropsWithChildren<P> : never;

export function reactCreateMemo<T extends React.ComponentType<any>>(
    Comp: T,
    propsAreEqual?: (prevProps: Readonly<ReactComponentProps<T>>, nextProps: Readonly<ReactComponentProps<T>>) => boolean
) : T {
    return (React.memo(Comp, propsAreEqual) as unknown) as T;
}

export function reactForwardRef<COMP extends React.FC<any>>(
    comp: COMP
) : COMP {
    return React.forwardRef((props: any, ref) => {
        return comp({
            ...props,
            ref: ref
        })
    }) as unknown as COMP;
}

// export function reactForwardRef<COMP extends ForwardRefExoticComponent<any>>(
//     render: COMP extends ForwardRefExoticComponent<PropsWithoutRef<infer P> & RefAttributes<infer T>> ?
//         ForwardRefRenderFunction<T, P> : never
// ) : COMP {
//     return (React.forwardRef(render) as unknown) as COMP;
// }

// export function reactForwardRef<RENDER extends ForwardRefRenderFunction<any, any>>(
//     render: RENDER
// ) : COMP {
//     return (React.forwardRef(render) as unknown) as COMP;
// }

export function reactIsRefObject<R>(value: unknown) : value is React.RefObject<R> {
    return (value as React.RefObject<R>).current !== undefined;
}

export function reactWrapNode<T extends React.ReactNode, R extends React.ReactNode> (reactNode: T, wrappingFunc: (reactNode: T) => R) {
    return wrappingFunc(reactNode);
}

export function reactProvideContextValue<T> (reactNode: React.ReactNode, context: React.Context<T>, value: T) {
    const ContextProvider = context.Provider;

    return (
        <ContextProvider value={value}>
            {reactNode}
        </ContextProvider>
    )
}

class ReactMultipleContextValuesProvider {

    private contextValues: {context: React.Context<any>, value: any}[] = [];

    public addContextValue<T> (context: React.Context<T>, value: T) {
        this.contextValues.push({context: context, value: value});

        return this;
    }

    public render (reactNode: React.ReactElement) {
        return this.contextValues.reduce((acc, {context, value}) => {
            return reactProvideContextValue(acc, context, value);
        }, reactNode);
    }
}

export function reactProvideContextValues () {
    return new ReactMultipleContextValuesProvider();
}

export function reactSetInnerHtml (html: string) {
    return {
        [['dangerously', 'SetInnerHTML'].join('')]: {
            __html: html
        }
    }
}

export function reactCreatePropsComparisonWithInspection<P> () {
    return (prevProps: Readonly<PropsWithChildren<P>>, nextProps: Readonly<PropsWithChildren<P>>) => {

        const prevPropsKeys = objectGetKeys(prevProps);
        const propsDiff = iterableMapToArray(prevPropsKeys, (key, skip) => {
            const prevPropValue = prevProps[key];
            const nextPropValue = nextProps[key];

            return prevPropValue !== nextPropValue ? {
                key: key,
                prevPropValue: prevPropValue,
                nextPropValue: nextPropValue
            } : skip
        })

        if (propsDiff.length > 0) {
            console.dir(propsDiff);
        }

        return objectShallowEqual(prevProps, nextProps);
    }
}

export function reactIsEmptyNode (reactNode: React.ReactNode) {
    return !reactIsNotEmptyNode(reactNode);
}

export function reactIsNotEmptyNode (reactNode: React.ReactNode) {
    return typeof reactNode === 'string' ?
        stringIsNotNullableOrWhiteSpace(reactNode) :
        reactNode !== null && reactNode !== undefined
}

export function reactAreSomeNodesNotEmpty (...reactNode: React.ReactNode[]) {
    return iterableAny(reactNode, reactIsNotEmptyNode);
}

export function reactIsValidReactElement<P = any> (value: unknown) : value is React.ReactElement<P> {
    return React.isValidElement<P>(value)
}