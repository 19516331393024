import {ArrayOrSingle, IsExactValueType, OmitByValue, Values} from "@wix/devzai-utils-common";
import {DoppeHideableValue, DoppeListActionSettings} from "../types";
import {RichTextContent} from "@wix/devzai-utils-react";
import {WixMediaResource} from "@wix/devzai-common-wix";
import {BymoPageMedia} from "../bymo-pages/bymo-page-media";

export const BymoPagePropsContentSettingSemanticMeaning = {
    // For some entities, the title is actually a name. For example, for a real-state property, the entity title is actually the property name.
    EntityTitle: 'EntityTitle',
    EntityPublishDate: 'EntityPublishDate',
    EntityDescription: 'EntityDescription',
    EntityRibbon: 'EntityRibbon',
    EntityAddress: 'EntityAddress',
    EntityCouponCode: 'EntityCouponCode',
    EntityPageUrl: 'EntityPageUrl',
    EntityMedia: 'EntityMedia',
    EntityScheduleInfo: 'EntityScheduleInfo',
    EntityParticipantsInfo: 'EntityParticipantsInfo',
    EntityDuration: 'EntityDuration',
    EntityActionButtonText: 'EntityActionButtonText',
} as const;

export type BymoPagePropsContentSettingSemanticMeaning = Values<typeof BymoPagePropsContentSettingSemanticMeaning>;

export const BymoPagePropsContentSettingType = {
    Text: 'Text',
    RichText: 'RichText',
    Url: 'Url',
    MultipleMedia: 'MultipleMedia',
    // Supports also render media in a player.
    MultipleBymoPageMedia: 'MultipleBymoPageMedia',
    SingleMedia: 'SingleMedia',
} as const;

export type BymoPagePropsContentSettingType = Values<typeof BymoPagePropsContentSettingType>;

const SemanticMeaningAllowedSettingTypes = {
    [BymoPagePropsContentSettingSemanticMeaning.EntityTitle]: {
        [BymoPagePropsContentSettingType.Text]: true,
        [BymoPagePropsContentSettingType.RichText]: true,
    },
    [BymoPagePropsContentSettingSemanticMeaning.EntityDescription]: {
        [BymoPagePropsContentSettingType.Text]: true,
        [BymoPagePropsContentSettingType.RichText]: true,
    },
    [BymoPagePropsContentSettingSemanticMeaning.EntityScheduleInfo]: {
        [BymoPagePropsContentSettingType.Text]: true,
        [BymoPagePropsContentSettingType.RichText]: true,
    },
    [BymoPagePropsContentSettingSemanticMeaning.EntityParticipantsInfo]: {
        [BymoPagePropsContentSettingType.Text]: true,
        [BymoPagePropsContentSettingType.RichText]: true,
    },
    [BymoPagePropsContentSettingSemanticMeaning.EntityDuration]: {
        [BymoPagePropsContentSettingType.Text]: true,
        [BymoPagePropsContentSettingType.RichText]: true,
    },
    [BymoPagePropsContentSettingSemanticMeaning.EntityRibbon]: {
        [BymoPagePropsContentSettingType.Text]: true,
    },
    [BymoPagePropsContentSettingSemanticMeaning.EntityPublishDate]: {
        [BymoPagePropsContentSettingType.Text]: true,
    },
    [BymoPagePropsContentSettingSemanticMeaning.EntityActionButtonText]: {
        [BymoPagePropsContentSettingType.Text]: true,
    },
    [BymoPagePropsContentSettingSemanticMeaning.EntityAddress]: {
        [BymoPagePropsContentSettingType.Text]: true,
    },
    [BymoPagePropsContentSettingSemanticMeaning.EntityCouponCode]: {
        [BymoPagePropsContentSettingType.Text]: true,
    },
    [BymoPagePropsContentSettingSemanticMeaning.EntityPageUrl]: {
        [BymoPagePropsContentSettingType.Url]: true,
    },
    [BymoPagePropsContentSettingSemanticMeaning.EntityMedia]: {
        [BymoPagePropsContentSettingType.MultipleMedia]: true,
        [BymoPagePropsContentSettingType.MultipleBymoPageMedia]: true,
        [BymoPagePropsContentSettingType.SingleMedia]: true,
    },
} satisfies Record<BymoPagePropsContentSettingSemanticMeaning, Partial<Record<BymoPagePropsContentSettingType, true>>>;

type SemanticMeaningAllowedSettingTypes = typeof SemanticMeaningAllowedSettingTypes;

export namespace BymoPagePropsContentSettingSemanticMeaning {
    export type ResolveAllowedForSettingType<T extends BymoPagePropsContentSettingType> = {
        [K in keyof SemanticMeaningAllowedSettingTypes]: T extends keyof SemanticMeaningAllowedSettingTypes[K] ? K : never;
    }[keyof SemanticMeaningAllowedSettingTypes]
}

type BymoPagePropsPickPotentialContentSettings<SETTINGS extends {}> = Pick<SETTINGS, keyof OmitByValue<{
    [K in keyof SETTINGS]: BymoPagePropsValueSupportedMetadata<SETTINGS[K]>
}, never>>

export type BymoPagePropsContentSettingsMetadata<
    SETTINGS extends {},
    POTENTIAL_CONTENT_SETTINGS = BymoPagePropsPickPotentialContentSettings<SETTINGS>
> = {
    [K in keyof POTENTIAL_CONTENT_SETTINGS]: BymoPagePropsValueSupportedMetadata<POTENTIAL_CONTENT_SETTINGS[K]>;
}

export type BymoPagePropsValueSupportedMetadata<T, V = DoppeHideableValue.ResolveValueType<T>> =
    IsExactValueType<RichTextContent, V> extends true ? BymoPagePropsContentSettingMetadata<typeof BymoPagePropsContentSettingType.RichText>:
    IsExactValueType<string, V> extends true ? (BymoPagePropsContentSettingMetadata<typeof BymoPagePropsContentSettingType.Text | typeof BymoPagePropsContentSettingType.Url>) :
    IsExactValueType<ArrayOrSingle<WixMediaResource> | null, V> extends true ? (BymoPagePropsContentSettingMetadata<typeof BymoPagePropsContentSettingType.MultipleMedia>) :
    IsExactValueType<BymoPageMedia[] | null, V> extends true ? (BymoPagePropsContentSettingMetadata<typeof BymoPagePropsContentSettingType.MultipleBymoPageMedia>) :
    IsExactValueType<WixMediaResource[] | null, V> extends true ? (BymoPagePropsContentSettingMetadata<typeof BymoPagePropsContentSettingType.MultipleMedia>) :
    IsExactValueType<WixMediaResource | null, V> extends true ? (BymoPagePropsContentSettingMetadata<typeof BymoPagePropsContentSettingType.SingleMedia>) :
    never;

export type BymoPagePropsContentSettingMetadata<TYPE extends BymoPagePropsContentSettingType> = {
    type: TYPE;
    semanticMeaning: BymoPagePropsContentSettingSemanticMeaning.ResolveAllowedForSettingType<TYPE>;
}

export function bymoPagePropsCreateContentSettingMetadata<TYPE extends BymoPagePropsContentSettingType>(
    type: TYPE,
    semanticMeaning: BymoPagePropsContentSettingMetadata<TYPE>['semanticMeaning']
): BymoPagePropsContentSettingMetadata<TYPE> {

    return {
        type: type,
        semanticMeaning: semanticMeaning
    }
}

export function doppeListActionContentSettingsMetadataCreateForListActionWithMainDetails() {
    return {
        mainTitle: bymoPagePropsCreateContentSettingMetadata(BymoPagePropsContentSettingType.Text, BymoPagePropsContentSettingSemanticMeaning.EntityTitle),
        mainImage: bymoPagePropsCreateContentSettingMetadata(BymoPagePropsContentSettingType.SingleMedia, BymoPagePropsContentSettingSemanticMeaning.EntityMedia),
    } satisfies BymoPagePropsContentSettingsMetadata<DoppeListActionSettings.WithMainDetails>
}