import {doppeActionTypeDefineMetadata} from '../../client-server-common/doppe-action-types/doppe-action-type';
import {
    DoppeActionPriceData,
    DoppeDtoStoreProduct,
    doppeDtoStoreProductPriceDataConvertToActionPriceData,
    DoppeExternalProductProvider,
    doppeExternalProductProviderResolveBusinessEntityReference,
    DoppeHideableValue,
    doppeHideableValueCreateHidden,
    doppeHideableValueGetValue,
    doppeHideableValueIsVisibleAndNotEqualValue,
    doppeHideableValueModifyValue
} from '../../client-server-common';
import {
    WixMediaResource,
    wixMediaResourceReplaceExternalImage,
    wixProductMediaTryConvertToWixMediaResource
} from '@wix/devzai-common-wix';
import {DoppeActionTypeProductViewerLang} from './doppe-action-type-product-viewer.lang';
import {arrayRemoveNullValues, asArray, immerProduce, stringIsNotNullableOrWhiteSpace} from '@wix/devzai-utils-common';
import {RichTextContent} from '@wix/devzai-utils-react';
import {DoppeAppPageContentLocales} from '../../client/doppe-app-locales/doppe-app-page-content-locales';
import {
    doppeDtoActionSettingsRemoveInnerCtaContentForLockedAction
} from '../../client-server-common/types/doppe-dto-action';
import {
    DoppeActionCtaButtonSettings,
    doppeActionCtaButtonTypeGetDefaultCtaButtonSettingsMigration,
    getDefaultDoppeActionCtaButtonSettings
} from '../../client-server-common/types/doppe-action-cta-button-settings';
import {
    doppeActionCtaButtonsResetSettingsForLock,
    doppeActionCtaButtonTypeGetDefaultSettings,
    DoppeActionCTAType,
    doppeActionMultipleCTAButtonCreate
} from '../../client-server-common/types/doppe-action-cta-button';
import {
    doppeActionCtaButtonsResolveMainURL
} from '../doppe-actions-common/doppe-cta-button-utils/doppe-cta-button-utils';
import {
    DoppeActionCtaButtonsSettings,
    doppeActionCtaButtonsSettingsDefaultValuesMigration
} from '../../client-server-common/types/doppe-action-cta-buttons-settings';
import {
    doppeActionContentSectionSettingsDefaultValuesMigration,
    DoppeActionContentSectionsSettings
} from '../../client-server-common/types/doppe-action-content-sections-settings';
import {
    BymoPagePropsContentSettingSemanticMeaning, BymoPagePropsContentSettingsMetadata,
    BymoPagePropsContentSettingType,
    bymoPagePropsCreateContentSettingMetadata
} from '../../client-server-common';

export namespace DoppeActionTypeProduct {

    export interface ProductSettings {
        productPageLink: string;
        priceData: DoppeHideableValue<DoppeActionPriceData>;
        productTitle: DoppeHideableValue<string>;
        description: DoppeHideableValue<RichTextContent>;
        productImage: WixMediaResource[] | WixMediaResource | null;
        couponCode: DoppeHideableValue<string>;
        ribbon: DoppeHideableValue<string>;
        productProvider: DoppeExternalProductProvider | null;
        productProviderId: string | null;
    }

    export interface ActionSettings extends
        DoppeActionTypeProduct.ProductSettings,
        DoppeActionContentSectionsSettings,
        DoppeActionCtaButtonsSettings {
    }

    export type DeprecatedFields = Partial<DoppeActionCtaButtonSettings> & {
        productButtonText?: string;
    }

    export type PartialProps = Partial<ActionSettings> & DeprecatedFields;
}

export const doppeActionTypeProductActionContentSettingsMetadata = {
    productTitle: bymoPagePropsCreateContentSettingMetadata(BymoPagePropsContentSettingType.Text, BymoPagePropsContentSettingSemanticMeaning.EntityTitle),
    description: bymoPagePropsCreateContentSettingMetadata(BymoPagePropsContentSettingType.RichText, BymoPagePropsContentSettingSemanticMeaning.EntityDescription),
    couponCode: bymoPagePropsCreateContentSettingMetadata(BymoPagePropsContentSettingType.Text, BymoPagePropsContentSettingSemanticMeaning.EntityCouponCode),
    ribbon: bymoPagePropsCreateContentSettingMetadata(BymoPagePropsContentSettingType.Text, BymoPagePropsContentSettingSemanticMeaning.EntityRibbon),
    productPageLink: bymoPagePropsCreateContentSettingMetadata(BymoPagePropsContentSettingType.Url, BymoPagePropsContentSettingSemanticMeaning.EntityPageUrl),
    productImage: bymoPagePropsCreateContentSettingMetadata(BymoPagePropsContentSettingType.MultipleMedia, BymoPagePropsContentSettingSemanticMeaning.EntityMedia),
} satisfies BymoPagePropsContentSettingsMetadata<DoppeActionTypeProduct.ActionSettings>

export const doppeActionTypeProduct = doppeActionTypeDefineMetadata<DoppeActionTypeProduct.ActionSettings, DoppeActionTypeProduct.PartialProps>({
    id: 'io.bymo.action/product',
    resolveActionSettings: action => {

        const {
            productButtonText = DoppeActionTypeProductViewerLang.defaultTexts.productButtonText,
            productPageLink = '',
            ctaButton,
            ctaButtons,
            sections,
            ...restSettings
        } = action.settings;

        return {
            productPageLink: productPageLink,
            priceData: {
                actualPrice: DoppeActionTypeProductViewerLang.defaultTexts.price,
            },
            productTitle: doppeHideableValueCreateHidden(DoppeActionTypeProductViewerLang.defaultTexts.productTitle),
            description: DoppeActionTypeProductViewerLang.defaultTexts.description,
            productButtonText: productButtonText,
            productImage: null,
            couponCode: doppeHideableValueCreateHidden('CouponCode'),
            ribbon: doppeHideableValueCreateHidden('SALE'),
            productProvider: null,
            productProviderId: null,
            ...getDefaultDoppeActionCtaButtonSettings({
                buttonText: productButtonText,
                showButton: true,
            }),
            ...doppeActionCtaButtonTypeGetDefaultCtaButtonSettingsMigration({
                hasSourceURL: true,
                ctaButton: ctaButton,
            }),
            ...doppeActionContentSectionSettingsDefaultValuesMigration(action.settings),
            ...doppeActionCtaButtonsSettingsDefaultValuesMigration({
                ctaButtons: ctaButtons,
            }, true),
            ...restSettings
        }
    },
    isWidgetOpener: true,
    isLinkOpener: true,
    canRenderInPage: true,
    skipReportingOnGoalAchievedOnActionButtonClick: true,
    settingsMetadata: {
        actionSettingsMetadata: {
            contentSettingsMetadata: doppeActionTypeProductActionContentSettingsMetadata,
            supportsContentSections: true
        },
        listItemsSettingsMetadata: false
    },
    resolveUsedMedia: actionSettings => {
        return arrayRemoveNullValues(asArray(actionSettings.productImage));
    },
    resolveMainMedia: actionSettings => {
        return actionSettings.productImage ? asArray(actionSettings.productImage)[0] ?? null : null;
    },
    resolveMainLink: (actionSettings, htmlSiteInfo) => {
        return doppeActionCtaButtonsResolveMainURL(actionSettings, {
            htmlSiteInfo: htmlSiteInfo,
            sourceURL: actionSettings.productPageLink
        }) ?? null;
    },
    resolveCouponData: actionSettings => {
        return {
            showCouponCode: doppeHideableValueIsVisibleAndNotEqualValue(actionSettings.couponCode, ''),
            couponCode: doppeHideableValueGetValue(actionSettings.couponCode),
            couponDescription: doppeHideableValueIsVisibleAndNotEqualValue(actionSettings.description, '') ? actionSettings.description : '',
            couponTitle: doppeHideableValueIsVisibleAndNotEqualValue(actionSettings.productTitle, '') ? actionSettings.productTitle : '',
        }
    },
    resolveUsedBusinessEntities: actionSettings => {
        return arrayRemoveNullValues([
            actionSettings.productProvider !== null && actionSettings.productProviderId !== null ?
                doppeExternalProductProviderResolveBusinessEntityReference(
                    actionSettings.productProvider,
                    actionSettings.productProviderId
                ) :
                null
        ])
    },
    replaceExternalImages: (actionSettings, externalImagesUrlToImageResourceMap) => {
        return immerProduce(actionSettings, actionSettings => {
            if (actionSettings.productImage) {
                const images = asArray(actionSettings.productImage);
                actionSettings.productImage = images.map(image => {
                    return wixMediaResourceReplaceExternalImage(image, externalImagesUrlToImageResourceMap)
                })
            }
        })
    },
    prepareForViewer: (actionSettings, options) => {
        doppeDtoActionSettingsRemoveInnerCtaContentForLockedAction(actionSettings, actionSettings => {
            actionSettings.productPageLink = '';
            doppeActionCtaButtonsResetSettingsForLock(actionSettings);
        }, options);
    },
});

export function doppeActionTypeProductResolveSettingsFromBoundEntity(
    actionSettings: DoppeActionTypeProduct.ProductSettings,
    storeProduct: DoppeDtoStoreProduct
)  {
    return {
        ...actionSettings,
        priceData: doppeHideableValueModifyValue(
            actionSettings.priceData,
            doppeDtoStoreProductPriceDataConvertToActionPriceData(storeProduct.price)
        ),
        productPageLink: stringIsNotNullableOrWhiteSpace(storeProduct.productPageUrl) ?
            storeProduct.productPageUrl : 'about:blank',
    } satisfies DoppeActionTypeProduct.ProductSettings
}

export function convertDoppeWixProductToProductSettingsWithDefaultTexts(options: {
    product: DoppeDtoStoreProduct,
    productSettings?: DoppeActionTypeProduct.ActionSettings,
    pageContentLocales: DoppeAppPageContentLocales
}) {

    const {
        product,
        productSettings,
        pageContentLocales
    } = options;

    const productImages = product.media?.items ?
        arrayRemoveNullValues(product.media?.items.map(item => wixProductMediaTryConvertToWixMediaResource(item))) : null;

    const productTitle = product?.name;

    return {
        ribbon: doppeHideableValueCreateHidden(pageContentLocales["product.actionSettings.ribbon"]),
        couponCode: doppeHideableValueCreateHidden(pageContentLocales["product.actionSettings.couponCode"]),
        sections: [],
        ctaButtons: [doppeActionMultipleCTAButtonCreate({
            text: pageContentLocales["product.actionSettings.productButtonText"],
            isRequired: false,
            settings: doppeActionCtaButtonTypeGetDefaultSettings(DoppeActionCTAType.Link, true)
        })],
        ...productSettings,
        productImage: productImages,
        productPageLink: product.productPageUrl ?? productSettings?.productPageLink ?? '',
        priceData: doppeDtoStoreProductPriceDataConvertToActionPriceData(product.price),
        productTitle: productTitle ?? pageContentLocales["product.actionSettings.title"],
        description: product.descriptionPlainText ?? pageContentLocales["product.actionSettings.description"],
        productProvider: DoppeExternalProductProvider.Wix,
        productProviderId: product.id,
    } satisfies DoppeActionTypeProduct.ActionSettings
}

export function convertDoppeWixProductToProductSettings(options: {
    product: DoppeDtoStoreProduct, productSettings?: DoppeActionTypeProduct.ActionSettings
}) {

    const {
        product,
        productSettings,
    } = options;

    const productImages = product.media?.items ?
        arrayRemoveNullValues(product.media?.items.map(item => wixProductMediaTryConvertToWixMediaResource(item))) : null;

    const productTitle = product?.name;

    return {
        ribbon: doppeHideableValueCreateHidden(''),
        couponCode: doppeHideableValueCreateHidden(''),
        sections: [],
        ...doppeActionCtaButtonsSettingsDefaultValuesMigration({
            ctaButtons: [doppeActionMultipleCTAButtonCreate({
                text: '',
                isRequired: false,
                settings: doppeActionCtaButtonTypeGetDefaultSettings(DoppeActionCTAType.Link, true)
            })],
        }, false),
        ...productSettings,
        productImage: productImages,
        productPageLink: product.productPageUrl ?? productSettings?.productPageLink ?? '',
        priceData: doppeDtoStoreProductPriceDataConvertToActionPriceData(product.price),
        productTitle: productTitle,
        description: product.descriptionPlainText,
        productProvider: DoppeExternalProductProvider.Wix,
        productProviderId: product.id,
    } satisfies DoppeActionTypeProduct.ActionSettings
}