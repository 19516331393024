import {Values} from "../common-types";
import {sizeCalculateContainedSize, sizeCalculateCoverSize} from "../size";

export const HtmlObjectResizeMode = {
    None: 'none',
    Fill: 'fill',
    Cover: 'cover',
    Contain: 'contain',
    ScaleDown: 'scale-down',
} as const;

export type HtmlObjectResizeMode = Values<typeof HtmlObjectResizeMode>;

export const HtmlObjectVerticalPosition = {
    Top: 'top',
    Center: 'center',
    Bottom: 'bottom',
} as const;

export type HtmlObjectVerticalPosition = Values<typeof HtmlObjectVerticalPosition>;

export const HtmlObjectHorizontalPosition = {
    Left: 'left',
    Center: 'center',
    Right: 'right',
} as const;

export type HtmlObjectHorizontalPosition = Values<typeof HtmlObjectHorizontalPosition>;

export function htmlObjectCalculateResizedSize (
    options: {
        width: number;
        height: number;
        resizeMode: HtmlObjectResizeMode;
        targetWidth?: number;
        targetHeight?: number;
    }
) {
    const {
        width,
        height,
        resizeMode,
        targetWidth,
        targetHeight
    } = options;

    if (targetWidth === 0 || targetHeight === 0 || (resizeMode !== HtmlObjectResizeMode.Fill && targetWidth === undefined && targetHeight === undefined)) {
        // Unknown size
        return undefined;
    }

    if (resizeMode === HtmlObjectResizeMode.ScaleDown && width <= (targetWidth ?? Infinity) && height <= (targetHeight ?? Infinity)) {
        return {width: width, height: height};
    } else {
        switch (resizeMode) {
            case HtmlObjectResizeMode.None: {
                return {width: width, height: height}
            }
            case HtmlObjectResizeMode.ScaleDown:
            case HtmlObjectResizeMode.Contain: {
                return sizeCalculateContainedSize(
                    {width: width, height: height},
                    {width: targetWidth ?? Infinity, height: targetHeight ?? Infinity}
                )
            }
            case HtmlObjectResizeMode.Fill: {
                return {width: targetWidth ?? width, height: targetHeight ?? height}
            }
            case HtmlObjectResizeMode.Cover: {
                return sizeCalculateCoverSize(
                    {width: width, height: height},
                    {width: targetWidth ?? 0, height: targetHeight ?? 0}
                )
            }
        }
    }
}

export function htmlObjectCanPositionHorizontally (
    htmlObjectResizeMode: Extract<HtmlObjectResizeMode, typeof HtmlObjectResizeMode.Contain | typeof HtmlObjectResizeMode.Cover>,
    objWidthHeightRatio: number,
    targetRectWidthHeightRatio: number,
) {
    switch (htmlObjectResizeMode) {
        case HtmlObjectResizeMode.Cover: {
            return objWidthHeightRatio > targetRectWidthHeightRatio;
        }
        case HtmlObjectResizeMode.Contain: {
            return objWidthHeightRatio < targetRectWidthHeightRatio;
        }
    }
}

export function htmlObjectCanPositionVertically (
    htmlObjectResizeMode: Extract<HtmlObjectResizeMode, typeof HtmlObjectResizeMode.Contain | typeof HtmlObjectResizeMode.Cover>,
    objWidthHeightRatio: number,
    targetRectWidthHeightRatio: number,
) {
    switch (htmlObjectResizeMode) {
        case HtmlObjectResizeMode.Cover: {
            return objWidthHeightRatio < targetRectWidthHeightRatio;
        }
        case HtmlObjectResizeMode.Contain: {
            return objWidthHeightRatio > targetRectWidthHeightRatio;
        }
    }
}