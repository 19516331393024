import {doppeActionTypeDefineMetadata} from '../../client-server-common/doppe-action-types/doppe-action-type';
import {
    arrayFlatten,
    arrayRemoveNullValues,
    asArray,
    autoValueIsAutoValue,
    immerProduce,
    PartiallyOptional
} from '@wix/devzai-utils-common';
import {DoppeActionTypeVideo} from '../doppe-action-type-video/doppe-action-type-video';
import {wixMediaResourceReplaceExternalImage} from '@wix/devzai-common-wix';
import {
    convertCommonListSettingToDefaultDoppeActionCtaButtonSettingsFromListAction,
    DoppeActionLayout,
    doppeBymoPageVideoSourceResolveVideoUrl,
    DoppeHideableValue,
    doppeHideableValueCreateHidden,
    doppeHideableValueGetValue,
    doppeHideableValueIsVisible,
    doppeHideableValueIsVisibleAndNotEqualValue,
    doppeListActionGetDefaultListSettings,
    doppeListActionGetDefaultSearchListSettings,
    doppeListActionGetItemClickBehaviourDefaultListSettings,
    doppeListActionGetMainDetailsDefaultSettings,
    doppeListActionItemCtaButtonsResetSettingsForLock,
    doppeListActionListItemGetDefaultListItemSettingsMigration,
    doppeListActionListItemWithCtaButtonsMigration,
    doppeListActionListItemWithSectionsMigration,
    DoppeListActionSettings,
    doppeListActionSettingsFilterHiddenListItemsFromActionView,
    doppeListActionTemplateCtaButtonsSettingsDefaultValuesMigration,
    doppeListActionTemplateSectionsSettingsDefaultValuesMigration,
    doppeListActionWithMainDetailsGetMainImage,
    doppeListActionWithMainDetailsReplaceMainImage
} from '../../client-server-common';
import {
    doppeDtoActionSettingsRemoveInnerCtaContentForLockedAction
} from '../../client-server-common/types/doppe-dto-action';
import {doppeActionCtaButtonsResetSettingsForLock} from '../../client-server-common/types/doppe-action-cta-button';
import {
    DoppeActionCtaButtonSettings,
    doppeActionCtaButtonTypeGetDefaultCtaButtonSettingsMigration,
    getDefaultDoppeActionCtaButtonSettings
} from '../../client-server-common/types/doppe-action-cta-button-settings';
import {
    doppeActionCtaButtonsResolveMainURL
} from '../doppe-actions-common/doppe-cta-button-utils/doppe-cta-button-utils';
import {
    doppeActionContentSectionSettingsDefaultValuesMigration,
    DoppeActionContentSectionsSettings
} from '../../client-server-common/types/doppe-action-content-sections-settings';
import {
    DoppeActionCtaButtonsSettings,
    doppeActionCtaButtonsSettingsDefaultValuesMigration
} from '../../client-server-common/types/doppe-action-cta-buttons-settings';


export namespace DoppeActionTypeVideosList {

    import DoppeActionTypeVideoURLSource = DoppeActionTypeVideo.DoppeActionTypeVideoURLSource;

    export interface VideoSettings extends
        DoppeActionTypeVideosList.DeprecatedItemFields,
        DoppeListActionSettings.ListItemWithCtaButtons,
        DoppeListActionSettings.ListItemWithSections,
        DoppeListActionSettings.ListItemBaseSettings,
        DoppeListActionSettings.ListItemBaseSettings,
        DoppeActionTypeVideo.VideoSettings {
        title: string;
    }

    export interface ActionSettings extends
        DoppeListActionSettings<VideoSettings>,
        DoppeListActionSettings.WithSearchSettings,
        DoppeListActionSettings.WithMainDetails,
        DoppeListActionSettings.WithItemClickBehaviour,
        DoppeActionContentSectionsSettings,
        DoppeActionCtaButtonsSettings
    {
        previewShowTitle: boolean;
        previewShowDescription: boolean;
    }

    export type ActionDeprecatedProps = Partial<DoppeListActionSettings.WithCtaButton> & {
        videos?: VideoSettings[];
        previewButtonText?: string;
    };

    export interface DeprecatedItemFields extends Partial<DoppeActionCtaButtonSettings> {
        videoButtonText?: DoppeHideableValue<string>;
        videoButtonURL?: DoppeActionTypeVideoURLSource;
    }

    export type PartialAndDeprecatedActionSettings = DoppeListActionSettings.ResolvePartialAndDeprecatedActionSettings<
        ActionSettings,
        DoppeActionTypeVideosList.VideoSettings,
        'listDataItems'
    > & ActionDeprecatedProps
}

export const doppeActionTypeVideosList = doppeActionTypeDefineMetadata<
    DoppeActionTypeVideosList.ActionSettings,
    DoppeActionTypeVideosList.PartialAndDeprecatedActionSettings
>({
    id: 'io.bymo.action/videos-list',
    resolveActionSettings: action => {

        const {
            listDataItems = [],
            ctaButton,
            previewButtonText,
            listSearchSettings,
            listItemTemplateCtaButtonsSettings,
            listItemTemplateSectionsSettings,
            ctaButtons,
            sections,
            ...restSettings
        } = action.settings;

        return {
            previewShowDescription: true,
            previewShowTitle: true,
            previewButtonText: previewButtonText ? previewButtonText : 'Open link',
            ...doppeListActionGetDefaultSearchListSettings(action.settings),
            ...doppeListActionGetDefaultListSettings({
                layout: DoppeActionLayout.Grid,
                listDataItems: listDataItems.map(video => {

                    const {
                        title = 'Video Title',
                        videoButtonText,
                        videoButtonURL,
                        ctaButton,
                        customSections,
                        customCtaButtons,
                        sectionsOverridesMap,
                        ctaButtonsOverridesMap,
                        videoProvider = null,
                        videoProviderId = null,
                        ...restVideoSettings
                    } = video;

                    return {
                        videoProvider: videoProvider,
                        videoProviderId: videoProviderId,
                        videoButtonURL: videoButtonURL,
                        videoButtonText: videoButtonText,
                        title: title,
                        ...getDefaultDoppeActionCtaButtonSettings({
                            buttonText: videoButtonText ? doppeHideableValueGetValue(videoButtonText) : '',
                            showButton: doppeHideableValueIsVisible(videoButtonText),
                            url: videoButtonURL ? (autoValueIsAutoValue(videoButtonURL) ? undefined : videoButtonURL) : undefined
                        }),
                        ...doppeActionCtaButtonTypeGetDefaultCtaButtonSettingsMigration({
                            hasSourceURL: true,
                            ctaButton: ctaButton,
                        }),
                        ...doppeListActionListItemGetDefaultListItemSettingsMigration(video.id),
                        ...doppeListActionListItemWithCtaButtonsMigration(video, true),
                        ...doppeListActionListItemWithSectionsMigration(video),
                        ...restVideoSettings as PartiallyOptional<DoppeActionTypeVideosList.VideoSettings & DoppeActionTypeVideosList.DeprecatedItemFields, 'title' | 'videoProviderId' | 'videoProvider' | 'ctaButton' | 'videoButtonURL' | 'videoButtonText' | 'customCtaButtons' |'customSections' | 'ctaButtonsOverridesMap' | 'sectionsOverridesMap'>,
                    }
                }),
            }),
            ...doppeListActionGetItemClickBehaviourDefaultListSettings(),
            ...doppeListActionGetMainDetailsDefaultSettings({
                defaultTitle: ''
            }),
            ...convertCommonListSettingToDefaultDoppeActionCtaButtonSettingsFromListAction(action.settings),
            ...doppeActionCtaButtonTypeGetDefaultCtaButtonSettingsMigration({
                ctaButton: ctaButton,
                hasSourceURL: false
            }),
            ...doppeActionContentSectionSettingsDefaultValuesMigration(action.settings),
            ...doppeActionCtaButtonsSettingsDefaultValuesMigration(action.settings, false),
            ...doppeListActionTemplateCtaButtonsSettingsDefaultValuesMigration(action.settings, true),
            ...doppeListActionTemplateSectionsSettingsDefaultValuesMigration(action.settings),
            ...restSettings
        }
    },
    isWidgetOpener: true,
    canRenderInPage: true,
    skipReportingOnGoalAchievedOnActionButtonClick: true,
    isListItemLinkOpener: true,
    supportsListLayout: true,
    resolveUsedMedia: actionSettings => {
        return arrayRemoveNullValues([...arrayFlatten(actionSettings.listDataItems.map(article => {
            return [article.videoImage]
        })), doppeHideableValueIsVisibleAndNotEqualValue(actionSettings.mainImage, null) ? actionSettings.mainImage : null]);
    },
    resolveMainMedia: actionSettings => {
        return doppeListActionWithMainDetailsGetMainImage(actionSettings, actionSettings.listDataItems[0]?.videoImage);
    },
    resolveMainLink: (actionSettings, htmlSiteInfo) => {
        return doppeActionCtaButtonsResolveMainURL(actionSettings, {
            htmlSiteInfo: htmlSiteInfo,
        }) ?? null;
    },
    prepareForViewer: (actionSettings, options) => {
        doppeDtoActionSettingsRemoveInnerCtaContentForLockedAction(actionSettings, actionSettings => {
            doppeListActionItemCtaButtonsResetSettingsForLock(actionSettings);
            doppeActionCtaButtonsResetSettingsForLock(actionSettings);
        }, options);
    },
    resolveListItemsCount: actionSettings => {
        return doppeListActionSettingsFilterHiddenListItemsFromActionView(actionSettings.listDataItems).length;
    },
    resolveListItems: action => {
        const actionSettings = action.settings;
        return actionSettings.listDataItems.map(item => {
            return {
                title: item.title,
                action: action,
                couponCode: doppeHideableValueCreateHidden(''),
                description: item.description,
                image: item.videoImage ? asArray(item.videoImage)[0] : null,
                itemId: item.id,
                ribbon: doppeHideableValueCreateHidden(''),
                url: doppeBymoPageVideoSourceResolveVideoUrl(item.externalVideoUrl),
                enabled: item.enabled,
                deleted: item.deleted,
                displayInSearch: item.displayInSearch,
            }
        });
    },
    resolveListSearchSettings: actionSettings => {
        return actionSettings.listSearchSettings;
    },
    replaceExternalImages: (actionSettings, externalImagesUrlToImageResourceMap) => {
        return immerProduce(actionSettings, actionSettings => {
            for (const item of actionSettings.listDataItems) {
                if (item.videoImage) {
                    item.videoImage =
                        wixMediaResourceReplaceExternalImage(item.videoImage, externalImagesUrlToImageResourceMap)
                }
            }

            doppeListActionWithMainDetailsReplaceMainImage(actionSettings, externalImagesUrlToImageResourceMap);

        })
    },
});