import {
    HtmlObjectHorizontalPosition,
    HtmlObjectVerticalPosition,
    PartiallyRequired,
    uuidCreate,
    Values
} from '@wix/devzai-utils-common';
import {DoppeHideableValue, doppeHideableValueCreateHidden} from './doppe-hideable-value';
import {WixMediaResource} from '@wix/devzai-common-wix';
import {RichTextContent} from '@wix/devzai-utils-react';
import {DoppeBymoPageVideoSource} from './doppe-bymo-page-video-source';
import {
    BymoPageActionMediaLayout,
    BymoPageActionMediaSize,
    BymoPageActionMediaSizing,
    BymoPageActionMediaViewSettings
} from '../bymo-pages';
import {
    DoppeActionTypeTestimonialsList, doppeActionTypeTestimonialsListResolveListItemSettings
} from '../../doppe-action-types/doppe-action-type-testimonials-list/doppe-action-type-testimonials-list';
import {DoppeActionLayout} from './doppe-action-layout';
import {
    DoppeActionTypeMediaGallery, doppeActionTypeMediaGalleryResolveListItemSettings
} from '../../doppe-action-types/doppe-action-type-media-gallery/doppe-action-type-media-gallery';
import {
    doppeListActionGetDefaultListSettings,
    DoppeListActionSettings,
    doppeListActionTemplateCtaButtonsSettingsDefaultValuesMigration,
    doppeListActionTemplateSectionsSettingsDefaultValuesMigration
} from './doppe-list-action-settings';


export const DoppeActionContentSectionType = {
    RichContent: 'RichContent',
    Image: 'Image',
    Video: 'Video',
    Gallery: 'Gallery',
    PropertyDetails: 'PropertyDetails',
    Testimonials: 'Testimonials',
} as const;

export type DoppeActionContentSectionType = Values<typeof DoppeActionContentSectionType>;

export namespace DoppeActionContentSectionGallery {

    export type GalleryItem = {
        media: WixMediaResource | null;
        title: DoppeHideableValue<string>;
        description: DoppeHideableValue<RichTextContent>;
    }
}


export type DoppeActionContentSection = DoppeActionContentSection.Section;

export type DoppeActionContentSectionContent =
    | DoppeActionContentSection.RichTextSettings
    | DoppeActionContentSection.ImageSettings
    | DoppeActionContentSection.VideoSettings
    | DoppeActionContentSection.GallerySettings
    | DoppeActionContentSection.TestimonialsSettings
    | DoppeActionContentSection.PropertyDetailsSettings;


export namespace DoppeActionContentSection {

    export type BaseType = {
        content: DoppeActionContentSectionContent;
    }

    export type Common = DoppeActionContentSection.BaseType & {
        isVisible: boolean;
    }

    export type Section = DoppeActionContentSection.Common & {
        layoutSettings: DoppeActionContentSectionLayoutSettings;
        title: string;
        isRequired: boolean;
        id: string;
    }

    export type Override = DoppeActionContentSection.Common & {
        useDefaultSectionContent: boolean;
    }

    export type ContentSectionWithOverride = DoppeActionContentSection.Section & DoppeActionContentSection.Override & {
        isDefaultSection: boolean;
    }


    type ContentBaseType<T extends DoppeActionContentSectionType> = {
        type: T;
    }

    export type RichTextSettings = ContentBaseType<typeof DoppeActionContentSectionType.RichContent> & {
        richText: RichTextContent
    };

    export type ImageSettings = ContentBaseType<typeof DoppeActionContentSectionType.Image> & {
        image: WixMediaResource | null;
        imageViewSettings: BymoPageActionMediaViewSettings;
    };

    export type VideoSettings = ContentBaseType<typeof DoppeActionContentSectionType.Video> & {
        video: DoppeBymoPageVideoSource | null;
        autoPlay: boolean;
    };

    export type GallerySettings = ContentBaseType<typeof DoppeActionContentSectionType.Gallery> &
        DoppeListActionSettings<DoppeActionTypeMediaGallery.MediaGalleryListItemSettings>  & {
    };

    export type TestimonialsSettings = ContentBaseType<typeof DoppeActionContentSectionType.Testimonials> &
        DoppeListActionSettings<DoppeActionTypeTestimonialsList.TestimonialItemSettings> & {

    }

    export type PropertyDetailsSettings = ContentBaseType<typeof DoppeActionContentSectionType.PropertyDetails> & {
        bedroomsNumber: DoppeHideableValue<number>;
        bathroomsNumber: DoppeHideableValue<number>;
        houseArea: DoppeHideableValue<string>;
        landArea: DoppeHideableValue<string>;
        floors: DoppeHideableValue<number>;
        yearBuilt: DoppeHideableValue<number>;
    };
}


export const DoppeActionContentSectionLayoutType = {
    Inline: 'Inline',
    Collapsable: 'Collapsable',
} as const;

export type DoppeActionContentSectionLayoutType = Values<typeof DoppeActionContentSectionLayoutType>;

export type DoppeActionContentSectionLayoutSettings =
    | DoppeActionContentSectionLayoutSettings.Inline
    | DoppeActionContentSectionLayoutSettings.Collapsable;

export namespace DoppeActionContentSectionLayoutSettings {

    type BaseType<T extends DoppeActionContentSectionLayoutType> = {
        type: T;
    }

    export type Inline = BaseType<typeof DoppeActionContentSectionLayoutType.Inline> & {
        hideHeader: boolean;
    };

    export type Collapsable = BaseType<typeof DoppeActionContentSectionLayoutType.Collapsable> & {
        isOpenByDefault: boolean;
    };
}

export function doppeActionSectionIsVisible(section: DoppeActionContentSection) {
    return section.isVisible;
}

export function doppeActionContentSectionLayoutCreateDefault(type: DoppeActionContentSectionLayoutType) {

    switch (type) {
        case DoppeActionContentSectionLayoutType.Inline: {
            return {
                type: DoppeActionContentSectionLayoutType.Inline,
                hideHeader: false,
            } satisfies DoppeActionContentSectionLayoutSettings.Inline;
        }
        case DoppeActionContentSectionLayoutType.Collapsable: {
            return {
                type: DoppeActionContentSectionLayoutType.Collapsable,
                isOpenByDefault: false,
            } satisfies DoppeActionContentSectionLayoutSettings.Collapsable;
        }
    }
}


export function doppeActionContentSectionGetDefaultValues(type: DoppeActionContentSectionType) {

    const baseSettings = {
        isVisible: true,
        id: uuidCreate(),
        layoutSettings: {
            type: DoppeActionContentSectionLayoutType.Collapsable,
            isOpenByDefault: false
        },
        isRequired: false,
        title: ''
    }

    const partialSectionContentSettings = doppeActionContentSectionPartialCreateDefaultContentSettings(type);

    return {
        content: partialSectionContentSettings,
        ...baseSettings
    }  satisfies DoppeActionContentSection.Section;
}

export function doppeActionContentSectionPartialCreateDefaultContentSettings(type: DoppeActionContentSectionType) : DoppeActionContentSectionContent {
    switch (type) {
        case DoppeActionContentSectionType.Image: {
            return {
                type: DoppeActionContentSectionType.Image,
                image: null,
                imageViewSettings: {
                    mediaLayout: BymoPageActionMediaLayout.Cropped,
                    mediaSizing: BymoPageActionMediaSizing.Custom,
                    size: BymoPageActionMediaSize.Square,
                    alignment: {
                        horizontal: HtmlObjectHorizontalPosition.Center,
                        vertical: HtmlObjectVerticalPosition.Center
                    }
                },
            } satisfies DoppeActionContentSection.ImageSettings;
        }
        case DoppeActionContentSectionType.Video: {
            return {
                type: DoppeActionContentSectionType.Video,
                video: null,
                autoPlay: true,
            } satisfies DoppeActionContentSection.VideoSettings;
        }
        case DoppeActionContentSectionType.Gallery: {
            return {
                type: DoppeActionContentSectionType.Gallery,
                ...doppeListActionGetDefaultListSettings({
                    layout: DoppeActionLayout.Carousel,
                    listDataItems: [],
                }),
                ...doppeListActionTemplateCtaButtonsSettingsDefaultValuesMigration({}, false),
                ...doppeListActionTemplateSectionsSettingsDefaultValuesMigration({}),
            } satisfies DoppeActionContentSection.GallerySettings;
        }
        case DoppeActionContentSectionType.Testimonials: {
            return {
                type: DoppeActionContentSectionType.Testimonials,
                ...doppeListActionGetDefaultListSettings({
                    layout: DoppeActionLayout.Carousel,
                    listDataItems: [],
                }),
                ...doppeListActionTemplateCtaButtonsSettingsDefaultValuesMigration({}, false),
                ...doppeListActionTemplateSectionsSettingsDefaultValuesMigration({}),
            } satisfies DoppeActionContentSection.TestimonialsSettings;
        }
        case DoppeActionContentSectionType.PropertyDetails: {
            return {
                type: DoppeActionContentSectionType.PropertyDetails,
                bedroomsNumber: doppeHideableValueCreateHidden(3),
                bathroomsNumber: doppeHideableValueCreateHidden(5),
                floors: doppeHideableValueCreateHidden(1),
                yearBuilt: doppeHideableValueCreateHidden(2000),
                houseArea: doppeHideableValueCreateHidden(''),
                landArea: doppeHideableValueCreateHidden(''),
            } satisfies DoppeActionContentSection.PropertyDetailsSettings
        }
        case DoppeActionContentSectionType.RichContent: {
            return {
                type: DoppeActionContentSectionType.RichContent,
                richText: ''
            } satisfies DoppeActionContentSection.RichTextSettings;
        }
    }
}

export function doppeActionContentSectionPartialCreateDefaultContentSettingsMigration(content: PartiallyRequired<DoppeActionContentSectionContent, 'type'>) : DoppeActionContentSectionContent {
    switch (content.type) {
        case DoppeActionContentSectionType.Image: {

            const actualContent = content as Partial<DoppeActionContentSection.ImageSettings>;

            const {
                imageViewSettings,
                ...restContent
            } = actualContent ?? {};


            return {
                type: DoppeActionContentSectionType.Image,
                image: null,
                imageViewSettings: {
                    mediaLayout: BymoPageActionMediaLayout.Cropped,
                    mediaSizing: BymoPageActionMediaSizing.Custom,
                    size: BymoPageActionMediaSize.Square,
                    alignment: {
                        horizontal: HtmlObjectHorizontalPosition.Center,
                        vertical: HtmlObjectVerticalPosition.Center
                    },
                    ...imageViewSettings
                },
                ...restContent,
            } satisfies DoppeActionContentSection.ImageSettings;
        }
        case DoppeActionContentSectionType.Video: {

            const actualContent = content as Partial<DoppeActionContentSection.VideoSettings>;

            return {
                type: DoppeActionContentSectionType.Video,
                video: null,
                autoPlay: true,
                ...actualContent
            } satisfies DoppeActionContentSection.VideoSettings;
        }
        case DoppeActionContentSectionType.Gallery: {

            const actualContent = content as Partial<DoppeActionContentSection.GallerySettings>;

            const {
                listDataItems = [],
                listItemTemplateCtaButtonsSettings,
                listItemTemplateSectionsSettings,
                ...restContent
            } = actualContent ?? {};

            return {
                type: DoppeActionContentSectionType.Gallery,
                ...doppeListActionGetDefaultListSettings({
                    layout: DoppeActionLayout.Carousel,
                    listDataItems: listDataItems.map(doppeActionTypeMediaGalleryResolveListItemSettings)
                }),
                ...doppeListActionTemplateCtaButtonsSettingsDefaultValuesMigration(actualContent, false),
                ...doppeListActionTemplateSectionsSettingsDefaultValuesMigration(actualContent),
                layout: DoppeActionLayout.Carousel,
                ...restContent
            } satisfies DoppeActionContentSection.GallerySettings;
        }
        case DoppeActionContentSectionType.Testimonials: {
            const actualContent = content as Partial<DoppeActionContentSection.TestimonialsSettings>;

            const {
                listDataItems = [],
                listItemTemplateCtaButtonsSettings,
                listItemTemplateSectionsSettings,
                ...restContent
            } = actualContent ?? {};

            return {
                type: DoppeActionContentSectionType.Testimonials,
                ...doppeListActionGetDefaultListSettings({
                    layout: DoppeActionLayout.Carousel,
                    listDataItems: listDataItems.map(doppeActionTypeTestimonialsListResolveListItemSettings)
                }),
                ...doppeListActionTemplateCtaButtonsSettingsDefaultValuesMigration(actualContent, false),
                ...doppeListActionTemplateSectionsSettingsDefaultValuesMigration(actualContent),
                ...restContent
            } satisfies DoppeActionContentSection.TestimonialsSettings;
        }
        case DoppeActionContentSectionType.PropertyDetails: {

            const actualContent = content as Partial<DoppeActionContentSection.PropertyDetailsSettings>;

            return {
                type: DoppeActionContentSectionType.PropertyDetails,
                bedroomsNumber: doppeHideableValueCreateHidden(3),
                bathroomsNumber: doppeHideableValueCreateHidden(5),
                floors: doppeHideableValueCreateHidden(1),
                yearBuilt: doppeHideableValueCreateHidden(2000),
                houseArea: doppeHideableValueCreateHidden(''),
                landArea: doppeHideableValueCreateHidden(''),
                ...actualContent,
            } satisfies DoppeActionContentSection.PropertyDetailsSettings
        }
        case DoppeActionContentSectionType.RichContent: {
            const actualContent = content as Partial<DoppeActionContentSection.RichTextSettings>;

            return {
                type: DoppeActionContentSectionType.RichContent,
                richText: '',
                ...actualContent
            } satisfies DoppeActionContentSection.RichTextSettings;
        }
    }
}
