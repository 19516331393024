import {doppeActionTypeDefineMetadata} from '../../client-server-common/doppe-action-types/doppe-action-type';
import {
    DoppeHideableValue,
    doppeHideableValueCreateHidden,
    doppeHideableValueGetVisibleValue,
    doppeHideableValueIsVisible
} from '../../client-server-common/types/doppe-hideable-value';
import {immerProduce, OmitStrict, PartiallyOptional, PartiallyRequired} from '@wix/devzai-utils-common';
import {WixImageResource, wixMediaResourceReplaceExternalImage} from '@wix/devzai-common-wix';
import {RichTextContent} from '@wix/devzai-utils-react';
import {
    DoppeDtoContactMethod,
    DoppeDtoContactMethodDeprecated
} from '../../client-server-common/types/doppe-dto-contact-method';
import {DoppeActionFullName} from '../../client-server-common/types/doppe-action-full-name';
import {
    DoppeActionCtaButtonSettings,
    doppeActionCtaButtonTypeGetDefaultCtaButtonSettingsMigration,
    getDefaultDoppeActionCtaButtonSettings
} from '../../client-server-common/types/doppe-action-cta-button-settings';
import {
    doppeActionCtaButtonsResetSettingsForLock,
    DoppeActionCTAType
} from '../../client-server-common/types/doppe-action-cta-button';
import {
    doppeDtoActionSettingsRemoveInnerCtaContentForLockedAction
} from '../../client-server-common/types/doppe-dto-action';
import {
    doppeActionCtaButtonsResolveMainURL
} from '../doppe-actions-common/doppe-cta-button-utils/doppe-cta-button-utils';
import {
    doppeActionContentSectionSettingsDefaultValuesMigration,
    DoppeActionContentSectionsSettings
} from '../../client-server-common/types/doppe-action-content-sections-settings';
import {
    DoppeActionCtaButtonsSettings,
    doppeActionCtaButtonsSettingsDefaultValuesMigration
} from '../../client-server-common/types/doppe-action-cta-buttons-settings';

export namespace DoppeActionTypeContactCard {

    export interface ContactSettings {
        media: WixImageResource[] | null;
        description: DoppeHideableValue<RichTextContent>;
        name: DoppeHideableValue<DoppeActionFullName>;
        jobTitle: DoppeHideableValue<string>;
        contactMethods: DoppeDtoContactMethod[];
        company: DoppeHideableValue<string>;
        notes: string;
        saveContactProfileImage: DoppeHideableValue<WixImageResource | null>
    }

    export interface ActionSettings extends
        DoppeActionContentSectionsSettings,
        DoppeActionCtaButtonsSettings,
        ContactSettings
    {

    }


    export type PartialActionSettings = Partial<OmitStrict<DoppeActionTypeContactCard.ActionSettings, 'contactMethods'>>
        & DeprecatedFields & {
    }

    export type xxx = DoppeDtoContactMethod & DoppeDtoContactMethodDeprecated;

    export type DeprecatedFields = Partial<DoppeActionCtaButtonSettings> & {
        contactMethods?: PartiallyRequired<Partial<xxx>, 'id'>[]
        buttonText?: DoppeHideableValue<string>;
    }
}


export const doppeActionTypeContactCard = doppeActionTypeDefineMetadata<
    DoppeActionTypeContactCard.ActionSettings,
    DoppeActionTypeContactCard.PartialActionSettings
>({
    id: 'io.bymo.action/contact-card',
    resolveActionSettings: action => {

        const {
            buttonText = '',
            notes = '',
            ctaButton,
            contactMethods = [],
            ctaButtons,
            sections,
            saveContactProfileImage = doppeHideableValueCreateHidden(null),
            ...restSettings
        } = action.settings;

        return {
            contactMethods: contactMethods.map(method => {

                const {
                    hidden
                } = method

                return {
                    enabled: !hidden,
                    ...method as PartiallyOptional<DoppeActionTypeContactCard.xxx, 'enabled'>,
                }
            }) as DoppeDtoContactMethod[],
            buttonText: buttonText,
            media: null,
            name: doppeHideableValueCreateHidden({
                firstName: '',
                lastName: ''
            }),
            tagLine: doppeHideableValueCreateHidden(''),
            company: doppeHideableValueCreateHidden(''),
            jobTitle: doppeHideableValueCreateHidden(''),
            description: doppeHideableValueCreateHidden(''),
            notes: '',
            saveContactProfileImage: doppeHideableValueCreateHidden(null),
            ...getDefaultDoppeActionCtaButtonSettings({
                buttonText: doppeHideableValueGetVisibleValue(buttonText, ''),
                showButton: doppeHideableValueIsVisible(buttonText),
                settings: {
                    type: DoppeActionCTAType.SaveContact,
                    notes:  notes,
                    saveContactProfileImage: saveContactProfileImage
                }
            }),
            ...doppeActionCtaButtonTypeGetDefaultCtaButtonSettingsMigration({
                hasSourceURL: false,
                ctaButton: ctaButton,
            }),
            ...doppeActionContentSectionSettingsDefaultValuesMigration(action.settings),
            ...doppeActionCtaButtonsSettingsDefaultValuesMigration(action.settings, false),
            ...restSettings
        }
    },
    isWidgetOpener: true,
    isLinkOpener: false,
    canRenderInPage: true,
    skipReportingOnGoalAchievedOnActionButtonClick: true,
    resolveUsedMedia: actionSettings => {
        return actionSettings.media ?? [];
    },
    resolveMainMedia: actionSettings => {
        return actionSettings.media?.[0] ?? null;
    },
    replaceExternalImages: (actionSettings, externalImagesUrlToImageResourceMap) => {
        return immerProduce(actionSettings, actionSettings => {
            if (actionSettings.media) {
                actionSettings.media = actionSettings.media.map(
                    image => wixMediaResourceReplaceExternalImage(image, externalImagesUrlToImageResourceMap))
            }
        })
    },
    resolveMainLink: (actionSettings, htmlSiteInfo) => {
        return doppeActionCtaButtonsResolveMainURL(actionSettings, {
            htmlSiteInfo: htmlSiteInfo,
        }) ?? null;
    },
    prepareForViewer: (actionSettings, options) => {
        doppeDtoActionSettingsRemoveInnerCtaContentForLockedAction(actionSettings, actionSettings => {
            doppeActionCtaButtonsResetSettingsForLock(actionSettings);
        }, options);
    },
});
