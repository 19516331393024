import {
    doppeActionTypeCreateSettingsResolvingFunction,
    doppeActionTypeDefineMetadata
} from '../../client-server-common/doppe-action-types/doppe-action-type';

export namespace DoppeActionTypeSocialIcon {
    export interface ActionSettings {
    }
}

export const doppeActionTypeSocialIcon = doppeActionTypeDefineMetadata<DoppeActionTypeSocialIcon.ActionSettings>({
    id: 'io.bymo.action/social-icon',
    resolveActionSettings: doppeActionTypeCreateSettingsResolvingFunction({}),
    settingsMetadata: false,
    canRenderInPage: false,
    isWidgetOpener: false
});