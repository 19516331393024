import React, {useCallback, useContext, useLayoutEffect, useState} from 'react';
import {classes, style, vars} from './doppe-page-media-view.st.css';
import {
    WixImageResource,
    wixImageResourceGetResizedImageUrl,
    wixImageResourceGetUrl,
    wixImageResourceIsResponsiveImage,
    WixMediaResource,
    wixMediaResourceHasKnownDimensions,
    wixMediaResourceIsWixImageResource,
    wixMediaResourceResolveMediaWidthHeightRatio,
    WixResponsiveImageResource,
    WixVideoInfoQualitySelector
} from "@wix/devzai-common-wix";
import {
    arrayDistinct,
    arrayFlatten,
    arrayGenerateIntervals,
    assertDefined,
    cachedValueCreate,
    compareNumbers,
    cssAddUnitsToNumericValue,
    enumIsEnumValue,
    Evaluable,
    evaluateFunction,
    evaluateWhenFunction,
    FocalPoint,
    focalPointResolveCssPosition,
    htmlImageGenerateResponsiveImageAttributes,
    HtmlObjectHorizontalPosition,
    HtmlObjectResizeMode,
    HtmlObjectVerticalPosition,
    OmitStrict,
    Size,
    sizeScaleToFit,
    Values
} from "@wix/devzai-utils-common";
import {FlexLayout} from "@wix/devzai-common-client";
import {
    ImageView,
    ImageViewPositionHorizontal,
    ImageViewPositionVertical,
    ImageViewResizeMode,
    ResponsiveImageView
} from "@wix/devzai-utils-react";
import {BymoPageLayout, BymoPageLayoutSizes} from "../bymo-page-style/bymo-page-style";
import EmptyImage from './images/empty-image.icon.svg'
import LinkImage from './images/link-image.icon.svg'
import {DoppePageVideoMediaView} from "../doppe-page-video-media-view/doppe-page-video-media-view";

export const DoppePageMediaViewEmptyMediaPlaceholder = {
    None: 'none',
    ImageIcon: 'imageIcon',
    LinkIcon: 'linkIcon',
} as const;

export type DoppePageMediaViewEmptyMediaPlaceholder = Values<typeof DoppePageMediaViewEmptyMediaPlaceholder>;

export const DoppePageMediaViewMediaBackground = {
    Transparent: 'transparent',
    Auto: 'auto'
} as const;

export type DoppePageMediaViewMediaBackground = Values<typeof DoppePageMediaViewMediaBackground>;

type DoppePageMediaViewPreferencesScopeContextData = {
    forcePreciseWidthForDesktop: boolean;
    forcePreciseWidthForMobile: boolean;
    lazyLoading: boolean;
}

const DoppePageMediaViewPreferencesScopeContext = React.createContext<DoppePageMediaViewPreferencesScopeContextData | null>(null);

export const DoppePageMediaView = React.memo(function DoppePageMediaView (props: DoppePageMediaView.Props) {
    const {
        mediaResource,
        layoutSpec,
        widthSpec,

        disableImageCropping = false,

        mediaBackground = DoppePageMediaViewMediaBackground.Auto,
        emptyMediaPlaceholder = DoppePageMediaViewEmptyMediaPlaceholder.None,

        mobileMaxImageWidth = 750,
        desktopMaxImageWidth = 1920,

        mediaResizeMode = HtmlObjectResizeMode.Cover,
        mediaHorizontalPosition = HtmlObjectHorizontalPosition.Center,
        mediaVerticalPosition = HtmlObjectVerticalPosition.Center,
        focalPoint,

        imageQuality,
        videoQuality = WixVideoInfoQualitySelector.Highest,
        videoMuted = true,
        autoPlayVideo = true,
        playVideoInLoop = true,

        style: styleProp,
        innerClassName,
        className,
        ...htmlAttributes
    } = props;

    const desktopWidthSpec = evaluateWhenFunction(widthSpec, BymoPageLayout.Wide);
    const mobileWidthSpec = evaluateWhenFunction(widthSpec, BymoPageLayout.Narrow);
    const desktopLayoutSpec = evaluateWhenFunction(layoutSpec, BymoPageLayout.Wide);
    const mobileLayoutSpec = evaluateWhenFunction(layoutSpec, BymoPageLayout.Narrow);

    const mediaViewPreferences = useContext(DoppePageMediaViewPreferencesScopeContext) ?? {
        forcePreciseWidthForMobile: false,
        forcePreciseWidthForDesktop: false,
        lazyLoading: false
    }

    const lazyLoading = mediaViewPreferences.lazyLoading;

    const resolveWidthHeightRatio = (layoutSpec: DoppePageMediaView.ImageLayoutSpec) => {
        if ('widthHeightRatio' in layoutSpec) {
            return layoutSpec.widthHeightRatio;
        } else if ('autoHeight' in layoutSpec) {
            return mediaResource === null ?
                16 / 9 :
                wixMediaResourceResolveMediaWidthHeightRatio(mediaResource)
        } else {
            return null;
        }
    }

    const desktopWidthHeightRatio = resolveWidthHeightRatio(desktopLayoutSpec);
    const mobileWidthHeightRatio = resolveWidthHeightRatio(mobileLayoutSpec);

    const desktopHeight = 'height' in desktopLayoutSpec ?
        desktopLayoutSpec.height : undefined;

    const mobileHeight = 'height' in mobileLayoutSpec ?
        mobileLayoutSpec.height : undefined;

    const resolveResponsiveImageUrl = (
        imageResource: WixResponsiveImageResource,
        targetWidth: number,
        layoutSpec: DoppePageMediaView.ImageLayoutSpec
    ) => {
        return assertDefined(wixImageResourceGetResizedImageUrl(imageResource, {
            resizeMode: mediaResizeMode,
            targetWidth: targetWidth,
            targetHeight: evaluateFunction(() => {
                if ('widthHeightRatio' in layoutSpec) {
                    return Math.floor(targetWidth / layoutSpec.widthHeightRatio);
                } else if ('autoHeight' in layoutSpec || 'dynamicHeight' in layoutSpec) {
                    return Math.floor(targetWidth / (imageResource.width / imageResource.height))
                } else {
                    return layoutSpec.height;
                }
            }),
            quality: imageQuality,
            cropImage: disableImageCropping || !imageLayoutSpecSupportsCropping(layoutSpec) ? undefined : evaluateFunction(() => {
                if (mediaResizeMode === ImageViewResizeMode.Cover && focalPoint !== undefined) {
                    return focalPoint;
                } else {
                    return {
                        positionVertical: mediaVerticalPosition,
                        positionHorizontal: mediaHorizontalPosition
                    }
                }
            })
        }))
    }

    const resolveMediaPositionOverride = (layoutSpec: DoppePageMediaView.ImageLayoutSpec) => {
        if (
            mediaResource &&
            wixMediaResourceHasKnownDimensions(mediaResource) &&
            mediaResizeMode === ImageViewResizeMode.Cover &&
            focalPoint !== undefined &&
            imageLayoutSpecSupportsCropping(layoutSpec)
        ) {
            if (wixMediaResourceIsWixImageResource(mediaResource) && wixImageResourceIsResponsiveImage(mediaResource) && !disableImageCropping) {
                return `center center`
            }

            const focalPointCssPosition = focalPointResolveCssPosition({
                focalPoint: focalPoint,
                mediaWithHeightRatio: wixMediaResourceResolveMediaWidthHeightRatio(mediaResource),
                containerWidthHeightRatio: layoutSpec.widthHeightRatio
            })

            return `${focalPointCssPosition.x}% ${focalPointCssPosition.y}%`;
        } else {
            return undefined;
        }
    }

    const desktopMediaPositionOverride = resolveMediaPositionOverride(desktopLayoutSpec);
    const mobileMediaPositionOverride = resolveMediaPositionOverride(mobileLayoutSpec);

    return (
        <div
            {...htmlAttributes}
            className={style(
                classes.root,
                {
                    maintainRatioDesktop: desktopWidthHeightRatio !== null,
                    maintainRatioMobile: mobileWidthHeightRatio !== null,
                    autoSizeMobile: mobileWidthHeightRatio === null && 'autoHeight' in mobileLayoutSpec,
                    autoSizeDesktop: desktopWidthHeightRatio === null && 'autoHeight' in desktopLayoutSpec,
                    dynamicHeightMobile: 'dynamicHeight' in mobileLayoutSpec,
                    dynamicHeightDesktop: 'dynamicHeight' in desktopLayoutSpec,
                    hasDesktopMediaPositionOverride: desktopMediaPositionOverride !== undefined,
                    hasMobileMediaPositionOverride: mobileMediaPositionOverride !== undefined,
                    mediaBackground: mediaBackground,
                },
                className
            )}
            style={{
                [vars.desktopRatio]: desktopWidthHeightRatio ? `${1 / desktopWidthHeightRatio * 100}%` : undefined,
                [vars.mobileRatio]: mobileWidthHeightRatio ? `${1 / mobileWidthHeightRatio * 100}%` : undefined,
                [vars.desktopHeight]: desktopHeight ? `${desktopHeight}px` : undefined,
                [vars.mobileHeight]: mobileHeight ? `${mobileHeight}px` : undefined,
                [vars.desktopMaxHeight]: cssAddUnitsToNumericValue(
                    'autoHeight' in desktopLayoutSpec ? desktopLayoutSpec.maxHeight : undefined,
                    'px'
                ),
                [vars.mobileMaxHeight]: cssAddUnitsToNumericValue(
                    'autoHeight' in mobileLayoutSpec ? mobileLayoutSpec.maxHeight : undefined,
                    'px'
                ),
                [vars.desktopMediaPosition]: desktopMediaPositionOverride,
                [vars.mobileMediaPosition]: mobileMediaPositionOverride,
                ...styleProp
            }}
        >
            <div className={style(classes.ratioPaddingContainer, classes.ratioMaintainerIgnore)}>
                <div className={style(classes.ratioPadding)}/>
            </div>
            {evaluateFunction(() => {

                if (mediaResource === null) {

                    if (enumIsEnumValue(DoppePageMediaViewEmptyMediaPlaceholder, emptyMediaPlaceholder)) {
                        return (
                            <div className={style(classes.emptyImageContainer, {}, FlexLayout.centerContent)}>
                                {evaluateFunction(() => {
                                    switch (emptyMediaPlaceholder) {
                                        case DoppePageMediaViewEmptyMediaPlaceholder.None: {
                                            return null;
                                        }
                                        case DoppePageMediaViewEmptyMediaPlaceholder.ImageIcon: {
                                            return (
                                                <EmptyImage
                                                    className={style(classes.emptyImage, {})}
                                                />
                                            )
                                        }
                                        case DoppePageMediaViewEmptyMediaPlaceholder.LinkIcon: {
                                            return (
                                                <LinkImage
                                                    className={style(classes.emptyLinkImage, {})}
                                                />
                                            )
                                        }
                                    }
                                })}
                            </div>
                        )
                    } else {
                        return emptyMediaPlaceholder;
                    }
                } else if (wixMediaResourceIsWixImageResource(mediaResource)) {

                    if (wixImageResourceIsResponsiveImage(mediaResource)) {
                        return (
                            <>
                                {evaluateFunction(() => {
                                    if ('hidden' in desktopWidthSpec) {
                                        return null;
                                    } else if ('precise' in desktopWidthSpec || mediaViewPreferences.forcePreciseWidthForDesktop) {
                                        return (
                                            <PreciseWidthSpecImageView
                                                className={style(classes.desktopImageView, {}, classes.mediaView, innerClassName)}
                                                resizeMode={mediaResizeMode}
                                                positionHorizontal={mediaHorizontalPosition}
                                                positionVertical={mediaVerticalPosition}
                                                focalPoint={focalPoint}
                                                maxImageWidth={desktopMaxImageWidth}
                                                lazyLoading={lazyLoading}
                                                src={mediaResource}
                                                disableImageCropping={disableImageCropping || !imageLayoutSpecSupportsCropping(desktopLayoutSpec)}
                                            />
                                        )
                                    } else {
                                        return (
                                            <ImageView
                                                className={style(classes.desktopImageView, {}, classes.mediaView, innerClassName)}
                                                resizeMode={mediaResizeMode}
                                                positionHorizontal={mediaHorizontalPosition}
                                                positionVertical={mediaVerticalPosition}
                                                loading={lazyLoading ? 'lazy' : undefined}
                                                {...htmlImageGenerateResponsiveImageAttributes({
                                                    renderingSizeSpec: [
                                                        // Mobile
                                                        {
                                                            screenMaxWidth: BymoPageLayoutSizes.WideLayoutMinWidth,
                                                            empty: true
                                                        },
                                                        // Desktop
                                                        evaluateFunction(() => {
                                                            if ('screenRelativeWidth' in desktopWidthSpec) {
                                                                return {
                                                                    relativeWidth: desktopWidthSpec.screenRelativeWidth
                                                                }
                                                            } else if ('contentRelativeWidth' in desktopWidthSpec) {
                                                                return {
                                                                    width: Math.floor(BymoPageLayoutSizes.WideLayoutContentWidth * desktopWidthSpec.contentRelativeWidth)
                                                                }
                                                            } else {
                                                                return {
                                                                    width: desktopWidthSpec.width
                                                                }
                                                            }
                                                        }),
                                                    ],
                                                    supportedScreenWidth: cachedWideLayoutTargetWidths.getValue(),
                                                    resolveResponsiveImageUrl: (width) => {
                                                        return resolveResponsiveImageUrl(mediaResource, width, desktopLayoutSpec);
                                                    }
                                                })}
                                            />
                                        )
                                    }
                                })}
                                {evaluateFunction(() => {
                                    if ('hidden' in mobileWidthSpec) {
                                        return null;
                                    } else if ('precise' in mobileWidthSpec || mediaViewPreferences.forcePreciseWidthForMobile) {
                                        return (
                                            <PreciseWidthSpecImageView
                                                className={style(classes.mobileImageView, {}, classes.mediaView, innerClassName)}
                                                resizeMode={mediaResizeMode}
                                                positionHorizontal={mediaHorizontalPosition}
                                                positionVertical={mediaVerticalPosition}
                                                focalPoint={focalPoint}
                                                maxImageWidth={mobileMaxImageWidth}
                                                lazyLoading={lazyLoading}
                                                src={mediaResource}
                                                disableImageCropping={disableImageCropping || !imageLayoutSpecSupportsCropping(mobileLayoutSpec)}
                                            />
                                        )
                                    } else {
                                        return (
                                            <ImageView
                                                className={style(classes.mobileImageView, {}, classes.mediaView, innerClassName)}
                                                resizeMode={mediaResizeMode}
                                                positionHorizontal={mediaHorizontalPosition}
                                                positionVertical={mediaVerticalPosition}
                                                loading={lazyLoading ? 'lazy' : undefined}
                                                {...htmlImageGenerateResponsiveImageAttributes({
                                                    maxDpr: 3,
                                                    renderingSizeSpec: [
                                                        // Mobile
                                                        {
                                                            screenMaxWidth: BymoPageLayoutSizes.WideLayoutMinWidth,
                                                            ...evaluateFunction(() => {
                                                                if ('screenRelativeWidth' in mobileWidthSpec) {
                                                                    return {
                                                                        relativeWidth: mobileWidthSpec.screenRelativeWidth
                                                                    }
                                                                } else if ('contentRelativeWidth' in mobileWidthSpec) {
                                                                    // Note: In mobile the content relative width is the same as
                                                                    // screen relative width
                                                                    return {
                                                                        relativeWidth: mobileWidthSpec.contentRelativeWidth
                                                                    }
                                                                } else {
                                                                    return {
                                                                        width: mobileWidthSpec.width
                                                                    }
                                                                }
                                                            }),
                                                        },
                                                        // Desktop
                                                        {empty: true}
                                                    ],
                                                    supportedScreenWidth: cachedNarrowLayoutTargetWidths.getValue(),
                                                    resolveResponsiveImageUrl: (width, skip) => {
                                                        if (width > mobileMaxImageWidth) {
                                                            return skip;
                                                        } else {
                                                            return resolveResponsiveImageUrl(mediaResource, width, mobileLayoutSpec);
                                                        }
                                                    }
                                                })}
                                            />
                                        )
                                    }
                                })}
                            </>
                        )
                    } else {
                        return (
                            <ImageView
                                className={style(classes.mediaView, {}, innerClassName)}
                                resizeMode={mediaResizeMode}
                                positionHorizontal={mediaHorizontalPosition}
                                positionVertical={mediaVerticalPosition}
                                src={wixImageResourceGetUrl(mediaResource)}
                            />
                        );
                    }
                } else {
                    return (
                        <>
                            {mediaResource.poster ? (
                                <DoppePageMediaView
                                    className={style(classes.videoMediaPoster, {}, innerClassName)}
                                    mediaResource={mediaResource.poster}
                                    layoutSpec={layoutSpec}
                                    widthSpec={widthSpec}
                                    mediaResizeMode={mediaResizeMode}
                                    mediaHorizontalPosition={mediaHorizontalPosition}
                                    mediaVerticalPosition={mediaVerticalPosition}
                                />
                            ) : null}
                            <DoppePageVideoMediaView
                                className={style(classes.mediaView, {}, innerClassName)}
                                src={mediaResource}
                                resizeMode={mediaResizeMode}
                                positionVertical={mediaVerticalPosition}
                                positionHorizontal={mediaHorizontalPosition}
                                quality={videoQuality}
                                autoPlay={autoPlayVideo}
                                loop={playVideoInLoop}
                                muted={autoPlayVideo ? true : videoMuted}
                                playsInline={true}
                            />
                        </>
                    )
                }
            })}
        </div>
    );
});

export namespace DoppePageMediaView {

    export type SizeSpec =
        | { width: number; }
        | { screenRelativeWidth: number; }
        | { contentRelativeWidth: number; }
        | { hidden: true; }
        | { precise: true; }

    export type RatioMaintainingLayoutSpec = { widthHeightRatio: number; };

    export type ImageLayoutSpec =
        | RatioMaintainingLayoutSpec
        | { height: number }
        | { autoHeight: true; maxHeight?: number; }
        | { dynamicHeight: true; }

    export interface Props extends React.HTMLAttributes<any> {
        mediaResource: WixMediaResource | null;
        layoutSpec: Evaluable<(bymoPageLayout: BymoPageLayout) => ImageLayoutSpec>;
        widthSpec: Evaluable<(bymoPageLayout: BymoPageLayout) => SizeSpec>;
        mediaResizeMode?: HtmlObjectResizeMode;
        mediaVerticalPosition?: HtmlObjectVerticalPosition;
        mediaHorizontalPosition?: HtmlObjectHorizontalPosition;

        // Focal point is used when the resize mode is cover and when the layout spec is widthHeightRatio.
        focalPoint?: FocalPoint;

        // Used to avoid large images loading due to a large DPR.
        mobileMaxImageWidth?: number;
        desktopMaxImageWidth?: number;

        imageQuality?: number;
        autoPlayVideo?: boolean;
        playVideoInLoop?: boolean;
        videoMuted?: boolean;
        videoQuality?: DoppePageVideoMediaView.Props['quality'];

        mediaBackground?: DoppePageMediaViewMediaBackground;
        emptyMediaPlaceholder?: DoppePageMediaViewEmptyMediaPlaceholder | React.ReactElement;

        disableImageCropping?: boolean;

        innerClassName?: string;
    }
}

const PreciseWidthSpecImageView = React.memo(function PreciseWidthSpecImageView (
    props: OmitStrict<
        ResponsiveImageView.Props<WixImageResource>,
        'containerSizeChangeDebounceMs' | 'isResponsiveImage' | 'resolveImageUrl'
    > & {
        positionVertical: ImageViewPositionVertical;
        positionHorizontal: ImageViewPositionHorizontal;
        focalPoint?: FocalPoint;
        maxImageWidth: number;
        imageQuality?: number;
        disableImageCropping: boolean;
        lazyLoading: boolean;
    }
) {
    const {
        positionHorizontal,
        positionVertical,
        imageQuality,
        focalPoint,
        disableImageCropping,
        lazyLoading,

        maxImageWidth,
        ...restProps
    } = props;

    const [devicePixelRatio, setDevicePixelRatio] = useState(1);
    useLayoutEffect(() => {
        setDevicePixelRatio(window.devicePixelRatio)
    }, []);

    const resolveImageUrl = useCallback((imageResource: WixImageResource, containerSize: Size, resizeMode: ImageViewResizeMode) => {

        const containerSizeMultipliedByDpr = {
            width: containerSize.width * devicePixelRatio,
            height: containerSize.height * devicePixelRatio,
        }

        const scaledContainerSize = sizeScaleToFit(containerSizeMultipliedByDpr, maxImageWidth);

        return wixImageResourceGetResizedImageUrl(imageResource, {
            resizeMode: resizeMode,
            targetWidth: scaledContainerSize.width,
            targetHeight: scaledContainerSize.height,
            quality: imageQuality,
            cropImage: disableImageCropping ? undefined : evaluateFunction(() => {
                return resizeMode === ImageViewResizeMode.Cover && focalPoint !== undefined ? focalPoint : {
                    positionVertical: positionVertical,
                    positionHorizontal: positionHorizontal
                }
            })
        })
    }, [positionHorizontal, positionVertical, imageQuality, devicePixelRatio, maxImageWidth, focalPoint, disableImageCropping])

    return (
        <ResponsiveImageView
            {...restProps}
            loading={lazyLoading ? 'lazy' : undefined}
            positionHorizontal={positionHorizontal}
            positionVertical={positionVertical}
            containerSizeChangeDebounceMs={200}
            isResponsiveImage={wixImageResourceIsResponsiveImage}
            resolveImageUrl={resolveImageUrl}
        />
    );
});

export const DoppePageMediaViewPreferencesScope = React.memo(function DoppePageMediaViewPreferencesScope(
    props: DoppePageMediaViewPreferencesScopeContextData & {
        children: React.ReactElement;
    }
) {
    const {
        children,
        ...contextData
    } = props;

    return (
        <DoppePageMediaViewPreferencesScopeContext.Provider
            value={contextData}
        >
            {children}
        </DoppePageMediaViewPreferencesScopeContext.Provider>
    )
})

function imageLayoutSpecSupportsCropping (layoutSpec: DoppePageMediaView.ImageLayoutSpec) : layoutSpec is DoppePageMediaView.RatioMaintainingLayoutSpec {
    return 'widthHeightRatio' in layoutSpec;
}

const cachedCommonMobileDevicesResolutions = cachedValueCreate(() => [
    { width: 1080, height: 2340 },  // Samsung Galaxy S20, S21, and S21 Ultra, OnePlus 8 and 9 series, Google Pixel 4a and 5, Xiaomi Mi 10 and Mi 11
    { width: 1080, height: 2400 },  // Samsung Galaxy A52 and A72, Xiaomi Redmi Note 10 Pro, Google Pixel 4a 5G
    { width: 1440, height: 3200 },  // Samsung Galaxy S20 FE and S21 Ultra, OnePlus 8 Pro and 9 Pro
    { width: 1440, height: 2560 },  // Samsung Galaxy S7 and S8 series, Google Pixel 2 XL and 3 XL, LG G6 and G7 ThinQ
    { width: 750, height: 1334 },   // Apple iPhone 6, 6s, 7, and 8
    { width: 1125, height: 2436 },  // Apple iPhone X, XS, 11 Pro, and 12 Pro
    { width: 720, height: 1280 },   // Samsung Galaxy J series, Moto G series
    { width: 1080, height: 1920 },  // Samsung Galaxy S5, S6, and Note 4, Google Pixel and Pixel XL
    { width: 1440, height: 2960 },  // Samsung Galaxy S8, S9, and Note 8
    { width: 1440, height: 3040 }   // Samsung Galaxy S10, S20, and Note 10 series
])

const cachedTargetWidths = cachedValueCreate(() => {
    return [...new Set([
        1902, 1080,
        1366, 768,
        360, 800,
        1536, 864,
        414, 896,
        360, 640,

        // Ipads
        2732, 2048,
        1024, 768,
        2048, 1536,

        375, 667,
        414, 896,
        390, 844,
        360, 740,
        412, 915
    ])].sort(compareNumbers);
})

const cachedNarrowLayoutTargetWidths = cachedValueCreate(() => {
    return arrayDistinct([
        ...arrayGenerateIntervals(360, BymoPageLayoutSizes.WideLayoutMinWidth, 20),
        ...arrayFlatten(cachedCommonMobileDevicesResolutions.getValue().map(resolution => ([resolution.width, resolution.height])))
    ])
})

const cachedWideLayoutTargetWidths = cachedValueCreate(() => {

    const commonWideLayoutWidths =
        cachedTargetWidths.getValue().filter(width => width >= BymoPageLayoutSizes.WideLayoutMinWidth);

    return arrayDistinct([
        ...commonWideLayoutWidths,
        ...arrayGenerateIntervals(BymoPageLayoutSizes.WideLayoutMinWidth, Math.max(...commonWideLayoutWidths), 100)
    ]).sort(compareNumbers)
})