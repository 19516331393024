import {Values} from "@wix/devzai-utils-common";
import {BymoTemplateLayout} from "./bymo-page-props";

export const BymoTemplateDesktopLayout = {
    Standard: 'Standard',
    Split: 'Split',
} as const;

export type BymoTemplateDesktopLayout = Values<typeof BymoTemplateDesktopLayout>;

export interface BymoTemplateDesktopLayoutData {
    layout: BymoTemplateDesktopLayout;
}

export function bymoTemplateLayoutIsStandardDesktopBackground(desktopLayout: BymoTemplateDesktopLayout) {
    return BymoTemplateDesktopLayout.Standard === desktopLayout
}

export function bymoTemplateDesktopLayoutIsSplitLayout (desktopLayout: BymoTemplateDesktopLayout) {
    switch (desktopLayout) {
        case BymoTemplateDesktopLayout.Split: {
            return true;
        }
        case BymoTemplateDesktopLayout.Standard: {
            return false;
        }
    }
}

export function bymoTemplateLayoutGetSupportedDesktopLayouts(bymoTemplateLayout: BymoTemplateLayout) {
    switch (bymoTemplateLayout) {
        case BymoTemplateLayout.Standard:
            return [BymoTemplateDesktopLayout.Standard];
        case BymoTemplateLayout.Blank: {
            return [BymoTemplateDesktopLayout.Standard];
        }
        case BymoTemplateLayout.Layout1: {
            return [BymoTemplateDesktopLayout.Standard];
        }
        case BymoTemplateLayout.Layout2: {
            return [BymoTemplateDesktopLayout.Standard];
        }
        case BymoTemplateLayout.Layout3: {
            return [BymoTemplateDesktopLayout.Standard];
        }
        case BymoTemplateLayout.Layout4: {
            return [BymoTemplateDesktopLayout.Standard];
        }
        case BymoTemplateLayout.Layout5: {
            return [BymoTemplateDesktopLayout.Standard, BymoTemplateDesktopLayout.Split];
        }
        case BymoTemplateLayout.Layout6: {
            return [BymoTemplateDesktopLayout.Standard];
        }
        case BymoTemplateLayout.Layout7: {
            return [BymoTemplateDesktopLayout.Standard];
        }
        case BymoTemplateLayout.Layout8: {
            return [BymoTemplateDesktopLayout.Standard];
        }
        case BymoTemplateLayout.Layout9: {
            return [BymoTemplateDesktopLayout.Standard];
        }
        case BymoTemplateLayout.Layout10: {
            return [BymoTemplateDesktopLayout.Standard];
        }
        case BymoTemplateLayout.Layout11: {
            return [BymoTemplateDesktopLayout.Standard];
        }
        case BymoTemplateLayout.Layout12: {
            return [BymoTemplateDesktopLayout.Standard];
        }
        case BymoTemplateLayout.Layout13: {
            return [BymoTemplateDesktopLayout.Standard];
        }
        case BymoTemplateLayout.Layout14: {
            return [BymoTemplateDesktopLayout.Standard];
        }
    }
}

export function bymoTemplateDesktopLayoutEnsureSupportedLayout (bymoTemplateLayout: BymoTemplateLayout, desktopLayout: BymoTemplateDesktopLayout) {
    return bymoTemplateLayoutGetSupportedDesktopLayouts(bymoTemplateLayout).includes(desktopLayout) ? desktopLayout : BymoTemplateDesktopLayout.Standard;
}