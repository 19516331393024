import {doppeActionTypeDefineMetadata} from '../../client-server-common/doppe-action-types/doppe-action-type';
import {
    arrayFlatten,
    arrayRemoveNullValues,
    asArray,
    immerProduce,
    OmitStrict,
    PartiallyOptional
} from '@wix/devzai-utils-common';
import {wixMediaResourceReplaceExternalImage} from '@wix/devzai-common-wix';
import {
    convertCommonListSettingToDefaultDoppeActionCtaButtonSettingsFromListAction,
    DoppeActionListItemClickBehaviour,
    DoppeHideableValue,
    doppeHideableValueGetVisibleValue,
    doppeHideableValueIsVisibleAndNotEqualValue,
    doppeListActionGetDefaultListSettings,
    doppeListActionGetDefaultSearchListSettings,
    doppeListActionGetItemClickBehaviourDefaultListSettings,
    doppeListActionGetMainDetailsDefaultSettings,
    doppeListActionItemCtaButtonsResetSettingsForLock,
    doppeListActionListItemCalculateItemButtons,
    doppeListActionListItemGetDefaultListItemSettingsMigration,
    doppeListActionListItemWithCtaButtonsMigration,
    doppeListActionListItemWithSectionsMigration,
    DoppeListActionSettings,
    doppeListActionSettingsFilterHiddenListItemsFromActionView,
    doppeListActionTemplateCtaButtonsSettingsDefaultValuesMigration,
    doppeListActionTemplateSectionsSettingsDefaultValuesMigration,
    doppeListActionWithMainDetailsGetMainImage,
    doppeListActionWithMainDetailsReplaceMainImage
} from '../../client-server-common';
import {doppeHideableValueCreateHidden} from '../../client-server-common/types/doppe-hideable-value';
import {DoppeActionTypeLinkView} from '../doppe-action-type-link-view/doppe-action-type-link-view';
import {
    doppeDtoActionSettingsRemoveInnerCtaContentForLockedAction
} from '../../client-server-common/types/doppe-dto-action';
import {doppeActionCtaButtonsResetSettingsForLock} from '../../client-server-common/types/doppe-action-cta-button';
import {
    DoppeActionCtaButtonSettings,
    doppeActionCtaButtonTypeGetDefaultCtaButtonSettingsMigration,
    getDefaultDoppeActionCtaButtonSettings
} from '../../client-server-common/types/doppe-action-cta-button-settings';
import {
    doppeActionCtaButtonsResolveMainURL
} from '../doppe-actions-common/doppe-cta-button-utils/doppe-cta-button-utils';
import {
    doppeActionContentSectionSettingsDefaultValuesMigration,
    DoppeActionContentSectionsSettings
} from '../../client-server-common/types/doppe-action-content-sections-settings';
import {
    DoppeActionCtaButtonsSettings,
    doppeActionCtaButtonsSettingsDefaultValuesMigration
} from '../../client-server-common/types/doppe-action-cta-buttons-settings';

export namespace DoppeActionTypeLinksList {

    export interface LinkSettings extends
        DoppeListActionSettings.ListItemWithCtaButtons,
        DoppeListActionSettings.ListItemWithSections,
        DoppeListActionSettings.ListItemBaseSettings,
        DoppeActionTypeLinkView.LinkSettings {
        title: string;
    }

    export interface ActionSettings extends
        DoppeListActionSettings<LinkSettings>,
        DoppeListActionSettings.WithSearchSettings,
        DoppeListActionSettings.WithMainDetails,
        DoppeListActionSettings.WithItemClickBehaviour,
        DoppeActionContentSectionsSettings,
        DoppeActionCtaButtonsSettings
    {
        previewShowTitle: boolean;
        previewShowDescription: boolean;
        previewShowCoupon: boolean;
    }

    export interface DeprecatedFields extends Partial<DoppeListActionSettings.WithCtaButton> {
        links?: LinkSettings[];
        previewLinkButtonText?: string;
    }

    export interface DeprecatedItemFields extends Partial<DoppeActionCtaButtonSettings> {
        linkButtonText?: DoppeHideableValue<string>;
    }

    export type PartialProps = Partial<OmitStrict<ActionSettings, keyof DoppeListActionSettings<LinkSettings>>> & DeprecatedFields & DoppeListActionSettings<LinkSettings & DeprecatedItemFields>;
}


export const doppeActionTypeLinksList = doppeActionTypeDefineMetadata<DoppeActionTypeLinksList.ActionSettings, DoppeActionTypeLinksList.PartialProps>({
    id: 'io.bymo.action/links-list',
    resolveActionSettings: action => {

        const {
            listDataItems = [],
            listSearchSettings,
            ctaButton,
            listItemTemplateCtaButtonsSettings,
            listItemTemplateSectionsSettings,
            ctaButtons,
            sections,
            ...restSettings
        } = action.settings;

        return {
            previewLinkButtonText: '',
            previewShowTitle: true,
            previewShowDescription: true,
            previewShowCoupon: true,
            ...doppeListActionGetDefaultSearchListSettings(action.settings),
            ...doppeListActionGetDefaultListSettings({
                listDataItems: listDataItems.map(link => {

                    const {
                        linkButtonText = 'Continue',
                        ctaButton,
                        customSections,
                        customCtaButtons,
                        sectionsOverridesMap,
                        ctaButtonsOverridesMap,
                        ...restLink
                    } = link;

                    return {
                        linkButtonText: linkButtonText,
                        description: doppeHideableValueCreateHidden(''),
                        couponCode: doppeHideableValueCreateHidden(''),
                        ribbon: doppeHideableValueCreateHidden(''),
                        ...getDefaultDoppeActionCtaButtonSettings({
                            buttonText: linkButtonText,
                            showButton: true
                        }),
                        ...doppeActionCtaButtonTypeGetDefaultCtaButtonSettingsMigration({
                            hasSourceURL: true,
                            ctaButton: ctaButton,
                        }),
                        ...doppeListActionListItemGetDefaultListItemSettingsMigration(link.id),
                        ...doppeListActionListItemWithCtaButtonsMigration(link, true),
                        ...doppeListActionListItemWithSectionsMigration(link),
                        ...restLink as PartiallyOptional<DoppeActionTypeLinksList.LinkSettings & DoppeActionTypeLinksList.DeprecatedItemFields, 'couponCode' | 'linkButtonText' | 'description' | 'ribbon' | 'ctaButton' | 'customCtaButtons' |'customSections' | 'ctaButtonsOverridesMap' | 'sectionsOverridesMap'>,
                        image: link.image ? asArray(link.image) : null,
                    }
                }),
            }),
            ...doppeListActionGetItemClickBehaviourDefaultListSettings({
                itemClickBehaviour: DoppeActionListItemClickBehaviour.OpenLink,
            }),
            ...doppeListActionGetMainDetailsDefaultSettings(),
            ...convertCommonListSettingToDefaultDoppeActionCtaButtonSettingsFromListAction(action.settings),
            ...doppeActionCtaButtonTypeGetDefaultCtaButtonSettingsMigration({
                ctaButton: ctaButton,
                hasSourceURL: false
            }),
            ...doppeActionContentSectionSettingsDefaultValuesMigration(action.settings),
            ...doppeActionCtaButtonsSettingsDefaultValuesMigration(action.settings, false),
            ...doppeListActionTemplateCtaButtonsSettingsDefaultValuesMigration(action.settings, true),
            ...doppeListActionTemplateSectionsSettingsDefaultValuesMigration(action.settings),
            ...restSettings
        }
    },
    isWidgetOpener: true,
    canRenderInPage: true,
    skipReportingOnGoalAchievedOnActionButtonClick: true,
    isListItemLinkOpener: true,
    supportsListLayout: true,
    resolveUsedMedia: actionSettings => {
        return arrayRemoveNullValues([...arrayFlatten(actionSettings.listDataItems.map(itemSettings => {
            return itemSettings.image ?? []
        })), doppeHideableValueIsVisibleAndNotEqualValue(actionSettings.mainImage, null) ? actionSettings.mainImage : null]);
    },
    resolveMainMedia: actionSettings => {
        return doppeListActionWithMainDetailsGetMainImage(actionSettings, actionSettings.listDataItems[0]?.image?.[0] ?? null);
    },
    resolveMainLink: (actionSettings, htmlSiteInfo) => {
        return doppeActionCtaButtonsResolveMainURL(actionSettings, {
            htmlSiteInfo: htmlSiteInfo,
        }) ?? null;
    },
    prepareForViewer: (actionSettings, options) => {
        doppeDtoActionSettingsRemoveInnerCtaContentForLockedAction(actionSettings, actionSettings => {
            doppeListActionItemCtaButtonsResetSettingsForLock(actionSettings);
            doppeActionCtaButtonsResetSettingsForLock(actionSettings);
        }, options);
    },
    resolveListItemsCount: actionSettings => {
        return doppeListActionSettingsFilterHiddenListItemsFromActionView(actionSettings.listDataItems).length;
    },
    resolveCouponDataFromListItem: listItem => {
        return {
            showCouponCode: doppeHideableValueIsVisibleAndNotEqualValue(listItem.couponCode, ''),
            couponCode: doppeHideableValueGetVisibleValue(listItem.couponCode, ''),
            couponDescription: '',
            couponTitle: doppeHideableValueGetVisibleValue(listItem.title, ''),
        }
    },
    resolveListItems: (action,htmlSiteInfo) => {
        const actionSettings = action.settings;
        return actionSettings.listDataItems.map(item => {
            return {
                url: item.url ?? doppeActionCtaButtonsResolveMainURL(
                    doppeListActionListItemCalculateItemButtons(actionSettings.listItemTemplateCtaButtonsSettings, item), {
                        htmlSiteInfo: htmlSiteInfo,
                        sourceURL: item.url,
                    }) ?? null,
                title: item.title,
                action: action,
                couponCode: doppeHideableValueCreateHidden(''),
                description: item.description,
                image: item.image?.[0] ?? null,
                itemId: item.id,
                ribbon: item.ribbon,
                enabled: item.enabled,
                deleted: item.deleted,
                displayInSearch: item.displayInSearch,
            }
        });
    },
    resolveListSearchSettings: actionSettings => {
        return actionSettings.listSearchSettings;
    },
    replaceExternalImages: (actionSettings, externalImagesUrlToImageResourceMap) => {
        return immerProduce(actionSettings, actionSettings => {
            for (const link of actionSettings.listDataItems) {
                if (link.image) {
                    link.image = asArray(link.image).map(
                        image => wixMediaResourceReplaceExternalImage(image, externalImagesUrlToImageResourceMap))
                }
            }

            doppeListActionWithMainDetailsReplaceMainImage(actionSettings, externalImagesUrlToImageResourceMap);
        })
    },
});
