import {
    arrayFindValue,
    CookieConfigMap,
    CookieMapItemConfig,
    EventEmitter,
    EventReference,
    IObservable,
    IObservableValue,
    jsonParseWithFallback,
    objectForEachValue,
} from '@wix/devzai-utils-common';
import {ConsentPreferences, CookieConsentManager} from "../cookie-consent-manager/cookie-consent-manager";

import {CookieStorage, ICookieStorage} from "../cookie-storage/cookie-storage";

const NoValue: unique symbol = Symbol('NoValue');

export class CookieStorageAccessor<COOKIE_NAME extends string> {
    private readonly supportedCookies: CookieConfigMap<COOKIE_NAME>;
    private cookieConsentManager: CookieConsentManager;
    private inMemoryStorage = new Map<COOKIE_NAME, string>();
    private storageValuesCache = new Map<string, CookieStorageValue<any>>();
    private cookieStorage: ICookieStorage;

    constructor(config: {supportedCookies: CookieConfigMap<COOKIE_NAME>,
        cookieConsentManager: CookieConsentManager,
        cookieStorage?: ICookieStorage
    }) {
        this.supportedCookies = config.supportedCookies;
        this.cookieConsentManager = config.cookieConsentManager;
        this.cookieStorage = config.cookieStorage ?? new CookieStorage();

        this.cookieConsentManager.eventEmitter.on('consentChanged', (newConsent: Partial<ConsentPreferences>) => {
            this.consentChanged(newConsent);
        });
    }

    private copyFromMemoryToCookieStorage(key: COOKIE_NAME) {
        const memoryValue = this.inMemoryStorage.get(key);

        if (memoryValue !== undefined) {
            this.cookiesSetItem(key, memoryValue);
            this.inMemoryStorage.delete(key);
        }
    };

    private copyFromCookieStorageToMemory(key: COOKIE_NAME) {
        const cookieValue = this.cookieStorage.getItem(key);

        if (cookieValue !== undefined) {
            this.inMemoryStorage.set(key, cookieValue);
            this.cookieStorage.removeItem(key);
        }
    }

    private consentChanged(newConsent: Partial<ConsentPreferences>) {
        objectForEachValue<CookieMapItemConfig>(this.supportedCookies, (value, key) => {
            if (Object.keys(newConsent).includes(value.consentType)) {
                if (newConsent[value.consentType] === 1) {
                    this.copyFromMemoryToCookieStorage(key as COOKIE_NAME);
                }
                else {
                    this.copyFromCookieStorageToMemory(key as COOKIE_NAME);
                }
            }
        });
    };

    private hasConsent(key:COOKIE_NAME) {
        return this.cookieConsentManager.hasConsent(this.supportedCookies[key].consentType);
    }

    public removeItem (key: COOKIE_NAME) {
        this.cookieStorage.removeItem(key)
        this.inMemoryStorage.delete(key);
    }

    public createValueAccessor<VALUE> (key: COOKIE_NAME) : CookieStorageValueAccessor<VALUE, false>
    public createValueAccessor<VALUE> (key: COOKIE_NAME, valueSanitizer: (value: VALUE | undefined) => VALUE) : CookieStorageValueAccessor<VALUE, true>
    public createValueAccessor<VALUE> (key: COOKIE_NAME, valueSanitizer?: (value: VALUE | undefined) => VALUE) : CookieStorageValueAccessor<VALUE, boolean> {
        return new CookieStorageValueAccessor<VALUE, boolean>(this, key, valueSanitizer);
    }

    public createStorageValue<VALUE> (key: COOKIE_NAME) : CookieStorageValue<VALUE> {

        const cachedStorageValue = this.storageValuesCache.get(key as string);

        if (cachedStorageValue) {
            return cachedStorageValue;
        } else {
            const storageValue = new CookieStorageValue<VALUE>(this, key);

            this.storageValuesCache.set(key as string, storageValue);

            return storageValue;
        }
    }

    public getItem<T> (key: COOKIE_NAME, defaultValue: T) : T;
    public getItem<T> (key: COOKIE_NAME) : T | undefined;
    public getItem<T> (key: COOKIE_NAME, defaultValue?: T) : T | undefined {
        const content = this.hasConsent(key)
            ? this.cookieStorage.getItem(key)
            : this.inMemoryStorage.get(key);

        return jsonParseWithFallback(content, defaultValue);
    }

    public updateItem<T> (key: COOKIE_NAME, updateFunction: (value: T | undefined) => T) {
        const item = this.getItem<T>(key);

        const updatedValue = updateFunction(item);

        this.setItem(key, updatedValue);

        return updatedValue;
    }

    public setItem<T> (key: COOKIE_NAME, value: T) {
        const content = JSON.stringify(value);

        if (this.hasConsent(key)) {
            this.cookiesSetItem(key, content)
        }
        else {
            this.inMemoryStorage.set(key, content);
        }
    }

    protected cookiesSetItem(key: COOKIE_NAME, content: string) {
        const cookieConfig = this.supportedCookies[key];
        const globalAttributes = this.cookieConsentManager.getGlobalCookieAttributes();

        let domain: string | undefined;

        if (typeof cookieConfig.domain === 'string') {
            domain = cookieConfig.domain;
        } else if (Array.isArray(cookieConfig.domain)) {
            domain = arrayFindValue(cookieConfig.domain, domain => location.hostname.endsWith(domain));
            if (domain === undefined) { return; }
        } else {
            domain = globalAttributes.domain;
        }

        this.cookieStorage.setItem(key, content, {
            maxAge: cookieConfig.maxAge,
            sameSite: cookieConfig.sameSite,
            path: cookieConfig.path ?? globalAttributes.path,
            domain: domain
        });
    }
}

export class CookieStorageValueAccessor<
    VALUE,
    WITH_SANITIZER extends boolean,
    SANITIZED_VALUE = WITH_SANITIZER extends false ? VALUE | undefined : VALUE
    > {

    constructor(
        private storageAccessor: CookieStorageAccessor<string>,
        private key: string,
        private valueSanitizer: WITH_SANITIZER extends false ? undefined : ((value: VALUE | undefined) => SANITIZED_VALUE)
    ) {}

    public getValue () : SANITIZED_VALUE {
        const value = this.storageAccessor.getItem<VALUE>(this.key);

        if (this.valueSanitizer !== undefined) {
            return this.valueSanitizer(value);
        } else {
            return value as SANITIZED_VALUE;
        }
    }

    public setValue (value: VALUE) {
        this.storageAccessor.setItem<VALUE>(this.key, value)
    }

    public updateValue (updateFunc: (value: SANITIZED_VALUE) => VALUE) {
        this.setValue(updateFunc(this.getValue()));
    }

    public clearValue () {
        this.storageAccessor.removeItem(this.key);
    }
}

export class CookieStorageValue<VALUE> implements IObservableValue<VALUE | undefined> {

    private eventEmitter = new EventEmitter<IObservable.Events>();
    private lastValue: VALUE | undefined | typeof NoValue = NoValue;
    private storageAccessor: CookieStorageAccessor<string>;
    private key: string;

    constructor(
        storageAccessor: CookieStorageAccessor<string>,
        key: string
    ) {
        this.storageAccessor = storageAccessor
        this.key = key;
    }

    public get eventUpdated () : EventReference<IObservable.Events, 'eventUpdated'> {
        return this.eventEmitter.createEventReference('eventUpdated')
    }

    public remove () {
        this.storageAccessor.removeItem(this.key);
    }

    public getValue () : VALUE | undefined {
        const lastValue = this.lastValue;
        if (lastValue !== NoValue) {
            return lastValue;
        }

        return this.lastValue = this.storageAccessor.getItem<VALUE>(this.key);
    }

    public setValue (value: VALUE | ((prevValue?: VALUE) => VALUE)) {
        const updatedValue = typeof value === 'function' ?
            (value as (prevValue?: VALUE) => VALUE)(this.getValue()) :
            value;

        if (updatedValue !== this.lastValue) {
            this.lastValue = updatedValue;
            this.storageAccessor.setItem<VALUE>(this.key, updatedValue)

            this.eventEmitter.emit('eventUpdated');
        }
    }

}