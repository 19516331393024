import {
    evaluateFunction,
    HtmlObjectHorizontalPosition,
    HtmlObjectVerticalPosition,
    OmitStrict,
    Values
} from "@wix/devzai-utils-common";

export const BymoPageActionMediaViewLayout = {
    FullWidth: 'FullWidth',
    WithMargins: 'WithMargins',
} as const;

export type BymoPageActionMediaViewLayout = Values<typeof BymoPageActionMediaViewLayout>;

export const BymoPageActionMediaSize = {
    Ratio21By9: 'Ratio21By9',
    Ratio16By9: 'Ratio16By9',
    Ratio3By2: 'Ratio3By2',
    Square: 'Square',
} as const;

export type BymoPageActionMediaSize = Values<typeof BymoPageActionMediaSize>;

export const BymoPageActionMediaSizing = {
    Custom: 'Custom',
    ShowFullImage: 'ShowFullImage',
    // This is only for backward compatibility and shouldn't be used for new actions.
    ShowFullImageWithHeightLimit: 'ShowFullImageWithHeightLimit',
} as const;

export type BymoPageActionMediaSizing = Values<typeof BymoPageActionMediaSizing>;

export const BymoPageActionMediaLayout = {
    Contain: 'Contain',
    Cropped: 'Cropped',
} as const;

export type BymoPageActionMediaLayout = Values<typeof BymoPageActionMediaLayout>;

export type BymoPageActionMediaViewSettings = {
    mediaLayout: BymoPageActionMediaLayout;
    mediaSizing: BymoPageActionMediaSizing;
    size: BymoPageActionMediaSize;
    alignment: {
        horizontal: HtmlObjectHorizontalPosition;
        vertical: HtmlObjectVerticalPosition;
    }
}

export namespace BymoPageActionMediaViewSettings {
    type BaseType<T extends BymoPageActionMediaLayout> = {
        mediaLayout: T;
    };

    type RatioMaintainingLayoutSettings<T extends BymoPageActionMediaLayout> = BaseType<T> & {
        widthHeightRatio: number;
        alignment: {
            horizontal: HtmlObjectHorizontalPosition;
            vertical: HtmlObjectVerticalPosition;
        }
    }

    export type Cropped = RatioMaintainingLayoutSettings<typeof BymoPageActionMediaLayout.Cropped>;
    export type Contain = RatioMaintainingLayoutSettings<typeof BymoPageActionMediaLayout.Contain>;
}


export function bymoPageActionMediaViewResolveWidthHeightRatio (mediaViewSettings: BymoPageActionMediaViewSettings) {
    return bymoPageActionMediaSizeResolveWidthHeightRatio(mediaViewSettings.size);
}

export function bymoPageActionMediaSizeResolveWidthHeightRatio (size: BymoPageActionMediaSize) {
    switch (size) {
        case BymoPageActionMediaSize.Ratio3By2: return 3 / 2;
        case BymoPageActionMediaSize.Ratio16By9: return 16 / 9;
        case BymoPageActionMediaSize.Ratio21By9: return 21 / 9;
        case BymoPageActionMediaSize.Square: return 1;
    }
}

export function bymoPageActionMediaViewSettingsResolveDefault (
    options: {
        mediaRatio: number | null;
        maxSize?: BymoPageActionMediaSize;
        defaultCustomMediaSize?: BymoPageActionMediaSize;
    }
) : OmitStrict<BymoPageActionMediaViewSettings, 'mediaLayout'> {

    const {
        mediaRatio,
        maxSize = BymoPageActionMediaSize.Square,
    } = options;

    const defaultCustomMediaSize = options.defaultCustomMediaSize ?? maxSize;
    const minWidthHeightRatio = bymoPageActionMediaSizeResolveWidthHeightRatio(maxSize)

    return {
        ...evaluateFunction<Pick<BymoPageActionMediaViewSettings, 'mediaSizing' | 'size'>>(() => {
            if (mediaRatio !== null && mediaRatio >= minWidthHeightRatio) {
                return {
                    mediaSizing: BymoPageActionMediaSizing.ShowFullImage,
                    size: defaultCustomMediaSize,
                }

            } else {
                return {
                    size: defaultCustomMediaSize,
                    mediaSizing: BymoPageActionMediaSizing.Custom
                }
            }
        }),
        alignment: {
            horizontal: HtmlObjectHorizontalPosition.Center,
            vertical: HtmlObjectVerticalPosition.Center
        }
    }
}