import React, {useCallback, useMemo} from 'react';
import {classes, style, vars} from './doppe-standard-action-button-template.st.css';
import {
    doppeActionButtonTemplateDefine,
    DoppeActionView,
    DoppeColorPaletteCalculatedColorType
} from '../../../doppe-sdk';
import {DoppeViewerActionView} from '../../doppe-viewer-action-view/doppe-viewer-action-view';
import {
    Button,
    Icon,
    ImageViewPositionHorizontal,
    ImageViewPositionVertical,
    ImageViewResizeMode,
    useHashedMemoForRecord
} from '@wix/devzai-utils-react';
import IconChevronDown from './icons/ChevronDown.svg';
import {FlexLayout} from '@wix/devzai-common-client';
import {
    doppeHideableValueIsVisible,
    doppeHideableValueIsVisibleAndNotEqualValue
} from '../../../client-server-common/types/doppe-hideable-value';
import {
    DopeStandardActionButtonColorMode,
    DoppeStandardActionButtonTemplate,
    DoppeStandardActionButtonTemplateMetadata
} from './doppe-standard-action-button-template.metadata'
import {DoppePageMediaView, DoppePageMediaViewMediaBackground} from '../../doppe-page-media-view/doppe-page-media-view';
import {
    arrayGetItemCyclic,
    colorBlend,
    colorIsDark,
    colorResolveLegibleForegroundColor,
    colorResolveMiddleColor,
    cssResolveRgbColorComponentsForFunctionalNotationFormat,
    evaluateFunction,
    HtmlObjectHorizontalPosition,
    HtmlObjectResizeMode,
    HtmlObjectVerticalPosition,
    materialPrimaryColorPaletteResolvePrimaryRelativeColorIndex
} from '@wix/devzai-utils-common';
import {DoppeDtoActionViewType} from '../../../client-server-common/types/doppe-dto-action';
import {DoppeViewerBannerDefaultMediaResource} from '../../../doppe-static-assets/doppe-static-assets';
import {
    useAssertDoppeViewerColorPaletteCalculatedColorsDataReactContext
} from "../../doppe-viewer-bymo-page-props-react/doppe-viewer-bymo-page-props-react";

const DoppeStandardActionButton = React.memo(function DoppeStandardActionButton(props: DoppeActionView.Props<DoppeStandardActionButtonTemplate.TemplateProps>) {
    const {
        templateProps: {
            roundness,
            skin,
            hoverAnimation,
            colorMode
        },
        enableAnimation = false,
        viewType,
        actionIndexInList,
        isLockedObservable,

        ...actionViewProps
    } = props;

    const colorPaletteCalculatedColorsData = useAssertDoppeViewerColorPaletteCalculatedColorsDataReactContext();

    const buttonColorTypes = useMemo(() => {

        switch (colorMode) {
            case DopeStandardActionButtonColorMode.Unified: {
                return [
                    DoppeColorPaletteCalculatedColorType.MainColor
                ];
            }
            case DopeStandardActionButtonColorMode.Alternate: {
                return [
                    DoppeColorPaletteCalculatedColorType.MainColor,
                    DoppeColorPaletteCalculatedColorType.MainColorBrightnessAlternating
                ]
            }
            case DopeStandardActionButtonColorMode.Analogous: {
                return [
                    DoppeColorPaletteCalculatedColorType.MainColor,
                    DoppeColorPaletteCalculatedColorType.MainColorAnalogous,
                ]
            }
            case DopeStandardActionButtonColorMode.Triad: {
                return [
                    DoppeColorPaletteCalculatedColorType.MainColor,
                    DoppeColorPaletteCalculatedColorType.MainColorTriadic1,
                    DoppeColorPaletteCalculatedColorType.MainColorTriadic2,
                ];
            }
        }

    }, [colorMode]);

    const normalizedActionIndexInList = actionIndexInList ?? 0;
    const buttonColorType = arrayGetItemCyclic(buttonColorTypes, normalizedActionIndexInList)
    const buttonColorData = colorPaletteCalculatedColorsData[buttonColorType];

    const mainColor = buttonColorData.color;

    const mainColorRgb = useMemo(() => cssResolveRgbColorComponentsForFunctionalNotationFormat(mainColor), [mainColor]);

    const blendedMainColor = useMemo(() => {
        return colorIsDark(mainColor) ? colorBlend('#fff', mainColor, 0.2) : colorBlend('#000', mainColor, 0.1)
    }, [mainColor])

    const gradientSkinColors = evaluateFunction(() => {

        const mainButtonColorType = buttonColorTypes[0];
        const mainButtonColorData = colorPaletteCalculatedColorsData[mainButtonColorType];
        const mainButtonColorMaterialPrimaryColorPalette = mainButtonColorData.materialPrimaryColorPalette;

        if (mainButtonColorMaterialPrimaryColorPalette) {

            const mainButtonColorFirstColorPaletteIndex = mainButtonColorMaterialPrimaryColorPalette.primaryColorIndex;
            const mainButtonColorSecondColorPaletteIndex = materialPrimaryColorPaletteResolvePrimaryRelativeColorIndex(mainButtonColorMaterialPrimaryColorPalette, 4);

            const mainButtonColorGradientStart = mainButtonColorMaterialPrimaryColorPalette.palette[mainButtonColorFirstColorPaletteIndex];
            const mainButtonColorGradientEnd = mainButtonColorMaterialPrimaryColorPalette.palette[mainButtonColorSecondColorPaletteIndex];

            const currentButtonColorMaterialPrimaryColorPalette = buttonColorData.materialPrimaryColorPalette ?? mainButtonColorMaterialPrimaryColorPalette;

            const gradientStart = currentButtonColorMaterialPrimaryColorPalette.palette[currentButtonColorMaterialPrimaryColorPalette.primaryColorIndex];
            const gradientEnd = currentButtonColorMaterialPrimaryColorPalette.palette[mainButtonColorSecondColorPaletteIndex];
            //
            // const gradientStart = currentButtonColorMaterialPrimaryColorPalette.palette[mainButtonColorFirstColorPaletteIndex];
            // const gradientEnd = currentButtonColorMaterialPrimaryColorPalette.palette[mainButtonColorSecondColorPaletteIndex];

            // const gradientStart = currentButtonColorMaterialPrimaryColorPalette.palette[currentButtonColorMaterialPrimaryColorPalette.primaryColorIndex];
            // const gradientEnd = currentButtonColorMaterialPrimaryColorPalette.palette[
            //     materialPrimaryColorPaletteResolvePrimaryRelativeColorIndex(
            //         currentButtonColorMaterialPrimaryColorPalette,
            //         4 * (mainButtonColorSecondColorPaletteIndex > mainButtonColorFirstColorPaletteIndex ? 1 : -1),
            //         false
            //     )
            //     ];



            const middleColor = colorResolveMiddleColor(mainButtonColorGradientStart, mainButtonColorGradientEnd);

            return {
                gradientStart: gradientStart,
                gradientEnd: gradientEnd,
                gradientLegibleForeground: middleColor ? colorResolveLegibleForegroundColor(middleColor) : null
            }

        } else {
            return null
        }
    });

    const styleProp = useHashedMemoForRecord({
        [vars.roundness]: `${roundness}px`,
        [vars.blendedMainColor]: blendedMainColor ?? undefined,
        [vars.buttonColor]: mainColor,
        [vars.mainColorRgb]: mainColorRgb ?? undefined,
        [vars.gradientStart]: gradientSkinColors?.gradientStart ?? undefined,
        [vars.gradientEnd]: gradientSkinColors?.gradientEnd ?? undefined,
        [vars.gradientLegibleForeground]: gradientSkinColors?.gradientLegibleForeground ?? undefined,
    });

    const renderActionButton = useCallback<DoppeViewerActionView.Props['renderActionButton']>((renderProps) => {

        const {
            actionButtonComponentProps: {
                className,
                href,
                ...htmlAttributes
            },
            actionTitle,
            actionDescription,
            actionImage,
            actionViewType,
            bannerMedia,
            isLocked,

            isWidgetOpener
        } = renderProps;

        const hasImage = doppeHideableValueIsVisibleAndNotEqualValue(actionImage, null) && actionImage !== null;

        return (
            <Button
                {...htmlAttributes}
                className={style(
                    classes.actionButton,
                    {
                        hasImage: hasImage,
                        hasCustomContent: actionViewType === DoppeDtoActionViewType.BannerButton
                    },
                    className
                )}
                tagName={!isLocked && href !== undefined ? 'a' : 'button'}
                href={isLocked ? undefined : href}
            >
                {evaluateFunction(() => {
                    switch (actionViewType) {
                        case DoppeDtoActionViewType.BannerButton: {
                            return (
                                <DoppePageMediaView
                                    className={style(classes.bannerMediaView, className)}
                                    mediaResource={bannerMedia ?? DoppeViewerBannerDefaultMediaResource}
                                    layoutSpec={{autoHeight: true, maxHeight: 860}}
                                    widthSpec={{contentRelativeWidth: 1}}
                                    mediaResizeMode={HtmlObjectResizeMode.Cover}
                                    mediaVerticalPosition={HtmlObjectVerticalPosition.Center}
                                    mediaHorizontalPosition={HtmlObjectHorizontalPosition.Center}
                                    videoMuted={true}
                                    autoPlayVideo={true}
                                    playVideoInLoop={true}
                                />
                            )
                        }
                        default: {
                            return (
                                <>
                                    {hasImage ? (
                                        <DoppePageMediaView
                                            className={style(classes.actionImageView, {})}
                                            mediaResource={actionImage}
                                            layoutSpec={{widthHeightRatio: 1}}
                                            widthSpec={{width: 44}}
                                            mediaVerticalPosition={ImageViewPositionVertical.Center}
                                            mediaHorizontalPosition={ImageViewPositionHorizontal.Center}
                                            mediaResizeMode={ImageViewResizeMode.Cover}
                                            mediaBackground={DoppePageMediaViewMediaBackground.Transparent}
                                        />
                                    ) : null}
                                    <div
                                        className={style(classes.actionTextContent, {}, FlexLayout.column.default, FlexLayout.fillRemainingHorizontalSpace)}
                                    >
                                        <div className={style(classes.actionTitle, {})}>{actionTitle}</div>
                                        {
                                            doppeHideableValueIsVisible(actionDescription) ?
                                                <div className={style(classes.actionDescription, {})}>{actionDescription}</div>
                                                :
                                                null
                                        }

                                    </div>
                                    {evaluateFunction(() => {

                                        if (isWidgetOpener) {
                                            return (
                                                <Icon
                                                    className={style(classes.chevronIcon, {})}
                                                    icon={IconChevronDown}
                                                />
                                            )
                                        }

                                        return null;

                                    })
                                    }
                                </>
                            )
                        }
                    }
                })}

            </Button>
        )

    }, [])

    return (
        <DoppeViewerActionView
            className={style(classes.root, {
                enableAnimation: enableAnimation,
                skin: skin,
                hoverAnimation: hoverAnimation,
                viewType: evaluateFunction(() => {
                    switch (viewType) {
                        case DoppeDtoActionViewType.InPageFrameless: return 'strip';
                        case DoppeDtoActionViewType.ActionButton: return 'button';
                        case DoppeDtoActionViewType.InPage: return 'card';
                        case DoppeDtoActionViewType.BannerButton: return 'banner';
                    }
                })
            })}
            actionWidgetContainerClassName={classes.actionWidgetContainer}
            actionButtonContainerClassName={classes.actionButtonContainer}
            actionWidgetRoundness={roundness}
            actionIndexInList={actionIndexInList}
            renderActionButton={renderActionButton}
            style={styleProp}
            viewType={viewType}
            isLockedObservable={isLockedObservable}
            {...actionViewProps}
        />
    )
});

export const DoppeStandardActionButtonTemplateData = doppeActionButtonTemplateDefine(
    DoppeStandardActionButtonTemplateMetadata,
    DoppeStandardActionButton)