import {doppeActionTypeDefineMetadata} from '../../client-server-common/doppe-action-types/doppe-action-type';
import {WixMediaResource} from '@wix/devzai-common-wix';
import {
    DoppeCheckoutActionSettings,
    getDefaultDoppeCheckoutSettingsForAction,
    prepareCheckoutSettingsForViewer
} from '../../client-server-common/types/doppe-checkout-action-settings';
import {DoppeActionPrice} from '../../client-server-common/types/doppe-action-price';
import {RichTextContent} from '@wix/devzai-utils-react';
import {
    DoppeAutomationTriggers,
    DoppeHideableValue,
    doppeHideableValueCreateHidden,
    doppePremiumFeatureIsDisabled,
    DoppePremiumFeatures,
} from '../../client-server-common';

export namespace DoppeActionTypePaidMessage {

    export interface ActionSettings extends
        DoppeCheckoutActionSettings
    {
        title: string;
        description: DoppeHideableValue<RichTextContent>;
        priceData: DoppeActionPrice;
        images: WixMediaResource[] | null;
        previewCheckoutButtonText: string;
    }

}


export const doppeActionTypePaidMessage = doppeActionTypeDefineMetadata<DoppeActionTypePaidMessage.ActionSettings>({
    id: 'co.hopp.action/paid-message',
    resolveActionSettings: action => {

        const {
            checkoutSettings,
            ...restSettings
        } = action.settings;

        return {
            id: '',
            title: '',
            description: doppeHideableValueCreateHidden(''),
            priceData: {
                actualPrice: 0,
                currencyCode: 'USD',
            },
            images: null,
            previewCheckoutButtonText: '',
            ...restSettings,
            checkoutSettings: getDefaultDoppeCheckoutSettingsForAction(checkoutSettings),
        }
    },
    prepareForViewer: actionSettings => {
        prepareCheckoutSettingsForViewer(actionSettings);
    },
    isWidgetOpener: true,
    canRenderInPage: true,
    skipReportingOnGoalAchievedOnActionButtonClick: true,
    isPaymentAction: true,
    getSupportedAutomationTrigger: () => DoppeAutomationTriggers.PaymentReceived,
    isFormAction: false,
    isPremiumAction: (premiumFeaturesStatus) => {
        return doppePremiumFeatureIsDisabled(premiumFeaturesStatus, DoppePremiumFeatures.creators_monetization_actions)
    },
});
