import {
    BymoPageActionMediaLayout,
    BymoPageActionMediaSize,
    BymoPageActionMediaSizing,
    BymoPageActionMediaViewLayout,
    DoppeActionListItemClickBehaviour,
    DoppeDtoBlogPost,
    doppeDtoBlogPostGetMedia,
    DoppeExternalBlogPostProvider,
    doppeHideableValueIsHidden,
    doppeListActionListItemGetDefaultListItemSettingsMigration,
    doppeListActionSettingsGetListItemCtaButtonsDefaultSettings,
    doppeListActionSettingsGetListItemSectionsDefaultSettings
} from '../../client-server-common';
import {
    dateGetCurrentDateTime,
    dateSerializedToString,
    HtmlObjectHorizontalPosition,
    HtmlObjectVerticalPosition
} from '@wix/devzai-utils-common';
import {DoppeActionMediaViewSettings} from '../../client-server-common/types/doppe-action-media-view-settings';
import {
    DoppeActionTypeArticlesList
} from '../../doppe-action-types/doppe-action-type-articles-list/doppe-action-type-articles-list';
import {
    DoppeActionTypeBlogPost
} from '../../doppe-action-types/doppe-action-type-blog-post/doppe-action-type-blog-post';

export function doppeBlogPostActionGetMediaViewDefaultSettings(
    overrides: Partial<DoppeActionMediaViewSettings> = {}
) {
    return {
        mainMediaViewSettings: {
            mediaLayout: BymoPageActionMediaLayout.Cropped,
            mediaSizing: BymoPageActionMediaSizing.Custom,
            size: BymoPageActionMediaSize.Ratio3By2,
            alignment: {
                horizontal: HtmlObjectHorizontalPosition.Center,
                vertical: HtmlObjectVerticalPosition.Center
            },
            ...overrides.mainMediaViewSettings,
        },
        mainMediaViewLayout: overrides.mainMediaViewLayout ?? BymoPageActionMediaViewLayout.WithMargins,
    } satisfies DoppeActionMediaViewSettings;
}

export function doppeBlogPostActionResolveSettingsFromDoppeDtoBlogPost(blogPost: DoppeDtoBlogPost, settings?: DoppeActionTypeBlogPost.ArticleSettings): DoppeActionTypeBlogPost.ArticleSettings {

    return {
        showBlogWebsite: true,
        ...settings,
        blogPostImages: doppeDtoBlogPostGetMedia(blogPost),
        blogPostLink: blogPost.blogPostPageUrl ?? '',
        blogPostTitle: blogPost?.title ?? '',
        description: blogPost.excerpt,
        blogPostProvider: DoppeExternalBlogPostProvider.Wix,
        blogPostProviderId: blogPost.id,
        blogPostDate: dateSerializedToString(blogPost.firstPublishedDate ?? dateGetCurrentDateTime()),
        ...doppeBlogPostActionGetMediaViewDefaultSettings()
    } satisfies DoppeActionTypeBlogPost.ArticleSettings
}

export function doppeBlogPostsListActionResolveItemSettingsFromDoppeDtoBlogPost(blogPost: DoppeDtoBlogPost): DoppeActionTypeArticlesList.ArticleSettings {

    const blogPostSettings = doppeBlogPostActionResolveSettingsFromDoppeDtoBlogPost(blogPost);

    return {
        ...blogPostSettings,
        blogPostTitle: doppeHideableValueIsHidden(blogPostSettings.blogPostTitle) ? '' : blogPostSettings.blogPostTitle,
        ...doppeListActionSettingsGetListItemCtaButtonsDefaultSettings(),
        ...doppeListActionSettingsGetListItemSectionsDefaultSettings(),
        ...doppeListActionListItemGetDefaultListItemSettingsMigration(blogPost.id),
    }

}

export function doppeBlogPostsListActionResolveExternalItemSettingsFromDoppeDtoBlogPost(
    options: {
        blogPost: DoppeDtoBlogPost;
        listItemClickBehaviour: DoppeActionListItemClickBehaviour
    }
): DoppeActionTypeArticlesList.ArticleSettings {

    const {
        blogPost,
        listItemClickBehaviour
    } = options;

    const blogPostSettings = doppeBlogPostsListActionResolveItemSettingsFromDoppeDtoBlogPost(blogPost);

    return {
        ...blogPostSettings,
        mainMediaViewSettings: {
            mediaLayout: BymoPageActionMediaLayout.Cropped,
            mediaSizing: BymoPageActionMediaSizing.ShowFullImageWithHeightLimit,
            size: BymoPageActionMediaSize.Ratio3By2,
            alignment: {
                horizontal: HtmlObjectHorizontalPosition.Center,
                vertical: HtmlObjectVerticalPosition.Center
            }
        },
        mainMediaViewLayout: listItemClickBehaviour === DoppeActionListItemClickBehaviour.PreviewItemAsPopup ?
            BymoPageActionMediaViewLayout.FullWidth :
            BymoPageActionMediaViewLayout.WithMargins
    }

}