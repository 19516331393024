import {doppeActionTypeDefineMetadata} from '../../client-server-common/doppe-action-types/doppe-action-type';
import {DoppeHideableValue} from '../../client-server-common';
import {WixMediaResource, wixMediaResourceReplaceExternalImage} from '@wix/devzai-common-wix';
import {asArray, immerProduce} from '@wix/devzai-utils-common';
import {RichTextContent} from '@wix/devzai-utils-react';
import {
    doppeActionContentSectionSettingsDefaultValuesMigration,
    DoppeActionContentSectionsSettings
} from '../../client-server-common/types/doppe-action-content-sections-settings';
import {
    DoppeActionCtaButtonsSettings,
    doppeActionCtaButtonsSettingsDefaultValuesMigration
} from '../../client-server-common/types/doppe-action-cta-buttons-settings';
import {
    doppeActionCtaButtonsResolveMainURL
} from '../doppe-actions-common/doppe-cta-button-utils/doppe-cta-button-utils';
import {
    doppeDtoActionSettingsRemoveInnerCtaContentForLockedAction
} from '../../client-server-common/types/doppe-dto-action';
import {doppeActionCtaButtonsResetSettingsForLock} from '../../client-server-common/types/doppe-action-cta-button';


export namespace DoppeActionTypeContent {

    export interface ActionSettings extends
        DoppeActionContentSectionsSettings,
        DoppeActionCtaButtonsSettings {
        title: DoppeHideableValue<string>;
        description: DoppeHideableValue<RichTextContent>;
        image: WixMediaResource[] | null;
    }
}

export const doppeActionTypeContent = doppeActionTypeDefineMetadata<DoppeActionTypeContent.ActionSettings>({
    id: 'io.bymo.action/content',
    resolveActionSettings: action => {

        const {
            ctaButtons,
            sections,
            ...restSettings
        } = action.settings;

        return {
            description: '',
            title: '',
            image: null,
            ...doppeActionContentSectionSettingsDefaultValuesMigration(action.settings),
            ...doppeActionCtaButtonsSettingsDefaultValuesMigration(action.settings, false),
            ...restSettings
        }
    },
    hasNoGoalMeasuring: false,
    hasNoLockInnerCTA: true,
    isLinkOpener: false,
    isWidgetOpener: true,
    canRenderInPage: true,
    skipReportingOnGoalAchievedOnActionButtonClick: true,
    resolveUsedMedia: actionSettings => {
        return actionSettings.image ? asArray(actionSettings.image) : [];
    },
    resolveMainMedia: actionSettings => {
        return actionSettings.image ? asArray(actionSettings.image)[0] : null
    },
    replaceExternalImages: (actionSettings, externalImagesUrlToImageResourceMap) => {
        return immerProduce(actionSettings, actionSettings => {
            if (actionSettings.image) {
                actionSettings.image = asArray(actionSettings.image).map(
                    image => wixMediaResourceReplaceExternalImage(image, externalImagesUrlToImageResourceMap))
            }
        })
    },
    resolveMainLink: (actionSettings, htmlSiteInfo) => {
        return doppeActionCtaButtonsResolveMainURL(actionSettings, {
            htmlSiteInfo: htmlSiteInfo,
        }) ?? null;
    },
    prepareForViewer: (actionSettings, options) => {
        doppeDtoActionSettingsRemoveInnerCtaContentForLockedAction(actionSettings, actionSettings => {
            doppeActionCtaButtonsResetSettingsForLock(actionSettings);
        }, options);
    },
})