import {doppeActionTypeDefineMetadata} from '../../client-server-common/doppe-action-types/doppe-action-type';
import {
    DoppeActionWidgetOpeningMode,
    DoppeHideableValue,
    doppeHideableValueCreateHidden,
    doppeHideableValueGetValue,
    doppeHideableValueIsVisibleAndNotEqualValue
} from '../../client-server-common';
import {WixMediaResource, wixMediaResourceReplaceExternalImage} from '@wix/devzai-common-wix';
import {asArray, immerProduce} from '@wix/devzai-utils-common';
import {RichTextContent} from '@wix/devzai-utils-react';
import {
    doppeDtoActionSettingsRemoveInnerCtaContentForLockedAction
} from '../../client-server-common/types/doppe-dto-action';
import {
    DoppeActionCtaButtonSettings,
    doppeActionCtaButtonTypeGetDefaultCtaButtonSettingsMigration,
    getDefaultDoppeActionCtaButtonSettings
} from '../../client-server-common/types/doppe-action-cta-button-settings';
import {doppeActionCtaButtonsResetSettingsForLock} from '../../client-server-common/types/doppe-action-cta-button';
import {
    doppeActionCtaButtonsResolveMainURL
} from '../doppe-actions-common/doppe-cta-button-utils/doppe-cta-button-utils';
import {
    doppeActionContentSectionSettingsDefaultValuesMigration,
    DoppeActionContentSectionsSettings
} from '../../client-server-common/types/doppe-action-content-sections-settings';
import {
    DoppeActionCtaButtonsSettings,
    doppeActionCtaButtonsSettingsDefaultValuesMigration
} from '../../client-server-common/types/doppe-action-cta-buttons-settings';


export namespace DoppeActionTypeLinkView {

    export interface LinkSettings  {
        url: string;
        title: DoppeHideableValue<string>;
        description: DoppeHideableValue<RichTextContent>;
        image: WixMediaResource[] | null;
        couponCode: DoppeHideableValue<string>;
        ribbon: DoppeHideableValue<string>;
    }

    export interface ActionSettings extends
        DoppeActionContentSectionsSettings,
        DoppeActionCtaButtonsSettings,
        DoppeActionTypeLinkView.LinkSettings {

    }

    export type DeprecatedFields = Partial<DoppeActionCtaButtonSettings> & {
        linkButtonText?: DoppeHideableValue<string>;
    }

    export type PartialProps = Partial<ActionSettings> & DeprecatedFields;
}

export const doppeActionTypeLinkView = doppeActionTypeDefineMetadata<DoppeActionTypeLinkView.ActionSettings, DoppeActionTypeLinkView.PartialProps>({
    id: 'io.bymo.action/link-view',
    resolveActionSettings: action => {

        const {
            url = '#',
            linkButtonText = 'Continue',
            ctaButton,
            ctaButtons,
            sections,
            ...restSettings
        } = action.settings;

        return {
            url: url,
            title: 'Link title',
            description: doppeHideableValueCreateHidden('Link description'),
            image: null,
            linkButtonText: linkButtonText,
            couponCode: doppeHideableValueCreateHidden('Coupon code'),
            ribbon: doppeHideableValueCreateHidden('SALE'),
            ...getDefaultDoppeActionCtaButtonSettings({
                buttonText: linkButtonText,
                showButton: true,
            }),
            ...doppeActionCtaButtonTypeGetDefaultCtaButtonSettingsMigration({
                hasSourceURL: true,
                ctaButton: ctaButton,
            }),
            ...doppeActionContentSectionSettingsDefaultValuesMigration(action.settings),
            ...doppeActionCtaButtonsSettingsDefaultValuesMigration(action.settings, true),
            ...restSettings
        }
    },
    isLinkOpener: true,
    isWidgetOpener: true,
    canRenderInPage: true,
    skipReportingOnGoalAchievedOnActionButtonClick: (actionContext) => actionContext.action.widgetOpeningMode === DoppeActionWidgetOpeningMode.OpenCTA ? false : true,
    resolveUsedMedia: actionSettings => {
        return actionSettings.image ? asArray(actionSettings.image) : [];
    },
    resolveMainMedia: actionSettings => {
        return actionSettings.image ? asArray(actionSettings.image)[0] : null
    },
    resolveCouponData: actionSettings => {
        return {
            showCouponCode: doppeHideableValueIsVisibleAndNotEqualValue(actionSettings.couponCode, ''),
            couponCode: doppeHideableValueGetValue(actionSettings.couponCode),
            couponDescription: doppeHideableValueIsVisibleAndNotEqualValue(actionSettings.description, '') ? actionSettings.description : '',
            couponTitle: doppeHideableValueIsVisibleAndNotEqualValue(actionSettings.title, '') ? actionSettings.title : '',
        }
    },
    replaceExternalImages: (actionSettings, externalImagesUrlToImageResourceMap) => {
        return immerProduce(actionSettings, actionSettings => {
            if (actionSettings.image) {
                actionSettings.image = asArray(actionSettings.image).map(
                    image => wixMediaResourceReplaceExternalImage(image, externalImagesUrlToImageResourceMap))
            }
        })
    },
    resolveMainLink: (actionSettings, htmlSiteInfo) => {
        return doppeActionCtaButtonsResolveMainURL(actionSettings, {
            htmlSiteInfo: htmlSiteInfo,
            sourceURL: actionSettings.url
        }) ?? null;
    },
    prepareForViewer: (actionSettings, options) => {
        doppeDtoActionSettingsRemoveInnerCtaContentForLockedAction(actionSettings, actionSettings => {
            actionSettings.url = '';
            doppeActionCtaButtonsResetSettingsForLock(actionSettings);
        }, options);
    },
})