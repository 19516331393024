import {doppeActionTypeDefineMetadata} from '../../client-server-common/doppe-action-types/doppe-action-type';
import {
    arrayFlatten,
    arrayRemoveNullValues,
    immerProduce,
    iterableMapToArray,
    OmitStrict,
    PartiallyOptional
} from '@wix/devzai-utils-common';
import {wixMediaResourceReplaceExternalImage} from '@wix/devzai-common-wix';
import {
    convertCommonListSettingToDefaultDoppeActionCtaButtonSettingsFromListAction,
    DoppeActionLayout,
    doppeExternalEventProviderResolveBusinessEntityReference,
    DoppeHideableValue,
    doppeHideableValueCreateHidden,
    doppeHideableValueGetValue,
    doppeHideableValueGetVisibleValue,
    doppeHideableValueIsVisibleAndNotEqualValue,
    doppeHideableValueModifyValue,
    doppeListActionGetDefaultListSettings,
    doppeListActionGetDefaultSearchListSettings,
    doppeListActionGetItemClickBehaviourDefaultListSettings,
    doppeListActionGetMainDetailsDefaultSettings,
    doppeListActionGetWithExternalDataSourceDefaultListSettings,
    doppeListActionItemCtaButtonsResetSettingsForLock,
    doppeListActionListItemCalculateItemButtons,
    doppeListActionListItemGetDefaultListItemSettingsMigration,
    doppeListActionListItemWithCtaButtonsMigration,
    doppeListActionListItemWithSectionsMigration,
    DoppeListActionSettings,
    doppeListActionSettingsFilterHiddenListItemsFromActionView,
    doppeListActionSettingsGetListItemCtaButtonsDefaultSettings,
    doppeListActionSettingsGetListItemSectionsDefaultSettings,
    doppeListActionTemplateCtaButtonsSettingsDefaultValuesMigration,
    doppeListActionTemplateSectionsSettingsDefaultValuesMigration,
    doppeListActionWithMainDetailsGetMainImage,
    doppeListActionWithMainDetailsReplaceMainImage
} from '../../client-server-common';
import {
    convertDoppeWixEventToEventSettings,
    DoppeActionTypeEvent
} from '../doppe-action-type-event/doppe-action-type-event';
import {DoppeDtoEvent} from '../../client-server-common/types/doppe-dto-event';
import {
    DoppeEventsExternalDataSource,
    doppeEventsExternalDataSourceResolveBusinessEntityReferences
} from '../../client-server-common/types/doppe-events-external-data-source';
import {
    doppeDtoActionSettingsRemoveInnerCtaContentForLockedAction
} from '../../client-server-common/types/doppe-dto-action';
import {DoppeAppPageContentLocales} from '../../client/doppe-app-locales/doppe-app-page-content-locales';
import {doppeActionCtaButtonsResetSettingsForLock} from '../../client-server-common/types/doppe-action-cta-button';
import {
    DoppeActionCtaButtonSettings,
    doppeActionCtaButtonTypeGetDefaultCtaButtonSettingsMigration,
    getDefaultDoppeActionCtaButtonSettings
} from '../../client-server-common/types/doppe-action-cta-button-settings';
import {
    doppeActionCtaButtonsResolveMainURL
} from '../doppe-actions-common/doppe-cta-button-utils/doppe-cta-button-utils';
import {
    doppeActionContentSectionSettingsDefaultValuesMigration,
    DoppeActionContentSectionsSettings
} from '../../client-server-common/types/doppe-action-content-sections-settings';
import {
    DoppeActionCtaButtonsSettings,
    doppeActionCtaButtonsSettingsDefaultValuesMigration
} from '../../client-server-common/types/doppe-action-cta-buttons-settings';


export namespace DoppeActionTypeEvents {

    export interface EventSettings extends
        DoppeListActionSettings.ListItemWithCtaButtons,
        DoppeListActionSettings.ListItemWithSections,
        DoppeListActionSettings.ListItemBaseSettings,
        DoppeActionTypeEvent.EventSettings {
        eventTitle: string;
    }

    export interface ActionSettings extends
        DoppeListActionSettings<EventSettings>,
        DoppeListActionSettings.WithSearchSettings,
        DoppeListActionSettings.WithMainDetails,
        DoppeListActionSettings.WithItemClickBehaviour,
        DoppeListActionSettings.WithExternalDataSourceSupport<DoppeEventsExternalDataSource>,
        DoppeActionContentSectionsSettings,
        DoppeActionCtaButtonsSettings {
        itemShowDate: boolean;
        previewShowTitle: boolean;
        previewShowDescription: boolean;
        previewShowCoupon: boolean;
        previewShowPrice: boolean;
    }

    export interface DeprecatedFields extends Partial<DoppeListActionSettings.WithCtaButton> {
        events?: EventSettings[];
        previewEventButtonText?: string;
    }

    export interface DeprecatedItemFields extends Partial<DoppeActionCtaButtonSettings> {
        eventButtonText?: DoppeHideableValue<string>;
    }

    export type PartialProps = Partial<OmitStrict<ActionSettings, keyof DoppeListActionSettings<EventSettings>>> & DeprecatedFields & DoppeListActionSettings<EventSettings & DeprecatedItemFields>;
}


export const doppeActionTypeEvents = doppeActionTypeDefineMetadata<DoppeActionTypeEvents.ActionSettings, DoppeActionTypeEvents.PartialProps >({
    id: 'io.bymo.action/events',
    resolveActionSettings: action => {

        const {
            listDataItems = [],
            listSearchSettings,
            ctaButton,
            listItemTemplateCtaButtonsSettings,
            listItemTemplateSectionsSettings,
            ctaButtons,
            sections,
            ...restSettings
        } = action.settings;

        return {
            itemShowDate: true,
            previewShowDescription: true,
            previewShowTitle: true,
            previewShowCoupon: true,
            previewShowPrice: true,
            previewEventButtonText: '',
            ...doppeListActionGetWithExternalDataSourceDefaultListSettings(),
            ...doppeListActionGetDefaultSearchListSettings(action.settings),
            ...doppeListActionGetDefaultListSettings({
                layout: DoppeActionLayout.Grid,
                listDataItems: listDataItems.map(event => {

                    const {
                        eventButtonText,
                        ctaButton,
                        customSections,
                        customCtaButtons,
                        sectionsOverridesMap,
                        ctaButtonsOverridesMap,
                        ...restEvent
                    } = event;

                    return {
                        eventProviderId: null,
                        eventProvider: null,
                        ribbon: doppeHideableValueCreateHidden(''),
                        ...getDefaultDoppeActionCtaButtonSettings({
                            buttonText: eventButtonText,
                            showButton: true
                        }),
                        ...doppeActionCtaButtonTypeGetDefaultCtaButtonSettingsMigration({
                            hasSourceURL: true,
                            ctaButton: ctaButton,
                        }),
                        ...doppeListActionListItemGetDefaultListItemSettingsMigration(event.id),
                        ...doppeListActionListItemWithCtaButtonsMigration(event, true),
                        ...doppeListActionListItemWithSectionsMigration(event),
                        ...restEvent as PartiallyOptional<DoppeActionTypeEvents.EventSettings & DoppeActionTypeEvents.DeprecatedItemFields, 'eventProvider' | 'eventProviderId' | 'ribbon' | 'ctaButton' | 'customCtaButtons' |'customSections' | 'ctaButtonsOverridesMap' | 'sectionsOverridesMap'>
                    }
                }),
            }),
            ...doppeListActionGetItemClickBehaviourDefaultListSettings(),
            ...doppeListActionGetMainDetailsDefaultSettings(),
            ...convertCommonListSettingToDefaultDoppeActionCtaButtonSettingsFromListAction(action.settings),
            ...doppeActionCtaButtonTypeGetDefaultCtaButtonSettingsMigration({
                ctaButton: ctaButton,
                hasSourceURL: false
            }),
            ...doppeActionContentSectionSettingsDefaultValuesMigration(action.settings),
            ...doppeActionCtaButtonsSettingsDefaultValuesMigration(action.settings, false),
            ...doppeListActionTemplateCtaButtonsSettingsDefaultValuesMigration(action.settings, true),
            ...doppeListActionTemplateSectionsSettingsDefaultValuesMigration(action.settings),
            ...restSettings
        }
    },
    isWidgetOpener: true,
    canRenderInPage: true,
    skipReportingOnGoalAchievedOnActionButtonClick: true,
    isListItemLinkOpener: true,
    supportsListLayout: true,
    resolveListItemsCount: actionSettings => {
        return actionSettings.externalDataSource ? actionSettings.externalDataSourceItemsCount : doppeListActionSettingsFilterHiddenListItemsFromActionView(actionSettings.listDataItems).length;
    },
    resolveCouponDataFromListItem: listItem => {
        return {
            showCouponCode: doppeHideableValueIsVisibleAndNotEqualValue(listItem.couponCode, ''),
            couponCode: doppeHideableValueGetVisibleValue(listItem.couponCode, ''),
            couponDescription: '',
            couponTitle: doppeHideableValueGetVisibleValue(listItem.title, ''),
        }
    },
    resolveListItems: (action, htmlSiteInfo) => {
        const actionSettings = action.settings;
        return actionSettings.listDataItems.map(item => {
            return {
                url: item.eventLink ?? doppeActionCtaButtonsResolveMainURL(
                    doppeListActionListItemCalculateItemButtons(actionSettings.listItemTemplateCtaButtonsSettings, item), {
                        htmlSiteInfo: htmlSiteInfo,
                        sourceURL: item.eventLink,
                    }) ?? null,
                title: item.eventTitle,
                action: action,
                couponCode: item.couponCode,
                description: item.description,
                image: item.eventImages?.[0] ?? null,
                itemId: item.id,
                ribbon: item.ribbon,
                enabled: item.enabled,
                deleted: item.deleted,
                displayInSearch: item.displayInSearch,
            }
        });
    },
    resolveListSearchSettings: actionSettings => {
        return actionSettings.listSearchSettings;
    },
    resolveUsedMedia: actionSettings => {
        return arrayRemoveNullValues([...arrayFlatten(actionSettings.listDataItems.map(itemSettings => {
            return itemSettings.eventImages ?? []
        })), doppeHideableValueIsVisibleAndNotEqualValue(actionSettings.mainImage, null) ? actionSettings.mainImage : null]);
    },
    resolveMainMedia: actionSettings => {
        return doppeListActionWithMainDetailsGetMainImage(actionSettings, actionSettings.listDataItems[0]?.eventImages?.[0] ?? null);
    },
    resolveMainLink: (actionSettings, htmlSiteInfo) => {
        return doppeActionCtaButtonsResolveMainURL(actionSettings, {
            htmlSiteInfo: htmlSiteInfo,
        }) ?? null;
    },
    prepareForViewer: (actionSettings, options) => {
        doppeDtoActionSettingsRemoveInnerCtaContentForLockedAction(actionSettings, actionSettings => {
            doppeListActionItemCtaButtonsResetSettingsForLock(actionSettings);
            doppeActionCtaButtonsResetSettingsForLock(actionSettings);
        }, options);
    },
    isConnectedToExternalDataSource: actionSettings => {
        return !!actionSettings.externalDataSource
    },
    resolveUsedBusinessEntities: actionSettings => {
        const externalDataSource = actionSettings.externalDataSource;
        if (externalDataSource) {
            return [
                doppeEventsExternalDataSourceResolveBusinessEntityReferences(externalDataSource)
            ];
        } else {
            return iterableMapToArray(actionSettings.listDataItems, (event, skip) => {
                return event.eventProvider !== null && event.eventProviderId !== null ?
                    doppeExternalEventProviderResolveBusinessEntityReference(
                        event.eventProvider,
                        event.eventProviderId
                    ) : skip
            });
        }
    },
    replaceExternalImages: (actionSettings, externalImagesUrlToImageResourceMap) => {
        return immerProduce(actionSettings, actionSettings => {
            for (const event of actionSettings.listDataItems) {
                if (event.eventImages) {
                    event.eventImages = event.eventImages.map(
                        image => wixMediaResourceReplaceExternalImage(image, externalImagesUrlToImageResourceMap))
                }
            }

            doppeListActionWithMainDetailsReplaceMainImage(actionSettings, externalImagesUrlToImageResourceMap);
        })
    },
});

export function populateEventsActionEventSettingsDefaultTexts(options: {
    event: DoppeActionTypeEvents.EventSettings,
    pageContentLocales: DoppeAppPageContentLocales,
}): DoppeActionTypeEvents.EventSettings {

    const {
        pageContentLocales,
        event,
    } = options;

    return {
        ...event,
        eventTitle: doppeHideableValueGetValue(event.eventTitle) !== '' ? event.eventTitle : pageContentLocales["event.actionSettings.title"],
        description: doppeHideableValueModifyValue(event.description, (value) => {
            return value !== '' ? value : pageContentLocales["event.actionSettings.description"];
        }),
        couponCode: doppeHideableValueModifyValue(event.couponCode, (value) => {
            return value !== '' ? value : pageContentLocales["event.actionSettings.couponCode"];
        }),
        ribbon: doppeHideableValueModifyValue(event.ribbon, (value) => {
            return value !== '' ? value : pageContentLocales["event.actionSettings.ribbon"];
        }),
        eventLocation: doppeHideableValueModifyValue(event.eventLocation, (value) => {
            return value !== '' ? value : pageContentLocales["event.actionSettings.location"];
        }),
        ...doppeListActionSettingsGetListItemCtaButtonsDefaultSettings(),
        ...doppeListActionSettingsGetListItemSectionsDefaultSettings(),
    }
}

export function convertDoppeWixEventToEventsSettings(
    event: DoppeDtoEvent,
): DoppeActionTypeEvents.EventSettings {
    return {
        ...convertDoppeWixEventToEventSettings(event),
        ...doppeListActionListItemGetDefaultListItemSettingsMigration(event.id),
        couponCode: doppeHideableValueCreateHidden(''),
        ribbon: doppeHideableValueCreateHidden(''),
        ...doppeListActionSettingsGetListItemCtaButtonsDefaultSettings(),
        ...doppeListActionSettingsGetListItemSectionsDefaultSettings(),
    }
}