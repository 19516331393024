import {bymoTemplateMetadataFactoryCreate} from "../../../doppe-sdk";


export namespace BymoTemplatePagePreview {

    export interface TemplateProps {
        showSocialIconsSkeleton: boolean;
        showProfileTitleSkeleton: boolean;
        showProfileBioSkeleton: boolean;
        showProfilePictureSkeleton: boolean;
        mobileContentInlinePaddingCss: string;
        skeletonButtonsCount: number;
        prefixSkeletonActionButtonsCount: number;
    }
}

export const BymoTemplatePagePreviewMetadata = bymoTemplateMetadataFactoryCreate<BymoTemplatePagePreview.TemplateProps>()({
    initialValues: {
        showSocialIconsSkeleton: true,
        showProfileTitleSkeleton: true,
        showProfileBioSkeleton: true,
        showProfilePictureSkeleton: true,
        mobileContentInlinePaddingCss: '18px',
        skeletonButtonsCount: 0,
        prefixSkeletonActionButtonsCount: 0
    },
    features: [
        'hasActions', 'hasActionButton'
    ]
})