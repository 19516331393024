import {doppeActionTypeDefineMetadata} from '../../client-server-common/doppe-action-types/doppe-action-type';
import {
    DoppeHideableValue,
    doppeHideableValueCreateHidden,
    doppeHideableValueGetValue,
    doppeHideableValueIsVisibleAndNotEqualValue,
    doppeHideableValueModifyValue
} from '../../client-server-common/types/doppe-hideable-value';
import {WixMediaResource, wixMediaResourceReplaceExternalImage} from '@wix/devzai-common-wix';
import {DoppeActionTypeServiceViewerLang} from './doppe-action-type-service-viewer.lang';
import {arrayRemoveNullValues, immerProduce, stringIsNotNullableOrWhiteSpace} from '@wix/devzai-utils-common';
import {
    BymoPageProps,
    BymoPagePropsContentSettingSemanticMeaning,
    BymoPagePropsContentSettingsMetadata,
    BymoPagePropsContentSettingType,
    bymoPagePropsCreateContentSettingMetadata,
    DoppeActionPriceData,
    DoppeDtoBookingService,
    doppeDtoBookingServiceCapacityConvertToActionFormattedCapacity,
    doppeDtoBookingServiceConvertToFormattedPrice,
    doppeDtoBookingServiceLocationConvertToActionFormattedLocation,
    doppeDtoBookingServiceScheduleConvertToActionFormattedDuration,
    doppeDtoBookingServiceScheduleConvertToActionFormattedSchedule,
    DoppeExternalServiceProvider,
    doppeExternalServiceProviderResolveBusinessEntityReference
} from '../../client-server-common';
import {BymoPageResource} from '../../client/resources/bymo-page-resource/bymo-page-resource';
import {RichTextContent} from '@wix/devzai-utils-react';
import {DoppeAppPageContentLocales} from '../../client/doppe-app-locales/doppe-app-page-content-locales';
import {
    doppeDtoActionSettingsRemoveInnerCtaContentForLockedAction
} from '../../client-server-common/types/doppe-dto-action';
import {
    DoppeActionCtaButtonSettings,
    doppeActionCtaButtonTypeGetDefaultCtaButtonSettingsMigration,
    getDefaultDoppeActionCtaButtonSettings
} from '../../client-server-common/types/doppe-action-cta-button-settings';
import {
    doppeActionCtaButtonsResetSettingsForLock,
    doppeActionCtaButtonTypeGetDefaultSettings,
    DoppeActionCTAType,
    doppeActionMultipleCTAButtonCreate
} from '../../client-server-common/types/doppe-action-cta-button';
import {
    doppeActionCtaButtonsResolveMainURL
} from '../doppe-actions-common/doppe-cta-button-utils/doppe-cta-button-utils';
import {
    doppeActionContentSectionSettingsDefaultValuesMigration,
    DoppeActionContentSectionsSettings
} from '../../client-server-common/types/doppe-action-content-sections-settings';
import {
    DoppeActionCtaButtonsSettings,
    doppeActionCtaButtonsSettingsDefaultValuesMigration
} from '../../client-server-common/types/doppe-action-cta-buttons-settings';

export namespace DoppeActionTypeService {

    export type ServiceSettings = {
        servicePageLink: string;
        serviceTitle: DoppeHideableValue<string>;
        description: DoppeHideableValue<RichTextContent>;
        serviceImages: WixMediaResource[] | null;
        priceData: DoppeHideableValue<DoppeActionPriceData>;
        servicePeriod: DoppeHideableValue<string>;
        serviceSchedule: DoppeHideableValue<string>;
        serviceParticipants: DoppeHideableValue<string>;
        location: DoppeHideableValue<string>;
        couponCode: DoppeHideableValue<string>;
        ribbon: DoppeHideableValue<string>;
        serviceProvider: DoppeExternalServiceProvider | null;
        serviceProviderId: string | null;
    }

    export type ActionSettings = DoppeActionTypeService.ServiceSettings &
        DoppeActionContentSectionsSettings &
        DoppeActionCtaButtonsSettings & {

    }

    export type DeprecatedFields = Partial<DoppeActionCtaButtonSettings> & {
        serviceButtonText?: DoppeHideableValue<string>;
    }

    export type PartialProps = Partial<ActionSettings> & DeprecatedFields;
}

export const doppeActionTypeServiceActionContentSettingsMetadata = {
    serviceTitle: bymoPagePropsCreateContentSettingMetadata(BymoPagePropsContentSettingType.Text, BymoPagePropsContentSettingSemanticMeaning.EntityTitle),
    description: bymoPagePropsCreateContentSettingMetadata(BymoPagePropsContentSettingType.RichText, BymoPagePropsContentSettingSemanticMeaning.EntityDescription),
    couponCode: bymoPagePropsCreateContentSettingMetadata(BymoPagePropsContentSettingType.Text, BymoPagePropsContentSettingSemanticMeaning.EntityCouponCode),
    ribbon: bymoPagePropsCreateContentSettingMetadata(BymoPagePropsContentSettingType.Text, BymoPagePropsContentSettingSemanticMeaning.EntityRibbon),
    servicePageLink: bymoPagePropsCreateContentSettingMetadata(BymoPagePropsContentSettingType.Url, BymoPagePropsContentSettingSemanticMeaning.EntityPageUrl),
    serviceImages: bymoPagePropsCreateContentSettingMetadata(BymoPagePropsContentSettingType.MultipleMedia, BymoPagePropsContentSettingSemanticMeaning.EntityMedia),
    servicePeriod: bymoPagePropsCreateContentSettingMetadata(BymoPagePropsContentSettingType.Text, BymoPagePropsContentSettingSemanticMeaning.EntityDuration),
    serviceSchedule: bymoPagePropsCreateContentSettingMetadata(BymoPagePropsContentSettingType.Text, BymoPagePropsContentSettingSemanticMeaning.EntityScheduleInfo),
    serviceParticipants: bymoPagePropsCreateContentSettingMetadata(BymoPagePropsContentSettingType.Text, BymoPagePropsContentSettingSemanticMeaning.EntityParticipantsInfo),
    location: bymoPagePropsCreateContentSettingMetadata(BymoPagePropsContentSettingType.Text, BymoPagePropsContentSettingSemanticMeaning.EntityAddress),
} satisfies BymoPagePropsContentSettingsMetadata<DoppeActionTypeService.ActionSettings>

export const doppeActionTypeService = doppeActionTypeDefineMetadata<DoppeActionTypeService.ActionSettings, DoppeActionTypeService.PartialProps>({
    id: 'io.bymo.action/service',
    resolveActionSettings: action => {

        const {
            servicePageLink = '',
            serviceButtonText = '',
            ctaButton,
            ctaButtons,
            sections,
            ...restSettings
        } = action.settings;

        return {
            servicePageLink: servicePageLink,
            priceData: {
                actualPrice: DoppeActionTypeServiceViewerLang.defaultTexts.price,
            },
            serviceTitle: DoppeActionTypeServiceViewerLang.defaultTexts.serviceTitle,
            description: DoppeActionTypeServiceViewerLang.defaultTexts.description,
            serviceButtonText: serviceButtonText,
            serviceImages: null,
            location: '',
            servicePeriod: '',
            serviceSchedule: doppeHideableValueCreateHidden(''),
            serviceParticipants: doppeHideableValueCreateHidden(''),
            couponCode: doppeHideableValueCreateHidden(''),
            ribbon: doppeHideableValueCreateHidden(''),
            serviceProvider: null,
            serviceProviderId: null,
            ...getDefaultDoppeActionCtaButtonSettings({
                buttonText: serviceButtonText,
                showButton: true,
            }),
            ...doppeActionCtaButtonTypeGetDefaultCtaButtonSettingsMigration({
                hasSourceURL: true,
                ctaButton: ctaButton,
            }),
            ...doppeActionContentSectionSettingsDefaultValuesMigration(action.settings),
            ...doppeActionCtaButtonsSettingsDefaultValuesMigration({
                ctaButtons: ctaButtons,
            }, true),
            ...restSettings
        }
    },
    isWidgetOpener: true,
    isLinkOpener: true,
    canRenderInPage: true,
    skipReportingOnGoalAchievedOnActionButtonClick: true,
    settingsMetadata: {
        actionSettingsMetadata: {
            contentSettingsMetadata: doppeActionTypeServiceActionContentSettingsMetadata,
            supportsContentSections: true
        },
        listItemsSettingsMetadata: false
    },
    resolveUsedMedia: actionSettings => {
        return actionSettings.serviceImages ?? [];
    },
    resolveMainMedia: actionSettings => {
        return actionSettings.serviceImages ? actionSettings.serviceImages[0] ?? null : null;
    },
    replaceExternalImages: (actionSettings, externalImagesUrlToImageResourceMap) => {
        return immerProduce(actionSettings, actionSettings => {
            if (actionSettings.serviceImages) {
                actionSettings.serviceImages = actionSettings.serviceImages.map(
                    image => wixMediaResourceReplaceExternalImage(image, externalImagesUrlToImageResourceMap))
            }
        })
    },
    prepareForViewer: (actionSettings, options) => {
        doppeDtoActionSettingsRemoveInnerCtaContentForLockedAction(actionSettings, actionSettings => {
            actionSettings.servicePageLink = '';
            doppeActionCtaButtonsResetSettingsForLock(actionSettings);
        }, options);
    },
    resolveMainLink: (actionSettings, htmlSiteInfo) => {
        return doppeActionCtaButtonsResolveMainURL(actionSettings, {
            htmlSiteInfo: htmlSiteInfo,
            sourceURL: actionSettings.servicePageLink
        }) ?? null;
    },
    resolveCouponData: actionSettings => {
        return {
            showCouponCode: doppeHideableValueIsVisibleAndNotEqualValue(actionSettings.couponCode, ''),
            couponCode: doppeHideableValueGetValue(actionSettings.couponCode),
            couponDescription: doppeHideableValueIsVisibleAndNotEqualValue(actionSettings.description, '') ? actionSettings.description : '',
            couponTitle: doppeHideableValueIsVisibleAndNotEqualValue(actionSettings.serviceTitle, '') ? actionSettings.serviceTitle : '',
        }
    },
    resolveUsedBusinessEntities: actionSettings => {
        return arrayRemoveNullValues([
            actionSettings.serviceProvider !== null && actionSettings.serviceProviderId !== null ?
                doppeExternalServiceProviderResolveBusinessEntityReference(
                    actionSettings.serviceProvider,
                    actionSettings.serviceProviderId
                ) :
                null
        ])
    },
});

export function doppeActionTypeServiceResolveSettingsFromBoundEntity(
    actionSettings: DoppeActionTypeService.ServiceSettings,
    doppeDtoService: DoppeDtoBookingService,
    _bymoPageProps: BymoPageProps,
): DoppeActionTypeService.ServiceSettings {

    // const location = arrayFirst(doppeDtoService.locations);

    return {
        ...actionSettings,
        // serviceSchedule: doppeDtoService.scheduale ? doppeHideableValueModifyValue(actionSettings.serviceSchedule, doppeDtoBookingServiceScheduleConvertToActionFormattedSchedule({
        //     serviceType: doppeDtoService.type,
        //     schedule: doppeDtoService.scheduale,
        //     bymoPageProps: bymoPageProps,
        // }) ?? '') : actionSettings.serviceSchedule,
        // servicePeriod: doppeDtoService.scheduale ? doppeHideableValueModifyValue(actionSettings.servicePeriod, doppeDtoBookingServiceScheduleConvertToActionFormattedDuration({
        //     serviceType: doppeDtoService.type,
        //     schedule: doppeDtoService.scheduale
        // })?.[0] ?? '') : actionSettings.servicePeriod,
        // serviceParticipants: doppeDtoService.capacity ? doppeHideableValueModifyValue(actionSettings.serviceParticipants, doppeDtoBookingServiceCapacityConvertToActionFormattedCapacity(doppeDtoService.capacity)) : actionSettings.serviceParticipants,
        // location: location ? doppeHideableValueModifyValue(actionSettings.location, doppeDtoBookingServiceLocationConvertToActionFormattedLocation(location)) : actionSettings.location,
        // priceData: doppeDtoService.payment ? doppeHideableValueModifyValue(actionSettings.priceData, {
        //     actualPrice: doppeDtoBookingServicePaymentConvertToActionFormattedPrice(doppeDtoService.payment),
        //     discountPrice: ''
        // }) : actionSettings.priceData,
        servicePageLink: stringIsNotNullableOrWhiteSpace(doppeDtoService.servicePageUrl) ?
            doppeDtoService.servicePageUrl : 'about:blank',
    }
}

export function populateServiceActionSettingsDefaultTexts(options: {
    service: DoppeActionTypeService.ActionSettings,
    pageContentLocales: DoppeAppPageContentLocales,
}) : DoppeActionTypeService.ActionSettings {

    const {
        service,
        pageContentLocales,
    } = options;

    const defaultButtonText = pageContentLocales["service.actionSettings.buttonText"]

    return  {
        ...service,
        serviceTitle: doppeHideableValueGetValue(service.serviceTitle) !== '' ? service.serviceTitle : pageContentLocales["service.actionSettings.title"],
        description: doppeHideableValueModifyValue(service.description, (value) => {
            return value !== '' ? value : pageContentLocales["service.actionSettings.description"];
        }),
        couponCode: doppeHideableValueModifyValue(service.couponCode, (value) => {
            return value !== '' ? value : pageContentLocales["service.actionSettings.couponCode"];
        }),
        ribbon: doppeHideableValueModifyValue(service.ribbon, (value) => {
            return value !== '' ? value : pageContentLocales["service.actionSettings.ribbon"];
        }),
        location: doppeHideableValueModifyValue(service.location, (value) => {
            return value !== '' ? value : pageContentLocales["service.actionSettings.location"];
        }),
        serviceParticipants: doppeHideableValueModifyValue(service.serviceParticipants, (value) => {
            return value !== '' ? value : pageContentLocales["service.actionSettings.serviceParticipants"];
        }),
        serviceSchedule: doppeHideableValueModifyValue(service.serviceSchedule, (value) => {
            return value !== '' ? value : pageContentLocales["service.actionSettings.serviceSchedule"];
        }),
        servicePeriod: doppeHideableValueModifyValue(service.servicePeriod, (value) => {
            return value !== '' ? value : pageContentLocales["service.actionSettings.servicePeriod"];
        }),
        priceData: doppeHideableValueModifyValue(service.priceData, (value) => {
            return value.actualPrice !== '' ? value : {
                actualPrice: pageContentLocales["service.actionSettings.price"],
                discountPrice: ''
            };
        }),
        sections: [],
        ...doppeActionCtaButtonsSettingsDefaultValuesMigration({
            ctaButtons: [doppeActionMultipleCTAButtonCreate({
                text: defaultButtonText,
                isRequired: false,
                settings: doppeActionCtaButtonTypeGetDefaultSettings(DoppeActionCTAType.Link, true)
            })],
        }, false),
    }
}


export function convertDoppeWixBookingServiceToServiceSettings(service: DoppeDtoBookingService, bymoPageResource?: BymoPageResource, settings?: DoppeActionTypeService.ActionSettings) {

    const durations = service.scheduale ? doppeDtoBookingServiceScheduleConvertToActionFormattedDuration({
        schedule: service.scheduale,
        serviceType: service.type
    }) : null;

    const schedule = service.scheduale ? doppeDtoBookingServiceScheduleConvertToActionFormattedSchedule({
        schedule: service.scheduale,
        serviceType: service.type,
        bymoPageProps: bymoPageResource ? bymoPageResource.getBymoPageProps() : undefined
    }) : null;

    return {
        ctaButtons: [],
        sections: [],
        couponCode: doppeHideableValueCreateHidden(''),
        ribbon: doppeHideableValueCreateHidden(''),
        ...settings,
        serviceImages: service.media,
        servicePageLink: service.servicePageUrl ?? settings?.servicePageLink ?? ``,
        priceData: service.payment ? {
            actualPrice: doppeDtoBookingServiceConvertToFormattedPrice(service.payment, ''),
            discountPrice: ''
        } : settings?.priceData ?? doppeHideableValueCreateHidden({
            actualPrice: '',
            discountPrice: ''
        }),
        serviceParticipants: service.capacity ? doppeDtoBookingServiceCapacityConvertToActionFormattedCapacity(service.capacity) : settings?.serviceParticipants ?? doppeHideableValueCreateHidden(''),
        serviceSchedule: schedule ?? settings?.serviceSchedule ?? doppeHideableValueCreateHidden(''),
        location: service.locations[0] ? service.locations.map(location => doppeDtoBookingServiceLocationConvertToActionFormattedLocation(location)).toString()  : settings?.location ?? doppeHideableValueCreateHidden(''),
        servicePeriod: durations ? durations[0] : settings?.servicePeriod ?? doppeHideableValueCreateHidden(''),
        serviceTitle: service?.name ?? settings?.serviceTitle ?? '',
        description: service.description ? service.description : settings?.description ?  settings?.description : doppeHideableValueCreateHidden(''),
        serviceProvider: DoppeExternalServiceProvider.Wix,
        serviceProviderId: service.id
    }
}