import {doppeActionTypeDefineMetadata} from '../../client-server-common/doppe-action-types/doppe-action-type';
import {urlRemoveQueryString, Values} from '@wix/devzai-utils-common';
import {DoppeHideableValue, doppeHideableValueGetValue, doppeHideableValueIsVisible} from '../../client-server-common';
import {RichTextContent} from '@wix/devzai-utils-react';
import {
    DoppeActionCtaButtonSettings,
    doppeActionCtaButtonTypeGetDefaultCtaButtonSettingsMigration,
    getDefaultDoppeActionCtaButtonSettings
} from '../../client-server-common/types/doppe-action-cta-button-settings';
import {
    doppeActionContentSectionSettingsDefaultValuesMigration,
    DoppeActionContentSectionsSettings
} from '../../client-server-common/types/doppe-action-content-sections-settings';
import {
    DoppeActionCtaButtonsSettings,
    doppeActionCtaButtonsSettingsDefaultValuesMigration
} from '../../client-server-common/types/doppe-action-cta-buttons-settings';
import {
    doppeActionCtaButtonsResolveMainURL
} from '../doppe-actions-common/doppe-cta-button-utils/doppe-cta-button-utils';
import {
    doppeDtoActionSettingsRemoveInnerCtaContentForLockedAction
} from '../../client-server-common/types/doppe-dto-action';
import {doppeActionCtaButtonsResetSettingsForLock} from '../../client-server-common/types/doppe-action-cta-button';


export const SoundcloudWidgetSize = {
    Big: 'Big',
    Normal: 'Normal',
    Compact: 'Compact',
} as const;


export namespace DoppeActionTypeSoundCloud {

    export type Size = Values<typeof SoundcloudWidgetSize>;

    export interface ActionSettings extends
        DoppeActionContentSectionsSettings,
        DoppeActionCtaButtonsSettings  {
        url: string;
        size: Size;
        title: DoppeHideableValue<string>;
        description: DoppeHideableValue<RichTextContent>;
    }

    export type DeprecatedFields = Partial<DoppeActionCtaButtonSettings> & {
        buttonText?: DoppeHideableValue<string>;
    }

    export type PartialProps = Partial<ActionSettings> & DeprecatedFields;
}

export const doppeActionTypeSoundcloud = doppeActionTypeDefineMetadata<DoppeActionTypeSoundCloud.ActionSettings, DoppeActionTypeSoundCloud.PartialProps>({
    id: 'io.bymo.action/soundcloud',
    resolveActionSettings: action => {

        const {
            buttonText = '',
            ctaButton,
            ctaButtons,
            sections,
            ...restSettings
        } = action.settings;

        return {
            url: '',
            size: SoundcloudWidgetSize.Normal,
            title: 'Title',
            description: 'Description',
            buttonText: buttonText,
            ...getDefaultDoppeActionCtaButtonSettings({
                buttonText: doppeHideableValueGetValue(buttonText),
                showButton: doppeHideableValueIsVisible(buttonText),
            }),
            ...doppeActionCtaButtonTypeGetDefaultCtaButtonSettingsMigration({
                hasSourceURL: true,
                ctaButton: ctaButton,
            }),
            ...doppeActionContentSectionSettingsDefaultValuesMigration(action.settings),
            ...doppeActionCtaButtonsSettingsDefaultValuesMigration(action.settings, true),
            ...restSettings
        }

    },
    hasNoGoalMeasuring: false,
    isWidgetOpener: true,
    canRenderInPage: true,
    skipReportingOnGoalAchievedOnActionButtonClick: true,
    hasNoLockInnerCTA: true,
    resolveMainLink: (actionSettings, htmlSiteInfo) => {
        return doppeActionCtaButtonsResolveMainURL(actionSettings, {
            htmlSiteInfo: htmlSiteInfo,
            sourceURL: actionSettings.url
        }) ?? null;
    },
    prepareForViewer: (actionSettings, options) => {
        doppeDtoActionSettingsRemoveInnerCtaContentForLockedAction(actionSettings, actionSettings => {
            actionSettings.url = '';
            doppeActionCtaButtonsResetSettingsForLock(actionSettings);
        }, options);
    },
})

export function doppeValidateSoundcloudURL(url: string) {
    return doppeValidateSoundcloudTrackURL(url) || doppeValidateSoundcloudPlaylistURL(url);
}

export function doppeValidateSoundcloudTrackURL(url: string) {
    const trackPattern = /^https:\/\/soundcloud\.com\/[\w-]+\/[\w-]+$/;
    return trackPattern.test(urlRemoveQueryString(url));
}

export function doppeValidateSoundcloudPlaylistURL(url: string) {
    const playlistPattern = /^https:\/\/soundcloud\.com\/[\w-]+\/sets\/[\w-]+$/;
    return playlistPattern.test(urlRemoveQueryString(url));
}