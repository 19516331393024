import {
    DoppeHideableValue,
    doppeHideableValueCreateHidden,
    doppeHideableValueModifyValue
} from './doppe-hideable-value';
import {RichTextContent} from '@wix/devzai-utils-react';
import {DoppeDtoBymoSubscribeListId} from './doppe-dto-bymo-subscribe-list';
import {CurrencyCode, OmitStrict} from '@wix/devzai-utils-common';

// never change this value, it is used to identify the default currency code
export const DefaultCurrencyCode = 'USD';

export interface DoppeCheckoutSettings {
    showEmailInput: boolean;
    showAddressInput: boolean;
    showPhoneInput: boolean;

    showConsent: boolean;
    showConsentCheckbox: boolean;
    customConsentText: DoppeHideableValue<string>;

    emailConfirmationMessage: DoppeHideableValue<RichTextContent>;
    customConfirmationMail: DoppeHideableValue<string>;
    confirmationPhone: DoppeHideableValue<string>;

    listId: DoppeDtoBymoSubscribeListId | null;

    customThankYouMessage: DoppeHideableValue<string>;

    withEmailNotification: boolean;
    customEmailForNotifications: DoppeHideableValue<string>;

    sendReceipt: boolean;

    currencyCode: CurrencyCode;
}

export interface DoppeCheckoutSettingsWithBindings extends OmitStrict<DoppeCheckoutSettings, 'currencyCode'>{
    title: DoppeHideableValue<string>;
}

export interface DoppeCheckoutActionSettings {
    checkoutSettings: DoppeCheckoutSettingsWithBindings;
}

export function getDefaultDoppeCheckoutActionSettings(overrides?: Partial<DoppeCheckoutSettings>) {
    return {
        checkoutSettings: getDefaultDoppeCheckoutSettingsForAction(overrides)
    } satisfies DoppeCheckoutActionSettings;
}

export function doppeCheckoutActionSettingsCalculateFromAccountSettings(
    checkoutSettings: DoppeCheckoutSettingsWithBindings,
    currencyCode: CurrencyCode
) {

    return {
        ...checkoutSettings,
        currencyCode: currencyCode
    } satisfies DoppeCheckoutSettings;
}

export function getDefaultDoppeCheckoutSettingsForAction(overrides?: Partial<DoppeCheckoutSettingsWithBindings>) {

    const {
        currencyCode,
        ...checkoutSettings
    } = doppeCheckoutActionSettingsGetDefaultValues(overrides)

    return {
        ...checkoutSettings,
        title: doppeHideableValueCreateHidden(''),

        ...overrides,
    } satisfies DoppeCheckoutSettingsWithBindings;
}

export function doppeCheckoutActionSettingsGetDefaultValues(overrides?: Partial<DoppeCheckoutSettings>) {
    return {
        showEmailInput: true,
        showAddressInput: false,
        showPhoneInput: true,

        sendReceipt: true,
        customConfirmationMail: doppeHideableValueCreateHidden(''),
        confirmationPhone: doppeHideableValueCreateHidden(''),
        emailConfirmationMessage: doppeHideableValueCreateHidden(''),
        showConsent: true,

        listId: null,
        withEmailNotification: true,
        customThankYouMessage: doppeHideableValueCreateHidden(''),
        showConsentCheckbox: false,
        customConsentText: doppeHideableValueCreateHidden(''),
        customEmailForNotifications: doppeHideableValueCreateHidden(''),

        currencyCode: DefaultCurrencyCode,
        ...overrides,
    } satisfies DoppeCheckoutSettings;
}


export function prepareCheckoutSettingsForViewer (settings: DoppeCheckoutActionSettings) {
    doppeHideableValueModifyValue(settings.checkoutSettings.customEmailForNotifications, '')
    doppeHideableValueModifyValue(settings.checkoutSettings.customConfirmationMail, '')
}

