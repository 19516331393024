import {DoppeViewerAppClientServiceBase} from "./doppe-viewer-app-client-service-base";
import {
    ConsentManagerState,
    CookieConsentManager,
    CookieStorage,
    CookieStorageAccessor,
    GlobalCookieAttributes,
    ICookieStorage
} from "@wix/devzai-utils-dom";
import {assertNotNullable, InitializableValue, isBrowserEnvironment,} from "@wix/devzai-utils-common";
import {SupportedCookieNames, SupportedCookies} from '../../client-server-common';
import {DoppeViewerClientServices} from "../doppe-user-app/doppe-viewer-client-services";

export class DoppeViewerAppCookieService extends DoppeViewerAppClientServiceBase {
    public cookieConsentManager: CookieConsentManager | undefined;
    public consentSafeCookieStorageAccessor: CookieStorageAccessor<SupportedCookieNames> | undefined;
    protected cookieStorage: ICookieStorage = new CookieStorage();

    constructor(deferredDoppeAppClientServices: InitializableValue<DoppeViewerClientServices>) {
        super(deferredDoppeAppClientServices);
    }
    protected getCookieGlobaAttributes(): GlobalCookieAttributes {
        let globalAttributes: GlobalCookieAttributes = {};

        if (!isBrowserEnvironment()) { return globalAttributes }

        const pageData = assertNotNullable(this.clientServices.bymoService.getBymoLinkViewerData()?.pageData);

        const hostname = location.hostname;
        const domains = ['doppe.com', 'hopp.bio', 'hopp.to', 'doppe-viewer-sec', 'bymo.io'];

        for (const domain of domains) {
            if (hostname.startsWith(domain) || hostname.startsWith(`www.${domain}`)) {
                return {
                    path: `/${pageData.ownerData.subdomain}`
                }
            }
        }

        return globalAttributes;
    }

    public setConsentManagerState(state: ConsentManagerState) {
        this.cookieConsentManager?.setState(state);
    };

    public initialize() {
        const cookieBannerEnabled = this.clientServices.bymoService.getBymoLinkViewerData()?.userCookieBannerSettings?.cookieBannerEnabled

        this.cookieConsentManager = new CookieConsentManager({
            globalCookieAttributes: this.getCookieGlobaAttributes(),
            consentState: cookieBannerEnabled ? 'active': 'inactive',
            cookieStorage: this.cookieStorage
        });

        this.consentSafeCookieStorageAccessor = new CookieStorageAccessor({
            cookieConsentManager: this.cookieConsentManager,
            supportedCookies: SupportedCookies,
            cookieStorage: this.cookieStorage
        })
    }
}