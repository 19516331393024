import {OperationsBatch} from '../operations-batch/operations-batch';
import {EventEmitter, EventReference, immerProduce} from '../index';

export interface ResourceConstructorOptions<DATA> {
    initialValue: DATA;
    onUpdate?: (resource: IResource<DATA>) => void;
}

interface UpdatedResourceEntry {
    modificationCallback?: () => void;
    prevValue: any;
}

const resourcesUpdateOperationsBatch = new OperationsBatch({
    dataInitializer: () => {
        return new Map<Resource<any>, UpdatedResourceEntry[]>();
    },
    onBatchEnded: batch => {
        for (const [resource, batchedUpdates] of batch.data.entries()) {

            for (const batchedUpdate of batchedUpdates) {
                const {
                    modificationCallback
                } = batchedUpdate;

                if (modificationCallback) {
                    modificationCallback();
                }
            }

            resource.handleResourceUpdated();
        }
    }
});

export interface IResourcesAccessTracker {
    finish () : Iterable<IVersionedResource>;
}

interface IResourcesAccessTrackerInternal extends IResourcesAccessTracker {
    accessedResources: Set<IVersionedResource>;
}

const resourcesAccessTrackers = new Set<IResourcesAccessTrackerInternal>();

export interface ResourceEvents {
    eventUpdated: void;
}

export interface IVersionedResource {
    getVersion () : number;
}

export interface IResource<DATA> extends IVersionedResource {
    getValue () : DATA;

    setValue (value: DATA) : void;

    update (
        updateFunction: (data: DATA) => DATA,
        modificationCallback?: () => void
    ) : boolean;

    modify (modificationFunc: (obj: DATA) => void) : void

    eventUpdated: EventReference<ResourceEvents, 'eventUpdated'>;
}

export class Resource<DATA> implements IResource<DATA> {

    private currentValue: DATA;
    private version = 0;
    private eventEmitter = new EventEmitter<ResourceEvents>();
    private onUpdate;

    constructor (options: ResourceConstructorOptions<DATA>) {

        const {
            initialValue,
            onUpdate
        } = options;

        this.currentValue = initialValue;
        this.onUpdate = onUpdate;
    }

    public get eventUpdated () { return this.eventEmitter.createEventReference('eventUpdated') }

    public getValue () {

        for (const accessTracker of resourcesAccessTrackers) {
            accessTracker.accessedResources.add(this);
        }

        return this.currentValue;
    }

    public getVersion () {
        return this.version;
    }

    public modify (modificationFunc: (obj: DATA) => void) {
        this.update(immerProduce(obj => {
            modificationFunc(obj);
        }))
    }

    public setValue (newValue: DATA) {
        this.update(() => newValue);
    }

    protected onBeforeUpdatingValue (_updatedValue: DATA, _prevValue: DATA) {
        return true;
    }

    public update (
        updateFunction: (data: DATA) => DATA,
        modificationCallback?: () => void
    ) : boolean {
        const prevValue = this.currentValue;

        const updatedValue = updateFunction(prevValue);

        if (updatedValue !== prevValue) {

            const shouldProceedWithUpdate = this.onBeforeUpdatingValue(updatedValue, prevValue);

            if (!shouldProceedWithUpdate) {
                return false;
            }

            this.currentValue = updatedValue;

            if (resourcesUpdateOperationsBatch.isActive()) {
                const updatedResourcesMap = resourcesUpdateOperationsBatch.data;

                let batchedUpdates = updatedResourcesMap.get(this);
                if (batchedUpdates === undefined) {
                    batchedUpdates = [];
                    updatedResourcesMap.set(this, batchedUpdates);
                }

                batchedUpdates.push({
                    modificationCallback: modificationCallback,
                    prevValue: prevValue
                })

            } else {
                if (modificationCallback) {
                    modificationCallback();
                }

                this.handleResourceUpdated();
            }

            return true;
        } else {
            return false;
        }
    }

    public handleResourceUpdated () {
        this.version++;
        this.eventEmitter.emit('eventUpdated');
        this.onUpdate?.(this);
    }
}

export function resourcesUpdateBatch<R> (updateFunction: () => R) : R {

    return resourcesUpdateOperationsBatch.batchScope(updateFunction);
}

export function resourceCreate<DATA> (options: ResourceConstructorOptions<DATA>) : IResource<DATA> {
    return new Resource<DATA>(options);
}

export function resourcesTrackAccess () : IResourcesAccessTracker {

    const accessTracker: IResourcesAccessTrackerInternal = {
        accessedResources: new Set<IVersionedResource>(),
        finish() {
            const result = accessTracker.accessedResources;

            resourcesAccessTrackers.delete(accessTracker);

            return result.values();
        }
    };

    resourcesAccessTrackers.add(accessTracker);

    return accessTracker;
}
