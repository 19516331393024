import {doppeActionTypeDefineViewerMetadata} from '../../client-server-common/doppe-action-types/doppe-action-type';
import React from 'react';
import {doppeActionTypeProperty} from './doppe-action-type-property';

export const doppeActionTypePropertyViewer = doppeActionTypeDefineViewerMetadata(doppeActionTypeProperty, {

    loadViewerComponents: async () => {
        return {
            DoppeActionTypePropertyWidget: (await import(
                './doppe-action-type-property-widget/doppe-action-type-property-widget'
                )).DoppeActionTypePropertyWidget
        }
    },

    renderWidget: (renderProps, viewerComponents) => {

        const {
            DoppeActionTypePropertyWidget
        } = viewerComponents;

        return (
            <DoppeActionTypePropertyWidget
                {...renderProps}
            />
        );
    },
})