import {doppeActionTypeDefineMetadata} from '../../client-server-common/doppe-action-types/doppe-action-type';
import {
    doppeHideableValueCreateHidden,
    doppeHideableValueIsVisibleAndNotEqualValue
} from '../../client-server-common/types/doppe-hideable-value';
import {
    arrayFlatten,
    arrayRemoveNullValues,
    asArray,
    immerProduce,
    OmitStrict,
    PartiallyOptional,
    valueConvertWhenNotNullable
} from '@wix/devzai-utils-common';
import {
    bymoPageMediaReplaceExternalImage,
    bymoPageMediaResolveWixMediaResource,
    DoppeActionLayout,
    doppeListActionContentSettingsMetadataCreateForListActionWithMainDetails,
    doppeListActionGetDefaultListSettings,
    doppeListActionGetDefaultSearchListSettings,
    doppeListActionGetItemClickBehaviourDefaultListSettings,
    doppeListActionGetMainDetailsDefaultSettings,
    doppeListActionItemCtaButtonsResetSettingsForLock,
    doppeListActionListItemCalculateItemButtons,
    doppeListActionListItemGetDefaultListItemSettingsMigration,
    doppeListActionListItemWithCtaButtonsMigration,
    doppeListActionListItemWithSectionsMigration,
    DoppeListActionSettings,
    doppeListActionSettingsFilterHiddenListItemsFromActionView,
    doppeListActionTemplateCtaButtonsSettingsDefaultValuesMigration,
    doppeListActionTemplateSectionsSettingsDefaultValuesMigration,
    doppeListActionWithMainDetailsGetMainImage,
    doppeListActionWithMainDetailsReplaceMainImage,
} from '../../client-server-common';
import {doppeActionCtaButtonsResetSettingsForLock} from '../../client-server-common/types/doppe-action-cta-button';
import {
    doppeDtoActionSettingsRemoveInnerCtaContentForLockedAction
} from '../../client-server-common/types/doppe-dto-action';
import {
    DoppeActionTypeProperty,
    doppeActionTypePropertyActionContentSettingsMetadata
} from '../doppe-action-type-property/doppe-action-type-property';
import {
    doppeActionContentSectionSettingsDefaultValuesMigration,
    DoppeActionContentSectionsSettings
} from '../../client-server-common/types/doppe-action-content-sections-settings';
import {
    doppeActionGetMediaViewDefaultSettingsMigration
} from '../../client-server-common/types/doppe-action-media-view-settings';
import {
    DoppeActionCtaButtonsSettings,
    doppeActionCtaButtonsSettingsDefaultValuesMigration
} from '../../client-server-common/types/doppe-action-cta-buttons-settings';
import {
    doppeActionCtaButtonsResolveMainURL
} from '../doppe-actions-common/doppe-cta-button-utils/doppe-cta-button-utils';


export namespace DoppeActionTypePropertiesList {

    export interface PropertyItemSettings extends
        DoppeListActionSettings.ListItemBaseSettings,
        DoppeListActionSettings.ListItemWithSections,
        DoppeListActionSettings.ListItemWithCtaButtons,
        OmitStrict<DoppeActionTypeProperty.ActionSettings, 'sections' | 'ctaButtons'> {
        propertyName: string;
    }

    export interface ActionSettings extends
        DoppeListActionSettings<DoppeActionTypePropertiesList.PropertyItemSettings>,
        DoppeListActionSettings.WithSearchSettings,
        DoppeListActionSettings.WithMainDetails,
        DoppeActionContentSectionsSettings,
        DoppeActionCtaButtonsSettings,
        DoppeListActionSettings.WithItemClickBehaviour {
        itemShowPrice: boolean;
        previewShowTitle: boolean;
        previewShowDescription: boolean;
        previewShowPrice: boolean;
        previewShowAddress: boolean;
    }

}


export const doppeActionTypePropertiesList = doppeActionTypeDefineMetadata<DoppeActionTypePropertiesList.ActionSettings>({
    id: 'co.hopp.action/properties-list',
    resolveActionSettings: action => {
        const {
            sections,
            listDataItems = [],
            ctaButtons,
            listItemTemplateCtaButtonsSettings,
            listItemTemplateSectionsSettings,
            listSearchSettings,
            ...restSettings
        } = action.settings;

        return {
            itemShowPrice: true,
            previewShowTitle: true,
            previewShowDescription: true,
            previewShowAddress: true,
            previewShowPrice: true,
            ...doppeListActionGetItemClickBehaviourDefaultListSettings(),
            ...doppeListActionGetMainDetailsDefaultSettings(),
            ...doppeListActionGetDefaultSearchListSettings(action.settings),
            ...doppeListActionGetDefaultListSettings({
                layout: DoppeActionLayout.Column,
                listDataItems: listDataItems.map(property => {

                    const {
                        ctaButtonsOverridesMap,
                        customCtaButtons,
                        customSections,
                        sectionsOverridesMap,
                        mainMediaViewSettings,
                        mainMediaViewLayout,
                        id,
                        deleted,
                        displayInSearch,
                        enabled,
                        ...restProduct
                    } = property;

                    return {
                        ...doppeActionGetMediaViewDefaultSettingsMigration(property),
                        ...doppeListActionListItemWithCtaButtonsMigration(property, false),
                        ...doppeListActionListItemWithSectionsMigration(property),
                        ...doppeListActionListItemGetDefaultListItemSettingsMigration(property.id, property),
                        ...restProduct as PartiallyOptional<DoppeActionTypePropertiesList.PropertyItemSettings, 'mainMediaViewSettings' | 'mainMediaViewLayout' | 'sectionsOverridesMap' | 'ctaButtonsOverridesMap' |'customCtaButtons' | 'customSections'>
                    }
                }),
            }),
            ...doppeActionContentSectionSettingsDefaultValuesMigration(action.settings),
            ...doppeActionCtaButtonsSettingsDefaultValuesMigration(action.settings, false),
            ...doppeListActionTemplateCtaButtonsSettingsDefaultValuesMigration(action.settings, false),
            ...doppeListActionTemplateSectionsSettingsDefaultValuesMigration(action.settings),
            ...restSettings
        }
    },
    prepareForViewer: (actionSettings, options) => {
        doppeDtoActionSettingsRemoveInnerCtaContentForLockedAction(actionSettings, actionSettings => {
            doppeListActionItemCtaButtonsResetSettingsForLock(actionSettings);
            doppeActionCtaButtonsResetSettingsForLock(actionSettings);
        }, options);
    },
    isWidgetOpener: true,
    canRenderInPage: true,
    skipReportingOnGoalAchievedOnActionButtonClick: true,
    isPaymentAction: false,
    isFormAction: false,
    isListItemLinkOpener: false,
    supportsListLayout: true,
    settingsMetadata: {
        actionSettingsMetadata: {
            contentSettingsMetadata: {
                ...doppeListActionContentSettingsMetadataCreateForListActionWithMainDetails()
            },
            supportsContentSections: true
        },
        listItemsSettingsMetadata: {
            contentSettingsMetadata: doppeActionTypePropertyActionContentSettingsMetadata,
            supportsContentSections: true
        }
    },
    resolveUsedMedia: actionSettings => {
        return arrayRemoveNullValues([...arrayFlatten(actionSettings.listDataItems.map(itemSettings => {
            return itemSettings.media ?? []
        })), doppeHideableValueIsVisibleAndNotEqualValue(actionSettings.mainImage, null) ? actionSettings.mainImage : null]);
    },
    resolveMainMedia: actionSettings => {
        return doppeListActionWithMainDetailsGetMainImage(actionSettings, actionSettings.listDataItems[0]?.media?.[0] ?? null);
    },
    resolveMainLink: (actionSettings, htmlSiteInfo) => {
        return doppeActionCtaButtonsResolveMainURL(actionSettings, {
            htmlSiteInfo: htmlSiteInfo
        }) ?? null;
    },
    resolveListItemsCount: actionSettings => {
        return doppeListActionSettingsFilterHiddenListItemsFromActionView(actionSettings.listDataItems).length;
    },
    resolveListItems: (action, htmlSiteInfo) => {
        const actionSettings = action.settings;
        return actionSettings.listDataItems.map(item => {
            return {
                url: doppeActionCtaButtonsResolveMainURL(
                    doppeListActionListItemCalculateItemButtons(actionSettings.listItemTemplateCtaButtonsSettings, item), {
                        htmlSiteInfo: htmlSiteInfo,
                    }) ?? null,
                title: item.propertyName,
                action: action,
                couponCode: doppeHideableValueCreateHidden(''),
                description: item.description,
                image: valueConvertWhenNotNullable(item.media ? asArray(item.media)[0] : null, bymoPageMediaResolveWixMediaResource),
                itemId: item.id,
                ribbon: item.ribbon,
                enabled: item.enabled,
                deleted: item.deleted,
                displayInSearch: item.displayInSearch
            }
        });
    },
    resolveListSearchSettings: actionSettings => {
        return actionSettings.listSearchSettings;
    },
    replaceExternalImages: (actionSettings, externalImagesUrlToImageResourceMap) => {
        return immerProduce(actionSettings, actionSettings => {
            for (const item of actionSettings.listDataItems) {
                if (item.media) {
                    const images = asArray(item.media);
                    item.media = images.map(image => {
                        return bymoPageMediaReplaceExternalImage(image, externalImagesUrlToImageResourceMap)
                    });
                }
            }

            doppeListActionWithMainDetailsReplaceMainImage(actionSettings, externalImagesUrlToImageResourceMap);
        })
    },
});
