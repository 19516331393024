import {doppeActionTypeDefineMetadata} from '../../client-server-common/doppe-action-types/doppe-action-type';
import {
    arrayFlatten,
    arrayRemoveNullValues,
    asArray,
    immerProduce,
    iterableMapToArray,
    OmitStrict,
    PartiallyOptional
} from '@wix/devzai-utils-common';
import {wixMediaResourceReplaceExternalImage} from '@wix/devzai-common-wix';
import {
    DoppeActionLayout,
    DoppeDtoStoreProduct,
    doppeExternalProductProviderResolveBusinessEntityReference,
    doppeHideableValueGetVisibleValue,
    doppeHideableValueIsVisibleAndNotEqualValue,
    doppeListActionGetDefaultListSettings,
    doppeListActionGetDefaultSearchListSettings,
    doppeListActionGetItemClickBehaviourDefaultListSettings,
    doppeListActionGetMainDetailsDefaultSettings,
    doppeListActionGetWithExternalDataSourceDefaultListSettings,
    doppeListActionItemCtaButtonsResetSettingsForLock,
    doppeListActionListItemGetDefaultListItemSettingsMigration,
    doppeListActionListItemWithCtaButtonsMigration,
    DoppeListActionSettings,
    DoppeListActionSettingsCtaButtonAutoValue,
    doppeListActionSettingsFilterHiddenListItemsFromActionView,
    doppeListActionTemplateCtaButtonsSettingsDefaultValuesMigration,
    doppeListActionWithMainDetailsGetMainImage,
    doppeListActionWithMainDetailsReplaceMainImage,
    DoppeProductsExternalDataSource,
    doppeProductsExternalDataSourceResolveBusinessEntityReferences
} from '../../client-server-common';
import {
    doppeDtoActionSettingsRemoveInnerCtaContentForLockedAction
} from '../../client-server-common/types/doppe-dto-action';
import {
    doppeActionCtaButtonsResetSettingsForLock
} from '../../client-server-common/types/doppe-action-cta-button';
import {
    convertDoppeWixProductToStoreProductSettings,
    DoppeActionTypeStoreProduct
} from '../doppe-action-type-store-product/doppe-action-type-store-product';
import {
    doppeActionContentSectionSettingsDefaultValuesMigration,
    DoppeActionContentSectionsSettings
} from '../../client-server-common/types/doppe-action-content-sections-settings';
import {
    DoppeActionCtaButtonsSettings,
    doppeActionCtaButtonsSettingsDefaultValuesMigration
} from '../../client-server-common/types/doppe-action-cta-buttons-settings';
import {
    doppeActionGetMediaViewDefaultSettingsMigration
} from '../../client-server-common/types/doppe-action-media-view-settings';
import {
    doppeActionCtaButtonsResolveMainURL
} from '../doppe-actions-common/doppe-cta-button-utils/doppe-cta-button-utils';

export namespace DoppeActionTypeStoreProducts {

    export interface ProductSettings extends
        DoppeListActionSettings.ListItemBaseSettings,
        DoppeListActionSettings.ListItemWithCtaButtons,
        OmitStrict<DoppeActionTypeStoreProduct.ActionSettings, 'ctaButtons'> {
    }

    export interface ActionSettings extends
        DoppeListActionSettings<ProductSettings>,
        DoppeActionContentSectionsSettings,
        DoppeActionCtaButtonsSettings,
        DoppeListActionSettings.WithSearchSettings,
        DoppeListActionSettings.WithMainDetails,
        DoppeListActionSettings.WithItemClickBehaviour,
        DoppeListActionSettings.WithExternalDataSourceSupport<DoppeProductsExternalDataSource> {
        itemShowPrice: boolean;
        previewShowTitle: boolean;
        previewShowDescription: boolean;
        previewShowPrice: boolean;
        previewShowCoupon: boolean;
    }
}

export const doppeActionTypeStoreProducts = doppeActionTypeDefineMetadata<DoppeActionTypeStoreProducts.ActionSettings>({
    id: 'io.bymo.action/store-products',
    prepareForViewer: (actionSettings, options) => {
        doppeDtoActionSettingsRemoveInnerCtaContentForLockedAction(actionSettings, actionSettings => {
            doppeListActionItemCtaButtonsResetSettingsForLock(actionSettings);
            doppeActionCtaButtonsResetSettingsForLock(actionSettings);
        }, options);
    },
    resolveActionSettings: action => {

        const {
            sections,
            listDataItems = [],
            ctaButtons,
            listItemTemplateCtaButtonsSettings,
            listSearchSettings,
            ...restSettings
        } = action.settings;

        return {
            itemShowPrice: true,
            itemShowDate: false,
            previewShowCoupon: true,
            previewShowPrice: true,
            previewShowDescription: true,
            previewShowTitle: true,
            ...doppeListActionGetWithExternalDataSourceDefaultListSettings(),
            ...doppeListActionGetItemClickBehaviourDefaultListSettings(),
            ...doppeListActionGetMainDetailsDefaultSettings(),
            ...doppeListActionGetDefaultSearchListSettings(action.settings),
            ...doppeListActionGetDefaultListSettings({
                layout: DoppeActionLayout.Column,
                listDataItems: listDataItems.map(product => {

                    const {
                        ctaButtonsOverridesMap,
                        customCtaButtons,
                        mainMediaViewSettings,
                        mainMediaViewLayout,
                        id,
                        deleted,
                        displayInSearch,
                        enabled,
                        ...restProduct
                    } = product;

                    return {
                        ...doppeActionGetMediaViewDefaultSettingsMigration(product),
                        ...doppeListActionListItemWithCtaButtonsMigration(product, false),
                        ...doppeListActionListItemGetDefaultListItemSettingsMigration(product.id, product),
                        ...restProduct as PartiallyOptional<DoppeActionTypeStoreProducts.ProductSettings, 'mainMediaViewSettings' | 'mainMediaViewLayout'  | 'ctaButtonsOverridesMap' |'customCtaButtons'>
                    }
                }),
            }),
            ...doppeActionContentSectionSettingsDefaultValuesMigration(action.settings),
            ...doppeActionCtaButtonsSettingsDefaultValuesMigration(action.settings, false),
            ...doppeListActionTemplateCtaButtonsSettingsDefaultValuesMigration(action.settings, false),
            ...restSettings
        }
    },
    isWidgetOpener: true,
    canRenderInPage: true,
    skipReportingOnGoalAchievedOnActionButtonClick: true,
    isListItemLinkOpener: true,
    supportsListLayout: true,
    resolveUsedMedia: actionSettings => {
        return arrayRemoveNullValues([...arrayFlatten(iterableMapToArray(actionSettings.listDataItems, (product, skip) => {
            const mediaItems = arrayRemoveNullValues(asArray(doppeHideableValueGetVisibleValue(product.productImage, []) ?? []));
            return mediaItems.length > 0 ? mediaItems : skip;
        })), doppeHideableValueIsVisibleAndNotEqualValue(actionSettings.mainImage, null) ? actionSettings.mainImage : null]);
    },
    resolveMainMedia: actionSettings => {
        const product = actionSettings.listDataItems[0];
        const mediaItems = product ? arrayRemoveNullValues(asArray(doppeHideableValueGetVisibleValue(product.productImage, []) ?? [])) : [];
        return doppeListActionWithMainDetailsGetMainImage(actionSettings, mediaItems[0]);
    },
    resolveMainLink: (actionSettings, htmlSiteInfo) => {
        return doppeActionCtaButtonsResolveMainURL(actionSettings, {
            htmlSiteInfo: htmlSiteInfo
        }) ?? null;
    },
    isConnectedToExternalDataSource: actionSettings => {
        return !!actionSettings.externalDataSource
    },
    resolveUsedBusinessEntities: actionSettings => {
        const externalDataSource = actionSettings.externalDataSource;
        if (externalDataSource) {
            return [
                doppeProductsExternalDataSourceResolveBusinessEntityReferences(externalDataSource)
            ];
        } else {
            return iterableMapToArray(actionSettings.listDataItems, (product, skip) => {
                return product.productProvider !== null && product.productProviderId !== null ?
                    doppeExternalProductProviderResolveBusinessEntityReference(
                        product.productProvider,
                        product.productProviderId
                    ) : skip
            });
        }
    },
    resolveListItemsCount: actionSettings => {
        return actionSettings.externalDataSource ? actionSettings.externalDataSourceItemsCount : doppeListActionSettingsFilterHiddenListItemsFromActionView(actionSettings.listDataItems).length;
    },
    resolveCouponDataFromListItem: listItem => {
        return {
            showCouponCode: doppeHideableValueIsVisibleAndNotEqualValue(listItem.couponCode, ''),
            couponCode: doppeHideableValueGetVisibleValue(listItem.couponCode, ''),
            couponDescription: '',
            couponTitle: doppeHideableValueGetVisibleValue(listItem.title, ''),
        }
    },
    resolveListItems: () => {
        return [];
        // const actionSettings = action.settings;
        // return actionSettings.listDataItems.map(item => {
        //
        //     const image = arrayRemoveNullValues(asArray(doppeHideableValueGetVisibleValue(item.productImage, []) ?? []))[0] ?? null;
        //
        //     return {
        //         title: doppeHideableValueGetVisibleValue(item.productTitle, ''),
        //         action: action,
        //         couponCode: item.couponCode,
        //         description: item.description,
        //         image: image,
        //         itemId: item.id,
        //         ribbon: item.ribbon,
        //         url: null,
        //         enabled: item.enabled,
        //         deleted: item.deleted,
        //         displayInSearch: item.displayInSearch,
        //     }
        // });
    },
    resolveListSearchSettings: actionSettings => {
        return actionSettings.listSearchSettings;
    },
    replaceExternalImages: (actionSettings, externalImagesUrlToImageResourceMap) => {
        return immerProduce(actionSettings, actionSettings => {
            for (const item of actionSettings.listDataItems) {
                if (item.productImage) {
                    const images = item.productImage?? [];
                    item.productImage = images.map(image => {
                        return wixMediaResourceReplaceExternalImage(image, externalImagesUrlToImageResourceMap)
                    })
                }
            }

            doppeListActionWithMainDetailsReplaceMainImage(actionSettings, externalImagesUrlToImageResourceMap);
        })
    },
});


export function convertDoppeWixProductToStoreProductsSettings(
    product: DoppeDtoStoreProduct,
): DoppeActionTypeStoreProducts.ProductSettings {
    const productSettings = convertDoppeWixProductToStoreProductSettings({
        storeProduct: product,
    });

    const {
        ctaButtons,
        ...restProductSettings
    } = productSettings;

    const newProductSettings = {
        ...restProductSettings,
        customCtaButtons: [DoppeListActionSettingsCtaButtonAutoValue.ListTemplateButtons],
        ctaButtonsOverridesMap: {}
    }

    return {
        ...newProductSettings,
        ...doppeListActionListItemGetDefaultListItemSettingsMigration(product.id),
        ...doppeListActionListItemWithCtaButtonsMigration(newProductSettings, false),
    }
}