import {Values} from '@wix/devzai-utils-common';

export const DoppeAutomationTriggers = {
    ContactAdded: 'hopp-contact_added',
    SubscribeFormSubmitted: 'hopp-subscribe_form_submitted',
    ContactFormSubmitted: 'hopp-contact_form_submitted',
    PaymentReceived: 'hopp-payment_received',
} as const;

export type DoppeAutomationTriggers = Values<typeof DoppeAutomationTriggers>;

export type DoppeAutomationTriggerData =
    | DoppeAutomationTriggerData.ContactAdded
    | DoppeAutomationTriggerData.ContactFormSubmitted
    | DoppeAutomationTriggerData.SubscribeFormSubmitted
    | DoppeAutomationTriggerData.PaymentReceived;

export namespace DoppeAutomationTriggerData {
    type BaseType<T extends DoppeAutomationTriggers> = {
        triggerKey: T;
        actionId: string;
        contactList?: string;
        contactId: string;
        isNewContact: boolean;
    };

    type Payment = {
        paymentId: string;
        price: {
            value: string;
            currency: string;
        };
    };

    type Message = {
        message?: string;
    };

    export type ContactAdded = BaseType<typeof DoppeAutomationTriggers.ContactAdded>;

    export type ContactFormSubmitted = BaseType<typeof DoppeAutomationTriggers.ContactFormSubmitted> & Message;

    export type SubscribeFormSubmitted = BaseType<typeof DoppeAutomationTriggers.SubscribeFormSubmitted>;

    export type PaymentReceived = BaseType<typeof DoppeAutomationTriggers.PaymentReceived> & Message & Payment;
}
