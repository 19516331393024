import {WixImageInfo, WixMembershipPlan} from '@wix/devzai-common-wix';
import {CurrencyCode, currencyFormatPrice, currencyGetAllCodes} from '@wix/devzai-utils-common';


export interface DoppeDtoMembershipPlan {
    id: string;
    name: string;
    description?: string;
    media: WixImageInfo | null;
    membershipPageUrl?: string;
    createdDate: Date | null;
    perks: string[];
    pricing: WixMembershipPlan.Pricing | null;

}

export namespace DoppeDtoMembershipPlan {
    export type Id = DoppeDtoMembershipPlan['id'];
}

export function doppeDtoMembershipPlanPaymentConvertToActionFormattedPrice<EmptyValue>(priceData: WixMembershipPlan.Pricing, emptyValue: EmptyValue): string | EmptyValue {
    const currency = priceData.price?.currency as CurrencyCode;
    const price = priceData.price?.value ? parseFloat(priceData.price?.value) : NaN;

    return !isNaN(price) && (currency && currencyGetAllCodes().includes(currency)) ? currencyFormatPrice({
        code: currency,
        price: price
    }) : (priceData.price?.formattedValue ?? emptyValue)
}
