import {
    BymoPageActionMediaLayout,
    BymoPageActionMediaSize,
    BymoPageActionMediaSizing,
    BymoPageActionMediaViewLayout,
    BymoPageActionMediaViewSettings
} from '../bymo-pages';
import {HtmlObjectHorizontalPosition, HtmlObjectVerticalPosition} from '@wix/devzai-utils-common';

export interface DoppeActionMediaViewSettings {
    mainMediaViewSettings: BymoPageActionMediaViewSettings;
    mainMediaViewLayout: BymoPageActionMediaViewLayout;
}

export function doppeActionGetMediaViewDefaultSettingsMigration (
    settings: Partial<DoppeActionMediaViewSettings>
) {
    return {
        mainMediaViewSettings: {
            mediaLayout: BymoPageActionMediaLayout.Cropped,
            mediaSizing: BymoPageActionMediaSizing.Custom,
            size: BymoPageActionMediaSize.Ratio3By2,
            alignment: {
                horizontal: HtmlObjectHorizontalPosition.Center,
                vertical: HtmlObjectVerticalPosition.Center
            },
            ...settings.mainMediaViewSettings,
        },
        mainMediaViewLayout: settings.mainMediaViewLayout ?? BymoPageActionMediaViewLayout.WithMargins,
    } satisfies DoppeActionMediaViewSettings;
}

