import {AutoValue, autoValueCreate, autoValueIsAutoValue, Values} from '@wix/devzai-utils-common';
import {doppeActionTypeDefineMetadata} from '../../client-server-common/doppe-action-types/doppe-action-type';
import {
    DoppeHideableValue,
    doppeHideableValueCreateHidden,
    doppeHideableValueGetValue,
    doppeHideableValueIsVisible
} from '../../client-server-common/types/doppe-hideable-value';
import {WixMediaResource} from '@wix/devzai-common-wix';
import {RichTextContent} from '@wix/devzai-utils-react';
import {
    doppeDtoActionSettingsRemoveInnerCtaContentForLockedAction
} from '../../client-server-common/types/doppe-dto-action';
import {doppeActionCtaButtonsResetSettingsForLock} from '../../client-server-common/types/doppe-action-cta-button';
import {
    DoppeActionCtaButtonSettings,
    doppeActionCtaButtonTypeGetDefaultCtaButtonSettingsMigration,
    getDefaultDoppeActionCtaButtonSettings
} from '../../client-server-common/types/doppe-action-cta-button-settings';
import {
    doppeActionContentSectionSettingsDefaultValuesMigration,
    DoppeActionContentSectionsSettings
} from '../../client-server-common/types/doppe-action-content-sections-settings';
import {
    DoppeActionCtaButtonsSettings,
    doppeActionCtaButtonsSettingsDefaultValuesMigration
} from '../../client-server-common/types/doppe-action-cta-buttons-settings';
import {doppeBymoPageVideoSourceResolveVideoUrl} from '../../client-server-common';
import {
    doppeActionCtaButtonsResolveMainURL
} from '../doppe-actions-common/doppe-cta-button-utils/doppe-cta-button-utils';

export const YoutubeEmbedType = {
    VideoUrl: 'VideoUrl',
    LatestVideo: 'LatestVideo',
} as const;


export namespace DoppeActionTypeYoutubeVideo {

    export type YoutubeEmbedType = Values<typeof YoutubeEmbedType>;

    export const DoppeActionTypeYoutubeVideoURLAutoSource = {
        SameAsVideoURL: 'SameAsVideoURL',
    } as const;

    export type DoppeActionTypeYoutubeVideoURLAutoSource = Values<typeof DoppeActionTypeYoutubeVideoURLAutoSource>;
    export type DoppeActionTypeYoutubeVideoURLSource = AutoValue<DoppeActionTypeYoutubeVideoURLAutoSource> | string;

    export interface ActionSettings extends
        DoppeActionContentSectionsSettings,
        DoppeActionCtaButtonsSettings {
        url: string;
        type: YoutubeEmbedType;
        title: DoppeHideableValue<string>;
        description: DoppeHideableValue<RichTextContent>;
        videoPlayerSettings: {
            autoplay: boolean;
            muteVideo: boolean;
        },
        videoImage: WixMediaResource | null;
    }

    export type DeprecatedFields = Partial<DoppeActionCtaButtonSettings> & {
        videoButtonText?: DoppeHideableValue<string>;
        videoButtonURL?: DoppeActionTypeYoutubeVideoURLSource;
    }

    export type PartialProps = Partial<ActionSettings> & DeprecatedFields;
}

export const doppeActionTypeYoutubeVideo = doppeActionTypeDefineMetadata<DoppeActionTypeYoutubeVideo.ActionSettings, DoppeActionTypeYoutubeVideo.PartialProps>({
    id: 'io.bymo.action/youtube-video',
    resolveActionSettings: action => {

        const {
            videoButtonText,
            videoButtonURL,
            ctaButton,
            ctaButtons,
            sections,
            ...restSettings
        } = action.settings;

        const buttonText = videoButtonText ?? doppeHideableValueCreateHidden('');

        return {
            url: '',
            type: YoutubeEmbedType.VideoUrl,
            videoPlayerSettings: {
                autoplay: true,
                muteVideo: false
            },
            title: doppeHideableValueCreateHidden(''),
            description: doppeHideableValueCreateHidden(''),
            videoButtonText: buttonText,
            videoImage: null,
            videoButtonURL: autoValueCreate(DoppeActionTypeYoutubeVideo.DoppeActionTypeYoutubeVideoURLAutoSource.SameAsVideoURL),
            ...getDefaultDoppeActionCtaButtonSettings({
                buttonText: doppeHideableValueGetValue(buttonText),
                showButton: doppeHideableValueIsVisible(buttonText),
                url: videoButtonURL ? (autoValueIsAutoValue(videoButtonURL) ? undefined : videoButtonURL) : undefined
            }),
            ...doppeActionCtaButtonTypeGetDefaultCtaButtonSettingsMigration({
                hasSourceURL: true,
                ctaButton: ctaButton,
            }),
            ...doppeActionContentSectionSettingsDefaultValuesMigration(action.settings),
            ...doppeActionCtaButtonsSettingsDefaultValuesMigration(action.settings, true),
            ...restSettings
        }
    },
    prepareForViewer: (actionSettings, options) => {
        doppeDtoActionSettingsRemoveInnerCtaContentForLockedAction(actionSettings, actionSettings => {
            actionSettings.url = ''
            doppeActionCtaButtonsResetSettingsForLock(actionSettings);
        }, options);
    },
    resolveMainLink: (actionSettings, htmlSiteInfo) => {
        const normalizedURL = doppeBymoPageVideoSourceResolveVideoUrl(actionSettings.url);
        return typeof actionSettings.url === 'string'  ? normalizedURL : doppeActionCtaButtonsResolveMainURL(actionSettings, {
            htmlSiteInfo: htmlSiteInfo,
            sourceURL: normalizedURL
        }) ?? null;
    },
    isWidgetOpener: true,
    isLinkOpener: true,
    canRenderInPage: true,
})

