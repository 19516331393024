import {
    AutoValue,
    autoValueCreate,
    autoValueIsAutoValue,
    stringTrimPrefix,
    uuidCreate,
    Values
} from '@wix/devzai-utils-common';
import {
    DoppeHideableValue,
    doppeHideableValueCreateHidden,
    doppeHideableValueGetVisibleValue
} from './doppe-hideable-value';
import {DoppeActionGoalType} from '../doppe-action-types/doppe-action-type';
import {WixImageResource} from '@wix/devzai-common-wix';
import {RichTextContent} from '@wix/devzai-utils-react';
import {DoppeActionCtaButtonSettings} from './doppe-action-cta-button-settings';
import {DoppeActionPrice} from './doppe-action-price';
import {
    DoppeCheckoutSettingsWithBindings,
    getDefaultDoppeCheckoutSettingsForAction
} from './doppe-checkout-action-settings';
import {DoppeActionCtaButtonsSettings} from './doppe-action-cta-buttons-settings';

import {DoppeHtmlSitePagesType} from './doppe-html-site';


export const DoppeActionCTAType = {
    Link: 'Link',
    Whatsapp: 'Whatsapp',
    Call: 'Call',
    Navigate: 'Navigate',
    Email: 'Email',
    FacebookMessage: 'FacebookMessage',
    SaveContact: 'SaveContact',
    Share: 'Share',
    Checkout: 'Checkout',
    OpenProduct: 'OpenProduct',
    OpenPage: 'OpenPage',
} as const;

export type DoppeActionCTAType = Values<typeof DoppeActionCTAType>;

export const DoppeActionCTATypeLinkAutoSource = {
    SameAsSourceURL: 'SameAsSourceURL',
} as const;

export type DoppeActionCTATypeLinkAutoSource = Values<typeof DoppeActionCTATypeLinkAutoSource>;

export const DoppeActionCTATypeLinkOpenType = {
    NewTab: 'NewTab',
    SameTab: 'SameTab',
    InPage: 'InPage',
} as const;

export type DoppeActionCTATypeLinkOpenType = Values<typeof DoppeActionCTATypeLinkOpenType>;

export type DoppeActionCTATypeLinkSource = AutoValue<DoppeActionCTATypeLinkAutoSource> | string;


export type DoppeActionCTAButtonSingle = DoppeActionCTAButton.SingleButton;
export type DoppeActionCTAButtonMultiple = DoppeActionCTAButton.MultipleButton;

export type DoppeActionCTAButton = DoppeActionCTAButton.BaseType;

export namespace DoppeActionCTAButton {

    export type BaseType = {
        settings: DoppeActionSettingsCTAButtonSettings;
    }

    export type SingleButton = DoppeActionCTAButton.BaseType & {
        text: DoppeHideableValue<string>;
        showButton: boolean;
    }

    export type MultipleButtonBase = DoppeActionCTAButton.BaseType & {
        isVisible: boolean;
    }

    export type MultipleButton = DoppeActionCTAButton.MultipleButtonBase & {
        text: string;
        isRequired: boolean;
        id: string;
    }

    export type MultipleButtonOverride = DoppeActionCTAButton.MultipleButtonBase & {
        useDefaultContent: boolean;
    }

    export type MultipleButtonWithOverride = DoppeActionCTAButton.MultipleButton & DoppeActionCTAButton.MultipleButtonOverride & {
        isDefaultButton: boolean;
    }
}

export function doppeActionCtaButtonCreateFromListAndOverride(rootId: string, actionCtaButton: DoppeActionCTAButton.MultipleButton, override: DoppeActionCTAButton.MultipleButtonOverride ) {
    return {
        ...actionCtaButton,
        ...override,
        isRequired: true,
        id: doppeActionCtaButtonCreateFromListAndOverrideId(rootId, actionCtaButton.id)
    } as DoppeActionCTAButton.MultipleButton;
}

export function doppeActionCtaButtonCreateFromListAndOverrideId(rootId: string, actionCtaButtonId: string) {
    return rootId + actionCtaButtonId;
}

export function doppeActionCtaButtonContactListOverrideId(rootId: string, actionCtaButtonId: string) {
    return stringTrimPrefix(actionCtaButtonId, rootId);
}

export function doppeActionCtaButtonConvertSingleToMultipleButtons(ctaButton: DoppeActionCTAButton.SingleButton) {
    return [{
        text: doppeHideableValueGetVisibleValue(ctaButton.text, ''),
        isVisible: ctaButton.showButton,
        isRequired: true,
        id: uuidCreate(),
        settings: ctaButton.settings
    }] satisfies DoppeActionCTAButton.MultipleButton[];
}

export type DoppeActionSettingsCTAButtonSettings =
    | DoppeActionSettingsCTAButtonSettings.Link
    | DoppeActionSettingsCTAButtonSettings.Whatsapp
    | DoppeActionSettingsCTAButtonSettings.Call
    | DoppeActionSettingsCTAButtonSettings.Navigate
    | DoppeActionSettingsCTAButtonSettings.Email
    | DoppeActionSettingsCTAButtonSettings.FacebookMessage
    | DoppeActionSettingsCTAButtonSettings.SaveContact
    | DoppeActionSettingsCTAButtonSettings.Share
    | DoppeActionSettingsCTAButtonSettings.Checkout
    | DoppeActionSettingsCTAButtonSettings.OpenProduct
    | DoppeActionSettingsCTAButtonSettings.OpenPage;

export namespace DoppeActionSettingsCTAButtonSettings {

    export type Override = Pick<DoppeActionCTAButtonSingle, 'showButton' | 'settings'>

    type BaseType<T extends DoppeActionCTAType> = {
        type: T;
    }

    export type Link = BaseType<typeof DoppeActionCTAType.Link> & {
        url: DoppeActionCTATypeLinkSource;
        openType: DoppeActionCTATypeLinkOpenType;
    };

    export type OpenProduct = BaseType<typeof DoppeActionCTAType.OpenProduct> & {
        openType: DoppeActionCTATypeLinkOpenType;
    };

    export type OpenPage = BaseType<typeof DoppeActionCTAType.OpenPage> & {
        openType: DoppeActionCTATypeLinkOpenType;
        pageType: DoppeHtmlSitePagesType | null;
    };



    export type Whatsapp = BaseType<typeof DoppeActionCTAType.Whatsapp> & {
        phone: string;
        customMessage: DoppeHideableValue<string>;
    };

    export type Call = BaseType<typeof DoppeActionCTAType.Call> & {
        phone: string;
    };

    export type Email = BaseType<typeof DoppeActionCTAType.Email> & {
        email: string;
        customSubject: DoppeHideableValue<string>;
        customMessage: DoppeHideableValue<string>;
    };

    export type Navigate = BaseType<typeof DoppeActionCTAType.Navigate> & {
        address: string;
    };

    export type FacebookMessage = BaseType<typeof DoppeActionCTAType.FacebookMessage> & {
        userName: string;
        customMessage: DoppeHideableValue<string>
    };

    export type SaveContact = BaseType<typeof DoppeActionCTAType.SaveContact> & {
        notes: string;
        saveContactProfileImage: DoppeHideableValue<WixImageResource | null>
    }

    export type Share = BaseType<typeof DoppeActionCTAType.Share> & {
        url: DoppeActionCTATypeLinkSource;
        title: string;
        description: string;
    }

    export type Checkout = BaseType<typeof DoppeActionCTAType.Checkout> & {
        price: DoppeActionPrice;
        name: DoppeHideableValue<string>;
        description: DoppeHideableValue<RichTextContent>;
        checkoutSettings: DoppeCheckoutSettingsWithBindings;
    }

}

export function doppeActionMultipleCTAButtonIsVisible(ctaButton: DoppeActionCTAButton.MultipleButton) {
    return ctaButton.isVisible;
}

export function doppeActionCTAButtonIsVisible(ctaButton: DoppeActionCTAButton.SingleButton) {
    return ctaButton.showButton;
}

export function doppeActionCTAButtonCreateLinkSameAsSource(options: {
    text: string,
    hidden?: boolean
}) {

    const {
        text,
        hidden = false
    } = options;

    return {
        settings: {
            type: DoppeActionCTAType.Link,
            url: autoValueCreate(DoppeActionCTATypeLinkAutoSource.SameAsSourceURL),
            openType: DoppeActionCTATypeLinkOpenType.NewTab,
        },
        text: text,
        showButton: !hidden
    } satisfies DoppeActionCTAButton.SingleButton;
}

export function doppeActionMultipleCTAButtonCreateLinkSameAsSource(options: {
    text: string,
    hidden?: boolean
    isRequired?: boolean
}) {

    const {
        text,
        hidden = false,
        isRequired = false
    } = options;

    return {
        settings: {
            type: DoppeActionCTAType.Link,
            url: autoValueCreate(DoppeActionCTATypeLinkAutoSource.SameAsSourceURL),
            openType: DoppeActionCTATypeLinkOpenType.NewTab,
        },
        text: text,
        isVisible: !hidden,
        isRequired: isRequired,
        id: uuidCreate(),
    } satisfies DoppeActionCTAButton.MultipleButton
}

export function doppeActionMultipleCTAButtonCreate(options: {
    text: string,
    hidden?: boolean
    isRequired?: boolean,
    settings: DoppeActionSettingsCTAButtonSettings;
}) {

    const {
        text,
        hidden = false,
        isRequired = false,
        settings
    } = options;

    return {
        settings: settings,
        text: text,
        isVisible: !hidden,
        isRequired: isRequired,
        id: uuidCreate(),
    } satisfies DoppeActionCTAButton.MultipleButton
}

export function doppeActionCtaButtonTypeGetGoalType(type: DoppeActionCTAType) {

    switch (type) {
        case DoppeActionCTAType.Whatsapp: {
            return DoppeActionGoalType.WhatsappClick;
        }
        case DoppeActionCTAType.Call: {
            return DoppeActionGoalType.CallButtonClick;
        }
        case DoppeActionCTAType.OpenPage:
        case DoppeActionCTAType.OpenProduct:
        case DoppeActionCTAType.Link: {
            return DoppeActionGoalType.Click;
        }
        case DoppeActionCTAType.Email: {
            return DoppeActionGoalType.EmailButtonClick;
        }
        case DoppeActionCTAType.Navigate: {
            return DoppeActionGoalType.NavigateButtonClick;
        }
        case DoppeActionCTAType.FacebookMessage: {
            return DoppeActionGoalType.FacebookMessageButtonClick;
        }
        case DoppeActionCTAType.SaveContact: {
            return DoppeActionGoalType.SaveContact;
        }
        case DoppeActionCTAType.Share: {
            return DoppeActionGoalType.Share;
        }
        case DoppeActionCTAType.Checkout: {
            return DoppeActionGoalType.Payment;
        }

    }
}

export function doppeActionCtaButtonResetSettingsForLockNew<T extends DoppeActionCTAButton.BaseType>(ctaButton: T) {
    const isSameSourceURl = (
            ctaButton.settings.type === DoppeActionCTAType.Link ||
            ctaButton.settings.type === DoppeActionCTAType.Share
        )
        && autoValueIsAutoValue(ctaButton.settings.url);
    ctaButton.settings = doppeActionCtaButtonTypeGetDefaultSettings(ctaButton.settings.type, isSameSourceURl);
}

export function doppeActionCtaButtonResetSettingsForLock(actionSettings: DoppeActionCtaButtonSettings) {
    const isSameSourceURl = (
            actionSettings.ctaButton.settings.type === DoppeActionCTAType.Link ||
            actionSettings.ctaButton.settings.type === DoppeActionCTAType.Share
        )
        && autoValueIsAutoValue(actionSettings.ctaButton.settings.url
        );
    actionSettings.ctaButton.settings = doppeActionCtaButtonTypeGetDefaultSettings(actionSettings.ctaButton.settings.type, isSameSourceURl);
}

export function doppeActionCtaButtonsResetSettingsForLock(actionSettings: DoppeActionCtaButtonsSettings) {
    actionSettings.ctaButtons.forEach(ctaButton => {
        doppeActionCtaButtonResetSettingsForLockNew(ctaButton);
    });
}

export function doppeActionCtaButtonTypeGetDefaultSettings(type: DoppeActionCTAType, hasSourceURL: boolean) {

    switch (type) {
        case DoppeActionCTAType.Checkout: {
            return {
                type: DoppeActionCTAType.Checkout,
                price: {
                    actualPrice: 20,
                    currencyCode: 'USD'
                },
                name: doppeHideableValueCreateHidden(''),
                description: doppeHideableValueCreateHidden(''),
                checkoutSettings: getDefaultDoppeCheckoutSettingsForAction()
            } satisfies DoppeActionSettingsCTAButtonSettings.Checkout;
        }
        case DoppeActionCTAType.Whatsapp: {
            return {
                type: DoppeActionCTAType.Whatsapp,
                phone: '',
                customMessage: '',
            };
        }
        case DoppeActionCTAType.Call: {
            return {
                type: DoppeActionCTAType.Call,
                phone: '',
            };
        }
        case DoppeActionCTAType.Share:
            return {
                type: DoppeActionCTAType.Share,
                url: hasSourceURL ? autoValueCreate(DoppeActionCTATypeLinkAutoSource.SameAsSourceURL) : '',
                title: '',
                description: '',
            };
        case DoppeActionCTAType.Link:
            return {
                type: DoppeActionCTAType.Link,
                url: hasSourceURL ? autoValueCreate(DoppeActionCTATypeLinkAutoSource.SameAsSourceURL) : '',
                openType: DoppeActionCTATypeLinkOpenType.NewTab,
            };
        case DoppeActionCTAType.Email: {
            return {
                type: DoppeActionCTAType.Email,
                email: '',
                customMessage: '',
                customSubject: ''
            };
        }
        case DoppeActionCTAType.Navigate: {
            return {
                type: DoppeActionCTAType.Navigate,
                address: '',
            };
        }
        case DoppeActionCTAType.FacebookMessage: {
            return {
                type: DoppeActionCTAType.FacebookMessage,
                userName: '',
                customMessage: '',
            };
        }
        case DoppeActionCTAType.SaveContact: {
            return {
                type: DoppeActionCTAType.SaveContact,
                notes: '',
                saveContactProfileImage: doppeHideableValueCreateHidden(null),
            };
        }
        case DoppeActionCTAType.OpenProduct: {
            return {
                type: DoppeActionCTAType.OpenProduct,
                openType: DoppeActionCTATypeLinkOpenType.InPage,
            };
        }
        case DoppeActionCTAType.OpenPage: {
            return {
                type: DoppeActionCTAType.OpenPage,
                openType: DoppeActionCTATypeLinkOpenType.InPage,
                pageType: null,
            };
        }
    }
}

export function doppeActionCtaButtonTypeOpenUrl(ctaSettings: DoppeActionSettingsCTAButtonSettings) {
    switch (ctaSettings.type) {
        case DoppeActionCTAType.Call:
        case DoppeActionCTAType.Email:
        case DoppeActionCTAType.Whatsapp:
        case DoppeActionCTAType.Navigate:
        case DoppeActionCTAType.FacebookMessage: {
            return true;
        }
        case DoppeActionCTAType.OpenPage:
        case DoppeActionCTAType.OpenProduct:
        case DoppeActionCTAType.Link: {
            return ctaSettings.openType !== DoppeActionCTATypeLinkOpenType.InPage;
        }
        case DoppeActionCTAType.Checkout:
        case DoppeActionCTAType.Share:
        case DoppeActionCTAType.SaveContact: {
            return false;
        }
    }
}

export function doppeActionCtaButtonLinkResolveTarget(ctaSettings: DoppeActionSettingsCTAButtonSettings) {

    switch (ctaSettings.type) {
        case DoppeActionCTAType.OpenPage:
        case DoppeActionCTAType.OpenProduct:
        case DoppeActionCTAType.Link: {

            if (ctaSettings.openType === DoppeActionCTATypeLinkOpenType.NewTab) {
                return '_blank';
            }
            else if (ctaSettings.openType === DoppeActionCTATypeLinkOpenType.SameTab) {
                return '_self';
            }
            else {
                return undefined;
            }
        }
        default: {
            return undefined;
        }
    }
}

