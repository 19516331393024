interface OperationsBatchOptions<DATA> {
    dataInitializer?: () => DATA;
    onBatchEnded?: (operationsBatch: OperationsBatch<DATA>) => void;
}

const NotInitializedData = Symbol('NotInitializedData');

export class OperationsBatch<DATA> {

    private dataInitializer: OperationsBatchOptions<DATA>['dataInitializer'];
    private onBatchEnded: OperationsBatchOptions<DATA>['onBatchEnded'];
    private batchCounter = 0;
    private _data: DATA | typeof NotInitializedData = NotInitializedData;

    public constructor (
        options: OperationsBatchOptions<DATA> = {}
    ) {
        const {
            dataInitializer,
            onBatchEnded
        } = options;

        this.dataInitializer = dataInitializer;
        this.onBatchEnded = onBatchEnded;
    }

    public get data () : DATA {
        const data = this._data;
        if (data === NotInitializedData) {
            throw new Error(`Data isn't initialized`);
        }

        return data;
    }

    public isActive () {
        return this.batchCounter > 0;
    }

    public start () {

        if (this.batchCounter === 0) {
            const dataInitializer = this.dataInitializer;
            if (dataInitializer) {
                this._data = dataInitializer();
            }
        }

        this.batchCounter++;
    }

    public finish () {
        this.batchCounter--;

        if (this.batchCounter === 0) {
            const onBatchEnded = this.onBatchEnded;
            if (onBatchEnded) {
                onBatchEnded(this);
            }

            this._data = NotInitializedData;
        }
    }

    public batchScope<R> (operation: (batch: OperationsBatch<DATA>) => R) : R {

        this.start();

        try {
            return operation(this);
        } finally {
            this.finish();
        }

    }

}
