import React, {useContext} from "react";
import {
    assertNotNullable,
    languageIsRtl,
    PartiallyOptional,
    stringIsNotNullableOrWhiteSpace
} from "@wix/devzai-utils-common";
import {DoppeColorPaletteCalculatedColorsData, useBymoPageContextProps} from "../../doppe-sdk";
import {DoppeViewerLang} from "../../client-server-common/doppe-viewer/doppe-viewer-lang";
import {
    type BymoPageProps,
    BymoPagePropsForViewer,
    BymoTemplateDesktopLayout, DoppeHtmlSiteInfo,
    useBymoPageProps
} from "../../client-server-common";
import {
    BymoTemplateSpeedDial,
    BymoTemplateSpeedDialSearchAppearance
} from "../bymo-templates/bymo-template-speed-dial/bymo-template-speed-dial.metadata";

export const DoppeViewerColorPaletteCalculatedColorsDataReactContext = React.createContext<DoppeColorPaletteCalculatedColorsData | null>(null);

export function useAssertDoppeViewerColorPaletteCalculatedColorsDataReactContext () {
    return assertNotNullable(useContext(DoppeViewerColorPaletteCalculatedColorsDataReactContext), `DoppeViewerColorPaletteCalculatedColorsDataReactContext is not provided`);
}

export const DoppeViewerLangReactContext = React.createContext<DoppeViewerLang | null>(null);

export function useDoppeViewerLang() {
    return assertNotNullable(useContext(DoppeViewerLangReactContext), `DoppeViewerLangReactContext is not provided`);
}

export const DoppeViewerHtmlSiteInfoReactContext = React.createContext<DoppeHtmlSiteInfo | null>(null);

export function useDoppeViewerHtmlSiteInfo() {
    return useContext(DoppeViewerHtmlSiteInfoReactContext);
}

export const DoppeViewerShouldShowCookieBannerReactContext = React.createContext<BymoPageProps['showCookieBanner'] | null>(null);

export function useDoppeViewerShouldShowCookieBanner() {
    return assertNotNullable(useContext(DoppeViewerShouldShowCookieBannerReactContext), `DoppeViewerShouldShowCookieBannerReactContext is not provided`);
}

export function useDoppViewerIsRtl() {
    return languageIsRtl(useDoppeViewerLang());
}

export const DoppeViewerPageDesktopLayoutReactContext = React.createContext<BymoTemplateDesktopLayout | null>(null);

export function useDoppeViewerPageDesktopLayout() {
    return assertNotNullable(useContext(DoppeViewerPageDesktopLayoutReactContext), `DoppeViewerPageDesktopLayoutReactContext is not provided`);
}

export const DoppeViewerShouldShowPrivacyAndTermsReactContext = React.createContext<BymoPageProps['showPrivacyAndTerms'] | null>(null);

export function useDoppeViewerPrivacyAndTermsForDisplay () {
    const bymoPageContextProps = useBymoPageContextProps();
    const showPrivacyAndTerms = assertNotNullable(useContext(DoppeViewerShouldShowPrivacyAndTermsReactContext), `DoppeViewerShouldShowPrivacyAndTermsReactContext is not provided`);

    const userPrivacyPolicyUrl = (bymoPageContextProps.userPrivacyPolicyUrl ?? '').trim();
    const userTermsOfServiceUrl = (bymoPageContextProps.userTermsOfServiceUrl ?? '').trim();

    if (showPrivacyAndTerms && (stringIsNotNullableOrWhiteSpace(userPrivacyPolicyUrl) || stringIsNotNullableOrWhiteSpace(userTermsOfServiceUrl))) {
        return {
            userPrivacyPolicyUrl: userPrivacyPolicyUrl,
            userTermsOfServiceUrl: userTermsOfServiceUrl,
            userDisplayName: bymoPageContextProps.userDisplayName
        }
    } else {
        return null;
    }
}

export function useDoppeViewerBymoTemplateProps<TEMPLATE_PROPS extends {} = Record<string, any>> () {
    return useBymoPageProps() as unknown as PartiallyOptional<BymoPagePropsForViewer<TEMPLATE_PROPS>, keyof TEMPLATE_PROPS>
}

export function useDoppeViewerShouldShowSearch () {
    return useDoppeViewerBymoTemplateProps<BymoTemplateSpeedDial.TemplateProps>().searchAppearance === BymoTemplateSpeedDialSearchAppearance.Default;
}