import {
    mimeTypeResolveFromPath,
    MimeTypeString,
    stringEnsurePrefix,
    urlModifyQueryParamsInEncodedUrl
} from "@wix/devzai-utils-common";

export function browserOpenNewTab (
    url: string,
    options: {
        rel?: 'noopener' | 'noreferrer' | null
    } = {}
) {
    const {
        rel = 'noreferrer'
    } = options;

    window.open(url, '_blank', rel ?? undefined)
}

export function browserDownloadStringAsFile (str: string, filename: string) {
    const blob = new Blob([str], {
        type: mimeTypeResolveFromPath(filename) ?? MimeTypeString.TextPlain
    });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.download = filename;
    link.href = url;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
}

export function browserDownloadObjectUrlAsFile (url: string, filename: string) {
    const link = document.createElement("a");
    link.download = filename;
    link.href = url;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
}

export function browserGetWindowOrigin (rootWindow?: Window) {

    const location = (rootWindow ?? window).location;

    return location.protocol + "//" + location.host;
}

export function browserGetWindowBaseUrl (rootWindow?: Window) {

    const location = (rootWindow ?? window).location;

    return location.protocol + "//" + location.host + stringEnsurePrefix(location.pathname, '/');
}

export function browserIsIframeWindow (rootWindow: Window) {
    return rootWindow !== rootWindow.top;
}

export function browserModifyCurrentLocationQueryParams (queryParamsModifier: Parameters<typeof urlModifyQueryParamsInEncodedUrl>[1]) {
    const currentPathWithSearch = location.pathname + location.search;
    return urlModifyQueryParamsInEncodedUrl(currentPathWithSearch, queryParamsModifier);
}

export async function browserConvertImageToBase64 (url: string) {
    // Fetch the image
    const response = await fetch(url);
    if (!response.ok) {
        throw new Error('Network response was not ok');
    }
    const blob = await response.blob();

    // Convert the blob to base64
    return new Promise<string>((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result as string);
        reader.onerror = reject;
        reader.readAsDataURL(blob);
    });
}

export async function browserEyeDropperPickColor () {

    const windowWithEyeDropper = window as {
        EyeDropper?: any
    }

    if (windowWithEyeDropper.EyeDropper) {
        const eyeDropper = new windowWithEyeDropper.EyeDropper();

        try {
            const result = await eyeDropper.open();

            return result.sRGBHex;
        } catch (error) {
            return null;
        }
    }
}