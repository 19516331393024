import {doppeActionTypeDefineMetadata} from '../../client-server-common/doppe-action-types/doppe-action-type';
import {urlRemoveQueryString, Values} from '@wix/devzai-utils-common';
import {DoppeHideableValue, doppeHideableValueGetValue, doppeHideableValueIsVisible} from '../../client-server-common';
import {RichTextContent} from '@wix/devzai-utils-react';
import {
    DoppeActionCtaButtonSettings,
    doppeActionCtaButtonTypeGetDefaultCtaButtonSettingsMigration,
    getDefaultDoppeActionCtaButtonSettings
} from '../../client-server-common/types/doppe-action-cta-button-settings';
import {
    doppeActionContentSectionSettingsDefaultValuesMigration,
    DoppeActionContentSectionsSettings
} from '../../client-server-common/types/doppe-action-content-sections-settings';
import {
    DoppeActionCtaButtonsSettings,
    doppeActionCtaButtonsSettingsDefaultValuesMigration
} from '../../client-server-common/types/doppe-action-cta-buttons-settings';
import {
    doppeActionCtaButtonsResolveMainURL
} from '../doppe-actions-common/doppe-cta-button-utils/doppe-cta-button-utils';
import {
    doppeDtoActionSettingsRemoveInnerCtaContentForLockedAction
} from '../../client-server-common/types/doppe-dto-action';
import {doppeActionCtaButtonsResetSettingsForLock} from '../../client-server-common/types/doppe-action-cta-button';


export const SpotifyWidgetSize = {
    Normal: 'Normal',
    Compact: 'Compact',
} as const;

export const SpotifyWidgetTheme = {
    Default: 'Default',
    Dark: 'Dark',
} as const;


export namespace DoppeActionTypeSpotify {

    export type Size = Values<typeof SpotifyWidgetSize>;
    export type Theme = Values<typeof SpotifyWidgetTheme>;

    export interface ActionSettings extends
        DoppeActionContentSectionsSettings,
        DoppeActionCtaButtonsSettings  {
        url: string;
        theme: Theme;
        size: Size;
        title: DoppeHideableValue<string>;
        description: DoppeHideableValue<RichTextContent>;
    }

    export type DeprecatedFields = Partial<DoppeActionCtaButtonSettings> & {
        buttonText?: DoppeHideableValue<string>;
    }

    export type PartialProps = Partial<ActionSettings> & DeprecatedFields;
}

export const doppeActionTypeSpotify = doppeActionTypeDefineMetadata<DoppeActionTypeSpotify.ActionSettings, DoppeActionTypeSpotify.PartialProps>({
    id: 'io.bymo.action/spotify',
    resolveActionSettings: action => {

        const {
            buttonText = '',
            ctaButton,
            ctaButtons,
            sections,
            ...restSettings
        } = action.settings;

        return {
            url: '',
            theme: SpotifyWidgetTheme.Default,
            size: SpotifyWidgetSize.Normal,
            buttonText: buttonText,
            title: 'Title',
            description: 'Description',
            ...getDefaultDoppeActionCtaButtonSettings({
                buttonText: doppeHideableValueGetValue(buttonText),
                showButton: doppeHideableValueIsVisible(buttonText),
            }),
            ...doppeActionCtaButtonTypeGetDefaultCtaButtonSettingsMigration({
                hasSourceURL: true,
                ctaButton: ctaButton,
            }),
            ...doppeActionContentSectionSettingsDefaultValuesMigration(action.settings),
            ...doppeActionCtaButtonsSettingsDefaultValuesMigration(action.settings, true),
            ...restSettings
        }
    },
    hasNoGoalMeasuring: false,
    isWidgetOpener: true,
    canRenderInPage: true,
    skipReportingOnGoalAchievedOnActionButtonClick: true,
    hasNoLockInnerCTA: true,
    resolveMainLink: (actionSettings, htmlSiteInfo) => {
        return doppeActionCtaButtonsResolveMainURL(actionSettings, {
            htmlSiteInfo: htmlSiteInfo,
            sourceURL: actionSettings.url
        }) ?? null;
    },
    prepareForViewer: (actionSettings, options) => {
        doppeDtoActionSettingsRemoveInnerCtaContentForLockedAction(actionSettings, actionSettings => {
            actionSettings.url = '';
            doppeActionCtaButtonsResetSettingsForLock(actionSettings);
        }, options);
    },
})

export function doppeValidateSpotifyPlaylistURL(url: string) {
    const pattern = /^https:\/\/open\.spotify\.com\/(?:playlist|embed\/playlist)\/[A-Za-z0-9_-]+$/;
    return pattern.test(urlRemoveQueryString(url));
}

export function doppeValidateSpotifyTrackURL(url: string) {
    // const pattern = /^https:\/\/open\.spotify\.com\/embed\/track\/[A-Za-z0-9_-]+$/;
    const pattern = /^https:\/\/open\.spotify\.com\/(?:track|embed\/track)\/[A-Za-z0-9_-]+$/;
    return pattern.test(urlRemoveQueryString(url));
}

export function doppeValidateSpotifyShowURL(url: string) {
    // const pattern = /^https:\/\/open\.spotify\.com\/embed\/show\/[A-Za-z0-9_-]+$/;
    const pattern = /^https:\/\/open\.spotify\.com\/(?:show|embed\/show)\/[A-Za-z0-9_-]+$/;
    return pattern.test(urlRemoveQueryString(url));
}

export function doppeValidateSpotifyEpisodeURL(url: string) {
    // const pattern = /^https:\/\/open\.spotify\.com\/embed\/episode\/[A-Za-z0-9_-]+$/;
    const pattern = /^https:\/\/open\.spotify\.com\/(?:episode|embed\/episode)\/[A-Za-z0-9_-]+$/;
    return pattern.test(urlRemoveQueryString(url));
}

export function doppeValidateSpotifyArtistURL(url: string) {
    // const pattern = /^https:\/\/open\.spotify\.com\/embed\/artist\/[A-Za-z0-9_-]+$/;
    const pattern = /^https:\/\/open\.spotify\.com\/(?:artist|embed\/artist)\/[A-Za-z0-9_-]+$/;
    return pattern.test(urlRemoveQueryString(url));
}

export function doppeValidateSpotifyURL(url: string) {
    const pattern = /^https:\/\/open\.spotify\.com\/([A-Za-z]+)\/([A-Za-z0-9_-]+)(\?.*)?$/;
    return pattern.test(urlRemoveQueryString(url));
}