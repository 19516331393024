import {doppeActionTypeDefineMetadata} from '../../client-server-common/doppe-action-types/doppe-action-type';
import {
    BymoPagePropsContentSettingSemanticMeaning,
    BymoPagePropsContentSettingType,
    bymoPagePropsCreateContentSettingMetadata,
    DoppeAutoBlogPostType,
    DoppeBusinessEntity,
    DoppeExternalBlogPostProvider
} from "../../client-server-common";
import {DoppeActionMediaViewSettings} from "../../client-server-common/types/doppe-action-media-view-settings";
import {
    doppeBlogPostActionGetMediaViewDefaultSettings
} from "../../doppe-action-types-client-server-common/doppe-blog-post-action-types-utils/doppe-blog-post-action-types-utils";

export namespace DoppeActionTypeAutoBlogPost {
    export interface ActionSettings extends DoppeActionMediaViewSettings {
        showTitle: boolean;
        showDescription: boolean;
        showBlogPostDate: boolean;
        showBlogWebsite: boolean;
        blogPostButtonText: string;
        blogPostProvider: DoppeExternalBlogPostProvider;
        autoPostType: DoppeAutoBlogPostType;
    }
}

export const doppeActionTypeAutoBlogPost = doppeActionTypeDefineMetadata<DoppeActionTypeAutoBlogPost.ActionSettings>({
    id: 'io.bymo.action/auto-blog-post',
    resolveActionSettings: action => {
        return {
            showTitle: true,
            showDescription: true,
            showBlogPostDate: true,
            showBlogWebsite: true,
            blogPostButtonText: 'Read Now',
            blogPostProvider: DoppeExternalBlogPostProvider.Wix,
            autoPostType: DoppeAutoBlogPostType.Latest,
            ...action.settings,
            ...doppeBlogPostActionGetMediaViewDefaultSettings({
                mainMediaViewSettings: action.settings.mainMediaViewSettings,
                mainMediaViewLayout: action.settings.mainMediaViewLayout,
            }),
        }
    },
    isWidgetOpener: true,
    canRenderInPage: true,
    skipReportingOnGoalAchievedOnActionButtonClick: true,
    settingsMetadata: {
        actionSettingsMetadata: {
            contentSettingsMetadata: {
                blogPostButtonText: bymoPagePropsCreateContentSettingMetadata(BymoPagePropsContentSettingType.Text, BymoPagePropsContentSettingSemanticMeaning.EntityActionButtonText),
            },
            supportsContentSections: false
        },
        listItemsSettingsMetadata: false
    },
    resolveUsedBusinessEntities: actionSettings => {
        return [
            {businessEntity: DoppeBusinessEntity.WixAutoBlogPost, id: actionSettings.autoPostType}
        ]
    },
});