import {
    WixImageResource,
    WixMediaResource, wixMediaResourceIsWixImageResource,
    wixMediaResourceIsWixVideoResource,
    wixMediaResourceReplaceExternalImage,
    WixVideoResource
} from "@wix/devzai-common-wix";

export type BymoPageWixVideoResource = WixVideoResource & {
    renderAsPlayer?: boolean;
    playerAutoPlay?: boolean;
};

export type BymoPageEmbeddedVideoPlayer = {
    externalVideoUrl: string;
    playerAutoPlay?: boolean;
}

export type BymoPageMedia = WixImageResource | BymoPageWixVideoResource | BymoPageEmbeddedVideoPlayer;

export function bymoPageMediaIsEmbeddedVideoPlayer (bymoPageMedia: BymoPageMedia) : bymoPageMedia is BymoPageEmbeddedVideoPlayer {
    return (bymoPageMedia as BymoPageEmbeddedVideoPlayer).externalVideoUrl !== undefined;
}

export function bymoPageMediaIsBymoPageWixVideoResource (bymoPageMedia: BymoPageMedia) : bymoPageMedia is BymoPageWixVideoResource {
    return !bymoPageMediaIsEmbeddedVideoPlayer(bymoPageMedia) && wixMediaResourceIsWixVideoResource(bymoPageMedia);
}

export function bymoPageMediaIsWixImageResource (bymoPageMedia: BymoPageMedia) : bymoPageMedia is WixImageResource {
    return !bymoPageMediaIsEmbeddedVideoPlayer(bymoPageMedia) && wixMediaResourceIsWixImageResource(bymoPageMedia);
}

export function bymoPageMediaResolveWixMediaResource (bymoPageMedia: BymoPageMedia) : WixMediaResource | null {
    // TODO: For embedded video player that we can figure out its image, we should try to return the image rather than null.
    return bymoPageMediaIsEmbeddedVideoPlayer(bymoPageMedia) ? null : bymoPageMedia;
}

export function bymoPageMediaReplaceExternalImage<V extends BymoPageMedia> (
    bymoPageMedia: V,
    externalImagesUrlToImageResourceMap: Map<string, WixImageResource>
) {
    if (bymoPageMediaIsEmbeddedVideoPlayer(bymoPageMedia)) {
        return bymoPageMedia;
    } else {
        return wixMediaResourceReplaceExternalImage(bymoPageMedia, externalImagesUrlToImageResourceMap);
    }
}