import {stringTrimPrefix, urlJoin, valueConvertWhenNotNullable, Values} from '@wix/devzai-utils-common';

export const DoppeHtmlSiteUriMappingType = {
    Default: 'Default',
} as const;

export type DoppeHtmlSiteUriMappingType = Values<typeof DoppeHtmlSiteUriMappingType>;

export type DoppeHtmlSiteUriMappings = Partial<Record<DoppeHtmlSiteUriMappingType, string>>;

export type DoppeHtmlSiteInfo = {
    htmlSiteUriMapping: DoppeHtmlSiteUriMappings;
    htmlSiteUrl: string;
}

export function doppeHtmlSiteResolvePageUrlFromBusinessEntityPageUrl (
    options: {
        doppeHtmlSiteInfo: DoppeHtmlSiteInfo;
        htmlSiteUriMappingType: DoppeHtmlSiteUriMappingType;
        businessEntityPageUrl: string;
    }
) {
    const {
        doppeHtmlSiteInfo,
        htmlSiteUriMappingType,
        businessEntityPageUrl,
    } = options;

    return doppeHtmlSiteResolvePageUrl({
        doppeHtmlSiteInfo: doppeHtmlSiteInfo,
        htmlSiteUriMappingType: htmlSiteUriMappingType,
        pagePath: stringTrimPrefix(businessEntityPageUrl, doppeHtmlSiteInfo.htmlSiteUrl)
    })
}

export function doppeHtmlSiteResolveProductPageUrl (
    options: {
        doppeHtmlSiteInfo: DoppeHtmlSiteInfo;
        productPageUrl: string;
    }
) {
    return doppeHtmlSiteResolvePageUrlFromBusinessEntityPageUrl({
        doppeHtmlSiteInfo: options.doppeHtmlSiteInfo,
        businessEntityPageUrl: options.productPageUrl,
        htmlSiteUriMappingType: DoppeHtmlSiteUriMappingType.Default,
    })
}

export function doppeHtmlSiteResolvePageUrl (
    options: {
        doppeHtmlSiteInfo: DoppeHtmlSiteInfo;
        htmlSiteUriMappingType: DoppeHtmlSiteUriMappingType;
        pagePath: string;
    }
) {
    const {
        doppeHtmlSiteInfo,
        htmlSiteUriMappingType,
        pagePath
    } = options;
    
    return valueConvertWhenNotNullable(doppeHtmlSiteInfo.htmlSiteUriMapping[htmlSiteUriMappingType], uriMapping => {
        return urlJoin(doppeHtmlSiteInfo.htmlSiteUrl, uriMapping, pagePath);
    }) ?? null
}

export function doppeHtmlSiteResolveAllProductsPageUrl (doppeHtmlSiteInfo: DoppeHtmlSiteInfo) {
    return doppeHtmlSiteResolvePageUrl({
        doppeHtmlSiteInfo: doppeHtmlSiteInfo,
        htmlSiteUriMappingType: DoppeHtmlSiteUriMappingType.Default,
        pagePath: '/category/all-products'
    })
}

export function doppeHtmlSiteResolveCartPageUrl (doppeHtmlSiteInfo: DoppeHtmlSiteInfo) {
    return doppeHtmlSiteResolvePageUrl({
        doppeHtmlSiteInfo: doppeHtmlSiteInfo,
        htmlSiteUriMappingType: DoppeHtmlSiteUriMappingType.Default,
        pagePath: '/cart-page'
    })
}

export function doppeHtmlSiteResolveBookOnlinePageUrl (doppeHtmlSiteInfo: DoppeHtmlSiteInfo) {
    return doppeHtmlSiteResolvePageUrl({
        doppeHtmlSiteInfo: doppeHtmlSiteInfo,
        htmlSiteUriMappingType: DoppeHtmlSiteUriMappingType.Default,
        pagePath: '/book-online'
    })
}

export const DoppeHtmlSitePagesType = {
    Store: 'Store',
    Events: 'Events',
    BookingServices: 'BookingServices',
    Blog: 'Blog',
    PricingPlans: 'PricingPlans',
    Cart: 'Cart',
} as const;

export type DoppeHtmlSitePagesType = Values<typeof DoppeHtmlSitePagesType>;