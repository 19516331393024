import {DoppeViewerAnalyticsEvents} from "../doppe-viewer-analytics-events/doppe-viewer-analytics-events";

export const GoogleAnalyticsEventMap: Partial<Record<DoppeViewerAnalyticsEvents.EventNames, {name: string, description: string}>> = {
    uouPageViewed: {
        name: "hopp_page_viewed",
        description: "Shows how many views a page has."
    },
    uouActionViewed: {
        name: "hopp_action_viewed",
        description: "Counts impressions of an action on a page, irrespective of whether the action was expanded or collapsed, as long as it was visible."
    },
    uouPageSkipClicked: {
        name: "hopp_pre_roll_skipped",
        description: "Counts the number of times a Pre-Roll page was skipped."
    },
    uouCtaButtonClicked: {
        name: "hopp_action_button_clicked",
        description: "Counts the number of clicks on an action button"
    },
    uouCtaButtonViewed: {
        name: "hopp_action_button_viewed",
        description: "Counts the number of times an action button was viewed"
    },
    uouActionClicked: {
        name: "hopp_action_clicked",
        description: "Counts the number of clicks on an action, including the opening of an action, being directed to the action's destination, or submitting an action."
    },
    uouFormSubmitted: {
        name: "hopp_contacts_submitted",
        description: "Tracks the number of times contacts were submitted through Hopp forms, or for a service (like donations or requests)."
    },
    uouDestinationRedirected: {
        name: "hopp_destination_url_reached",
        description: "Counts the number of times visitors reached a URL destination of a Short Link created with Hopp."
    },
    uouPageUnload: {
        name: "hopp_page_closed",
        description: "Measures how many times a visitor closed the browser window while on a Hopp page."
    },
    uouBounced: {
        name: "hopp_visits_bounced",
        description: "Tracks the number of times visitors entered a Hopp page but didn't interact with any elements."
    },
    uouSpeedDialLinkRedirected: {
        name: "hopp_search_result_clicked",
        description: "Counts the number of times visitors clicked on a search result from the Link in Bio search."
    },
    uouCouponCopied: {
        name: "hopp_coupon_copied",
        description: "Tracks how many times visitors copied a coupon created with Hopp."
    },
    uouSearchBoxClicked: {
        name: "hopp_search_box_clicked_without_search",
        description: "Records instances where visitors clicked on the search box but didn't enter any text."
    },
    uouSocialIconClicked: {
        name: "hopp_social_icon_clicked",
        description: "Tracks when a visitor clicks on a social icon on your Link in Bio page."
    }
};