import {doppeActionTypeDefineMetadata} from '../../client-server-common/doppe-action-types/doppe-action-type';
import {
    DoppeHideableValue,
    doppeHideableValueCreateHidden,
    doppeHideableValueIsVisibleAndNotEqualValue
} from '../../client-server-common/types/doppe-hideable-value';
import {WixMediaResource, wixMediaResourceReplaceExternalImage} from '@wix/devzai-common-wix';
import {
    arrayFlatten,
    arrayRemoveNullValues,
    asArray,
    immerProduce,
    PartiallyOptional,
    PartiallyRequired
} from '@wix/devzai-utils-common';
import {
    DoppeActionLayout,
    DoppeActionListItemClickBehaviour,
    doppeListActionGetDefaultListSettings,
    doppeListActionGetDefaultSearchListSettings,
    doppeListActionGetItemClickBehaviourDefaultListSettings,
    doppeListActionGetMainDetailsDefaultSettings,
    doppeListActionItemCtaButtonsResetSettingsForLock,
    doppeListActionListItemCalculateItemButtons,
    doppeListActionListItemGetDefaultListItemSettingsMigration,
    doppeListActionListItemWithCtaButtonsMigration,
    doppeListActionListItemWithSectionsMigration,
    DoppeListActionSettings,
    doppeListActionSettingsFilterHiddenListItemsFromActionView,
    doppeListActionTemplateCtaButtonsSettingsDefaultValuesMigration,
    doppeListActionTemplateSectionsSettingsDefaultValuesMigration,
    doppeListActionWithMainDetailsGetMainImage,
    doppeListActionWithMainDetailsReplaceMainImage,
} from '../../client-server-common';
import {doppeActionCtaButtonsResetSettingsForLock} from '../../client-server-common/types/doppe-action-cta-button';
import {
    doppeDtoActionSettingsRemoveInnerCtaContentForLockedAction
} from '../../client-server-common/types/doppe-dto-action';
import {RichTextContent} from '@wix/devzai-utils-react';
import {
    doppeActionCtaButtonsResolveMainURL
} from '../doppe-actions-common/doppe-cta-button-utils/doppe-cta-button-utils';


export namespace DoppeActionTypeMediaGallery {

    export interface MediaGalleryListItemSettings extends
        DoppeListActionSettings.ListItemWithSections,
        DoppeListActionSettings.ListItemWithCtaButtons,
        DoppeListActionSettings.ListItemBaseSettings {
        media: WixMediaResource;
        title: string;
        description: DoppeHideableValue<RichTextContent>;
        ribbon: DoppeHideableValue<string>;
    }

    export interface ActionSettings extends
        DoppeListActionSettings<DoppeActionTypeMediaGallery.MediaGalleryListItemSettings>,
        DoppeListActionSettings.WithItemClickBehaviour,
        DoppeListActionSettings.WithMainDetails,
        DoppeListActionSettings.WithSearchSettings {
        previewShowDescription: boolean;
    }

}

export function doppeActionTypeMediaGalleryResolveListItemSettings(mediaItem: PartiallyRequired<DoppeActionTypeMediaGallery.MediaGalleryListItemSettings, 'id'>) {

    const {
        id,
        deleted,
        displayInSearch,
        enabled,
        customSections,
        customCtaButtons,
        ctaButtonsOverridesMap,
        sectionsOverridesMap,
        // ctaButton,
        ...restProduct
    } = mediaItem;

    return {
        // ...doppeActionCtaButtonTypeGetDefaultCtaButtonSettingsMigration({
        //     hasSourceURL: false,
        //     ctaButton: ctaButton,
        // }),
        ...doppeListActionListItemGetDefaultListItemSettingsMigration(mediaItem.id, mediaItem),
        ...doppeListActionListItemWithCtaButtonsMigration(mediaItem, false),
        ...doppeListActionListItemWithSectionsMigration(mediaItem),
        ...restProduct as PartiallyOptional<DoppeActionTypeMediaGallery.MediaGalleryListItemSettings, 'enabled' | 'displayInSearch' | 'deleted' | 'id' | 'ctaButtonsOverridesMap' | 'sectionsOverridesMap' | 'customSections' | 'customCtaButtons'>
    }
}

export function doppeActionTypeMediaGalleryResolveActionSettings(actionSettings: Partial<DoppeActionTypeMediaGallery.ActionSettings>) {
    const {
        listDataItems = [],
        ctaButtons,
        sections,
        listSearchSettings,
        ...restSettings
    } = actionSettings;

    return {
        previewShowDescription: true,
        ...doppeListActionGetMainDetailsDefaultSettings(),
        ...doppeListActionGetItemClickBehaviourDefaultListSettings({
            itemClickBehaviour: DoppeActionListItemClickBehaviour.PreviewItemAsPopup,
        }),
        ...doppeListActionGetDefaultSearchListSettings(actionSettings),
        ...doppeListActionGetDefaultListSettings({
            layout: DoppeActionLayout.Carousel,
            listDataItems: listDataItems.map(doppeActionTypeMediaGalleryResolveListItemSettings),
        }),
        ...doppeListActionTemplateCtaButtonsSettingsDefaultValuesMigration(actionSettings, false),
        ...doppeListActionTemplateSectionsSettingsDefaultValuesMigration(actionSettings),
        ...restSettings
    }
}

export const doppeActionTypeMediaGallery = doppeActionTypeDefineMetadata<DoppeActionTypeMediaGallery.ActionSettings>({
    id: 'co.hopp.action/media-gallery',
    resolveActionSettings: action => {
        return doppeActionTypeMediaGalleryResolveActionSettings(action.settings);
    },
    prepareForViewer: (actionSettings, options) => {
        doppeDtoActionSettingsRemoveInnerCtaContentForLockedAction(actionSettings, actionSettings => {
            doppeListActionItemCtaButtonsResetSettingsForLock(actionSettings);
            doppeActionCtaButtonsResetSettingsForLock(actionSettings);
        }, options);
    },
    isWidgetOpener: true,
    canRenderInPage: true,
    skipReportingOnGoalAchievedOnActionButtonClick: true,
    isPaymentAction: false,
    isFormAction: false,
    isListItemLinkOpener: false,
    supportsListLayout: true,
    notSupportsListItemClickBeahviour: true,
    forceFullScreenOnListItemClick: true,
    resolveUsedMedia: actionSettings => {
        return arrayRemoveNullValues([...arrayFlatten(actionSettings.listDataItems.map(itemSettings => {
            return itemSettings.media ? asArray(itemSettings.media) : []
        })), doppeHideableValueIsVisibleAndNotEqualValue(actionSettings.mainImage, null) ? actionSettings.mainImage : null]);
    },
    resolveMainMedia: actionSettings => {
        return doppeListActionWithMainDetailsGetMainImage(actionSettings, actionSettings.listDataItems[0]?.media ?? null);
    },
    resolveMainLink: (actionSettings, htmlSiteInfo) => {
        return doppeActionCtaButtonsResolveMainURL(actionSettings, {
            htmlSiteInfo: htmlSiteInfo
        }) ?? null;
    },
    resolveListItemsCount: actionSettings => {
        return doppeListActionSettingsFilterHiddenListItemsFromActionView(actionSettings.listDataItems).length;
    },
    resolveListItems: (action, htmlSiteInfo) => {
        const actionSettings = action.settings;
        return actionSettings.listDataItems.map(item => {
            return {
                url: doppeActionCtaButtonsResolveMainURL(
                    doppeListActionListItemCalculateItemButtons(actionSettings.listItemTemplateCtaButtonsSettings, item), {
                        htmlSiteInfo: htmlSiteInfo,
                    }) ?? null,
                title: item.title,
                action: action,
                couponCode: doppeHideableValueCreateHidden(''),
                description: item.description,
                image: item.media,
                itemId: item.id,
                ribbon: item.ribbon,
                enabled: item.enabled,
                deleted: item.deleted,
                displayInSearch: item.displayInSearch
            }
        });
    },
    resolveListSearchSettings: actionSettings => {
        return actionSettings.listSearchSettings;
    },
    replaceExternalImages: (actionSettings, externalImagesUrlToImageResourceMap) => {
        return immerProduce(actionSettings, actionSettings => {
            for (const item of actionSettings.listDataItems) {
                if (item.media) {
                    item.media = wixMediaResourceReplaceExternalImage(item.media, externalImagesUrlToImageResourceMap)
                }
            }

            doppeListActionWithMainDetailsReplaceMainImage(actionSettings, externalImagesUrlToImageResourceMap);
        })
    },
});
