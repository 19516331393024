import {ArrayValues} from "@wix/devzai-utils-common";

export const currencyCodes = [
    "USD",
    "EUR",
    "GBP",
    "JPY",
    "CAD",
    "AUD",
    "CHF",
    "SGD",
    "TRY",
    "INR",
    "CNY",
    "AED",
    "ALL",
    "AFN",
    "ARS",
    "AWG",
    "AZN",
    "BSD",
    "BBD",
    "BYR",
    "BZD",
    "BMD",
    "BOB",
    "BAM",
    "BWP",
    "BGN",
    "BRL",
    "BND",
    "KHR",
    "KYD",
    "CLP",
    "COP",
    "CRC",
    "HRK",
    "CUP",
    "CZK",
    "DKK",
    "DOP",
    "XCD",
    "EGP",
    "SVC",
    "EEK",
    "FKP",
    "FJD",
    "GHC",
    "GIP",
    "GTQ",
    "GGP",
    "GYD",
    "HNL",
    "HKD",
    "HUF",
    "ISK",
    "IDR",
    "IRR",
    "IMP",
    "ILS",
    "JMD",
    "JEP",
    "KZT",
    "KPW",
    "KRW",
    "KGS",
    "LAK",
    "LVL",
    "LBP",
    "LRD",
    "LTL",
    "MKD",
    "MYR",
    "MUR",
    "MXN",
    "MNT",
    "MZN",
    "NAD",
    "NPR",
    "ANG",
    "NZD",
    "NIO",
    "NGN",
    "NOK",
    "OMR",
    "PKR",
    "PAB",
    "PYG",
    "PEN",
    "PHP",
    "PLN",
    "QAR",
    "RON",
    "RUB",
    "SHP",
    "SAR",
    "RSD",
    "SCR",
    "SBD",
    "SOS",
    "ZAR",
    "LKR",
    "SEK",
    "SRD",
    "SYP",
    "TWD",
    "THB",
    "TTD",
    "TRL",
    "TVD",
    "UAH",
    "UYU",
    "UZS",
    "VEF",
    "VND",
    "YER",
    "ZWD"
] as const;

export type CurrencyCode = ArrayValues<typeof currencyCodes>

export const currencyCodeToText = {
    "EUR": "Euro Member Countries",
    "USD": "United States Dollar",
    "AED": "United Arab Emirates Dirham",
    "ALL": "Albania Lek",
    "AFN": "Afghanistan Afghani",
    "ARS": "Argentina Peso",
    "AWG": "Aruba Guilder",
    "AUD": "Australia Dollar",
    "AZN": "Azerbaijan New Manat",
    "BSD": "Bahamas Dollar",
    "BBD": "Barbados Dollar",
    "BYR": "Belarus Ruble",
    "BZD": "Belize Dollar",
    "BMD": "Bermuda Dollar",
    "BOB": "Bolivia Boliviano",
    "BAM": "Bosnia and Herzegovina Convertible Marka",
    "BWP": "Botswana Pula",
    "BGN": "Bulgaria Lev",
    "BRL": "Brazil Real",
    "BND": "Brunei Darussalam Dollar",
    "KHR": "Cambodia Riel",
    "CAD": "Canada Dollar",
    "KYD": "Cayman Islands Dollar",
    "CLP": "Chile Peso",
    "CNY": "China Yuan Renminbi",
    "COP": "Colombia Peso",
    "CRC": "Costa Rica Colon",
    "HRK": "Croatia Kuna",
    "CUP": "Cuba Peso",
    "CZK": "Czech Republic Koruna",
    "DKK": "Denmark Krone",
    "DOP": "Dominican Republic Peso",
    "XCD": "East Caribbean Dollar",
    "EGP": "Egypt Pound",
    "SVC": "El Salvador Colon",
    "EEK": "Estonia Kroon",
    "FKP": "Falkland Islands (Malvinas) Pound",
    "FJD": "Fiji Dollar",
    "GHC": "Ghana Cedis",
    "GIP": "Gibraltar Pound",
    "GTQ": "Guatemala Quetzal",
    "GGP": "Guernsey Pound",
    "GYD": "Guyana Dollar",
    "HNL": "Honduras Lempira",
    "HKD": "Hong Kong Dollar",
    "HUF": "Hungary Forint",
    "ISK": "Iceland Krona",
    "INR": "India Rupee",
    "IDR": "Indonesia Rupiah",
    "IRR": "Iran Rial",
    "IMP": "Isle of Man Pound",
    "ILS": "Israel Shekel",
    "JMD": "Jamaica Dollar",
    "JPY": "Japan Yen",
    "JEP": "Jersey Pound",
    "KZT": "Kazakhstan Tenge",
    "KPW": "Korea (North) Won",
    "KRW": "Korea (South) Won",
    "KGS": "Kyrgyzstan Som",
    "LAK": "Laos Kip",
    "LVL": "Latvia Lat",
    "LBP": "Lebanon Pound",
    "LRD": "Liberia Dollar",
    "LTL": "Lithuania Litas",
    "MKD": "Macedonia Denar",
    "MYR": "Malaysia Ringgit",
    "MUR": "Mauritius Rupee",
    "MXN": "Mexico Peso",
    "MNT": "Mongolia Tughrik",
    "MZN": "Mozambique Metical",
    "NAD": "Namibia Dollar",
    "NPR": "Nepal Rupee",
    "ANG": "Netherlands Antilles Guilder",
    "NZD": "New Zealand Dollar",
    "NIO": "Nicaragua Cordoba",
    "NGN": "Nigeria Naira",
    "NOK": "Norway Krone",
    "OMR": "Oman Rial",
    "PKR": "Pakistan Rupee",
    "PAB": "Panama Balboa",
    "PYG": "Paraguay Guarani",
    "PEN": "Peru Nuevo Sol",
    "PHP": "Philippines Peso",
    "PLN": "Poland Zloty",
    "QAR": "Qatar Riyal",
    "RON": "Romania New Leu",
    "RUB": "Russia Ruble",
    "SHP": "Saint Helena Pound",
    "SAR": "Saudi Arabia Riyal",
    "RSD": "Serbia Dinar",
    "SCR": "Seychelles Rupee",
    "SGD": "Singapore Dollar",
    "SBD": "Solomon Islands Dollar",
    "SOS": "Somalia Shilling",
    "ZAR": "South Africa Rand",
    "LKR": "Sri Lanka Rupee",
    "SEK": "Sweden Krona",
    "CHF": "Switzerland Franc",
    "SRD": "Suriname Dollar",
    "SYP": "Syria Pound",
    "TWD": "Taiwan New Dollar",
    "THB": "Thailand Baht",
    "TTD": "Trinidad and Tobago Dollar",
    "TRY": "Turkey Lira",
    "TRL": "Turkey Lira",
    "TVD": "Tuvalu Dollar",
    "UAH": "Ukraine Hryvna",
    "GBP": "United Kingdom Pound",
    "UYU": "Uruguay Peso",
    "UZS": "Uzbekistan Som",
    "VEF": "Venezuela Bolivar",
    "VND": "Viet Nam Dong",
    "YER": "Yemen Rial",
    "ZWD": "Zimbabwe Dollar"
}

