import {doppeActionTypeDefineViewerMetadata} from '../../client-server-common/doppe-action-types/doppe-action-type';
import {doppeActionTypeWhatsappButton} from './doppe-action-type-whatsapp-button';
import {
    doppeDtoContactMethodLinkResolveValue,
    DoppeDtoContactMethods
} from '../../client-server-common/types/doppe-dto-contact-method';

export const doppeActionTypeWhatsappButtonViewer = doppeActionTypeDefineViewerMetadata(doppeActionTypeWhatsappButton, {

    getActionButtonProps: actionContext => {

        const action = actionContext.action;

        const value = action.settings.phone;
        const type = DoppeDtoContactMethods.WhatsApp

        return  {
            href: doppeDtoContactMethodLinkResolveValue({
                type: type,
                displayValue: value
            }),
            target: '_blank',
            rel: 'noopener',
        }

    }
})