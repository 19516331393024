import {doppeActionTypeDefineMetadata} from '../../client-server-common/doppe-action-types/doppe-action-type';
import {
    arrayFlatten,
    arrayRemoveNullValues,
    immerProduce,
    OmitStrict,
    PartiallyOptional,
    PartiallyRequired
} from '@wix/devzai-utils-common';
import {wixMediaResourceReplaceExternalImage} from '@wix/devzai-common-wix';
import {
    convertCommonListSettingToDefaultDoppeActionCtaButtonSettingsFromListAction,
    DoppeActionLayout,
    DoppeHideableValue,
    doppeHideableValueCreateHidden,
    doppeHideableValueGetVisibleValue,
    doppeHideableValueIsVisible,
    doppeHideableValueIsVisibleAndNotEqualValue,
    doppeListActionGetDefaultListSettings,
    doppeListActionGetDefaultSearchListSettings,
    doppeListActionGetItemClickBehaviourDefaultListSettings,
    doppeListActionGetMainDetailsDefaultSettings,
    doppeListActionItemCtaButtonsResetSettingsForLock,
    doppeListActionListItemCalculateItemButtons,
    doppeListActionListItemGetDefaultListItemSettingsMigration,
    doppeListActionListItemWithCtaButtonsMigration,
    doppeListActionListItemWithSectionsMigration,
    DoppeListActionSettings,
    doppeListActionSettingsFilterHiddenListItemsFromActionView,
    doppeListActionTemplateCtaButtonsSettingsDefaultValuesMigration,
    doppeListActionTemplateSectionsSettingsDefaultValuesMigration,
    doppeListActionWithMainDetailsGetMainImage,
    doppeListActionWithMainDetailsReplaceMainImage
} from '../../client-server-common';
import {DoppeActionTypeContactCard} from '../doppe-action-type-contact-card/doppe-action-type-contact-card';
import {DoppeActionFullName, doppeActionFullNameGetName} from '../../client-server-common/types/doppe-action-full-name';
import {
    doppeActionCtaButtonsResetSettingsForLock,
    DoppeActionCTAType
} from '../../client-server-common/types/doppe-action-cta-button';
import {
    DoppeActionCtaButtonSettings,
    doppeActionCtaButtonTypeGetDefaultCtaButtonSettingsMigration,
    getDefaultDoppeActionCtaButtonSettings
} from '../../client-server-common/types/doppe-action-cta-button-settings';
import {
    doppeDtoActionSettingsRemoveInnerCtaContentForLockedAction
} from '../../client-server-common/types/doppe-dto-action';
import {
    DoppeDtoContactMethod,
    DoppeDtoContactMethodDeprecated
} from '../../client-server-common/types/doppe-dto-contact-method';
import {
    doppeActionCtaButtonsResolveMainURL
} from '../doppe-actions-common/doppe-cta-button-utils/doppe-cta-button-utils';
import {
    doppeActionContentSectionSettingsDefaultValuesMigration,
    DoppeActionContentSectionsSettings
} from '../../client-server-common/types/doppe-action-content-sections-settings';
import {
    DoppeActionCtaButtonsSettings,
    doppeActionCtaButtonsSettingsDefaultValuesMigration
} from '../../client-server-common/types/doppe-action-cta-buttons-settings';


export namespace DoppeActionTypeContactCardList {

    export interface ContactCardSettings extends
        DoppeListActionSettings.ListItemWithCtaButtons,
        DoppeListActionSettings.ListItemWithSections,
        DoppeListActionSettings.ListItemBaseSettings,
        DoppeActionTypeContactCard.ContactSettings {
        name: DoppeActionFullName;
    }

    export interface ActionSettings extends
        DoppeListActionSettings.WithSearchSettings,
        DoppeListActionSettings<ContactCardSettings>,
        DoppeListActionSettings.WithMainDetails,
        DoppeListActionSettings.WithItemClickBehaviour,
        DoppeActionContentSectionsSettings,
        DoppeActionCtaButtonsSettings {
        previewShowTitle: boolean;
        previewShowDescription: boolean;
    }


    export interface DeprecatedFields extends Partial<DoppeListActionSettings.WithCtaButton> {
        contacts?: ContactCardSettings[];
        previewSaveButtonText?: string;
    }

    export interface DeprecatedItemFields extends Partial<DoppeActionCtaButtonSettings> {
        contactMethods?: PartiallyRequired<Partial<DoppeDtoContactMethod & DoppeDtoContactMethodDeprecated>, 'id'>[]
        buttonText?: DoppeHideableValue<string>;
    }

    export type PartialProps = Partial<OmitStrict<ActionSettings, keyof DoppeListActionSettings<ContactCardSettings>>> & DeprecatedFields & DoppeListActionSettings<ContactCardSettings & DeprecatedItemFields>;
}


export const doppeActionTypeContactCardList = doppeActionTypeDefineMetadata<DoppeActionTypeContactCardList.ActionSettings, DoppeActionTypeContactCardList.PartialProps>({
    id: 'io.bymo.action/contact-card-list',
    resolveActionSettings: action => {

        const {
            listDataItems = [],
            ctaButton,
            listItemTemplateCtaButtonsSettings,
            listItemTemplateSectionsSettings,
            ctaButtons,
            sections,
            ...restSettings
        } = action.settings;

        return {
            previewShowDescription: true,
            previewShowTitle: true,
            previewSaveButtonText: '',
            ...doppeListActionGetDefaultSearchListSettings(action.settings),
            ...doppeListActionGetDefaultListSettings({
                itemShowTextOnImage: true,
                layout: DoppeActionLayout.Grid,
                listDataItems: listDataItems.map(contact => {

                    const {
                        buttonText,
                        notes,
                        saveContactProfileImage,
                        ctaButton,
                        customSections,
                        customCtaButtons,
                        sectionsOverridesMap,
                        ctaButtonsOverridesMap,
                        contactMethods,
                        ...restContactSettings
                    } = contact;

                    return {
                        ...getDefaultDoppeActionCtaButtonSettings({
                            buttonText: doppeHideableValueGetVisibleValue(buttonText, ''),
                            showButton: doppeHideableValueIsVisible(buttonText),
                            settings: {
                                type: DoppeActionCTAType.SaveContact,
                                notes: notes,
                                saveContactProfileImage: saveContactProfileImage
                            }
                        }),
                        ...doppeActionCtaButtonTypeGetDefaultCtaButtonSettingsMigration({
                            hasSourceURL: false,
                            ctaButton: ctaButton,
                        }),
                        ...doppeListActionListItemGetDefaultListItemSettingsMigration(contact.id),
                        contactMethods: (contactMethods as DoppeActionTypeContactCard.xxx[]).map(method => {

                            const {
                                hidden
                            } = method

                            return {
                                ...doppeListActionListItemGetDefaultListItemSettingsMigration(method.id, {
                                    enabled: !hidden
                                }),
                                ...method as PartiallyOptional<DoppeDtoContactMethod, 'enabled'>,
                            }
                        }) as DoppeDtoContactMethod[],
                        ...doppeListActionListItemWithCtaButtonsMigration(contact, false),
                        ...doppeListActionListItemWithSectionsMigration(contact),
                        ...restContactSettings as PartiallyOptional<DoppeActionTypeContactCardList.ContactCardSettings & DoppeActionTypeContactCardList.DeprecatedItemFields, 'ctaButton' | 'contactMethods' | 'customCtaButtons' |'customSections' | 'ctaButtonsOverridesMap' | 'sectionsOverridesMap'>,
                    };
                }),
            }),
            ...doppeListActionGetItemClickBehaviourDefaultListSettings(),
            ...doppeListActionGetMainDetailsDefaultSettings(),
            ...convertCommonListSettingToDefaultDoppeActionCtaButtonSettingsFromListAction(action.settings),
            ...doppeActionCtaButtonTypeGetDefaultCtaButtonSettingsMigration({
                ctaButton: ctaButton,
                hasSourceURL: false
            }),
            ...doppeActionContentSectionSettingsDefaultValuesMigration(action.settings),
            ...doppeActionCtaButtonsSettingsDefaultValuesMigration(action.settings, false),
            ...doppeListActionTemplateCtaButtonsSettingsDefaultValuesMigration(action.settings, false),
            ...doppeListActionTemplateSectionsSettingsDefaultValuesMigration(action.settings),
            ...restSettings
        }
    },
    canRenderInPage: true,
    isWidgetOpener: true,
    skipReportingOnGoalAchievedOnActionButtonClick: true,
    isListItemLinkOpener: false,
    supportsListLayout: true,
    resolveListItemsCount: actionSettings => {
        return doppeListActionSettingsFilterHiddenListItemsFromActionView(actionSettings.listDataItems).length;
    },
    resolveListItems: (action, htmlSiteInfo) => {
        const actionSettings = action.settings;
        return actionSettings.listDataItems.map(item => {
            return {
                url: doppeActionCtaButtonsResolveMainURL(
                    doppeListActionListItemCalculateItemButtons(actionSettings.listItemTemplateCtaButtonsSettings, item), {
                        htmlSiteInfo: htmlSiteInfo,
                    }) ?? null,
                title: doppeActionFullNameGetName(item.name),
                action: action,
                description: item.description,
                image: item.media?.[0] ?? null,
                itemId: item.id,
                couponCode: doppeHideableValueCreateHidden(''),
                ribbon: doppeHideableValueCreateHidden(''),
                enabled: item.enabled,
                deleted: item.deleted,
                displayInSearch: item.displayInSearch,
            }
        });
    },
    resolveListSearchSettings: actionSettings => {
        return actionSettings.listSearchSettings;
    },
    resolveUsedMedia: actionSettings => {
        return arrayRemoveNullValues([...arrayFlatten(actionSettings.listDataItems.map(itemSettings => {
            return itemSettings.media ?? []
        })), doppeHideableValueIsVisibleAndNotEqualValue(actionSettings.mainImage, null) ? actionSettings.mainImage : null]);
    },
    resolveMainMedia: actionSettings => {
        return doppeListActionWithMainDetailsGetMainImage(actionSettings, actionSettings.listDataItems[0]?.media?.[0] ?? null);
    },
    resolveMainLink: (actionSettings, htmlSiteInfo) => {
        return doppeActionCtaButtonsResolveMainURL(actionSettings, {
            htmlSiteInfo: htmlSiteInfo,
        }) ?? null;
    },
    prepareForViewer: (actionSettings, options) => {
        doppeDtoActionSettingsRemoveInnerCtaContentForLockedAction(actionSettings, actionSettings => {
            doppeListActionItemCtaButtonsResetSettingsForLock(actionSettings);
            doppeActionCtaButtonsResetSettingsForLock(actionSettings);
        }, options);
    },
    replaceExternalImages: (actionSettings, externalImagesUrlToImageResourceMap) => {
        return immerProduce(actionSettings, actionSettings => {
            for (const contact of actionSettings.listDataItems) {
                if (contact.media) {
                    contact.media = contact.media.map(
                        image => wixMediaResourceReplaceExternalImage(image, externalImagesUrlToImageResourceMap))
                }
            }

            doppeListActionWithMainDetailsReplaceMainImage(actionSettings, externalImagesUrlToImageResourceMap);
        })
    },
});

