import {
    doppeActionTypeCreateSettingsResolvingFunction,
    doppeActionTypeDefineMetadata
} from '../../client-server-common/doppe-action-types/doppe-action-type';


export namespace DoppeActionTypeIframe {

    export interface ActionSettings {
        iframeUrl: string;
    }

}

export const doppeActionTypeIframe = doppeActionTypeDefineMetadata<DoppeActionTypeIframe.ActionSettings>({
    id: 'io.bymo.action/iframe',
    resolveActionSettings: doppeActionTypeCreateSettingsResolvingFunction({
        iframeUrl: '',
    }),
    hasNoGoalMeasuring: false,
    isWidgetOpener: true,
    isModalOpener: true,
    canRenderInPage: false,
    skipReportingOnGoalAchievedOnActionButtonClick: false,
    hasNoLockInnerCTA: true,
})

