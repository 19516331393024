import {doppeActionTypeDefineMetadata} from '../../client-server-common/doppe-action-types/doppe-action-type';
import {
    DoppeHideableValue,
    doppeHideableValueCreateHidden,
    doppeHideableValueGetVisibleValue
} from '../../client-server-common/types/doppe-hideable-value';
import {WixMediaResource, wixMediaResourceReplaceExternalImage} from '@wix/devzai-common-wix';
import {arrayRemoveNullValues, autoValueCreate, immerProduce} from '@wix/devzai-utils-common';
import {RichTextContent} from '@wix/devzai-utils-react';
import {
    doppeDtoActionSettingsRemoveInnerCtaContentForLockedAction
} from '../../client-server-common/types/doppe-dto-action';
import {
    DoppeActionCtaButtonSettings,
    doppeActionCtaButtonTypeGetDefaultCtaButtonSettingsMigration,
    getDefaultDoppeActionCtaButtonSettings
} from '../../client-server-common/types/doppe-action-cta-button-settings';
import {
    doppeActionCtaButtonsResetSettingsForLock,
    DoppeActionCTAType,
    DoppeActionCTATypeLinkAutoSource
} from '../../client-server-common/types/doppe-action-cta-button';
import {
    doppeActionCtaButtonsResolveMainURL
} from '../doppe-actions-common/doppe-cta-button-utils/doppe-cta-button-utils';
import {
    doppeActionContentSectionSettingsDefaultValuesMigration,
    DoppeActionContentSectionsSettings
} from '../../client-server-common/types/doppe-action-content-sections-settings';
import {
    DoppeActionCtaButtonsSettings,
    doppeActionCtaButtonsSettingsDefaultValuesMigration
} from '../../client-server-common/types/doppe-action-cta-buttons-settings';

export namespace DoppeActionTypeShare {
    export interface ActionSettings extends
        DoppeActionContentSectionsSettings,
        DoppeActionCtaButtonsSettings {
        shareLink: string;
        shareTitle: DoppeHideableValue<string>;
        description: DoppeHideableValue<RichTextContent>;
        shareImage: WixMediaResource | null;
    }

    export type DeprecatedFields = Partial<DoppeActionCtaButtonSettings> & {
        shareButtonText?: string;
    }

    export type PartialProps = Partial<ActionSettings> & DeprecatedFields;
}

export const doppeActionTypeShare = doppeActionTypeDefineMetadata<DoppeActionTypeShare.ActionSettings, DoppeActionTypeShare.PartialProps>({
    id: 'io.bymo.action/share',
    resolveActionSettings: action => {

        const {
            shareButtonText = '',
            shareTitle = doppeHideableValueCreateHidden(''),
            ctaButton,
            ctaButtons,
            sections,
            ...restSettings
        } = action.settings;

        return {
            shareLink: '',
            shareTitle: shareTitle,
            description: '',
            shareButtonText: '',
            shareImage: null,
            ...getDefaultDoppeActionCtaButtonSettings({
                buttonText: shareButtonText,
                showButton: true,
                settings: {
                    type: DoppeActionCTAType.Share,
                    title:  doppeHideableValueGetVisibleValue(shareTitle, ''),
                    description: '',
                    url: autoValueCreate(DoppeActionCTATypeLinkAutoSource.SameAsSourceURL),
                }
            }),
            ...doppeActionCtaButtonTypeGetDefaultCtaButtonSettingsMigration({
                hasSourceURL: true,
                ctaButton: ctaButton,
            }),
            ...doppeActionContentSectionSettingsDefaultValuesMigration(action.settings),
            ...doppeActionCtaButtonsSettingsDefaultValuesMigration(action.settings, true),
            ...restSettings
        }
    },
    isWidgetOpener: true,
    canRenderInPage: true,
    skipReportingOnGoalAchievedOnActionButtonClick: true,
    resolveUsedMedia: actionSettings => {
        return arrayRemoveNullValues([actionSettings.shareImage]);
    },
    resolveMainMedia: actionSettings => {
        return actionSettings.shareImage;
    },
    resolveMainLink: (actionSettings, htmlSiteInfo) => {
        return doppeActionCtaButtonsResolveMainURL(actionSettings, {
            htmlSiteInfo: htmlSiteInfo,
            sourceURL: actionSettings.shareLink
        }) ?? null;
    },
    prepareForViewer: (actionSettings, options) => {
        doppeDtoActionSettingsRemoveInnerCtaContentForLockedAction(actionSettings, actionSettings => {
            actionSettings.shareLink = '';
            doppeActionCtaButtonsResetSettingsForLock(actionSettings);
        }, options);
    },
    replaceExternalImages: (actionSettings, externalImagesUrlToImageResourceMap) => {
        return immerProduce(actionSettings, actionSettings => {
            if (actionSettings.shareImage) {
                actionSettings.shareImage =
                    wixMediaResourceReplaceExternalImage(actionSettings.shareImage, externalImagesUrlToImageResourceMap)
            }
        })
    },
});