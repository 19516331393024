import {
    doppeActionTypeCreateSettingsResolvingFunction,
    doppeActionTypeDefineMetadata
} from '../../client-server-common/doppe-action-types/doppe-action-type';
import {Values} from '@wix/devzai-utils-common';


export namespace DoppeActionTypeSubscribeEmbed {
    export const SubscribeType = {
        Substack: 'substack',
    } as const;

    export type SubscribeType = Values<typeof SubscribeType>;

    export interface ActionSettings {
        userId: string;
        subscribeType: SubscribeType
    }


}

export const doppeActionTypeSubscribeEmbed = doppeActionTypeDefineMetadata<DoppeActionTypeSubscribeEmbed.ActionSettings>({
    id: 'io.bymo.action/subscribe-embed',
    resolveActionSettings: doppeActionTypeCreateSettingsResolvingFunction({
        userId: '',
        subscribeType: DoppeActionTypeSubscribeEmbed.SubscribeType.Substack
    }),
    hasNoGoalMeasuring: true,
    isWidgetOpener: true,
    canRenderInPage: true,
    skipReportingOnGoalAchievedOnActionButtonClick: true,
    hasNoLockInnerCTA: true,
})