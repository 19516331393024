import {doppeActionTypeDefineMetadata} from '../../client-server-common/doppe-action-types/doppe-action-type';
import {
    doppeHideableValueCreateHidden,
    doppeHideableValueIsVisibleAndNotEqualValue
} from '../../client-server-common/types/doppe-hideable-value';
import {wixMediaResourceReplaceExternalImage} from '@wix/devzai-common-wix';
import {
    arrayFlatten,
    arrayRemoveNullValues,
    asArray,
    immerProduce,
    OmitStrict,
    PartiallyOptional,
    PartiallyRequired
} from '@wix/devzai-utils-common';
import {
    DoppeActionLayout,
    doppeListActionGetDefaultListSettings, doppeListActionGetDefaultSearchListSettings,
    doppeListActionGetItemClickBehaviourDefaultListSettings,
    doppeListActionGetMainDetailsDefaultSettings,
    doppeListActionItemCtaButtonsResetSettingsForLock, doppeListActionListItemCalculateItemButtons,
    doppeListActionListItemGetDefaultListItemSettingsMigration,
    doppeListActionListItemWithCtaButtonsMigration,
    doppeListActionListItemWithSectionsMigration,
    DoppeListActionSettings, doppeListActionSettingsFilterHiddenListItemsFromActionView,
    doppeListActionTemplateCtaButtonsSettingsDefaultValuesMigration,
    doppeListActionTemplateSectionsSettingsDefaultValuesMigration,
    doppeListActionWithMainDetailsGetMainImage,
    doppeListActionWithMainDetailsReplaceMainImage,
} from '../../client-server-common';
import {
    doppeActionCtaButtonsResetSettingsForLock
} from '../../client-server-common/types/doppe-action-cta-button';
import {
    doppeDtoActionSettingsRemoveInnerCtaContentForLockedAction
} from '../../client-server-common/types/doppe-dto-action';
import {
    doppeActionContentSectionSettingsDefaultValuesMigration,
    DoppeActionContentSectionsSettings
} from '../../client-server-common/types/doppe-action-content-sections-settings';
import {
    DoppeActionCtaButtonsSettings,
    doppeActionCtaButtonsSettingsDefaultValuesMigration
} from '../../client-server-common/types/doppe-action-cta-buttons-settings';
import {DoppeActionTypeTestimonial} from '../doppe-action-type-testimonial/doppe-action-type-testimonial';
import {
    doppeActionCtaButtonsResolveMainURL
} from '../doppe-actions-common/doppe-cta-button-utils/doppe-cta-button-utils';


export namespace DoppeActionTypeTestimonialsList {


    export interface TestimonialItemBaseSettings extends DoppeListActionSettings.ListItemBaseSettings,
        OmitStrict<DoppeActionTypeTestimonial.ActionSettings, 'sections' | 'ctaButtons'> {
    }

    export interface TestimonialItemSettings extends DoppeListActionSettings.ListItemWithSections,
        DoppeListActionSettings.ListItemWithCtaButtons,
        TestimonialItemBaseSettings {
    }

    export interface ActionSettings extends DoppeListActionSettings<DoppeActionTypeTestimonialsList.TestimonialItemSettings>,
        DoppeActionContentSectionsSettings,
        DoppeListActionSettings.WithSearchSettings,
        DoppeListActionSettings.WithMainDetails,
        DoppeActionCtaButtonsSettings,
        DoppeListActionSettings.WithItemClickBehaviour {
        itemShowRating: boolean;
        previewShowDescription: boolean;
    }

}

export function doppeActionTypeTestimonialsListResolveListItemSettings(testimonial: PartiallyRequired<DoppeActionTypeTestimonialsList.TestimonialItemSettings, 'id'>) {

    const {
        ctaButtonsOverridesMap,
        customCtaButtons,
        customSections,
        sectionsOverridesMap,
        id,
        deleted,
        displayInSearch,
        enabled,
        ...restProduct
    } = testimonial;

    return {
        ...doppeListActionListItemWithCtaButtonsMigration(testimonial, false),
        ...doppeListActionListItemWithSectionsMigration(testimonial),
        ...doppeListActionListItemGetDefaultListItemSettingsMigration(testimonial.id, testimonial),
        ...restProduct as PartiallyOptional<DoppeActionTypeTestimonialsList.TestimonialItemSettings, 'sectionsOverridesMap' | 'ctaButtonsOverridesMap' | 'customCtaButtons' | 'customSections'>
    }
}

export function doppeActionTypeTestimonialsListResolveActionSettings(actionSettings: Partial<DoppeActionTypeTestimonialsList.ActionSettings>) {
    const {
        sections,
        listDataItems = [],
        ctaButtons,
        listItemTemplateCtaButtonsSettings,
        listItemTemplateSectionsSettings,
        listSearchSettings,
        ...restSettings
    } = actionSettings;

    return {
        itemShowRating: true,
        previewShowTitle: true,
        previewShowDescription: true,
        previewShowAddress: true,
        previewShowPrice: true,
        ...doppeListActionGetItemClickBehaviourDefaultListSettings(),
        ...doppeListActionGetMainDetailsDefaultSettings(),
        ...doppeListActionGetDefaultSearchListSettings(actionSettings),
        ...doppeListActionGetDefaultListSettings({
            layout: DoppeActionLayout.Column,
            listDataItems: listDataItems.map(doppeActionTypeTestimonialsListResolveListItemSettings),
        }),
        ...doppeActionContentSectionSettingsDefaultValuesMigration(actionSettings),
        ...doppeActionCtaButtonsSettingsDefaultValuesMigration(actionSettings, false),
        ...doppeListActionTemplateCtaButtonsSettingsDefaultValuesMigration(actionSettings, false),
        ...doppeListActionTemplateSectionsSettingsDefaultValuesMigration(actionSettings),
        ...restSettings
    }
}

export const doppeActionTypeTestimonialsList = doppeActionTypeDefineMetadata<DoppeActionTypeTestimonialsList.ActionSettings>({
    id: 'co.hopp.action/testimonials-list',
    resolveActionSettings: action => {
        return doppeActionTypeTestimonialsListResolveActionSettings(action.settings);
    },
    prepareForViewer: (actionSettings, options) => {
        doppeDtoActionSettingsRemoveInnerCtaContentForLockedAction(actionSettings, actionSettings => {
            doppeListActionItemCtaButtonsResetSettingsForLock(actionSettings);
            doppeActionCtaButtonsResetSettingsForLock(actionSettings);
        }, options);
    },
    isWidgetOpener: true,
    canRenderInPage: true,
    skipReportingOnGoalAchievedOnActionButtonClick: true,
    isPaymentAction: false,
    isFormAction: false,
    isListItemLinkOpener: false,
    supportsListLayout: true,
    resolveUsedMedia: actionSettings => {
        return arrayRemoveNullValues([...arrayFlatten(actionSettings.listDataItems.map(itemSettings => {
            return itemSettings.profileMedia ? asArray(itemSettings.profileMedia) : []
        })), doppeHideableValueIsVisibleAndNotEqualValue(actionSettings.mainImage, null) ? actionSettings.mainImage : null]);
    },
    resolveMainMedia: actionSettings => {
        return doppeListActionWithMainDetailsGetMainImage(actionSettings, actionSettings.listDataItems[0]?.profileMedia ?? null);
    },
    resolveMainLink: (actionSettings, htmlSiteInfo) => {
        return doppeActionCtaButtonsResolveMainURL(actionSettings, {
            htmlSiteInfo: htmlSiteInfo
        }) ?? null;
    },
    resolveListItemsCount: actionSettings => {
        return doppeListActionSettingsFilterHiddenListItemsFromActionView(actionSettings.listDataItems).length;
    },
    resolveListItems: (action, htmlSiteInfo) => {
        const actionSettings = action.settings;
        return actionSettings.listDataItems.map(item => {
            return {
                url: doppeActionCtaButtonsResolveMainURL(
                    doppeListActionListItemCalculateItemButtons(actionSettings.listItemTemplateCtaButtonsSettings, item), {
                        htmlSiteInfo: htmlSiteInfo,
                    }) ?? null,
                title: item.name,
                action: action,
                couponCode: doppeHideableValueCreateHidden(''),
                description: item.description,
                image: item.profileMedia ? asArray(item.profileMedia)[0] : null,
                itemId: item.id,
                ribbon: item.ribbon,
                enabled: item.enabled,
                deleted: item.deleted,
                displayInSearch: item.displayInSearch
            }
        });
    },
    resolveListSearchSettings: actionSettings => {
        return actionSettings.listSearchSettings;
    },
    replaceExternalImages: (actionSettings, externalImagesUrlToImageResourceMap) => {
        return immerProduce(actionSettings, actionSettings => {
            for (const item of actionSettings.listDataItems) {
                if (item.profileMedia) {
                    item.profileMedia = wixMediaResourceReplaceExternalImage(item.profileMedia, externalImagesUrlToImageResourceMap)
                }
            }

            doppeListActionWithMainDetailsReplaceMainImage(actionSettings, externalImagesUrlToImageResourceMap);
        })
    },
});
