import {
    doppeActionTypeCreateSettingsResolvingFunction,
    doppeActionTypeDefineMetadata
} from '../../client-server-common/doppe-action-types/doppe-action-type';
import {WixMediaResource} from "@wix/devzai-common-wix";

export namespace DoppeActionTypeDevMediaView {
    export interface ActionSettings {
        mediaResource: WixMediaResource | null;
    }
}

export const doppeActionTypeDevMediaView = doppeActionTypeDefineMetadata<DoppeActionTypeDevMediaView.ActionSettings>({
    id: 'io.bymo.action/dev-media-view',
    resolveActionSettings: doppeActionTypeCreateSettingsResolvingFunction({
        mediaResource: null
    }),
    isWidgetOpener: true,
    canRenderInPage: true,
    settingsMetadata: false,
    skipReportingOnGoalAchievedOnActionButtonClick: true
});