import {doppeActionTypeDefineMetadata} from '../../client-server-common/doppe-action-types/doppe-action-type';
import {
    arrayFlatten,
    arrayRemoveNullValues,
    immerProduce,
    iterableMapToArray,
    objectOmit,
    OmitStrict,
    PartiallyOptional
} from '@wix/devzai-utils-common';
import {wixMediaResourceReplaceExternalImage} from '@wix/devzai-common-wix';
import {
    convertCommonListSettingToDefaultDoppeActionCtaButtonSettingsFromListAction,
    DoppeActionLayout,
    doppeExternalMembershipPlanProviderResolveBusinessEntityReference,
    DoppeHideableValue,
    doppeHideableValueIsVisibleAndNotEqualValue,
    doppeListActionGetDefaultListSettings,
    doppeListActionGetDefaultSearchListSettings,
    doppeListActionGetItemClickBehaviourDefaultListSettings,
    doppeListActionGetMainDetailsDefaultSettings,
    doppeListActionGetWithExternalDataSourceDefaultListSettings,
    doppeListActionItemCtaButtonsResetSettingsForLock,
    doppeListActionListItemGetDefaultListItemSettingsMigration,
    doppeListActionListItemWithCtaButtonsMigration,
    doppeListActionListItemWithSectionsMigration,
    DoppeListActionSettings,
    doppeListActionSettingsFilterHiddenListItemsFromActionView,
    doppeListActionSettingsGetListItemCtaButtonsDefaultSettings,
    doppeListActionSettingsGetListItemSectionsDefaultSettings,
    doppeListActionTemplateCtaButtonsSettingsDefaultValuesMigration,
    doppeListActionTemplateSectionsSettingsDefaultValuesMigration,
    doppeListActionWithMainDetailsGetMainImage,
    doppeListActionWithMainDetailsReplaceMainImage
} from '../../client-server-common';
import {
    convertDoppeWixMembershipPlanToPlanSettings,
    DoppeActionTypeMembership
} from '../doppe-action-type-membership/doppe-action-type-membership';
import {DoppeDtoMembershipPlan} from '../../client-server-common/types/doppe-dto-membership-plan';
import {
    DoppeMembershipPlansExternalDataSource,
    doppeMembershipPlansExternalDataSourceResolveBusinessEntityReferences
} from '../../client-server-common/types/doppe-membership-plans-external-data-source';
import {
    doppeDtoActionSettingsRemoveInnerCtaContentForLockedAction
} from '../../client-server-common/types/doppe-dto-action';
import {doppeActionCtaButtonsResetSettingsForLock} from '../../client-server-common/types/doppe-action-cta-button';
import {
    DoppeActionCtaButtonSettings,
    doppeActionCtaButtonTypeGetDefaultCtaButtonSettingsMigration,
    getDefaultDoppeActionCtaButtonSettings
} from '../../client-server-common/types/doppe-action-cta-button-settings';
import {
    doppeActionCtaButtonsResolveMainURL
} from '../doppe-actions-common/doppe-cta-button-utils/doppe-cta-button-utils';
import {
    doppeActionContentSectionSettingsDefaultValuesMigration,
    DoppeActionContentSectionsSettings
} from '../../client-server-common/types/doppe-action-content-sections-settings';
import {
    DoppeActionCtaButtonsSettings,
    doppeActionCtaButtonsSettingsDefaultValuesMigration
} from '../../client-server-common/types/doppe-action-cta-buttons-settings';


export namespace DoppeActionTypeMembershipList {

    export interface PlanSettings extends
        DoppeListActionSettings.ListItemWithCtaButtons,
        DoppeListActionSettings.ListItemWithSections,
        DoppeListActionSettings.ListItemBaseSettings,
        DoppeActionTypeMembership.PlanSettings {
        membershipTitle: string;
    }

    export interface ActionSettings extends
        DoppeListActionSettings<PlanSettings>,
        DoppeListActionSettings.WithSearchSettings,
        DoppeListActionSettings.WithMainDetails,
        DoppeListActionSettings.WithItemClickBehaviour,
        DoppeListActionSettings.WithExternalDataSourceSupport<DoppeMembershipPlansExternalDataSource>,
        DoppeActionContentSectionsSettings,
        DoppeActionCtaButtonsSettings {
        itemShowPrice: boolean;
        previewShowTitle: boolean;
        previewShowDescription: boolean;
        previewShowPrice: boolean;
        previewShowCoupon: boolean;
    }

    export interface DeprecatedFields extends Partial<DoppeListActionSettings.WithCtaButton> {
        plans?: PlanSettings[];
        previewPlanButtonText?: string;
    }

    export interface DeprecatedItemFields extends Partial<DoppeActionCtaButtonSettings> {
        linkButtonText?: DoppeHideableValue<string>;
    }

    export type PartialProps = Partial<OmitStrict<ActionSettings, keyof DoppeListActionSettings<PlanSettings>>> & DeprecatedFields & DoppeListActionSettings<PlanSettings & DeprecatedItemFields>;
}


export const doppeActionTypeMembershipList = doppeActionTypeDefineMetadata<DoppeActionTypeMembershipList.ActionSettings, DoppeActionTypeMembershipList.PartialProps>({
    id: 'io.bymo.action/membership-list',
    resolveActionSettings: action => {

        const {
            listDataItems = [],
            ctaButton,
            listItemTemplateCtaButtonsSettings,
            listItemTemplateSectionsSettings,
            ctaButtons,
            sections,
            listSearchSettings,
            ...restSettings
        } = action.settings;

        return {
            itemShowPrice: true,
            previewShowCoupon: true,
            previewShowPrice: true,
            previewShowDescription: true,
            previewShowTitle: true,
            previewPlanButtonText: 'Subscribe Now',
            ...doppeListActionGetWithExternalDataSourceDefaultListSettings(),
            ...doppeListActionGetDefaultSearchListSettings(action.settings),
            ...doppeListActionGetDefaultListSettings({
                layout: DoppeActionLayout.Swiper,
                listDataItems: listDataItems.map(plan => {

                    const {
                        linkButtonText,
                        ctaButton,
                        customSections,
                        customCtaButtons,
                        sectionsOverridesMap,
                        ctaButtonsOverridesMap,
                        ...resetPlan
                    } = plan;

                    return {
                        membershipProvider: null,
                        membershipProviderId: null,
                        ...getDefaultDoppeActionCtaButtonSettings({
                            buttonText: linkButtonText,
                            showButton: true
                        }),
                        ...doppeActionCtaButtonTypeGetDefaultCtaButtonSettingsMigration({
                            hasSourceURL: true,
                            ctaButton: ctaButton,
                        }),
                        ...doppeListActionListItemGetDefaultListItemSettingsMigration(plan.id),
                        ...doppeListActionListItemWithCtaButtonsMigration(plan, true),
                        ...doppeListActionListItemWithSectionsMigration(plan),
                        ...resetPlan as PartiallyOptional<DoppeActionTypeMembershipList.PlanSettings & DoppeActionTypeMembershipList.DeprecatedItemFields, 'membershipProvider' | 'membershipProviderId' | 'ctaButton' | 'customCtaButtons' |'customSections' | 'ctaButtonsOverridesMap' | 'sectionsOverridesMap'>
                    }
                }),
            }),
            ...doppeListActionGetItemClickBehaviourDefaultListSettings(),
            ...doppeListActionGetMainDetailsDefaultSettings({
                defaultTitle: ''
            }),
            ...convertCommonListSettingToDefaultDoppeActionCtaButtonSettingsFromListAction(action.settings),
            ...doppeActionCtaButtonTypeGetDefaultCtaButtonSettingsMigration({
                ctaButton: ctaButton,
                hasSourceURL: false
            }),
            ...doppeActionContentSectionSettingsDefaultValuesMigration(action.settings),
            ...doppeActionCtaButtonsSettingsDefaultValuesMigration(action.settings, false),
            ...doppeListActionTemplateCtaButtonsSettingsDefaultValuesMigration(action.settings, true),
            ...doppeListActionTemplateSectionsSettingsDefaultValuesMigration(action.settings),
            ...restSettings
        }
    },
    prepareForViewer: (actionSettings, options) => {
        doppeDtoActionSettingsRemoveInnerCtaContentForLockedAction(actionSettings, actionSettings => {
            doppeListActionItemCtaButtonsResetSettingsForLock(actionSettings);
            doppeActionCtaButtonsResetSettingsForLock(actionSettings);
        }, options);
    },
    isWidgetOpener: true,
    canRenderInPage: true,
    skipReportingOnGoalAchievedOnActionButtonClick: true,
    supportsListLayout: true,
    isListItemLinkOpener: true,
    resolveUsedMedia: actionSettings => {
        return arrayRemoveNullValues([...arrayFlatten(actionSettings.listDataItems.map(itemSettings => {
            return itemSettings.membershipImages ?? []
        })), doppeHideableValueIsVisibleAndNotEqualValue(actionSettings.mainImage, null) ? actionSettings.mainImage : null]);
    },
    resolveMainMedia: actionSettings => {
        return doppeListActionWithMainDetailsGetMainImage(actionSettings, actionSettings.listDataItems[0]?.membershipImages?.[0] ?? null);
    },
    resolveMainLink: (actionSettings, htmlSiteInfo) => {
        return doppeActionCtaButtonsResolveMainURL(actionSettings, {
            htmlSiteInfo: htmlSiteInfo,
        }) ?? null;
    },
    isConnectedToExternalDataSource: actionSettings => {
        return !!actionSettings.externalDataSource
    },
    resolveUsedBusinessEntities: actionSettings => {
        const externalDataSource = actionSettings.externalDataSource;
        if (externalDataSource) {
            return [
                doppeMembershipPlansExternalDataSourceResolveBusinessEntityReferences(externalDataSource)
            ];
        } else {
            return iterableMapToArray(actionSettings.listDataItems, (plan, skip) => {
                return plan.membershipProvider !== null && plan.membershipProviderId !== null ?
                    doppeExternalMembershipPlanProviderResolveBusinessEntityReference(
                        plan.membershipProvider,
                        plan.membershipProviderId
                    ) : skip
            });
        }
    },
    resolveListItemsCount: actionSettings => {
        return actionSettings.externalDataSource ? actionSettings.externalDataSourceItemsCount : doppeListActionSettingsFilterHiddenListItemsFromActionView(actionSettings.listDataItems).length;
    },
    resolveListItems: action => {
        const actionSettings = action.settings;
        return actionSettings.listDataItems.map(item => {
            return {
                title: item.membershipTitle,
                action: action,
                couponCode: item.couponCode,
                description: item.membershipDescription,
                image: item.membershipImages?.[0] ?? null,
                itemId: item.id,
                ribbon: item.ribbon,
                url: item.membershipPageLink,
                enabled: item.enabled,
                deleted: item.deleted,
                displayInSearch: item.displayInSearch,
            }
        });
    },
    resolveListSearchSettings: actionSettings => {
        return actionSettings.listSearchSettings;
    },
    replaceExternalImages: (actionSettings, externalImagesUrlToImageResourceMap) => {
        return immerProduce(actionSettings, actionSettings => {
            for (const plan of actionSettings.listDataItems) {
                if (plan.membershipImages) {
                    plan.membershipImages = plan.membershipImages.map(
                        image => wixMediaResourceReplaceExternalImage(image, externalImagesUrlToImageResourceMap))
                }
            }

            doppeListActionWithMainDetailsReplaceMainImage(actionSettings, externalImagesUrlToImageResourceMap);

        })
    },
});

export function convertDoppeWixMembershipPlanToPlansSettings(
    plan: DoppeDtoMembershipPlan,
    websiteUrl?: string,
): DoppeActionTypeMembershipList.PlanSettings {
    return {
        ...objectOmit(convertDoppeWixMembershipPlanToPlanSettings(plan, websiteUrl), ['sections', 'ctaButtons']),
        ...doppeListActionListItemGetDefaultListItemSettingsMigration(plan.id),
        membershipTitle: plan?.name ?? '',
        ...doppeListActionSettingsGetListItemCtaButtonsDefaultSettings(),
        ...doppeListActionSettingsGetListItemSectionsDefaultSettings(),
    }
}