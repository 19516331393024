import {enumGetValues, evaluateFunction, Values} from '@wix/devzai-utils-common';
import {DoppeBusinessEntity, DoppeBusinessEntityReference} from "./doppe-business-entity";

/**
 * An enum of all the external providers that can provide a product data.
 * For example: Wix, Amazon, Shopify.
 */
export const DoppeExternalProductProvider = {
    Wix: 'wix',
    Hopp: 'hopp',
} as const;

export type DoppeExternalProductProvider = Values<typeof DoppeExternalProductProvider>;

// TODO: This field should include all the possible SEMANTIC fields that an external product providers can provide
// TODO: for a product.
export const DoppeExternalProductField = {
    Price: 'Price',
    ProductPageUrl: 'ProductPageUrl',
    Media: 'Media',
    Title: 'Title',
    Description: 'Description',
    Ribbon: 'Ribbon',
} as const;

export type DoppeExternalProductField = Values<typeof DoppeExternalProductField>;

const AllExternalProductProviders = enumGetValues(DoppeExternalProductProvider);

const externalProductFieldsToSupportedProviders = {
    [DoppeExternalProductField.Price]: AllExternalProductProviders,
    [DoppeExternalProductField.ProductPageUrl]: AllExternalProductProviders,
    [DoppeExternalProductField.Media]: AllExternalProductProviders,
    [DoppeExternalProductField.Title]: AllExternalProductProviders,
    [DoppeExternalProductField.Description]: AllExternalProductProviders,
    [DoppeExternalProductField.Ribbon]: AllExternalProductProviders,
} satisfies {
    [field in DoppeExternalProductField]: DoppeExternalProductProvider[]
}

export function doppeExternalProductProviderIsFieldProvided (
    externalProductProvider: DoppeExternalProductProvider | null | undefined,
    externalProductField: DoppeExternalProductField
) {
    if (externalProductProvider === null || externalProductProvider === undefined) {
        return false;
    }

    return externalProductFieldsToSupportedProviders[externalProductField].includes(externalProductProvider);
}

export function doppeExternalProductProviderResolveBusinessEntityReference (
    externalItemProvider: DoppeExternalProductProvider,
    externalItemId: string
) {
    return evaluateFunction(() => {
        switch (externalItemProvider) {
            case DoppeExternalProductProvider.Wix: {
                return {
                    businessEntity: DoppeBusinessEntity.WixProduct,
                    id: externalItemId
                } as DoppeBusinessEntityReference<typeof DoppeBusinessEntity.WixProduct>
            }
            case DoppeExternalProductProvider.Hopp: {
                return {
                    businessEntity: DoppeBusinessEntity.HoppProduct,
                    id: externalItemId
                } as DoppeBusinessEntityReference<typeof DoppeBusinessEntity.HoppProduct>
            }
        }
    }) satisfies DoppeBusinessEntityReference
}