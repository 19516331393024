import {doppeActionTypeDefineViewerMetadata} from '../../client-server-common/doppe-action-types/doppe-action-type';
import React from 'react';
import {doppeActionTypePropertiesList} from './doppe-action-type-properties-list';

export const doppeActionTypePropertiesListViewer = doppeActionTypeDefineViewerMetadata(doppeActionTypePropertiesList, {

    loadViewerComponents: async () => {
        return {
            DoppeActionTypePropertiesListWidget: (await import('./doppe-action-type-properties-list-widget/doppe-action-type-properties-list-widget')).DoppeActionTypePropertiesListWidget
        }
    },

    renderWidget: (renderProps, viewerComponents) => {

        const {
            DoppeActionTypePropertiesListWidget
        } = viewerComponents;

        return <DoppeActionTypePropertiesListWidget {...renderProps} />;
    }
})