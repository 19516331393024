import {
    arrayRemoveNullValues,
    AutoValue,
    autoValueCreate,
    autoValueIsAutoValue,
    Values
} from '@wix/devzai-utils-common';
import {doppeActionTypeDefineMetadata} from '../../client-server-common/doppe-action-types/doppe-action-type';
import {
    DoppeHideableValue,
    doppeHideableValueCreateHidden,
    doppeHideableValueGetValue,
    doppeHideableValueIsVisible
} from '../../client-server-common/types/doppe-hideable-value';
import {DoppeDtoVideo} from '../../client-server-common/types/doppe-dto-video';
import {
    DoppeBymoPageVideoSource,
    doppeBymoPageVideoSourceResolveVideoUrl,
    DoppeExternalVideoProvider,
    doppeExternalVideoProviderResolveBusinessEntityReference
} from '../../client-server-common';
import {WixMediaResource} from '@wix/devzai-common-wix';
import {RichTextContent} from '@wix/devzai-utils-react';
import {
    doppeDtoActionSettingsRemoveInnerCtaContentForLockedAction
} from '../../client-server-common/types/doppe-dto-action';
import {
    DoppeActionCtaButtonSettings,
    doppeActionCtaButtonTypeGetDefaultCtaButtonSettingsMigration,
    getDefaultDoppeActionCtaButtonSettings
} from '../../client-server-common/types/doppe-action-cta-button-settings';
import {doppeActionCtaButtonsResetSettingsForLock} from '../../client-server-common/types/doppe-action-cta-button';
import {
    doppeActionCtaButtonsResolveMainURL
} from '../doppe-actions-common/doppe-cta-button-utils/doppe-cta-button-utils';
import {
    doppeActionContentSectionSettingsDefaultValuesMigration,
    DoppeActionContentSectionsSettings
} from '../../client-server-common/types/doppe-action-content-sections-settings';
import {
    DoppeActionCtaButtonsSettings,
    doppeActionCtaButtonsSettingsDefaultValuesMigration
} from '../../client-server-common/types/doppe-action-cta-buttons-settings';

export const DoppeActionTypeVideoURLAutoSource = {
    SameAsVideoURL: 'SameAsVideoURL',
} as const;

export type DoppeActionTypeVideoURLAutoSource = Values<typeof DoppeActionTypeVideoURLAutoSource>;

export namespace DoppeActionTypeVideo {

    export type DoppeActionTypeVideoURLSource = AutoValue<DoppeActionTypeVideoURLAutoSource> | string;

    export interface VideoSettings {
        externalVideoUrl: DoppeBymoPageVideoSource;
        title: DoppeHideableValue<string>;
        description: DoppeHideableValue<RichTextContent>;
        videoPlayerSettings: {
            autoplay: boolean;
            muteVideo: boolean;
        },
        videoImage: WixMediaResource | null;
        videoProvider: DoppeExternalVideoProvider | null,
        videoProviderId: string | null,
    }

    export interface ActionSettings extends
        DoppeActionContentSectionsSettings,
        DoppeActionCtaButtonsSettings,
        DoppeActionTypeVideo.VideoSettings {

    }

    export type DeprecatedFields = Partial<DoppeActionCtaButtonSettings> & {
        videoButtonText?: DoppeHideableValue<string>;
        videoButtonURL?: DoppeActionTypeVideoURLSource;
    }

    export type PartialProps = Partial<ActionSettings> & DeprecatedFields;
}

export const doppeActionTypeVideo = doppeActionTypeDefineMetadata<DoppeActionTypeVideo.ActionSettings, DoppeActionTypeVideo.PartialProps>({
    id: 'io.bymo.action/video',
    prepareForViewer: (actionSettings, options) => {
        doppeDtoActionSettingsRemoveInnerCtaContentForLockedAction(actionSettings, actionSettings => {
            actionSettings.externalVideoUrl = '';
            doppeActionCtaButtonsResetSettingsForLock(actionSettings);
        }, options);
    },
    resolveActionSettings: action => {

        const {
            videoButtonText,
            videoButtonURL,
            ctaButton,
            ctaButtons,
            sections,
            ...restSettings
        } = action.settings;

        return {
            externalVideoUrl: '',
            videoPlayerSettings: {
                autoplay: true,
                muteVideo: false
            },
            title: doppeHideableValueCreateHidden(''),
            description: doppeHideableValueCreateHidden(''),
            videoImage: null,
            videoProvider: null,
            videoProviderId: null,
            videoButtonURL: autoValueCreate(DoppeActionTypeVideoURLAutoSource.SameAsVideoURL),
            videoButtonText: videoButtonText,
            ...getDefaultDoppeActionCtaButtonSettings({
                buttonText: doppeHideableValueGetValue(videoButtonText),
                showButton: doppeHideableValueIsVisible(videoButtonText),
                url: videoButtonURL ? (autoValueIsAutoValue(videoButtonURL) ? undefined : videoButtonURL) : undefined
            }),
            ...doppeActionCtaButtonTypeGetDefaultCtaButtonSettingsMigration({
                hasSourceURL: true,
                ctaButton: ctaButton,
            }),
            ...doppeActionContentSectionSettingsDefaultValuesMigration(action.settings),
            ...doppeActionCtaButtonsSettingsDefaultValuesMigration(action.settings, true),
            ...restSettings
        }
    },
    resolveUsedBusinessEntities: actionSettings => {
        return arrayRemoveNullValues([
            actionSettings.videoProvider !== null && actionSettings.videoProviderId !== null ?
                doppeExternalVideoProviderResolveBusinessEntityReference(
                    actionSettings.videoProvider,
                    actionSettings.videoProviderId
                ) :
                null
        ])
    },
    resolveMainLink: (actionSettings, htmlSiteInfo) => {
        const url = doppeBymoPageVideoSourceResolveVideoUrl(actionSettings.externalVideoUrl);
        return typeof actionSettings.externalVideoUrl === 'string'  ? url : doppeActionCtaButtonsResolveMainURL(actionSettings, {
            htmlSiteInfo: htmlSiteInfo,
            sourceURL: url
        }) ?? null;
    },
    isWidgetOpener: true,
    isLinkOpener: true,
    canRenderInPage: true,
})

export function doppeActionTypeVideoResolveSettingsFromBoundEntity(
    actionSettings: DoppeActionTypeVideo.VideoSettings,
    _doppeDtoCoupon: DoppeDtoVideo
): DoppeActionTypeVideo.VideoSettings {
    return actionSettings
}

