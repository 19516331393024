import {doppeActionTypeDefineMetadata} from '../../client-server-common/doppe-action-types/doppe-action-type';
import {
    doppeDtoProductGetMediaItems,
    DoppeDtoStoreProduct,
    doppeDtoStoreProductPriceDataConvertToActionPrice,
    DoppeExternalProductProvider,
    doppeExternalProductProviderResolveBusinessEntityReference,
    DoppeHideableValue,
    doppeHideableValueCreateHidden,
    doppeHideableValueGetValue,
    doppeHideableValueGetVisibleValue,
    doppeHideableValueIsVisibleAndNotEqualValue
} from '../../client-server-common';
import {WixMediaResource, wixMediaResourceReplaceExternalImage} from '@wix/devzai-common-wix';
import {arrayRemoveNullValues, asArray, immerProduce} from '@wix/devzai-utils-common';
import {RichTextContent} from '@wix/devzai-utils-react';
import {
    doppeDtoActionSettingsRemoveInnerCtaContentForLockedAction
} from '../../client-server-common/types/doppe-dto-action';
import {
    doppeActionCtaButtonsResetSettingsForLock,
    doppeActionCtaButtonTypeGetDefaultSettings,
    DoppeActionCTAType,
    doppeActionMultipleCTAButtonCreate
} from '../../client-server-common/types/doppe-action-cta-button';
import {
    DoppeActionCtaButtonsSettings,
    doppeActionCtaButtonsSettingsDefaultValuesMigration
} from '../../client-server-common/types/doppe-action-cta-buttons-settings';
import {DoppeActionPrice} from '../../client-server-common/types/doppe-action-price';
import {
    doppeActionGetMediaViewDefaultSettingsMigration,
    DoppeActionMediaViewSettings
} from '../../client-server-common/types/doppe-action-media-view-settings';
import {
    doppeActionCtaButtonsResolveMainURL
} from '../doppe-actions-common/doppe-cta-button-utils/doppe-cta-button-utils';

export namespace DoppeActionTypeStoreProduct {

    export interface FilledBaseActionSettings extends DoppeActionTypeStoreProduct.BaseActionSettings {
        price: DoppeActionPrice
        productTitle: string;
        description: RichTextContent;
        productImage: WixMediaResource[] | null;
        ribbon: string;
    }

    export interface BaseActionSettings extends
        DoppeActionMediaViewSettings {
        price: DoppeActionPrice
        productTitle: string;
        description: RichTextContent;
        productImage: WixMediaResource[] | null;
        couponCode: DoppeHideableValue<string>;
        ribbon: string;
        productProvider: DoppeExternalProductProvider | null;
        productProviderId: string | null;
    }

    export interface ActionSettings extends
        BaseActionSettings,
        DoppeActionCtaButtonsSettings {
    }
}

export const doppeActionTypeStoreProduct = doppeActionTypeDefineMetadata<DoppeActionTypeStoreProduct.ActionSettings>({
    id: 'io.bymo.action/store-product',
    resolveActionSettings: action => {

        const {
            ctaButtons,
            mainMediaViewSettings,
            mainMediaViewLayout,
            ...restSettings
        } = action.settings;

        return {
            productTitle: (''),
            description: (''),
            productImage: null,
            couponCode: doppeHideableValueCreateHidden('SALE30'),
            ribbon: ('SALE'),
            productProvider: DoppeExternalProductProvider.Hopp,
            productProviderId: null,
            price: ({
                actualPrice: 100,
                currencyCode: 'USD',
            }),
            ...doppeActionGetMediaViewDefaultSettingsMigration(action.settings),
            ...doppeActionCtaButtonsSettingsDefaultValuesMigration({
                ctaButtons: ctaButtons,
            }, false),
            ...restSettings
        }
    },
    isWidgetOpener: true,
    canRenderInPage: true,
    skipReportingOnGoalAchievedOnActionButtonClick: true,
    resolveUsedMedia: actionSettings => {
        return arrayRemoveNullValues(asArray(doppeHideableValueGetVisibleValue(actionSettings.productImage, []) ?? []));
    },
    resolveMainMedia: actionSettings => {
        const mediaItems = arrayRemoveNullValues(asArray(doppeHideableValueGetVisibleValue(actionSettings.productImage, []) ?? []));
        return mediaItems[0] ?? null;
    },
    resolveMainLink: (actionSettings, htmlSiteInfo) => {
        return doppeActionCtaButtonsResolveMainURL(actionSettings, {
            htmlSiteInfo: htmlSiteInfo
        }) ?? null;
    },
    resolveCouponData: actionSettings => {
        return {
            showCouponCode: doppeHideableValueIsVisibleAndNotEqualValue(actionSettings.couponCode, ''),
            couponCode: doppeHideableValueGetValue(actionSettings.couponCode),
            couponDescription: '',
            couponTitle: doppeHideableValueGetVisibleValue(actionSettings.productTitle, ''),
        }
    },
    resolveUsedBusinessEntities: actionSettings => {
        return arrayRemoveNullValues([
            actionSettings.productProvider !== null && actionSettings.productProviderId !== null ?
                doppeExternalProductProviderResolveBusinessEntityReference(
                    actionSettings.productProvider,
                    actionSettings.productProviderId
                ) :
                null
        ])
    },
    replaceExternalImages: (actionSettings, externalImagesUrlToImageResourceMap) => {
        return immerProduce(actionSettings, actionSettings => {
            if (actionSettings.productImage) {
                const images = actionSettings.productImage ?? [];
                actionSettings.productImage = images.map(image => {
                    return wixMediaResourceReplaceExternalImage(image, externalImagesUrlToImageResourceMap)
                })
            }
        })
    },
    prepareForViewer: (actionSettings, options) => {
        doppeDtoActionSettingsRemoveInnerCtaContentForLockedAction(actionSettings, actionSettings => {
            doppeActionCtaButtonsResetSettingsForLock(actionSettings);
        }, options);
    },
});

export function doppeActionTypeStoreProductResolveSettingsFromBoundEntity(
    actionSettings: DoppeActionTypeStoreProduct.BaseActionSettings,
    storeProduct: DoppeDtoStoreProduct
): DoppeActionTypeStoreProduct.FilledBaseActionSettings {

    const productImages = doppeDtoProductGetMediaItems(storeProduct);

    return {
        ...actionSettings,
        productImage: productImages ?? actionSettings.productImage,
        price: doppeDtoStoreProductPriceDataConvertToActionPrice(storeProduct.price) ?? actionSettings.price,
        productTitle: (storeProduct.name ?? actionSettings.productTitle),
        description: (storeProduct.description ?? actionSettings.description),
        ribbon: storeProduct.ribbon ?? actionSettings.ribbon,
    }
}

export function convertDoppeWixProductToStoreProductSettings(options: {
    storeProduct: DoppeDtoStoreProduct, productSettings?: DoppeActionTypeStoreProduct.ActionSettings
}) {

    const {
        storeProduct,
        productSettings = {},
    } = options;

    const productImages = doppeDtoProductGetMediaItems(storeProduct)

    const productTitle = storeProduct?.name;

    return {
        couponCode: doppeHideableValueCreateHidden(''),
        ...doppeActionCtaButtonsSettingsDefaultValuesMigration({
            ctaButtons: [doppeActionMultipleCTAButtonCreate({
                text: '',
                isRequired: true,
                settings: doppeActionCtaButtonTypeGetDefaultSettings(DoppeActionCTAType.OpenProduct, false)
            })],
        }, false),
        ...productSettings,
        ...doppeActionGetMediaViewDefaultSettingsMigration(productSettings),
        price: (doppeDtoStoreProductPriceDataConvertToActionPrice(storeProduct.price)),
        ribbon: (storeProduct.ribbon ?? ''),
        productImage: (productImages),
        productTitle: (productTitle ?? ''),
        description: (storeProduct.descriptionPlainText),
        productProvider: DoppeExternalProductProvider.Hopp,
        productProviderId: storeProduct.id,
    } satisfies DoppeActionTypeStoreProduct.ActionSettings
}