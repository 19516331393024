import {doppeActionTypeDefineMetadata} from '../../client-server-common/doppe-action-types/doppe-action-type';
import {
    arrayFlatten,
    arrayRemoveNullValues,
    asArray,
    immerProduce,
    iterableMapToArray,
    objectOmit,
    OmitStrict,
    PartiallyOptional
} from '@wix/devzai-utils-common';
import {
    convertDoppeWixBookingServiceToServiceSettings,
    DoppeActionTypeService,
    doppeActionTypeServiceActionContentSettingsMetadata
} from '../doppe-action-type-service/doppe-action-type-service';
import {wixMediaResourceReplaceExternalImage} from '@wix/devzai-common-wix';
import {
    convertCommonListSettingToDefaultDoppeActionCtaButtonSettingsFromListAction,
    DoppeDtoBookingService,
    DoppeHideableValue,
    doppeHideableValueCreateHidden,
    doppeHideableValueGetValue,
    doppeHideableValueGetVisibleValue,
    doppeHideableValueIsVisibleAndNotEqualValue,
    doppeHideableValueModifyValue,
    doppeListActionContentSettingsMetadataCreateForListActionWithMainDetails,
    doppeListActionGetDefaultListSettings,
    doppeListActionGetDefaultSearchListSettings,
    doppeListActionGetItemClickBehaviourDefaultListSettings,
    doppeListActionGetMainDetailsDefaultSettings,
    doppeListActionGetWithExternalDataSourceDefaultListSettings,
    doppeListActionItemCtaButtonsResetSettingsForLock,
    doppeListActionListItemGetDefaultListItemSettingsMigration,
    doppeListActionListItemWithCtaButtonsMigration,
    doppeListActionListItemWithSectionsMigration,
    DoppeListActionSettings,
    doppeListActionSettingsFilterHiddenListItemsFromActionView,
    doppeListActionSettingsGetListItemCtaButtonsDefaultSettings,
    doppeListActionSettingsGetListItemSectionsDefaultSettings,
    doppeListActionTemplateCtaButtonsSettingsDefaultValuesMigration,
    doppeListActionTemplateSectionsSettingsDefaultValuesMigration,
    doppeListActionWithMainDetailsGetMainImage,
    doppeListActionWithMainDetailsReplaceMainImage
} from '../../client-server-common';
import {
    doppeExternalServiceProviderResolveBusinessEntityReference
} from '../../client-server-common/types/doppe-external-services';
import {
    DoppeServicesExternalDataSource,
    doppeServicesExternalDataSourceResolveBusinessEntityReferences
} from '../../client-server-common/types/doppe-services-external-data-source';
import {
    doppeDtoActionSettingsRemoveInnerCtaContentForLockedAction
} from '../../client-server-common/types/doppe-dto-action';
import {DoppeAppPageContentLocales} from '../../client/doppe-app-locales/doppe-app-page-content-locales';
import {doppeActionCtaButtonsResetSettingsForLock} from '../../client-server-common/types/doppe-action-cta-button';
import {
    DoppeActionCtaButtonSettings,
    doppeActionCtaButtonTypeGetDefaultCtaButtonSettingsMigration,
    getDefaultDoppeActionCtaButtonSettings
} from '../../client-server-common/types/doppe-action-cta-button-settings';
import {
    doppeActionCtaButtonsResolveMainURL
} from '../doppe-actions-common/doppe-cta-button-utils/doppe-cta-button-utils';
import {
    doppeActionContentSectionSettingsDefaultValuesMigration,
    DoppeActionContentSectionsSettings
} from '../../client-server-common/types/doppe-action-content-sections-settings';
import {
    DoppeActionCtaButtonsSettings,
    doppeActionCtaButtonsSettingsDefaultValuesMigration
} from '../../client-server-common/types/doppe-action-cta-buttons-settings';


export namespace DoppeActionTypeServicesList {

    export interface ServiceSettings extends
        DoppeListActionSettings.ListItemWithCtaButtons,
        DoppeListActionSettings.ListItemWithSections,
        DoppeListActionSettings.ListItemBaseSettings,
        DoppeActionTypeService.ServiceSettings {
        serviceTitle: string;
    }

    export interface ActionSettings extends
        DoppeListActionSettings<ServiceSettings>,
        DoppeListActionSettings.WithSearchSettings,
        DoppeListActionSettings.WithMainDetails,
        DoppeListActionSettings.WithItemClickBehaviour,
        DoppeListActionSettings.WithExternalDataSourceSupport<DoppeServicesExternalDataSource>,
        DoppeActionContentSectionsSettings,
        DoppeActionCtaButtonsSettings {
        itemShowPrice: boolean;
        previewShowTitle: boolean;
        previewShowDescription: boolean;
        previewShowCoupon: boolean;
        previewShowPrice: boolean;
    }

    export interface DeprecatedFields extends Partial<DoppeListActionSettings.WithCtaButton> {
        services?: ServiceSettings[];
        previewServiceButtonText?: string;
    }

    export interface DeprecatedItemFields extends Partial<DoppeActionCtaButtonSettings> {
        serviceButtonText?: DoppeHideableValue<string>;
    }

    export type PartialProps = Partial<OmitStrict<ActionSettings, keyof DoppeListActionSettings<ServiceSettings>>> & DeprecatedFields & DoppeListActionSettings<ServiceSettings & DeprecatedItemFields>;
}


export const doppeActionTypeServicesList = doppeActionTypeDefineMetadata<DoppeActionTypeServicesList.ActionSettings, DoppeActionTypeServicesList.PartialProps>({
    id: 'io.bymo.action/services-list',
    resolveActionSettings: action => {

        const {
            listDataItems = [],
            ctaButton,
            listItemTemplateCtaButtonsSettings,
            listItemTemplateSectionsSettings,
            ctaButtons,
            sections,
            ...restSettings
        } = action.settings;

        return {
            itemShowPrice: true,
            previewShowDescription: true,
            previewShowTitle: true,
            previewShowCoupon: true,
            previewShowPrice: true,
            previewServiceButtonText: '',
            ...doppeListActionGetWithExternalDataSourceDefaultListSettings(),
            ...doppeListActionGetDefaultSearchListSettings(action.settings),
            ...doppeListActionGetDefaultListSettings({
                listDataItems: listDataItems.map(service => {

                    const {
                        serviceButtonText,
                        ctaButton,
                        customSections,
                        customCtaButtons,
                        sectionsOverridesMap,
                        ctaButtonsOverridesMap,
                        ...restService
                    } = service;

                    return {
                        serviceProviderId: null,
                        serviceProvider: null,
                        ribbon: doppeHideableValueCreateHidden('SALE'),
                        ...getDefaultDoppeActionCtaButtonSettings({
                            buttonText: serviceButtonText,
                            showButton: true
                        }),
                        ...doppeActionCtaButtonTypeGetDefaultCtaButtonSettingsMigration({
                            hasSourceURL: true,
                            ctaButton: ctaButton,
                        }),
                        ...doppeListActionListItemGetDefaultListItemSettingsMigration(service.id),
                        ...doppeListActionListItemWithCtaButtonsMigration(service, true),
                        ...doppeListActionListItemWithSectionsMigration(service),
                        ...restService as PartiallyOptional<DoppeActionTypeServicesList.ServiceSettings & DoppeActionTypeServicesList.DeprecatedItemFields, 'serviceProvider' | 'serviceProviderId' | 'ribbon' | 'ctaButton' | 'customCtaButtons' |'customSections' | 'ctaButtonsOverridesMap' | 'sectionsOverridesMap'>
                    }
                }),
            }),
            ...doppeListActionGetItemClickBehaviourDefaultListSettings(),
            ...doppeListActionGetMainDetailsDefaultSettings({
                defaultTitle: ''
            }),
            ...convertCommonListSettingToDefaultDoppeActionCtaButtonSettingsFromListAction(action.settings),
            ...doppeActionCtaButtonTypeGetDefaultCtaButtonSettingsMigration({
                ctaButton: ctaButton,
                hasSourceURL: false
            }),
            ...doppeActionContentSectionSettingsDefaultValuesMigration(action.settings),
            ...doppeActionCtaButtonsSettingsDefaultValuesMigration(action.settings, false),
            ...doppeListActionTemplateCtaButtonsSettingsDefaultValuesMigration(action.settings, true),
            ...doppeListActionTemplateSectionsSettingsDefaultValuesMigration(action.settings),
            ...restSettings
        }
    },
    isWidgetOpener: true,
    canRenderInPage: true,
    skipReportingOnGoalAchievedOnActionButtonClick: true,
    isListItemLinkOpener: true,
    supportsListLayout: true,
    settingsMetadata: {
        actionSettingsMetadata: {
            contentSettingsMetadata: {
                ...doppeListActionContentSettingsMetadataCreateForListActionWithMainDetails()
            },
            supportsContentSections: true
        },
        listItemsSettingsMetadata: {
            contentSettingsMetadata: doppeActionTypeServiceActionContentSettingsMetadata,
            supportsContentSections: true
        }
    },
    resolveUsedMedia: actionSettings => {
        return arrayRemoveNullValues([...arrayFlatten(actionSettings.listDataItems.map(article => {
            return article.serviceImages ?? []
        })), doppeHideableValueIsVisibleAndNotEqualValue(actionSettings.mainImage, null) ? actionSettings.mainImage : null]);
    },
    resolveMainMedia: actionSettings => {
        return doppeListActionWithMainDetailsGetMainImage(actionSettings, actionSettings.listDataItems[0]?.serviceImages?.[0] ?? null);
    },
    resolveMainLink: (actionSettings, htmlSiteInfo) => {
        return doppeActionCtaButtonsResolveMainURL(actionSettings, {
            htmlSiteInfo: htmlSiteInfo,
        }) ?? null;
    },
    prepareForViewer: (actionSettings, options) => {
        doppeDtoActionSettingsRemoveInnerCtaContentForLockedAction(actionSettings, actionSettings => {
            doppeListActionItemCtaButtonsResetSettingsForLock(actionSettings);
            doppeActionCtaButtonsResetSettingsForLock(actionSettings);
        }, options);
    },
    isConnectedToExternalDataSource: actionSettings => {
        return !!actionSettings.externalDataSource
    },
    resolveUsedBusinessEntities: actionSettings => {
        const externalDataSource = actionSettings.externalDataSource;
        if (externalDataSource) {
            return [
                doppeServicesExternalDataSourceResolveBusinessEntityReferences(externalDataSource)
            ];
        } else {
            return iterableMapToArray(actionSettings.listDataItems, (plan, skip) => {
                return plan.serviceProvider !== null && plan.serviceProviderId !== null ?
                    doppeExternalServiceProviderResolveBusinessEntityReference(
                        plan.serviceProvider,
                        plan.serviceProviderId
                    ) : skip
            });
        }
    },
    resolveListSearchSettings: actionSettings => {
        return actionSettings.listSearchSettings;
    },
    resolveListItemsCount: actionSettings => {
        return actionSettings.externalDataSource ? actionSettings.externalDataSourceItemsCount : doppeListActionSettingsFilterHiddenListItemsFromActionView(actionSettings.listDataItems).length;
    },
    resolveCouponDataFromListItem: listItem => {
        return {
            showCouponCode: doppeHideableValueIsVisibleAndNotEqualValue(listItem.couponCode, ''),
            couponCode: doppeHideableValueGetVisibleValue(listItem.couponCode, ''),
            couponDescription: '',
            couponTitle: doppeHideableValueGetVisibleValue(listItem.title, ''),
        }
    },
    resolveListItems: action => {
        const actionSettings = action.settings;
        return actionSettings.listDataItems.map(item => {
            return {
                title: item.serviceTitle,
                action: action,
                couponCode: item.couponCode,
                description: item.description,
                image: item.serviceImages ? asArray(item.serviceImages)[0] : null,
                itemId: item.id,
                ribbon: item.ribbon,
                url: item.servicePageLink,
                enabled: item.enabled,
                deleted: item.deleted,
                displayInSearch: item.displayInSearch,
            }
        });
    },
    replaceExternalImages: (actionSettings, externalImagesUrlToImageResourceMap) => {
        return immerProduce(actionSettings, actionSettings => {
            for (const service of actionSettings.listDataItems) {
                if (service.serviceImages) {
                    service.serviceImages = service.serviceImages.map(
                        image => wixMediaResourceReplaceExternalImage(image, externalImagesUrlToImageResourceMap))
                }
            }

            doppeListActionWithMainDetailsReplaceMainImage(actionSettings, externalImagesUrlToImageResourceMap);
        })
    },
});

export function populateServicesActionServiceSettingsDefaultTexts(options: {
    service: DoppeActionTypeServicesList.ServiceSettings,
    pageContentLocales: DoppeAppPageContentLocales,
}): DoppeActionTypeServicesList.ServiceSettings {

    const {
        pageContentLocales,
        service,
    } = options;

    return {
        ...service,
        serviceTitle: doppeHideableValueGetValue(service.serviceTitle) !== '' ? service.serviceTitle : pageContentLocales["service.actionSettings.title"],
        description: doppeHideableValueModifyValue(service.description, (value) => {
            return value !== '' ? value : pageContentLocales["service.actionSettings.description"];
        }),
        couponCode: doppeHideableValueModifyValue(service.couponCode, (value) => {
            return value !== '' ? value : pageContentLocales["service.actionSettings.couponCode"];
        }),
        ribbon: doppeHideableValueModifyValue(service.ribbon, (value) => {
            return value !== '' ? value : pageContentLocales["service.actionSettings.ribbon"];
        }),
        location: doppeHideableValueModifyValue(service.location, (value) => {
            return value !== '' ? value : pageContentLocales["service.actionSettings.location"];
        }),
        serviceParticipants: doppeHideableValueModifyValue(service.serviceParticipants, (value) => {
            return value !== '' ? value : pageContentLocales["service.actionSettings.serviceParticipants"];
        }),
        serviceSchedule: doppeHideableValueModifyValue(service.serviceSchedule, (value) => {
            return value !== '' ? value : pageContentLocales["service.actionSettings.serviceSchedule"];
        }),
        servicePeriod: doppeHideableValueModifyValue(service.servicePeriod, (value) => {
            return value !== '' ? value : pageContentLocales["service.actionSettings.servicePeriod"];
        }),
        priceData: doppeHideableValueModifyValue(service.priceData, (value) => {
            return value.actualPrice !== '' ? value : {
                actualPrice: pageContentLocales["service.actionSettings.price"],
                discountPrice: ''
            };
        }),
        ...doppeListActionSettingsGetListItemCtaButtonsDefaultSettings(),
        ...doppeListActionSettingsGetListItemSectionsDefaultSettings(),
    }
}

export function convertDoppeWixServiceToServicesSettings(
    service: DoppeDtoBookingService,
): DoppeActionTypeServicesList.ServiceSettings {

    return {
        ...objectOmit(convertDoppeWixBookingServiceToServiceSettings(service), ['sections', 'ctaButtons']),
        ...doppeListActionListItemGetDefaultListItemSettingsMigration(service.id),
        ...doppeListActionSettingsGetListItemCtaButtonsDefaultSettings(),
        ...doppeListActionSettingsGetListItemSectionsDefaultSettings(),
    }
}