import {doppeActionTypeDefineMetadata} from '../../client-server-common/doppe-action-types/doppe-action-type';
import {
    DoppeHideableValue,
    doppeHideableValueCreateHidden,
    doppeHideableValueGetValue,
    doppeHideableValueModifyValue
} from '../../client-server-common/types/doppe-hideable-value';
import {
    arrayRemoveNullValues,
    asArray,
    immerProduce,
    stringTrimPrefix,
    urlGetHostname,
    urlNormalizeHttpUrl,
    Values
} from '@wix/devzai-utils-common';
import {DoppeActionTypeMembershipViewerLang} from './doppe-action-type-membership-viewer.lang';
import {
    DoppeActionPriceData,
    DoppeExternalMembershipPlanProvider,
    doppeExternalMembershipPlanProviderResolveBusinessEntityReference
} from '../../client-server-common';
import {WixMediaResource, wixMediaResourceReplaceExternalImage} from '@wix/devzai-common-wix';
import {
    DoppeDtoMembershipPlan,
    doppeDtoMembershipPlanPaymentConvertToActionFormattedPrice
} from '../../client-server-common/types/doppe-dto-membership-plan';
import {RichTextContent} from '@wix/devzai-utils-react';
import {DoppeAppPageContentLocales} from '../../client/doppe-app-locales/doppe-app-page-content-locales';
import {
    doppeDtoActionSettingsRemoveInnerCtaContentForLockedAction
} from '../../client-server-common/types/doppe-dto-action';
import {
    DoppeActionCtaButtonSettings,
    doppeActionCtaButtonTypeGetDefaultCtaButtonSettingsMigration,
    getDefaultDoppeActionCtaButtonSettings
} from '../../client-server-common/types/doppe-action-cta-button-settings';
import {
    doppeActionCtaButtonsResetSettingsForLock,
    doppeActionCtaButtonTypeGetDefaultSettings,
    DoppeActionCTAType,
    doppeActionMultipleCTAButtonCreate
} from '../../client-server-common/types/doppe-action-cta-button';
import {
    doppeActionCtaButtonsResolveMainURL
} from '../doppe-actions-common/doppe-cta-button-utils/doppe-cta-button-utils';
import {
    doppeActionContentSectionSettingsDefaultValuesMigration,
    DoppeActionContentSectionsSettings
} from '../../client-server-common/types/doppe-action-content-sections-settings';
import {
    DoppeActionCtaButtonsSettings,
    doppeActionCtaButtonsSettingsDefaultValuesMigration
} from '../../client-server-common/types/doppe-action-cta-buttons-settings';
import MembershipType = DoppeActionTypeMembership.MembershipType;


export namespace DoppeActionTypeMembership {

    export interface PlanSettings {
        membershipPageLink: string;
        membershipImages: WixMediaResource[] | null;
        membershipTitle: DoppeHideableValue<string>;
        membershipDescription: DoppeHideableValue<RichTextContent>;
        priceData: DoppeHideableValue<DoppeActionPriceData>;
        membershipBenefits: string[];
        membershipType: MembershipType;
        couponCode: DoppeHideableValue<string>;
        ribbon: DoppeHideableValue<string>;
        membershipProvider: DoppeExternalMembershipPlanProvider | null;
        membershipProviderId: string | null;
    }

    export type ActionSettings = DoppeActionTypeMembership.PlanSettings &
        DoppeActionContentSectionsSettings &
        DoppeActionCtaButtonsSettings & {

    }

    export type DeprecatedFields = Partial<DoppeActionCtaButtonSettings> & {
        linkButtonText?: DoppeHideableValue<string>;
    }

    export type PartialProps = Partial<ActionSettings> & DeprecatedFields;

    export const MembershipType = {
        Other: 'Other',
        Patreon: 'Patreon',
        Deviantart: 'Deviantart',
        Youtube: 'Youtube',
        Twitter: 'Twitter',
        Twitch: 'Twitch',
        Memberful: 'Memberful',
    } as const;

    export type MembershipType = Values<typeof MembershipType>;

}

export const doppeActionTypeMembership = doppeActionTypeDefineMetadata<DoppeActionTypeMembership.ActionSettings, DoppeActionTypeMembership.PartialProps>({
    id: 'io.bymo.action/membership',
    resolveActionSettings: action => {

        const {
            membershipPageLink = '',
            linkButtonText = '',
            ctaButton,
            ctaButtons,
            sections,
            ...restSettings
        } = action.settings;

        return {
            membershipPageLink: membershipPageLink,
            priceData: {
                actualPrice: DoppeActionTypeMembershipViewerLang.defaultTexts.price,
            },
            membershipBenefits: [DoppeActionTypeMembershipViewerLang.defaultTexts.membershipBenefit],
            membershipType: DoppeActionTypeMembership.MembershipType.Other,
            linkButtonText: linkButtonText,
            couponCode: doppeHideableValueCreateHidden(''),
            membershipProvider: null,
            membershipProviderId: null,
            membershipImages: null,
            membershipTitle: doppeHideableValueCreateHidden(''),
            membershipDescription: doppeHideableValueCreateHidden(''),
            ribbon: doppeHideableValueCreateHidden(''),
            ...getDefaultDoppeActionCtaButtonSettings({
                buttonText: linkButtonText,
                showButton: true,
            }),
            ...doppeActionCtaButtonTypeGetDefaultCtaButtonSettingsMigration({
                hasSourceURL: true,
                ctaButton: ctaButton,
            }),
            ...doppeActionContentSectionSettingsDefaultValuesMigration(action.settings),
            ...doppeActionCtaButtonsSettingsDefaultValuesMigration({
                ctaButtons: ctaButtons,
            }, true),
            ...restSettings
        }
    },
    isWidgetOpener: true,
    isLinkOpener: true,
    canRenderInPage: true,
    skipReportingOnGoalAchievedOnActionButtonClick: true,
    resolveUsedBusinessEntities: actionSettings => {
        return arrayRemoveNullValues([
            actionSettings.membershipProvider !== null && actionSettings.membershipProviderId !== null ?
                doppeExternalMembershipPlanProviderResolveBusinessEntityReference(
                    actionSettings.membershipProvider,
                    actionSettings.membershipProviderId
                ) :
                null
        ])
    },
    resolveMainLink: (actionSettings, htmlSiteInfo) => {
        return doppeActionCtaButtonsResolveMainURL(actionSettings, {
            htmlSiteInfo: htmlSiteInfo,
            sourceURL: actionSettings.membershipPageLink
        }) ?? null;
    },
    resolveUsedMedia: actionSettings => {
        return actionSettings.membershipImages ?? [];
    },
    resolveMainMedia: actionSettings => {
        return actionSettings.membershipImages?.[0] ?? null;
    },
    replaceExternalImages: (actionSettings, externalImagesUrlToImageResourceMap) => {
        return immerProduce(actionSettings, actionSettings => {
            if (actionSettings.membershipImages) {
                actionSettings.membershipImages = actionSettings.membershipImages.map(
                    image => wixMediaResourceReplaceExternalImage(image, externalImagesUrlToImageResourceMap))
            }
        })
    },
    prepareForViewer: (actionSettings, options) => {
        doppeDtoActionSettingsRemoveInnerCtaContentForLockedAction(actionSettings, actionSettings => {
            actionSettings.membershipPageLink = '';
            doppeActionCtaButtonsResetSettingsForLock(actionSettings);
        }, options);
    },
});


export function doppeActionTypeMembershipResolveTypeFromUrl(url: string) {

    const hostname = stringTrimPrefix(urlGetHostname(urlNormalizeHttpUrl(url)), 'www.')

    switch (hostname) {
        case 'patreon.com':
            return DoppeActionTypeMembership.MembershipType.Patreon;
        case 'twitch.tv':
            return DoppeActionTypeMembership.MembershipType.Twitch;
        case 'twitter.com':
            return DoppeActionTypeMembership.MembershipType.Twitter;
        case 'youtube.com':
            return DoppeActionTypeMembership.MembershipType.Youtube;
        case 'deviantart.com':
            return DoppeActionTypeMembership.MembershipType.Deviantart;
        default:
            return DoppeActionTypeMembership.MembershipType.Other;
    }

}

export function doppeActionTypeMembershipPlanResolveSettingsFromBoundEntity(
    actionSettings: DoppeActionTypeMembership.PlanSettings,
    _doppeDtoMembershipPlan: DoppeDtoMembershipPlan,
): DoppeActionTypeMembership.PlanSettings {

    // const location = arrayFirst(doppeDtoService.locations);

    return {
        ...actionSettings,
        // membershipPageLink: stringIsNotNullableOrWhiteSpace(_doppeDtoMembershipPlan.membershipPageUrl) ?
        //     doppeDtoService.membershipPageUrl : 'about:blank',
    }
}

export function populateMembershipPlanActionSettingsDefaultTexts(options: {
    plan: DoppeActionTypeMembership.ActionSettings,
    pageContentLocales: DoppeAppPageContentLocales,
}): DoppeActionTypeMembership.ActionSettings {

    const {
        plan,
        pageContentLocales,
    } = options;

    return {
        ...plan,
        membershipTitle: doppeHideableValueGetValue(plan.membershipTitle) !== '' ? plan.membershipTitle : pageContentLocales['membership.actionSettings.title'],
        membershipDescription: doppeHideableValueModifyValue(plan.membershipDescription, (value) => {
            return value !== '' ? value : pageContentLocales['membership.actionSettings.description'];
        }),
        couponCode: doppeHideableValueModifyValue(plan.couponCode, (value) => {
            return value !== '' ? value : pageContentLocales['membership.actionSettings.couponCode'];
        }),
        ribbon: doppeHideableValueModifyValue(plan.ribbon, (value) => {
            return value !== '' ? value : pageContentLocales['membership.actionSettings.ribbon'];
        }),
        sections: [],
        ...doppeActionCtaButtonsSettingsDefaultValuesMigration({
            ctaButtons: [doppeActionMultipleCTAButtonCreate({
                text: pageContentLocales["membership.actionSettings.buttonText"],
                isRequired: false,
                settings: doppeActionCtaButtonTypeGetDefaultSettings(DoppeActionCTAType.Link, true)
            })],
        }, false),
    }
}


export function convertDoppeWixMembershipPlanToPlanSettings(plan: DoppeDtoMembershipPlan, websiteUrl?: string, settings?: DoppeActionTypeMembership.ActionSettings): DoppeActionTypeMembership.ActionSettings {

    return {
        ctaButtons: [],
        sections: [],
        ribbon: doppeHideableValueCreateHidden(''),
        couponCode: doppeHideableValueCreateHidden(''),
        ...settings,
        membershipImages: plan.media ? asArray(plan.media) : null,
        membershipPageLink: websiteUrl ?? settings?.membershipPageLink ?? ``,
        priceData: plan.pricing ? {
            actualPrice: doppeDtoMembershipPlanPaymentConvertToActionFormattedPrice(plan.pricing, ''),
            discountPrice: ''
        } : settings?.priceData ?? doppeHideableValueCreateHidden({
            actualPrice: '',
            discountPrice: ''
        }),
        membershipTitle: plan?.name ?? '',
        membershipDescription: plan.description ?? doppeHideableValueCreateHidden(''),
        membershipProvider: DoppeExternalMembershipPlanProvider.Wix,
        membershipProviderId: plan.id,
        membershipType: DoppeActionTypeMembership.MembershipType.Other,
        membershipBenefits: plan.perks ?? settings?.membershipBenefits ?? [],
    }
}

export function doppeActionTypeMembershipResolveDefaultSettingByType(membershipType: MembershipType, pageContentLocales: DoppeAppPageContentLocales) {
    switch (membershipType) {
        case DoppeActionTypeMembership.MembershipType.Deviantart:
            return {
                title: pageContentLocales['membership.defaultContent.deviantart.title'],
                membershipBenefits: pageContentLocales['membership.defaultContent.deviantart.benefits'],
                membershipPageLink: 'https://www.deviantart.com/username'
            }
        case DoppeActionTypeMembership.MembershipType.Patreon:
            return {
                title: pageContentLocales['membership.defaultContent.patreon.title'],
                membershipBenefits: pageContentLocales['membership.defaultContent.patreon.benefits'],
                membershipPageLink: 'https://www.patreon.com/username'
            }
        case DoppeActionTypeMembership.MembershipType.Youtube:
            return {
                title: pageContentLocales['membership.defaultContent.youtube.title'],
                membershipBenefits: pageContentLocales['membership.defaultContent.youtube.benefits'],
                membershipPageLink: 'https://www.youtube.com/username'
            }
        case DoppeActionTypeMembership.MembershipType.Twitter:
            return {
                title: pageContentLocales['membership.defaultContent.x.title'],
                membershipBenefits: pageContentLocales['membership.defaultContent.x.benefits'],
                membershipPageLink: 'https://www.x.com/username',
                linkButtonText: pageContentLocales['membership.defaultContent.x.linkButtonText']
            }
        case DoppeActionTypeMembership.MembershipType.Twitch:
            return {
                title: pageContentLocales['membership.defaultContent.twitch.title'],
                membershipBenefits: pageContentLocales['membership.defaultContent.twitch.benefits'],
                membershipPageLink: 'https://www.twitch.tv/username',
                linkButtonText: pageContentLocales['membership.defaultContent.twitch.linkButtonText']
            }
        case DoppeActionTypeMembership.MembershipType.Memberful:
            return {
                title: pageContentLocales['membership.defaultContent.memberful.title'],
                membershipBenefits: pageContentLocales['membership.defaultContent.memberful.benefits'],
                membershipPageLink: 'https://www.your-memberful-page-url.com',
                linkButtonText: pageContentLocales['membership.defaultContent.memberful.linkButtonText'],
            }
        case DoppeActionTypeMembership.MembershipType.Other:
            return {
                title: pageContentLocales['membership.defaultContent.other.title'],
                membershipBenefits: pageContentLocales['membership.defaultContent.other.benefits'],
                membershipPageLink: 'https://www.your-memebership-page-url.com'
            }
    }
}

export function doppeActionTypeMembershipResolveDefaultSettingByTypeWithTexts(membershipType: MembershipType) {
    switch (membershipType) {
        case DoppeActionTypeMembership.MembershipType.Deviantart:
            return {
                title: 'Support me on DeviantArt',
                membershipBenefits: ['Monthly Sketch Dumps & W.I.P. posts', 'Plush & Product Previews', 'Support my work 🙏'],
                membershipPageLink: 'https://www.deviantart.com/username'
            }
        case DoppeActionTypeMembership.MembershipType.Patreon:
            return {
                title: 'Support me on Patreon',
                membershipBenefits: ['Ad-free content', 'Exclusive Content', 'Behind-the-scenes content', 'Live chat'],
                membershipPageLink: 'https://www.patreon.com/username'
            }
        case DoppeActionTypeMembership.MembershipType.Youtube:
            return {
                title: 'Support me on Youtube',
                membershipBenefits: ['Ad-free content', 'Exclusive Content', 'Behind-the-scenes content', 'Live chat'],
                membershipPageLink: 'https://www.youtube.com/username'
            }
        case DoppeActionTypeMembership.MembershipType.Twitter:
            return {
                title: 'Super Follow me on Twitter',
                membershipBenefits: ['Ad-free content', 'Exclusive Content', 'Behind-the-scenes content', 'Live chat'],
                membershipPageLink: 'https://www.twitter.com/username',
                linkButtonText: 'Super Follow'
            }
        case DoppeActionTypeMembership.MembershipType.Twitch:
            return {
                title: 'Support me on Twitch',
                membershipBenefits: ['Ad-free content', 'Channel Points 1.2x', 'Sub-Only Chat', 'Subscriber Streams'],
                membershipPageLink: 'https://www.twitch.tv/username',
                linkButtonText: 'Subscribe'
            }
        case DoppeActionTypeMembership.MembershipType.Memberful:
            return {
                title: 'My Membership Benefits',
                membershipBenefits: ['Ad-free content', 'Exclusive Content', 'Behind-the-scenes content', 'Live chat'],
                membershipPageLink: 'https://www.your-memberful-page-url.com',
                linkButtonText: 'Become a Member'
            }
        case DoppeActionTypeMembership.MembershipType.Other:
            return {
                title: 'Support my Membership Page',
                membershipBenefits: ['Ad-free content', 'Exclusive Content', 'Behind-the-scenes content', 'Live chat'],
                membershipPageLink: 'https://www.your-memebership-page-url.com'
            }
    }

}