import {
    doppeActionTypeCreateSettingsResolvingFunction,
    doppeActionTypeDefineMetadata
} from "../../client-server-common/doppe-action-types/doppe-action-type";

export const doppeActionTypeDebugApi = doppeActionTypeDefineMetadata({
    id: 'io.bymo.action/debug/api',
    settingsMetadata: false,
    resolveActionSettings: doppeActionTypeCreateSettingsResolvingFunction({}),
    isWidgetOpener: false
})