import {doppeActionTypeDefineViewerMetadata} from '../../client-server-common/doppe-action-types/doppe-action-type';
import React from 'react';
import {doppeActionTypeTestimonial} from './doppe-action-type-testimonial';

export const doppeActionTypeTestimonialViewer = doppeActionTypeDefineViewerMetadata(doppeActionTypeTestimonial, {

    loadViewerComponents: async () => {
        return {
            DoppeActionTypeTestimonialWidget: (await import(
                './doppe-action-type-testimonial-widget/doppe-action-type-testimonial-widget'
                )).DoppeActionTypeTestimonialWidget
        }
    },

    renderWidget: (renderProps, viewerComponents) => {

        const {
            DoppeActionTypeTestimonialWidget
        } = viewerComponents;

        return (
            <DoppeActionTypeTestimonialWidget
                {...renderProps}
            />
        );
    },
})