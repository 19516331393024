import {
    doppeActionTypeCreateSettingsResolvingFunction,
    doppeActionTypeDefineMetadata
} from '../../client-server-common/doppe-action-types/doppe-action-type';
import {Values} from '@wix/devzai-utils-common';


export namespace DoppeActionTypeEmbedIframe {
    export const IframeEmbedType = {
        Standard: 'standard',
    } as const;

    export type IframeEmbedType = Values<typeof IframeEmbedType>;

    export interface ActionSettings {
        url: string;
        embedType: IframeEmbedType;
        height: number;
    }

}

export const doppeActionTypeEmbedIframe = doppeActionTypeDefineMetadata<DoppeActionTypeEmbedIframe.ActionSettings>({
    id: 'io.bymo.action/iframe-embed',
    resolveActionSettings: doppeActionTypeCreateSettingsResolvingFunction({
        url: '',
        embedType: DoppeActionTypeEmbedIframe.IframeEmbedType.Standard,
        height: 360
    }),
    hasNoGoalMeasuring: true,
    isWidgetOpener: true,
    canRenderInPage: true,
    skipReportingOnGoalAchievedOnActionButtonClick: true,
    hasNoLockInnerCTA: true,
})