import {
    doppeActionTypeCreateSettingsResolvingFunction,
    doppeActionTypeDefineMetadata
} from '../../client-server-common/doppe-action-types/doppe-action-type';

export namespace DoppeActionTypeDevModals {
    export interface ActionSettings {

    }
}

export const doppeActionTypeDevModals = doppeActionTypeDefineMetadata<DoppeActionTypeDevModals.ActionSettings>({
    id: 'io.bymo.action/dev-modals',
    resolveActionSettings: doppeActionTypeCreateSettingsResolvingFunction({
    }),
    isWidgetOpener: true,
    canRenderInPage: true,
    settingsMetadata: false,
    skipReportingOnGoalAchievedOnActionButtonClick: true
});