export const DoppeSubdomainMaxLength = 63;
export const DoppeSubdomainMinLength = 2;
export const DoppePageNameMaxLength = 100;
export const DoppeTargetUrlMaxLength = 2048;
export const DoppeUserPagePathMaxLength = 100;
export const DoppeBymoLinkDisplayNameMaxLength = 2048;
export const DoppeTemplateIdMaxLength = 100;
export const DoppeUserDisplayNameMaxLength = 40;
export const DoppeUserDisplayNameMinLength = 2;
export const DoppeInvitationCodeLength = 96;

export const DoppeWixAppId = '17fda750-26c4-4ffb-8bd3-c8600d12348c'

export const DoppeRealTimeAnalyticsAgeSecs = 4;
export const DoppeDemoLinkPath = 'demo-link';
export const DoppeDemoLinkId = '00000000-0000-0000-0000-000000000000';
export const DoppeDummyPageId = '00000000-0000-0000-0000-000000000000';
export const DoppeSystemWixUserId = '00000000-0000-0000-0000-000000000000';
export const DoppeSpeedDialLinkId = 'b982d018-89ee-4498-8b8c-87dc70e9c38a';
export const DoppeBiEmptyTargetUrl = '';

/**
 * On local environment only, the admin API is enabled when called with this value in the 'Authorization' header.
 */
export const AdminApiLocalEnvironmentAuthorizationHeader = 'automation';

export const DoppeLinksCsvTemplateColumns = {
    targetUrl: 'Destination URL',
    displayName: 'Title',
    path: 'Path'
} as const;

export const DoppeSearchLinksCsvTemplateColumns = {
    targetUrl: 'Destination URL',
    title: 'Title',
    code: 'Keyword',
    showCoupon: 'Show Coupon',
    couponCode: 'Coupon Code',
    couponTitle: 'Coupon Title',
    couponDescription: 'Coupon Description'
} as const;

export const DoppeUserIdHeader = 'X-Hopp-Account-Id' as const;
export const DoppeReadOnlyAccountAccessHeader = 'X-Hopp-Read-Only-Account-Access' as const;

export const DoppeBlankPagePresetId = '00000000-0000-0000-0000-000000000000' as const;

export const DoppeSentryProjectsIds = {
    Server: '4506983377272832',
    App: '4506983403945984',
    Viewer: '4506983406305280'
}

export const DoppeSmartlingProjectId = '49619d4d4';
export const DoppeBymoPagePreviewLinkExpirationInDays = 7;

export const DoppeHtmlAnywhereTemplatesIds = {
    ClassicEditorSite: 'a7964857-8a0a-45b9-b2b0-81cda51f26f6',
    StudioSite: '980ccabe-8a87-4c56-a6b2-dc0b27331749'
}

export const DoppeWixSearchAdminMetaSites = {
    Production: 'abc238bc-332b-4490-a379-733ed4e705cb',
    Staging: '39beadc1-eaba-4d86-989f-1c6b4f36c52d',
    // The indexed data will be shared among all our local users.
    Local: '0293af3a-bd26-4349-9398-688780e509ea'
}