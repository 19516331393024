import {NonNullishValue, Nullable} from "../common-types";

export function valueConvertWhenNotNullable<T extends NonNullishValue, R> (value: T, converter: (value: T) => R) : R;
export function valueConvertWhenNotNullable<T extends NonNullishValue, R> (value: T | null, converter: (value: T) => R) : R | null;
export function valueConvertWhenNotNullable<T extends NonNullishValue, R> (value: T | undefined, converter: (value: T) => R) : R | undefined;
export function valueConvertWhenNotNullable<T extends NonNullishValue, R> (value: Nullable<T>, converter: (value: T) => R) : Nullable<R>;
export function valueConvertWhenNotNullable<T extends NonNullishValue, R> (value: Nullable<T>, converter: (value: T) => R) {
    if (value === null || value === undefined) {
        return value;
    }

    return converter(value);
}

export const SymbolNone: unique symbol = Symbol('SymbolNone');
export type SymbolNone = typeof SymbolNone;

export type BooleanLike = boolean | 'true' | 'false';

export function booleanLikeIsTrue (value: BooleanLike) : value is true {
    return value === true || value === 'true';
}

export function booleanValueParse (value: 'false') : false;
export function booleanValueParse (value: 'true') : true;
export function booleanValueParse (value: boolean) : boolean;
export function booleanValueParse (value: BooleanLike) : boolean;
export function booleanValueParse (value: Nullable<string>) : boolean | undefined;
export function booleanValueParse (value: Nullable<string | boolean>) : boolean | undefined {

    if (typeof value === 'boolean') {
        return value;
    } else if (value === 'true') {
        return true;
    } else if (value === 'false') {
        return false;
    } else {
        return undefined;
    }
}

export function valueIsNullable (value: any) : value is (null | undefined) {
    return value === null || value === undefined;
}

export function valueIsNotNullable<T> (value: Nullable<T>) : value is T {
    return value !== null && value !== undefined;
}