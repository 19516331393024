import {useMemo} from "react";
import {iterableMapToArray, objectGetKeys} from "@wix/devzai-utils-common";

export function useHashedMemo<T> (value: T) {
    return useMemo(() => value, [JSON.stringify(value)]);
}

export function useHashedMemoForRecord<T extends Record<string, unknown>> (value: T) {

    const sortedKeys = objectGetKeys(value).sort();

    return useMemo(() => value, [JSON.stringify(sortedKeys.map(key => [key, value[key]]))]);
}

export function useHashedMemoForIterableIgnoringOrder<T extends Iterable<unknown>> (iterable: T) {

    const hashesArr = iterableMapToArray(
        iterable,
        value => typeof value === 'string' ? value : JSON.stringify(value)
    );

    return useMemo(() => iterable, [JSON.stringify(hashesArr.sort())]);
}