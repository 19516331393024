import {Values} from "../common-types";

export const LanguageCodes = {
    English: 'en',
    Hebrew: 'he',

    Czech: 'cs',
    Danish: 'da',
    German: 'de',
    Spanish: 'es',
    French: 'fr',
    Hindi: 'hi',
    Indonesian: 'id',
    Italian: 'it',
    Japanese: 'ja',
    Korean: 'ko',
    Lithuanian: 'lt',
    Dutch: 'nl',
    Norwegian: 'no',
    Polish: 'pl',
    Portuguese: 'pt',
    Russian: 'ru',
    Swedish: 'sv',
    Thai: 'th',
    Turkish: 'tr',
    Ukrainian: 'uk',
    Vietnamese: 'vi',
    Chinese: 'zh',
    Arabic: 'ar',
    Bulgarian: 'bg',
    Catalan: 'ca',
    Greek: 'el',
    Finnish: 'fi',
    Croatian: 'hr',
    Hungarian: 'hu',
    Latvian: 'lv',
    Malay: 'ms',
    Romanian: 'ro',
    Slovak: 'sk',
    Slovenian: 'sl',
    Tagalog: 'tl',
} as const;

export type LanguageCodes = Values<typeof LanguageCodes>;

const LanguageMetadata = {
    [LanguageCodes.English]: {
        displayNameEnglish: 'English',
        displayNameNative: 'English',
        flag: '🇬🇧' // United Kingdom flag
    },
    [LanguageCodes.Hebrew]: {
        displayNameEnglish: 'Hebrew',
        displayNameNative: 'עברית',
        flag: '🇮🇱'
    },
    [LanguageCodes.Czech]: {
        displayNameEnglish: 'Czech',
        displayNameNative: 'Čeština',
        flag: '🇨🇿'
    },
    [LanguageCodes.Danish]: {
        displayNameEnglish: 'Danish',
        displayNameNative: 'Dansk',
        flag: '🇩🇰'
    },
    [LanguageCodes.German]: {
        displayNameEnglish: 'German',
        displayNameNative: 'Deutsch',
        flag: '🇩🇪'
    },
    [LanguageCodes.Spanish]: {
        displayNameEnglish: 'Spanish',
        displayNameNative: 'Español',
        flag: '🇪🇸'
    },
    [LanguageCodes.French]: {
        displayNameEnglish: 'French',
        displayNameNative: 'Français',
        flag: '🇫🇷'
    },
    [LanguageCodes.Hindi]: {
        displayNameEnglish: 'Hindi',
        displayNameNative: 'हिन्दी',
        flag: '🇮🇳'
    },
    [LanguageCodes.Indonesian]: {
        displayNameEnglish: 'Indonesian',
        displayNameNative: 'Bahasa Indonesia',
        flag: '🇮🇩'
    },
    [LanguageCodes.Italian]: {
        displayNameEnglish: 'Italian',
        displayNameNative: 'Italiano',
        flag: '🇮🇹'
    },
    [LanguageCodes.Japanese]: {
        displayNameEnglish: 'Japanese',
        displayNameNative: '日本語',
        flag: '🇯🇵'
    },
    [LanguageCodes.Korean]: {
        displayNameEnglish: 'Korean',
        displayNameNative: '한국어',
        flag: '🇰🇷'
    },
    [LanguageCodes.Lithuanian]: {
        displayNameEnglish: 'Lithuanian',
        displayNameNative: 'Lietuvių',
        flag: '🇱🇹'
    },
    [LanguageCodes.Dutch]: {
        displayNameEnglish: 'Dutch',
        displayNameNative: 'Nederlands',
        flag: '🇳🇱'
    },
    [LanguageCodes.Norwegian]: {
        displayNameEnglish: 'Norwegian',
        displayNameNative: 'Norsk',
        flag: '🇳🇴'
    },
    [LanguageCodes.Polish]: {
        displayNameEnglish: 'Polish',
        displayNameNative: 'Polski',
        flag: '🇵🇱'
    },
    [LanguageCodes.Portuguese]: {
        displayNameEnglish: 'Portuguese',
        displayNameNative: 'Português',
        flag: '🇵🇹' // Using Portugal's flag, though Brazil is also a primary Portuguese-speaking country
    },
    [LanguageCodes.Russian]: {
        displayNameEnglish: 'Russian',
        displayNameNative: 'Русский',
        flag: '🇷🇺'
    },
    [LanguageCodes.Swedish]: {
        displayNameEnglish: 'Swedish',
        displayNameNative: 'Svenska',
        flag: '🇸🇪'
    },
    [LanguageCodes.Thai]: {
        displayNameEnglish: 'Thai',
        displayNameNative: 'ไทย',
        flag: '🇹🇭'
    },
    [LanguageCodes.Turkish]: {
        displayNameEnglish: 'Turkish',
        displayNameNative: 'Türkçe',
        flag: '🇹🇷'
    },
    [LanguageCodes.Ukrainian]: {
        displayNameEnglish: 'Ukrainian',
        displayNameNative: 'Українська',
        flag: '🇺🇦'
    },
    [LanguageCodes.Vietnamese]: {
        displayNameEnglish: 'Vietnamese',
        displayNameNative: 'Tiếng Việt',
        flag: '🇻🇳'
    },
    [LanguageCodes.Chinese]: {
        displayNameEnglish: 'Chinese',
        displayNameNative: '中文',
        flag: '🇨🇳' // Simplified Chinese; for Traditional Chinese, often associated with Taiwan: 🇹🇼
    },
    [LanguageCodes.Arabic]: {
        displayNameEnglish: 'Arabic',
        displayNameNative: 'العربية',
        flag: '🇸🇦' // Saudi Arabia, though Arabic is widely spoken across many countries
    },
    [LanguageCodes.Bulgarian]: {
        displayNameEnglish: 'Bulgarian',
        displayNameNative: 'Български',
        flag: '🇧🇬'
    },
    [LanguageCodes.Catalan]: {
        displayNameEnglish: 'Catalan',
        displayNameNative: 'Català',
        flag: '🇪🇸' // Catalonia is a region in Spain
    },
    [LanguageCodes.Greek]: {
        displayNameEnglish: 'Greek',
        displayNameNative: 'Ελληνικά',
        flag: '🇬🇷'
    },
    [LanguageCodes.Finnish]: {
        displayNameEnglish: 'Finnish',
        displayNameNative: 'Suomi',
        flag: '🇫🇮'
    },
    [LanguageCodes.Croatian]: {
        displayNameEnglish: 'Croatian',
        displayNameNative: 'Hrvatski',
        flag: '🇭🇷'
    },
    [LanguageCodes.Hungarian]: {
        displayNameEnglish: 'Hungarian',
        displayNameNative: 'Magyar',
        flag: '🇭🇺'
    },
    [LanguageCodes.Latvian]: {
        displayNameEnglish: 'Latvian',
        displayNameNative: 'Latviešu',
        flag: '🇱🇻'
    },
    [LanguageCodes.Malay]: {
        displayNameEnglish: 'Malay',
        displayNameNative: 'Bahasa Melayu',
        flag: '🇲🇾' // Malaysia
    },
    [LanguageCodes.Romanian]: {
        displayNameEnglish: 'Romanian',
        displayNameNative: 'Română',
        flag: '🇷🇴'
    },
    [LanguageCodes.Slovak]: {
        displayNameEnglish: 'Slovak',
        displayNameNative: 'Slovenčina',
        flag: '🇸🇰'
    },
    [LanguageCodes.Slovenian]: {
        displayNameEnglish: 'Slovenian',
        displayNameNative: 'Slovenščina',
        flag: '🇸🇮'
    },
    [LanguageCodes.Tagalog]: {
        displayNameEnglish: 'Tagalog',
        displayNameNative: 'Tagalog',
        flag: '🇵🇭' // Philippines
    },
};

export const LanguageScript = {
    Latin: 'Latin',
    Devanagari: 'Devanagari',
    Cyrillic: 'Cyrillic',
    Hebrew: 'Hebrew',
    Thai: 'Thai',
    Japanese: 'Japanese',
    Korean: 'Korean',
    Chinese: 'Chinese',
    Vietnamese: 'Vietnamese',
    Greek: 'Greek',
    Arabic: 'Arabic',
} as const;

export type LanguageScript = Values<typeof LanguageScript>;

const LanguageCodeToLanguageScript = {
    [LanguageCodes.Czech]: LanguageScript.Latin,
    [LanguageCodes.Danish]: LanguageScript.Latin,
    [LanguageCodes.German]: LanguageScript.Latin,
    [LanguageCodes.English]: LanguageScript.Latin,
    [LanguageCodes.Spanish]: LanguageScript.Latin,
    [LanguageCodes.French]: LanguageScript.Latin,
    [LanguageCodes.Hebrew]: LanguageScript.Hebrew,
    [LanguageCodes.Hindi]: LanguageScript.Devanagari,
    [LanguageCodes.Indonesian]: LanguageScript.Latin,
    [LanguageCodes.Italian]: LanguageScript.Latin,
    [LanguageCodes.Japanese]: LanguageScript.Japanese,
    [LanguageCodes.Korean]: LanguageScript.Korean,
    [LanguageCodes.Lithuanian]: LanguageScript.Latin,
    [LanguageCodes.Dutch]: LanguageScript.Latin,
    [LanguageCodes.Norwegian]: LanguageScript.Latin,
    [LanguageCodes.Polish]: LanguageScript.Latin,
    [LanguageCodes.Portuguese]: LanguageScript.Latin,
    [LanguageCodes.Russian]: LanguageScript.Cyrillic,
    [LanguageCodes.Swedish]: LanguageScript.Latin,
    [LanguageCodes.Thai]: LanguageScript.Thai,
    [LanguageCodes.Turkish]: LanguageScript.Latin,
    [LanguageCodes.Ukrainian]: LanguageScript.Cyrillic,
    [LanguageCodes.Vietnamese]: LanguageScript.Vietnamese,
    [LanguageCodes.Chinese]: LanguageScript.Chinese,
    [LanguageCodes.Arabic]: LanguageScript.Arabic,
    [LanguageCodes.Bulgarian]: LanguageScript.Cyrillic,
    [LanguageCodes.Catalan]: LanguageScript.Latin,
    [LanguageCodes.Greek]: LanguageScript.Greek,
    [LanguageCodes.Finnish]: LanguageScript.Latin,
    [LanguageCodes.Croatian]: LanguageScript.Latin,
    [LanguageCodes.Hungarian]: LanguageScript.Latin,
    [LanguageCodes.Latvian]: LanguageScript.Latin,
    [LanguageCodes.Malay]: LanguageScript.Latin,
    [LanguageCodes.Romanian]: LanguageScript.Latin,
    [LanguageCodes.Slovak]: LanguageScript.Latin,
    [LanguageCodes.Slovenian]: LanguageScript.Latin,
    [LanguageCodes.Tagalog]: LanguageScript.Latin,
} satisfies Record<LanguageCodes, LanguageScript>;

export function languageScriptResolveFromLanguageCode (langCode: LanguageCodes) {
    return LanguageCodeToLanguageScript[langCode];
}

export function languageScriptFilterSupportedLanguageCodes (languageScript: LanguageScript, langCodes: LanguageCodes[]) {
    return langCodes.filter(langCode => LanguageCodeToLanguageScript[langCode] === languageScript);
}

export function languageResolveFromLocale (locale: string) {
    const lang = locale.slice(0, 2);
    return lang.toLowerCase() as LanguageCodes;
}

export function languageIsRtl (lang: LanguageCodes) {
    switch (lang) {
        case LanguageCodes.Arabic:
        case LanguageCodes.Hebrew: {
            return true;
        }
        default: {
            return false;
        }
    }
}

export function languageGetFlagEmoji (lang: LanguageCodes) {
    return LanguageMetadata[lang].flag;
}

export function languageGetDisplayNameInEnglish (lang: LanguageCodes) {
    return LanguageMetadata[lang].displayNameEnglish;
}

export function languageGetLocalizedDisplayName (lang: LanguageCodes) {
    return LanguageMetadata[lang].displayNameNative;
}