import {doppeActionTypeDefineMetadata} from '../../client-server-common/doppe-action-types/doppe-action-type';
import {
    BymoPageActionMediaLayout,
    BymoPageActionMediaSize, BymoPageActionMediaSizing,
    BymoPageActionMediaViewLayout,
    BymoPageActionMediaViewSettings,
    DoppeHideableValue
} from '../../client-server-common';
import {WixMediaResource, wixMediaResourceReplaceExternalImage} from '@wix/devzai-common-wix';
import {
    asArray,
    HtmlObjectHorizontalPosition,
    HtmlObjectVerticalPosition,
    immerProduce
} from '@wix/devzai-utils-common';
import {RichTextContent} from "@wix/devzai-utils-react";

export namespace DoppeActionTypeDevContent {

    export interface ActionSettings {
        title: DoppeHideableValue<string>;
        description: DoppeHideableValue<RichTextContent>;
        image: WixMediaResource[] | null;
        mediaViewSettings: BymoPageActionMediaViewSettings;
        mediaViewLayout: BymoPageActionMediaViewLayout;
    }
}

export const doppeActionTypeDevContent = doppeActionTypeDefineMetadata<DoppeActionTypeDevContent.ActionSettings>({
    id: 'io.bymo.action/dev-content',
    resolveActionSettings: (action) => {
        return {
            title: '',
            description: '',
            image: null,
            mediaViewSettings: {
                mediaLayout: BymoPageActionMediaLayout.Cropped,
                mediaSizing: BymoPageActionMediaSizing.ShowFullImage,
                size: BymoPageActionMediaSize.Ratio16By9,
                alignment: {
                    horizontal: HtmlObjectHorizontalPosition.Center,
                    vertical: HtmlObjectVerticalPosition.Center
                }
            },
            mediaViewLayout: BymoPageActionMediaViewLayout.FullWidth,
            ...action.settings,
        }
    },
    hasNoGoalMeasuring: true,
    isLinkOpener: false,
    isWidgetOpener: true,
    canRenderInPage: true,
    skipReportingOnGoalAchievedOnActionButtonClick: true,
    settingsMetadata: false,
    resolveUsedMedia: actionSettings => {
        return actionSettings.image ? asArray(actionSettings.image) : [];
    },
    resolveMainMedia: actionSettings => {
        return actionSettings.image ? asArray(actionSettings.image)[0] : null
    },
    replaceExternalImages: (actionSettings, externalImagesUrlToImageResourceMap) => {
        return immerProduce(actionSettings, actionSettings => {
            if (actionSettings.image) {
                actionSettings.image = asArray(actionSettings.image).map(
                    image => wixMediaResourceReplaceExternalImage(image, externalImagesUrlToImageResourceMap))
            }
        })
    },
})