import {DoppeActionCTAButton} from './doppe-action-cta-button';
import {assertDefined} from '@wix/devzai-utils-common';
import {doppeActionCtaButtonTypeGetDefaultCtaButtonSettingsMigration} from './doppe-action-cta-button-settings';

export interface DoppeActionCtaButtonsSettings {
    ctaButtons: DoppeActionCTAButton.MultipleButton[];
}

export function doppeActionCtaButtonsSettingsDefaultValuesMigration(ctaButtonsSettings: Partial<DoppeActionCtaButtonsSettings>, hasSourceURL: boolean) {
    return {
        ctaButtons: ctaButtonsSettings?.ctaButtons ? ctaButtonsSettings.ctaButtons.map(ctaButton => {
            return assertDefined(doppeActionCtaButtonTypeGetDefaultCtaButtonSettingsMigration({
                ctaButton: ctaButton,
                hasSourceURL: hasSourceURL
            })).ctaButton
        }) : []
    } satisfies DoppeActionCtaButtonsSettings
}

export function doppeActionCtaButtonsSettingsHasVisibleButton(ctaButtonsSettings: DoppeActionCtaButtonsSettings) {
    return ctaButtonsSettings.ctaButtons.length > 0 && ctaButtonsSettings.ctaButtons.some(ctaButton => ctaButton.isVisible);
}

export function doppeActionCtaButtonsSettingsGetVisibleButtonsCount(ctaButtons: DoppeActionCTAButton.MultipleButton[]) {
    return ctaButtons.filter(ctaButton => ctaButton.isVisible).length;
}


