import {doppeActionTypeDefineMetadata} from '../../client-server-common/doppe-action-types/doppe-action-type';
import {arrayFlatten, arrayRemoveNullValues, asArray, immerProduce, PartiallyOptional} from '@wix/devzai-utils-common';
import {WixMediaResource, wixMediaResourceReplaceExternalImage} from '@wix/devzai-common-wix';
import {
    convertCommonListSettingToDefaultDoppeActionCtaButtonSettingsFromListAction,
    DoppeActionListItemClickBehaviour,
    DoppeHideableValue,
    doppeHideableValueCreateHidden,
    doppeHideableValueIsVisibleAndNotEqualValue,
    doppeListActionGetDefaultListSettings,
    doppeListActionGetDefaultSearchListSettings,
    doppeListActionGetItemClickBehaviourDefaultListSettings,
    doppeListActionGetMainDetailsDefaultSettings,
    doppeListActionListItemGetDefaultListItemSettingsMigration,
    DoppeListActionSettings,
    doppeListActionSettingsFilterHiddenListItemsFromActionView,
    doppeListActionWithMainDetailsGetMainImage,
    doppeListActionWithMainDetailsReplaceMainImage
} from '../../client-server-common';
import {DoppeActionTypeIframe} from '../doppe-action-type-iframe/doppe-action-type-iframe';
import {RichTextContent} from '@wix/devzai-utils-react';
import {doppeActionCtaButtonsResetSettingsForLock} from '../../client-server-common/types/doppe-action-cta-button';
import {
    doppeDtoActionSettingsRemoveInnerCtaContentForLockedAction
} from '../../client-server-common/types/doppe-dto-action';
import {
    doppeActionCtaButtonTypeGetDefaultCtaButtonSettingsMigration
} from '../../client-server-common/types/doppe-action-cta-button-settings';
import {
    doppeActionCtaButtonsResolveMainURL
} from '../doppe-actions-common/doppe-cta-button-utils/doppe-cta-button-utils';
import {
    doppeActionContentSectionSettingsDefaultValuesMigration,
    DoppeActionContentSectionsSettings
} from '../../client-server-common/types/doppe-action-content-sections-settings';
import {
    DoppeActionCtaButtonsSettings,
    doppeActionCtaButtonsSettingsDefaultValuesMigration
} from '../../client-server-common/types/doppe-action-cta-buttons-settings';

export namespace DoppeActionTypeEmbedPageList {

    export interface EmbedPageSettings extends
        DoppeListActionSettings.ListItemBaseSettings,
        DoppeActionTypeIframe.ActionSettings {
        title: string;
        image: WixMediaResource[] | null;
        description: DoppeHideableValue<RichTextContent>;
    }

    export interface ActionSettings extends
        DoppeListActionSettings.WithSearchSettings,
        DoppeListActionSettings<EmbedPageSettings>,
        DoppeListActionSettings.WithMainDetails,
        DoppeListActionSettings.WithItemClickBehaviour,
        DoppeActionContentSectionsSettings,
        DoppeActionCtaButtonsSettings
    {
    }

    export interface DeprecatedFields extends Partial<DoppeListActionSettings.WithCtaButton> {
        links?: EmbedPageSettings[];
    }

    export type PartialProps = Partial<ActionSettings> & DeprecatedFields;
}


export const doppeActionTypeEmbedPageList = doppeActionTypeDefineMetadata<DoppeActionTypeEmbedPageList.ActionSettings, DoppeActionTypeEmbedPageList.PartialProps>({
    id: 'io.bymo.action/embed-page-list',
    resolveActionSettings: action => {

        const {
            listDataItems = [],
            listSearchSettings,
            ctaButton,
            ctaButtons,
            sections,
            ...restSettings
        } = action.settings;

        return {

            previewLinkButtonText: 'Continue',
            ...doppeListActionGetDefaultSearchListSettings(action.settings),
            ...doppeListActionGetDefaultListSettings({
                listDataItems: listDataItems.map(link => {
                    return {
                        ...doppeListActionListItemGetDefaultListItemSettingsMigration(link.id),
                        ...link as PartiallyOptional<DoppeActionTypeEmbedPageList.EmbedPageSettings, 'enabled'>,
                    }
                }),
            }),
            ...doppeListActionGetMainDetailsDefaultSettings(),
            ...doppeListActionGetItemClickBehaviourDefaultListSettings({
                itemClickBehaviour: DoppeActionListItemClickBehaviour.OpenLink,
            }),
            ...convertCommonListSettingToDefaultDoppeActionCtaButtonSettingsFromListAction(action.settings),
            ...doppeActionCtaButtonTypeGetDefaultCtaButtonSettingsMigration({
                ctaButton: ctaButton,
                hasSourceURL: false
            }),
            ...doppeActionContentSectionSettingsDefaultValuesMigration(action.settings),
            ...doppeActionCtaButtonsSettingsDefaultValuesMigration(action.settings, false),
            ...restSettings
        }
    },
    isWidgetOpener: true,
    canRenderInPage: true,
    skipReportingOnGoalAchievedOnActionButtonClick: true,
    isListItemLinkOpener: false,
    notSupportsRenderingPreviewItem: true,
    supportsListLayout: true,
    notSupportsListItemClickBeahviour: true,
    resolveUsedMedia: actionSettings => {
        return arrayRemoveNullValues([...arrayFlatten(actionSettings.listDataItems.map(itemSettings => {
            return itemSettings.image ?? []
        })), doppeHideableValueIsVisibleAndNotEqualValue(actionSettings.mainImage, null) ? actionSettings.mainImage : null]);
    },
    resolveMainMedia: actionSettings => {
        return doppeListActionWithMainDetailsGetMainImage(actionSettings, actionSettings.listDataItems[0]?.image?.[0] ?? null);
    },
    resolveMainLink: (actionSettings, htmlSiteInfo) => {
        return doppeActionCtaButtonsResolveMainURL(actionSettings, {
            htmlSiteInfo: htmlSiteInfo,
        }) ?? null;
    },
    prepareForViewer: (actionSettings, options) => {
        doppeDtoActionSettingsRemoveInnerCtaContentForLockedAction(actionSettings, actionSettings => {
            doppeActionCtaButtonsResetSettingsForLock(actionSettings);
        }, options);
    },
    resolveListItemsCount: actionSettings => {
        return doppeListActionSettingsFilterHiddenListItemsFromActionView(actionSettings.listDataItems).length;
    },
    resolveListItems: (action) => {
        const actionSettings = action.settings;
        return actionSettings.listDataItems.map(item => {
            return {
                url: item.iframeUrl,
                title: item.title,
                action: action,
                couponCode: doppeHideableValueCreateHidden(''),
                description: item.description,
                image: item.image?.[0] ?? null,
                itemId: item.id,
                ribbon: doppeHideableValueCreateHidden(''),
                enabled: item.enabled,
                deleted: item.deleted,
                displayInSearch: item.displayInSearch,
            }
        });
    },
    resolveListSearchSettings: actionSettings => {
        return actionSettings.listSearchSettings;
    },
    replaceExternalImages: (actionSettings, externalImagesUrlToImageResourceMap) => {
        return immerProduce(actionSettings, actionSettings => {
            for (const link of actionSettings.listDataItems) {
                if (link.image) {
                    link.image = asArray(link.image).map(
                        image => wixMediaResourceReplaceExternalImage(image, externalImagesUrlToImageResourceMap))
                }
            }

            doppeListActionWithMainDetailsReplaceMainImage(actionSettings, externalImagesUrlToImageResourceMap);
        })
    },
    hasNoLockInnerCTA: true,
});
