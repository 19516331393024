import {
    DoppeActionContentSection,
    DoppeActionContentSectionLayoutType,
    doppeActionContentSectionPartialCreateDefaultContentSettings,
    doppeActionContentSectionPartialCreateDefaultContentSettingsMigration,
    DoppeActionContentSectionType
} from './doppe-action-content-section';
import {uuidCreate} from '@wix/devzai-utils-common';


export type DoppeActionContentSectionsSettings = {
    sections: DoppeActionContentSection[];
}


export function doppeActionContentSectionsSettingsGetVisibleButtonsCount(sections: DoppeActionContentSection[]) {
    return sections.filter(section => section.isVisible).length;
}


export function doppeActionContentSectionSettingsGetDefaultSettings(type: DoppeActionContentSectionType) {

    const baseSettings = {
        isVisible: true,
        id: uuidCreate(),
        layoutSettings: {
            type: DoppeActionContentSectionLayoutType.Collapsable,
            isOpenByDefault: false
        },
        isRequired: false,
    }

    const content = doppeActionContentSectionPartialCreateDefaultContentSettings(type);

    return {
        ...baseSettings,
        content: content
    } as DoppeActionContentSection;
}


export function doppeActionContentSectionSettingsDefaultValuesMigration(sectionsSettings: Partial<DoppeActionContentSectionsSettings>) {
    return {
        sections: sectionsSettings?.sections?.map(doppeActionContentSectionDefaultValuesMigration) ?? []
    } satisfies DoppeActionContentSectionsSettings
}


export function doppeActionContentSectionDefaultValuesMigration(contentSection: DoppeActionContentSection) {
    const defaultSettings = doppeActionContentSectionSettingsGetDefaultSettings(contentSection?.content?.type ?? DoppeActionContentSectionType.RichContent);

    const {
        layoutSettings,
        content,
        ...restDefaultSettings
    } = defaultSettings

    const {
        layoutSettings: overrideLayoutSettings,
        content: overrideContent,
        ...restOverrideSettings
    } = contentSection

    return {
        ...restDefaultSettings,
        ...restOverrideSettings,
        layoutSettings: {
            ...layoutSettings,
            ...overrideLayoutSettings
        },
        content: doppeActionContentSectionPartialCreateDefaultContentSettingsMigration({
            ...content,
            ...overrideContent,
        })
    } as DoppeActionContentSection;
}
