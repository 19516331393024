import React, {useContext} from 'react';
import {assertNotNullable, iterableFindAndSelect} from '@wix/devzai-utils-common';
import {
    DoppeBusinessEntity,
    DoppeBusinessEntityData,
    DoppeBusinessEntityReference,
    DoppeDtoBymoPageOwnerData,
    DoppeDtoBymoPageType,
    DoppeDtoSpeedDialLinkViewerData,
    doppeDtoUserGetLinkedMetaSiteId, DoppeHtmlSiteInfo,
    DoppePremiumFeaturesStatus
} from '../../client-server-common';
import {WixImageResource} from '@wix/devzai-common-wix';
import {
    DoppeDtoViewerFeatures,
    DoppeDtoViewerFeaturesDefaultValues
} from '../../client-server-common/types/doppe-dto-viewer-features';
import {
    DoppeCookieBannerSettings,
    DoppeGoogleAnalyticsSettings
} from '../../client-server-common/types/doppe-dto-user-settings';
import {DoppeDtoAction} from '../../client-server-common/types/doppe-dto-action';

export interface BymoPageSpeedDialData {
    links: DoppeDtoSpeedDialLinkViewerData[];
    charsCount: number;
}

export interface BymoPageEditorContextProps {
    editorSelectedActionsIds?: DoppeDtoAction.Id[];
    editorEditedActionId?: DoppeDtoAction.Id | null;
}

export interface BymoPageContextProps extends Required<DoppeDtoViewerFeatures>, BymoPageEditorContextProps {
    targetUrl: string | null;
    targetUrlImage: WixImageResource | null;
    userDisplayName: string;
    userProfilePicture: WixImageResource | null;
    userAccountName: string;
    userPrivacyPolicyUrl?: string;
    userTermsOfServiceUrl?: string;
    userCookieBannerSettings?: DoppeCookieBannerSettings;
    userGoogleAnalyticsSettings?: DoppeGoogleAnalyticsSettings;
    speedDialData?: BymoPageSpeedDialData;
    linkedMetaSiteId: string | null;
    businessEntitiesData?: DoppeBusinessEntityData[];
    premiumFeaturesStatus: DoppePremiumFeaturesStatus | null;
    htmlSiteInfo: DoppeHtmlSiteInfo | null;
}

export function bymoPageContextPropsResolveFromPageOwnerData (
    pageOwnerData: DoppeDtoBymoPageOwnerData,
    pageType: DoppeDtoBymoPageType
) : BymoPageContextProps {
    return bymoPageContextPropsExtendDefaultValues({
        userDisplayName: pageOwnerData.displayName,
        userTermsOfServiceUrl: pageOwnerData.userSettings.termsOfServiceUrl,
        userPrivacyPolicyUrl: pageOwnerData.userSettings.privacyPolicyUrl,
        userCookieBannerSettings: pageOwnerData.userSettings.cookieBannerSettings,
        userProfilePicture: pageOwnerData.profilePicture,
        userAccountName: pageOwnerData.subdomain,
        viewerHideFooterBanner: pageOwnerData.userSettings.viewerHideFooterBanner,
        linkedMetaSiteId: doppeDtoUserGetLinkedMetaSiteId(pageOwnerData)
    }, pageType === DoppeDtoBymoPageType.PreRoll)
}

export function bymoPageContextPropsAdjustByPageType (
    bymoPageContextProps: BymoPageContextProps,
    pageType: DoppeDtoBymoPageType
) : BymoPageContextProps {
    return {
        ...bymoPageContextProps,
        targetUrl: pageType === DoppeDtoBymoPageType.PreRoll ? 'https://www.example.com' : null,
    }
}

export function bymoPageContextPropsExtendDefaultValues (
    partialContextProps: Partial<BymoPageContextProps> = {},
    withTargetUrl = true
) : BymoPageContextProps {
    return {
        userAccountName: 'user',
        userProfilePicture: null,
        userDisplayName: 'John Doe',
        targetUrlImage: null,
        linkedMetaSiteId: null,
        targetUrl: withTargetUrl ? 'https://hopp.co' : null,
        premiumFeaturesStatus: null,
        htmlSiteInfo: null,
        ...DoppeDtoViewerFeaturesDefaultValues,
        ...partialContextProps
    }
}

export const BymoPageContextPropsContext = React.createContext<BymoPageContextProps | null>(null);

export function useBymoPageContextProps () {
    return assertNotNullable(useContext((BymoPageContextPropsContext)));
}

export function bymoPageContextPropsResolveBusinessEntity<E extends DoppeBusinessEntity> (
    bymoPageContextProps: BymoPageContextProps,
    businessEntityReference: DoppeBusinessEntityReference<E>
) {
    const entityType = businessEntityReference.businessEntity;
    const entityId = businessEntityReference.id;

    return iterableFindAndSelect(bymoPageContextProps.businessEntitiesData ?? [], (businessEntity, skip) => {
        return businessEntity.type === entityType && businessEntity.id === entityId ? businessEntity.dto : skip;
    }) as DoppeBusinessEntityData.ResolveDtoFromType<E> | undefined
}