import {doppeActionTypeDefineViewerMetadata} from '../../client-server-common/doppe-action-types/doppe-action-type';
import React from 'react';
import {doppeActionTypeMediaGallery} from './doppe-action-type-media-gallery';

export const doppeActionTypeMediaGalleryViewer = doppeActionTypeDefineViewerMetadata(doppeActionTypeMediaGallery, {

    loadViewerComponents: async () => {
        return {
            DoppeActionTypeMediaGalleryWidget: (await import('./doppe-action-type-media-gallery-widget/doppe-action-type-media-gallery-widget')).DoppeActionTypeMediaGalleryWidget
        }
    },

    renderWidget: (renderProps, viewerComponents) => {

        const {
            DoppeActionTypeMediaGalleryWidget
        } = viewerComponents;

        return <DoppeActionTypeMediaGalleryWidget {...renderProps} />;
    }
})