import {doppeActionTypeDefineMetadata} from '../../client-server-common/doppe-action-types/doppe-action-type';
import {
    DoppeHideableValue,
    doppeHideableValueCreateHidden,
    doppeHideableValueGetVisibleValue,
    doppeHideableValueIsVisibleAndNotEqualValue
} from '../../client-server-common/types/doppe-hideable-value';
import {WixMediaResource, wixMediaResourceReplaceExternalImage} from '@wix/devzai-common-wix';
import {arrayRemoveNullValues, immerProduce} from '@wix/devzai-utils-common';
import {
    DoppeExternalCouponProvider,
    doppeExternalCouponProviderResolveBusinessEntityReference
} from '../../client-server-common/types/doppe-external-coupon';
import {DoppeDtoCoupon} from '../../client-server-common/types/doppe-dto-coupon';
import {RichTextContent} from '@wix/devzai-utils-react';
import {
    DoppeActionCtaButtonSettings,
    doppeActionCtaButtonTypeGetDefaultCtaButtonSettingsMigration,
    getDefaultDoppeActionCtaButtonSettings
} from '../../client-server-common/types/doppe-action-cta-button-settings';
import {doppeActionCtaButtonsResetSettingsForLock} from '../../client-server-common/types/doppe-action-cta-button';
import {DoppeActionTypeCouponViewerLang} from './doppe-action-type-coupon-viewer.lang';
import {
    doppeDtoActionSettingsRemoveInnerCtaContentForLockedAction
} from '../../client-server-common/types/doppe-dto-action';
import {
    doppeActionCtaButtonsResolveMainURL
} from '../doppe-actions-common/doppe-cta-button-utils/doppe-cta-button-utils';
import {
    doppeActionContentSectionSettingsDefaultValuesMigration,
    DoppeActionContentSectionsSettings
} from '../../client-server-common/types/doppe-action-content-sections-settings';
import {
    DoppeActionCtaButtonsSettings,
    doppeActionCtaButtonsSettingsDefaultValuesMigration
} from '../../client-server-common/types/doppe-action-cta-buttons-settings';

export namespace DoppeActionTypeCoupon {

    export interface CouponSettings  {
        coupon: string;
        title: DoppeHideableValue<string>;
        description: DoppeHideableValue<RichTextContent>;
        couponImage: WixMediaResource | null;
        couponProvider: DoppeExternalCouponProvider | null;
        couponProviderId: string | null;
        ribbon: DoppeHideableValue<string>;
    }

    export interface ActionSettings extends
        DoppeActionContentSectionsSettings,
        DoppeActionCtaButtonsSettings,
        DoppeActionTypeCoupon.CouponSettings {

    }

    export type DeprecatedFields = Partial<DoppeActionCtaButtonSettings> & {
        couponButtonText?: DoppeHideableValue<string>;
        showCouponButtonLink?: boolean;
        couponPageLink?: string;
    }

    export type PartialProps = Partial<ActionSettings> & DeprecatedFields;
}

export const doppeActionTypeCoupon = doppeActionTypeDefineMetadata<DoppeActionTypeCoupon.ActionSettings, DoppeActionTypeCoupon.PartialProps>({
    id: 'co.hopp.action/coupon',
    resolveActionSettings: action => {

        const {
            showCouponButtonLink = true,
            couponButtonText = DoppeActionTypeCouponViewerLang.defaultTexts.couponButtonText,
            couponPageLink = '',
            ctaButton,
            ctaButtons,
            sections,
            ...restSettings
        } = action.settings;

        return {
            coupon: 'Coupon code',
            couponButtonText: couponButtonText,
            description: 'Describe the coupon you want to give',
            couponImage: null,
            couponPageLink: 'www.coupon-website-url.com',
            title: 'Coupon title',
            showCouponButtonLink: showCouponButtonLink,
            couponProvider: null,
            couponProviderId: null,
            ribbon: doppeHideableValueCreateHidden('SALE'),
            ...getDefaultDoppeActionCtaButtonSettings({
                buttonText: doppeHideableValueGetVisibleValue(couponButtonText, DoppeActionTypeCouponViewerLang.defaultTexts.couponButtonText),
                showButton: showCouponButtonLink ?? false,
                url: couponPageLink
            }),
            ...doppeActionCtaButtonTypeGetDefaultCtaButtonSettingsMigration({
                hasSourceURL: false,
                ctaButton: ctaButton,
            }),
            ...doppeActionContentSectionSettingsDefaultValuesMigration(action.settings),
            ...doppeActionCtaButtonsSettingsDefaultValuesMigration(action.settings, true),
            ...restSettings,
        }
    },
    isWidgetOpener: true,
    skipReportingOnGoalAchievedOnActionButtonClick: true,
    canRenderInPage: true,
    resolveUsedMedia: actionSettings => {
        return arrayRemoveNullValues([actionSettings.couponImage]);
    },
    resolveMainMedia: actionSettings => {
        return actionSettings.couponImage;
    },
    resolveCouponData: actionSettings => {
        return {
            showCouponCode: true,
            couponCode: actionSettings.coupon,
            couponDescription: doppeHideableValueIsVisibleAndNotEqualValue(actionSettings.description, '') ? actionSettings.description : '',
            couponTitle: doppeHideableValueIsVisibleAndNotEqualValue(actionSettings.title, '') ? actionSettings.title : '',
        }
    },
    resolveMainLink: (actionSettings, htmlSiteInfo) => {
        return doppeActionCtaButtonsResolveMainURL(actionSettings, {
            htmlSiteInfo: htmlSiteInfo,
        }) ?? null;
    },
    prepareForViewer: (actionSettings, options) => {
        doppeDtoActionSettingsRemoveInnerCtaContentForLockedAction(actionSettings, actionSettings => {
            doppeActionCtaButtonsResetSettingsForLock(actionSettings);
        }, options);
    },
    resolveUsedBusinessEntities: actionSettings => {
        return arrayRemoveNullValues([
            actionSettings.couponProvider !== null && actionSettings.couponProviderId !== null ?
                doppeExternalCouponProviderResolveBusinessEntityReference(
                    actionSettings.couponProvider,
                    actionSettings.couponProviderId
                ) :
                null
        ])
    },
    replaceExternalImages: (actionSettings, externalImagesUrlToImageResourceMap) => {
        return immerProduce(actionSettings, actionSettings => {
            if (actionSettings.couponImage) {
                actionSettings.couponImage =
                    wixMediaResourceReplaceExternalImage(actionSettings.couponImage, externalImagesUrlToImageResourceMap)
            }
        })
    },
    hasNoLockInnerCTA: true,

})

export function doppeActionTypeCouponResolveSettingsFromBoundEntity(
    actionSettings: DoppeActionTypeCoupon.CouponSettings,
    doppeDtoCoupon: DoppeDtoCoupon
): DoppeActionTypeCoupon.CouponSettings {
    return {
        ...actionSettings,
        coupon: doppeDtoCoupon.couponCode ?? actionSettings.coupon
    }
}