import {arrayRemoveNullValues, AutoValue, autoValueCreate, immerProduce, Values} from '@wix/devzai-utils-common';
import {doppeActionTypeDefineMetadata} from '../../client-server-common/doppe-action-types/doppe-action-type';
import {DoppeDtoVideo} from '../../client-server-common/types/doppe-dto-video';
import {
    DoppeExternalVideoProvider,
    doppeExternalVideoProviderResolveBusinessEntityReference
} from '../../client-server-common';
import {WixMediaResource, wixMediaResourceReplaceExternalImage, WixVideoResource} from '@wix/devzai-common-wix';


export const DoppeLockActionTypeVideoMinTimeToUnlockValue = {
    None: 'None',
    FullVideo: 'FullVideo',
} as const;

export type DoppeLockActionTypeVideoMinTimeToUnlockValue = Values<typeof DoppeLockActionTypeVideoMinTimeToUnlockValue>;

// TODO: Shouldn't it be "AutoValue<...> | number" instead of "AutoValue<...> | string"?
export type DoppeLockActionTypeVideoMinTimeToUnlock = AutoValue<DoppeLockActionTypeVideoMinTimeToUnlockValue> | number;

export namespace DoppeLockActionTypeVideo {


    export interface ActionSettings {
        videoFile: WixVideoResource | null;
        videoPlayerSettings: {
            autoplay: boolean;
            muteVideo: boolean;
            showPlayerControls: boolean;
        },
        minTimeToUnlock: DoppeLockActionTypeVideoMinTimeToUnlock,
        videoImage: WixMediaResource | null;
        videoProvider: DoppeExternalVideoProvider | null,
        videoProviderId: string | null,
    }
}

export const doppeLockActionTypeVideo = doppeActionTypeDefineMetadata<DoppeLockActionTypeVideo.ActionSettings>({
    id: 'io.bymo.lockAction/video-lock',
    resolveActionSettings: action => {
        return {
            videoFile: null,
            videoPlayerSettings: {
                autoplay: true,
                muteVideo: false,
                showPlayerControls: false,
            },
            minTimeToUnlock: autoValueCreate(DoppeLockActionTypeVideoMinTimeToUnlockValue.None),
            videoImage: null,
            videoProvider: null,
            videoProviderId: null,
            ...action.settings
        }
    },
    resolveUsedBusinessEntities: actionSettings => {
        return arrayRemoveNullValues([
            actionSettings.videoProvider !== null && actionSettings.videoProviderId !== null ?
                doppeExternalVideoProviderResolveBusinessEntityReference(
                    actionSettings.videoProvider,
                    actionSettings.videoProviderId
                ) :
                null
        ])
    },
    resolveUsedMedia: actionSettings => {
        return actionSettings.videoImage ? [actionSettings.videoImage] : [];
    },
    resolveMainMedia: actionSettings => {
        return actionSettings.videoImage ?? null;
    },
    replaceExternalImages: (actionSettings, externalImagesUrlToImageResourceMap) => {
        return immerProduce(actionSettings, actionSettings => {
            if (actionSettings.videoImage) {
                actionSettings.videoImage = wixMediaResourceReplaceExternalImage(actionSettings.videoImage, externalImagesUrlToImageResourceMap)
            }
        })
    },
    isWidgetOpener: true,
    isLinkOpener: false,
    canRenderInPage: true,
})

export function doppeActionTypeVideoResolveSettingsFromBoundEntity(
    actionSettings: DoppeLockActionTypeVideo.ActionSettings,
    _doppeDtoCoupon: DoppeDtoVideo
): DoppeLockActionTypeVideo.ActionSettings {
    return actionSettings
}
